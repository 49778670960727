import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RequireAuthentication from '../../../../authentication/RequireAuthentication';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import { setMetaverseDetails, setMetaverseId } from '../../../../redux/metaverse';
import useAccessMetaverse from '../../hooks/useAccessMetaverse';
import MetaverseLandingPage from './MetaverseLandingPage';

const MetaverseLandingPageAuthContainer = () => {
  const { metaverse, error } = useAccessMetaverse();
  const navigate = useNavigate();

  const { user } = usePxAuthContext();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    document.title = metaverse?.name || 'Pixel Canvas - Metaverse';

    if (!metaverse) return;

    dispatch(setMetaverseId(metaverse._id));
    dispatch(setMetaverseDetails(metaverse));
  }, [metaverse]);

  useEffect(() => {
    if (!error?.response?.status) return;
    const { status } = error.response;
    if (status === 401 || status === 403) {
      if (user) {
        navigate('/noaccess');
      } else {
        setLogin(true);
      }
    }
  }, [error]);

  useEffect(() => {
    if (!user) return;
    setLogin(false);
  }, [user]);

  if (!user && login) {
    return <RequireAuthentication />;
  }

  return <MetaverseLandingPage />;
};

export default MetaverseLandingPageAuthContainer;
