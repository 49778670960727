import { Button } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import logo from '../../../../assets/images/pixel-canvas-logo.png';
import heroImage from './hero-image.png';

import styles from './CheckoutPage.module.scss';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'src/static/strings';

const clientSuggestions = ['INNOVATION', 'IMMERSION', 'AGENCIES', 'PRODUCT DEMOS', 'SKOS'];

export default function CheckoutPage() {
  const { plan } = useParams<{ plan: string }>();
  const [searchParams] = useSearchParams();
  const { text } = useTypewriterEffect(clientSuggestions);
  const navigate = useNavigate();

  const handleClick = () => {
    const url = !searchParams.toString()
      ? `/checkout/${plan}/next`
      : `/checkout/${plan}/next?${searchParams.toString()}`;
    navigate(url);
  }

  return (
    <div className={styles.page}>
      <div className={styles.background} />
      <div className={styles.topGradient} />
      <img className={styles.logo} src={logo} alt="logo" />
      <div className={styles.bottomGradient} />
      <div className={styles.content}>
        <p className={styles.title}>A Metaverse Made For</p>
        <p className={styles.banner}>{text}</p>
        <Button className={styles.button} onClick={handleClick}>Access Now</Button>
      </div>
      <img className={styles.heroImage} src={heroImage} alt="hero-image" />
      <div className={styles.footer}>
        <div className={styles.legal}>
          <a className={styles.link} href={TERMS_OF_USE_LINK}>Terms &amp; Conditions</a>
          <a className={styles.link} href={PRIVACY_POLICY_LINK}>Privacy Policy</a>
        </div>
        <p>@ Pixel Canvas 2023</p>
      </div>
    </div>
  );
}

function useTypewriterEffect(values: string[]) {
  const [valueIndex, setValueIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);

  const suggestionText = values[valueIndex];
  const text = suggestionText.slice(0, textIndex);

  useEffect(() => {
    if (textIndex >= suggestionText.length) {
      const timeout = setTimeout(() => {
        setTextIndex(1);
        setValueIndex((i) => i + 1 >= values.length ? 0 : i + 1);
      }, 1500);
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setTextIndex((i) => i >= suggestionText.length ? suggestionText.length - 1 : i + 1);
      }, 75);
      return () => clearTimeout(timeout);
    }
  }, [textIndex, suggestionText]);

  return { text };
}
