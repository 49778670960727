import clsx from 'clsx';
import styles from './ButtonGroup.module.scss';

type ButtonGroupType = 'wide' | 'icon';

export interface ButtonGroupProps {
  className?: string;
  type: ButtonGroupType;
  children?: React.ReactNode;
}

export default function ButtonGroup({ className, type, children }: ButtonGroupProps) {
  return (
    <div className={clsx(type === 'wide' ? styles.wide : styles['small-buttons'], className)}>
      {children}
    </div>
  );
}
