import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, ConfirmationModal, SortBy } from '@pixelcanvas/ui';

import { selectMetaverseId } from 'src/redux/metaverse';
import { useToastMessageContext } from '../../../../components/ToastMessage/ToastMessageContextProvider';
import SearchBox from '../SearchBox/SearchBox';
import { deleteAllowedEmailDomain, getAllowedEmailDomains, putAllowedEmailDomain } from '../../../../services/MetaverseService';
import PlatformSpinner from '../../../../components/PlatformSpinner/PlatformSpinner';
import DomainDetails from './DomainDetails/DomainDetails';
import AddDomainModal from './AddDomainModal/AddDomainModal';
import { ReactComponent as PlusCircle } from '../../../../assets/svg/plus-circle.svg';

import styles from './DomainWhitelist.module.scss';

const sortOptions = [
  'Alphabetical: A to Z',
  'Alphabetical: Z to A',
  'Date & Time Added: Newest to Oldest',
  'Date & Time Added: Oldest to Newest',
  'Added by: Alphabetical',
];

export default function DomainWhitelist() {
  const { addToast } = useToastMessageContext();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [emailDomains, setEmailDomains] = useState<any[]>([]);
  const [confirmDeleteDomain, setConfirmDeleteDomain] = useState('');
  const [isAddModalOpen, setIsAddModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [, setError] = useState<any>();

  const updateEmailDomains = async () => {
    try {
      setEmailDomains(await getAllowedEmailDomains(metaverseId));
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteEmailDomain = async () => {
    try {
      setIsLoading(true);
      setConfirmDeleteDomain('');
      await deleteAllowedEmailDomain(metaverseId, confirmDeleteDomain);
      await updateEmailDomains();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  // No try-catch because we're handling error within AddDomainModal.tsx
  const onAddEmailDomain = async (domain: string) => {
    await putAllowedEmailDomain(metaverseId, domain);
    await updateEmailDomains();
    addToast('Your changes were successfully saved.');
  };

  useEffect(() => {
    updateEmailDomains();
  }, [metaverseId]);

  const accessMessage = emailDomains?.length > 0
    ? 'This metaverse is only accessible by the following domain(s)'
    : 'This metaverse is accessible to all domains';

  return (
    <div className={styles['domain-whitelist-container']}>
      <PlatformSpinner visible={isLoading} />
      <p className={styles['page-description']}>Here is where you can control who can access your metaverse. If you leave this list empty, your metaverse will be open to the public and anyone can register to attend.<br />If you enter domains to whitelist, only registrants with emails from the specified domains will be able to register for and attend your metaverse.</p>
      <div className={styles['header-controls']}>
        <SearchBox className={styles.search} onChange={(e) => setSearchTerm(e.target.value)} />
        <div className={styles['secondary-controls']}>
          <SortBy options={sortOptions} onChange={(option) => setSortOption(option)} />
          <Button color="secondary" disabled={isLoading} onClick={() => setIsAddModelOpen(true)}>
            <PlusCircle className={styles['action-icon']} />
            Add New
          </Button>
        </div>
      </div>
      {
        !isLoading &&
        <>
          <div className={styles['domain-whitelist-content-header-section']}>
            <div className={styles['domain-whitelist-content-header']}>
              <h2>{`${emailDomains?.length || 0} Whitelisted Domain(s)`}</h2>
              <p>{accessMessage}</p>
            </div>
          </div>
          <DomainDetails
            domains={emailDomains}
            onDelete={(domain) => setConfirmDeleteDomain(domain)}
            searchTerm={searchTerm}
            sortOption={sortOption}
          />
        </>
      }
      <AddDomainModal
        open={isAddModalOpen}
        onClose={() => setIsAddModelOpen(false)}
        onAdd={onAddEmailDomain}
      />
      <ConfirmationModal
        title={`ARE YOU SURE YOU WANT TO REMOVE: ${confirmDeleteDomain}`}
        open={!!confirmDeleteDomain}
        description="This cannot be undone. If you wish to re-allow any removed domains, you will have to re-add it."
        onClose={() => setConfirmDeleteDomain('')}
        onConfirm={() => onDeleteEmailDomain()}
        confirm="Delete"
      />
    </div>
  );
}
