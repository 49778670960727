import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Button from 'src/features/Visitor/components/Button/Button';
import landingBg from '../../../../assets/images/landing-bg.png';
import heroImage from '../../../../assets/images/hero-image.png';
import PxFooter from '../../../../components/PxFooter/PxFooter';
import styles from './EmailVerifiedPage.module.scss';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';

export function EmailVerifiedPage() {
  const { user } = usePxAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Pixel Canvas - Email Verified';
  }, []);

  const message =
    user?.email_verified
      ? 'Email verified!'
      : 'Invalid verification link!';

  return (
    <>
      <div className={styles.bg} style={{ backgroundImage: `url(${landingBg})` }} />
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.title}>
            {message}
          </div>
          <Button
            className={clsx(styles['platform-button'], styles.button)}
            color="primary"
            onClick={() => navigate('/')}
          >
            Go To Dashboard
          </Button>
        </div>
        <img className={styles.heroImage} src={heroImage} alt="hero" />
      </div>
      <PxFooter />
    </>
  );
}
