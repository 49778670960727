import { useDispatch, useSelector } from 'react-redux';

import { selectError, setError } from '../../redux/metaverseError';
import Button from '../Button';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';
import VisitorModalTitle from '../VisitorModalTitle/VisitorModalTitle';

import styles from './VisitorErrorModal.module.scss';

export default function VisitorErrorModal() {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const handleResetError = () => dispatch(setError(null));
  return (
    <VisitorModal className={styles.modal} open={!!error} onClose={handleResetError}>
      <VisitorModalTitle>Error</VisitorModalTitle>
      <VisitorModalContent>
        {error?.message ? error.message : 'An error has occurred'}
      </VisitorModalContent>
      <VisitorModalActions>
        <Button color="primary" onClick={handleResetError}>OK</Button>
      </VisitorModalActions>
    </VisitorModal>
  );
}
