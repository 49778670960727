import { PIXELCANVAS_COM_LINK, PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'src/static/strings';
import styles from './PxFooter.module.scss';

export default function PxFooter() {
  const termsText = 'Terms & Conditions';

  const getYear = () => new Date().getFullYear();
  const openTermsAndConditions = () => window.open(TERMS_OF_USE_LINK, '_blank')?.focus();
  const openPrivacyPolicy = () => window.open(PRIVACY_POLICY_LINK, '_blank')?.focus();
  const openPixelCanvasCom = () => window.open(PIXELCANVAS_COM_LINK, '_blank')?.focus();

  return (
    <div className={styles['landing-footer']}>
      <div className={styles['terms-conditions']} onClick={openTermsAndConditions}>
        {termsText}
      </div>
      <div className={styles['terms-conditions']} onClick={openPrivacyPolicy}>
        Privacy Policy
      </div>
      <div className={styles['terms-conditions']} onClick={openPixelCanvasCom}>
        pixelcanvas.com
      </div>
      <div className={styles['pixel-canvas-year']}>
        @ Pixel Canvas, Inc {getYear()}
      </div>
    </div>
  );
}
