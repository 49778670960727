import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'src/static/strings';
import styles from './Footer.module.scss';

export default function Footer() {
  const openTermsAndConditions = () => window.open(TERMS_OF_USE_LINK, '_blank')?.focus();
  const openPrivacyPolicy = () => window.open(PRIVACY_POLICY_LINK, '_blank')?.focus();

  return (
    <div className={styles.footer}>
      <div className={styles.link} onClick={openTermsAndConditions}>
        Terms &amp; Conditions
      </div>
      <div className={styles.link} onClick={openPrivacyPolicy}>
        Privacy Policy
      </div>
    </div>
  );
}
