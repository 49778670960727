import { Card, Typography } from '@pixelcanvas/ui';

import ISpace from 'src/features/Dashboard/interfaces/ISpace';

import { ReactComponent as ClockIcon } from '../../../../../../assets/svg/clock-solid.svg';
import { ReactComponent as GameIcon } from '../../../../../../assets/svg/game.svg';
import { ReactComponent as MapLinkIcon } from '../../../../../../assets/svg/map-link.svg';

import styles from './SpaceCard.module.scss';

type Props = {
  space: ISpace;
  userId: string;
  profile?: string;
  hoursUsed?: number;
  availableGames?: number;
  linkedMaps?: number;
};

export default function SpaceCard({
  space,
  userId,
  profile,
  hoursUsed = 0,
  availableGames = 0,
  linkedMaps = 0,
}: Props) {
  const ownershipText = userId === space.ownerOf ? 'Model Owned by You' : `Model Owned by ${space.ownerOf}`;
  return (
    <Card>
      <Card.Image className={styles.image} src={space.image}>
        <div className={styles.imageHover} />
        <div className={styles.upperLeft}>
          <Typography variant="h4">{space.tier}</Typography>
        </div>
        <div className={styles.lowerLeft}>
          <ClockIcon className={styles.icon} />
          <div className={styles.hours}>
            <Typography variant="h4">{hoursUsed.toFixed(1)}</Typography>
            <Typography variant="h7">HRS</Typography>
          </div>
        </div>
        <div className={styles.lowerRight}>
          <div className={styles.availableGames}>
            <GameIcon className={styles.icon} />
            <Typography variant="h4">{availableGames}</Typography>
          </div>
          <div className={styles.linkedMaps}>
            <MapLinkIcon className={styles.icon} />
            <Typography variant="h4">{linkedMaps}</Typography>
          </div>
        </div>
      </Card.Image>
      <Card.Content className={styles.content}>
        <Typography className={styles.name} variant="h2" emphasis="high">{space.name}</Typography>
        <div className={styles.owner}>
          {profile && <img className={styles.profile} src={profile} alt="profile" />}
          <Typography variant="body2">{ownershipText}</Typography>
        </div>
      </Card.Content>
    </Card>
  );
}
