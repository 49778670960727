import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TabPosition } from '@pixelcanvas/ui';
import type { RootState } from '../../../store';

const initialState: TabPosition = {
  width: 0,
  offset: 0,
};

export const customizeTabNavSlice = createSlice({
  name: 'customizeTabNav',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<TabPosition>) => {
      state.width = action.payload.width;
      state.offset = action.payload.offset;
    },
  },
});

export const { setPosition } = customizeTabNavSlice.actions;

export const selectPosition = (state: RootState) => state.customizeTabNav;

export default customizeTabNavSlice.reducer;
