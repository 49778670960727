import { Typography, ActionModal, Button, Theme } from '@pixelcanvas/ui';
import styles from './PlatformUsageWarningModal.module.scss';

type Props = {
  isOwner: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  spaceName?: string;
};

const REQUEST_TIME_URL = window.env.REACT_APP_SUPPORT_MAILTO_LINK ?? 'mailto:support@pixelcanvas.com';

export default function PlatformUsageWarningModal({
  isOwner, open, onClose, onConfirm, spaceName,
}: Props) {
  const message = isOwner ? 'Your account has run out of 3D streaming time.'
    : `The owner of the space “${spaceName}" has run out of 3D streaming time.`;

  return (
    <ActionModal
      className={styles.modal}
      open={open}
      onClose={onClose}
    >
      <ActionModal.Title>
        OUT OF 3D STREAMING TIME
      </ActionModal.Title>
      <ActionModal.Content>
        <Typography variant="subtitle2" className={styles.subtitle}>
          {message} You and your metaverse visitors are not able to access the 3D platform without additional 3D streaming time.
          <br /><br />To request more, or if you have any other questions,
          please contact <a className={styles.link} target="_blank" rel="noopener noreferrer" href={REQUEST_TIME_URL}>support@pixelcanvas.com</a>
        </Typography>
      </ActionModal.Content>
      <ActionModal.Actions>
        <Button color="tertiary" onClick={onClose}>Close</Button>
        <Button theme={Theme.Platform} onClick={onConfirm}>Request More Time</Button>
      </ActionModal.Actions>
    </ActionModal>
  );
}
