import React from 'react';
import styles from './SidebarButton.module.scss';
import clsx from 'clsx';
import { isBright } from '../../../../utils/HexCodeUtil';

interface SidebarButtonProps {
  className?: string;
  logo: React.ReactNode;
  hoverLogo: React.ReactNode;
  hoverColor?: string;
  text: string;
  hoverTextColor?: string;
  tabColor?: string;
  activeColor: string;
  selected: boolean;
  onClick: Function;
  overlayTabSelected?: boolean;
  navBarColor: string;
}

interface SidebarButtonState {
  hovering: boolean;
}

class SidebarButton extends React.Component<SidebarButtonProps, SidebarButtonState> {
  state = {
  hovering: false,
  };

  onMouseEnter() {
    if (!this.state.hovering) {
      this.setState({ hovering: true });
    }
  }

  onMouseExit() {
    if (this.state.hovering) {
      this.setState({ hovering: false });
    }
  }

  checkActiveAndBright(selected: boolean, color: string) {
    if (selected) {
      if (isBright(color)) {
        return true;
      }
    } else {
      return false;
    }
  }

  render() {
    const {
      className, logo, hoverLogo, hoverColor, hoverTextColor, text, tabColor, activeColor, selected, overlayTabSelected, onClick, navBarColor,
    } = this.props;

    return (
      <div
        className={clsx(styles.button, className, { [styles.selected]: overlayTabSelected, [styles.dark]: isBright(navBarColor) })}
        style={this.state.hovering ? { background: hoverColor, color: hoverTextColor } : { }}
        onMouseEnter={() => this.onMouseEnter()}
        onMouseLeave={() => this.onMouseExit()}
        onClick={() => onClick()}
      >
        <div className={styles.content}>
          {
            logo &&
            <div className={styles.logo} style={overlayTabSelected ? { background: activeColor } : {}}>
              {
                !this.state.hovering
                  ?
                    <>
                      {logo}
                    </>
                  :
                    <>
                      {hoverLogo}
                    </>
              }
            </div>
          }
          {text}
        </div>
      </div>
    );
  }
}

export default SidebarButton;
