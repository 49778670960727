import { Button, InputFieldsGroup, MainContent, Page, PageContent, SubNavAddButton, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, TextInputField, TooltipButton, Typography } from '@pixelcanvas/ui';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import CheckoutHeader from 'src/features/Dashboard/components/CheckoutHeader/CheckoutHeader';
import OrganizationSelect from 'src/features/Dashboard/components/OrganizationSelect/OrganizationSelect';
import { OrganizationResponse } from 'src/interfaces/IOrganization';
import { useCheckout } from 'src/queries/checkout';

import styles from './CheckoutSelectOrganizationPage.module.scss';

export default function CheckoutSelectOrganizationPage() {
  const { plan } = useParams<{ plan: string }>();
  const [searchParams] = useSearchParams();
  const { user } = usePxAuthContext();

  const { mutate: checkout, data, error, isLoading } = useCheckout();
  const [selectedPlan, setSelectedPlan] = useState<string>('lite');
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationResponse | null>(null);
  const [ownedOrganizations, setOwnedOrganizations] = useState<OrganizationResponse[]>([]);

  const [openNewModal, setOpenNewModal] = useState(false);

  const referral = searchParams.get('referral') ?? undefined;

  useEffect(() => {
    if (!plan) return;
    setSelectedPlan(plan);
    checkout({ plan, referral });
  }, [plan]);

  useEffect(() => {
    if (!data?.url) return;
    window.location.href = data.url;
  }, [data?.url]);

  useEffect(() => {
    if (!axios.isAxiosError(error) || !error.response?.data.organizations) return;
    setOwnedOrganizations(error.response.data.organizations);
  }, [error]);

  const handleCheckoutWithOrganization = () => {
    if (!selectedOrganization) return;
    checkout({
      plan: selectedPlan,
      organizationId: selectedOrganization._id,
      referral,
    });
  }

  const handleCheckoutWithName = (name: string) => {
    checkout({ plan: selectedPlan, referral, name });
  }

  if (!axios.isAxiosError(error) || !error.response?.data.organizations || isLoading) {
    return (
      <Page>
        <PageContent>
          <PlatformSpinner fixed visible />
        </PageContent>
      </Page>
    );
  }

  return (
    <Page>
      <PageContent>
        <CheckoutHeader />
        <MainContent classes={{ main: styles.main, content: styles.content }}>
          <Typography variant="h1" className={styles.title}>Welcome Back, {user?.displayName}</Typography>
          <div className={styles.withTooltip}>
            <Typography variant="subtitle1" className={styles.subtitle}>Select an Organization or Create a New One to Upgrade</Typography>
            <TooltipButton className={styles.tooltip}>Only organizations owned by you can be upgraded.<br />Contact the owner for assistance with other organizations.</TooltipButton>
          </div>
          <div className={styles.organizations}>
            {
              !!ownedOrganizations && (
                ownedOrganizations.map((organization) => (
                  <OrganizationSelect
                    className={styles.organization}
                    key={organization._id}
                    organization={organization}
                    onClick={() => setSelectedOrganization(organization)}
                    selected={selectedOrganization?._id === organization._id}
                  />
                ))
              )
            }
            <SubNavAddButton
              className={styles.add}
              label="Add New"
              onClick={() => setOpenNewModal(true)}
            />
          </div>
          <Footer>
            <Button className={styles.button} color="primary" disabled={!selectedOrganization} onClick={handleCheckoutWithOrganization}>
              Proceed to Checkout
            </Button>
          </Footer>
        </MainContent>
      </PageContent>
      <NameYourNewOrganizationModal open={openNewModal} onClose={() => setOpenNewModal(false)} onCheckout={handleCheckoutWithName} />
    </Page>
  );
}

interface FooterProps {
  children: React.ReactNode;
}

function Footer({ children }: FooterProps) {
  return (
    <div className={styles.footer}>
      {children}
    </div>
  );
}

interface NameYourNewOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  onCheckout: (name: string) => void;
}

function NameYourNewOrganizationModal({ open, onClose, onCheckout }: NameYourNewOrganizationModalProps) {
  const [name, setName] = useState('');
  const handleCheckout = () => onCheckout(name);
  return (
    <TaskModal className={styles.new} open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>NAME YOUR NEW ORGANIZATION</TaskModalTitle>
      <TaskModalContent>
        <InputFieldsGroup>
          <TextInputField
            label="ORGANIZATION NAME"
            required
            placeholder="Enter organization name"
            maxLength={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputFieldsGroup>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="primary" onClick={handleCheckout} disabled={name.length === 0}>
          Proceed to Checkout
        </Button>
      </TaskModalActions>
    </TaskModal>
  )
}
