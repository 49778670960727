import { useState, useEffect } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Check } from '../../../../assets/svg/check.svg';
import styles from './Dropdown.module.scss';

interface Props {
  options: Array<string | number>;
  onOpenChanged?: Function;
  onValueChanged?: Function;
  multiSelect?: boolean;
  children: JSX.Element;
}

export default function DropdownOptions({
  options,
  onOpenChanged,
  onValueChanged,
  multiSelect,
  children,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    onOpenChanged?.(isOpen);
  }, [isOpen]);

  function optionsClicked(e: any) {
    e.stopPropagation();
    if (!isOpen) {
      setOpen(true);
    } else if (!multiSelect) {
      setOpen(!isOpen);
    }
  }

  function multiOptionSelected(value: string) {
    const newSet = new Set(selectedOptions);
    if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
    setSelectedOptions(newSet);
    onValueChanged?.(newSet);
  }

  return (
    <div className={styles['dropdown-options']} onClick={(e) => optionsClicked(e)}>
      {children}
      <div className={styles['select-options-container']}>
        {isOpen && (
          <ClickAwayListener
            disableReactTree
            onClickAway={() => {
              if (isOpen) setOpen(false);
            }}
          >
            <div key={JSON.stringify(options)} className={styles['select-options']}>
              {multiSelect
                ? options.map((value) => (
                  <div
                    key={value}
                    className={styles.option}
                    onClick={() => multiOptionSelected(value as string)}
                  >
                    <Check
                      className={clsx(styles['check-box'], {
                        checked: selectedOptions.has(value as string),
                      })}
                    />
                    {value}
                  </div>
                ))
                : options.map((value) => (
                  <div
                    key={value}
                    className={styles.option}
                    onClick={() => onValueChanged?.(value)}
                  >
                    {value}
                  </div>
                ))}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
}
