import { useQuery } from 'react-query';
import { UserFeatureStatusEnum } from '../enums/UserFeatureStatusEnum';
import UserFeatureStatus from '../interfaces/UserFeatureStatus';
import api from '../services/api';

type UserFeatureStatusState = {
  [featureName: string]: UserFeatureStatusEnum;
};

function transformUserFeatureStatus(userFeatureStatuses: UserFeatureStatus[]) {
  const statuses = userFeatureStatuses.reduce((s, { name, status }) => {
    s[name] = status;
    return s;
  }, {} as UserFeatureStatusState);
  return statuses;
}

export const queryKey = 'features';

export const useUserFeatureStatus = () => useQuery<UserFeatureStatusState>(
  [queryKey, 'users'],
  () => api
    .get<UserFeatureStatus[]>('/api/features/user')
    .then((res) => transformUserFeatureStatus(res.data)),
);
