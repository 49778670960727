import { ColorInputField, ImageUploadField, InputFieldsGroup, MainContent, TextInputField, Typography } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { useUpdateMetaverse } from 'src/queries/metaverse';
import VisualSource, { VISUAL_SOURCE_TYPE } from 'src/interfaces/VisualSource';
import VisualSourceInput from '../../components/VisualSourceInput/VisualSourceInput';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { setTitle } from '../../redux/editMetaverse';

import styles from './EditMetaverseLandingPage.module.scss';

export default function EditMetaverseLandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metaverse, isFetching, isLoading } = useCurrentMetaverse();
  const [welcomeMessage, setWelcomeMessage] = useState('');

  useEffect(() => {
    dispatch(setTitle('Add-On: Landing Page'));
  }, []);

  useEffect(() => {
    if (!metaverse || metaverse.landingPage || isFetching || isLoading) return;
    navigate('..');
  }, [metaverse]);

  useEffect(() => {
    if (!metaverse?.landingPage?.welcomeMessage) return;
    setWelcomeMessage(metaverse.landingPage.welcomeMessage);
  }, [metaverse?.landingPage?.welcomeMessage]);

  const {
    mutate: updateMetaverse,
    isSuccess,
    reset,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess) return;
    reset();
  }, [isSuccess, reset]);

  const handleWelcomeMessageOnBlur = () => {
    updateMetaverse({ landingPage: { welcomeMessage } });
  };

  const handleLandingPageBackgroundColorChange = (color: string) => {
    updateMetaverse({ landingPage: { colors: { background: color } } });
  };

  const handleUploadBackgroundSource = (s: VisualSource) => {
    updateMetaverse({ landingPage: { background: s.src } });
  };

  const source = metaverse?.landingPage?.background
    ? parseSource(metaverse?.landingPage?.background)
    : undefined;

  return (
    <>
      <MainContent>
        <Typography variant="subtitle1">Here is where you can customize your landing page with further details not already covered in your key detail sections. This is the first page visitors will see.</Typography>
        <InputFieldsGroup className={styles.hero} title="Hero Area">
          <Typography className={styles.field} variant="body1">Give your visitors a preview of what your metaverse will look like with a main image or video trailer for your metaverse.</Typography>
          <VisualSourceInput
            label="BACKGROUND IMAGE/VIDEO"
            source={source}
            onChange={handleUploadBackgroundSource}
          />
        </InputFieldsGroup>
        <InputFieldsGroup title="Left Sidebar Area">
          <TextInputField
            className={styles.field}
            label="WELCOME MESSAGE"
            placeholder="Greetings! We're excited to..."
            maxLength={120}
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            onBlur={handleWelcomeMessageOnBlur}
          />
          <ColorInputField
            label="BACKGROUND COLOR"
            color={metaverse?.landingPage?.colors?.background}
            onChange={handleLandingPageBackgroundColorChange}
          />
        </InputFieldsGroup>
      </MainContent>
      <SaveFooter disabled />
    </>
  );
}

function parseSource(src: string): VisualSource {
  if (src.endsWith('.mp4')) {
    return {
      srcType: VISUAL_SOURCE_TYPE.Video,
      src,
    };
  }
  return {
    srcType: VISUAL_SOURCE_TYPE.Image,
    src,
  };
}
