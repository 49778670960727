import { useMutation } from 'react-query';
import api from '../services/api';

export function useAcceptInvite() {
  return useMutation(
    (token: string) => api
      .post('/api/v2/invites/accept', { token })
      .then((res) => res.data),
  );
}
