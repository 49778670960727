import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, Theme } from '@pixelcanvas/ui';

import AuthProviderWithHistory from './authentication/AuthProviderWithHistory';
import { AppRoutes } from './AppRoutes';
import ToastMessageContextProvider from './components/ToastMessage/ToastMessageContextProvider';

const queryClient = new QueryClient();

export function Router() {
  return (
    <ThemeProvider theme={Theme.Platform}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProviderWithHistory>
            <ToastMessageContextProvider>
              <AppRoutes />
            </ToastMessageContextProvider>
          </AuthProviderWithHistory>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
