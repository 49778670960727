import clsx from 'clsx';
import styles from './VisitorModalActions.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function VisitorModal({
  className,
  children,
}: Props) {
  return (
    <div className={clsx(className, styles['modal-actions'])}>
      {children}
    </div>
  );
}
