import { isArray, isString } from 'lodash';

export default interface IShowcaseContent {
  tabId: string;
  name: string;
  description?: string;
  thumbnail: string;
  type: ShowcaseContentType;
  createdBy: string;
  updatedBy: string;
}

export interface ShowcaseContentDocument extends IShowcaseContent {
  _id: string;
}

export interface IShowcaseVideoContent extends IShowcaseContent {
  type: ShowcaseContentType.Video;
  src: string;
}

export interface ShowcaseVideoContentDocument extends IShowcaseVideoContent {
  _id: string;
}

export function isShowcaseVideoContent(content: IShowcaseContent): content is IShowcaseVideoContent {
  return (content as IShowcaseVideoContent).type === ShowcaseContentType.Video &&
    isString((content as IShowcaseVideoContent).src);
}

export interface IShowcaseLinkContent extends IShowcaseContent {
  type: ShowcaseContentType.Link;
  href: string;
}

export function isShowcaseLinkContent(content: IShowcaseContent): content is IShowcaseLinkContent {
  return (content as IShowcaseLinkContent).type === ShowcaseContentType.Link &&
    isString((content as IShowcaseLinkContent).href);
}

export interface ShowcaseLinkContentDocument extends IShowcaseLinkContent {
  _id: string;
}

export interface IShowcaseImageContent extends IShowcaseContent {
  type: ShowcaseContentType.Image;
  src: string;
}

export function isShowcaseImageContent(content: IShowcaseContent): content is IShowcaseImageContent {
  return (content as IShowcaseImageContent).type === ShowcaseContentType.Image &&
    isString((content as IShowcaseImageContent).src);
}

export interface ShowcaseImageContentDocument extends IShowcaseImageContent {
  _id: string;
}

export interface IShowcaseSlideshowContent extends IShowcaseContent {
  type: ShowcaseContentType.Slideshow;
  images: string[];
}

export function isShowcaseSlideshowContent(content: IShowcaseContent): content is IShowcaseSlideshowContent {
  return (content as IShowcaseSlideshowContent).type === ShowcaseContentType.Slideshow &&
    isArray((content as IShowcaseSlideshowContent).images) &&
    (content as IShowcaseSlideshowContent).images.every(isString);
}

export interface ShowcaseSlideshowContentDocument extends IShowcaseSlideshowContent {
  _id: string;
}

export enum ShowcaseContentType {
  Video = 'Video',
  Link = 'Link',
  Image = 'Image',
  Slideshow = 'Slideshow',
}
