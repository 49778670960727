import { shallowEqual, useSelector } from 'react-redux';
import { selectMetaverseDetails, selectMetaverseId } from 'src/redux/metaverse';

import { ReactComponent as Times } from '../../../../assets/svg/times.svg';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import { SocketEvent } from '../../../../interfaces';
import { markReadBefore } from '../../../../services/NotificationService';
import { shortDateTimeFromUTC } from '../../../../utils/TimeHelper';
import { IDisplayNotification, useNotificationsContext } from './NotificationsContextProvider';

import styles from './NotificationsPanel.module.scss';
import clsx from 'clsx';

const NotificationsPanel = ({ onClickChannelJoin }: { onClickChannelJoin: Function }) => {
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const metaverseDetails = useSelector(selectMetaverseDetails, shallowEqual);
  const { notifications, dismissNotification, readNotification } = useNotificationsContext();
  const { socket } = useSocketContext();

  const onNotificationClick = async (e: any, notification: IDisplayNotification) => {
    await readNotification(metaverseId, notification);
    if (!notification.action) return;
    switch (notification.action) {
      case SocketEvent.JoinRoom:
        onClickChannelJoin(notification.actionPayload._id);
        break;
      default:
        socket?.emit(notification.action, notification.actionPayload);
    }
  };

  return (
    <div className={styles['notifications-panel']}>
      <div className={styles['panel-heading']}>
        Notifications
        <div
          className={styles['mark-all-read']}
          onClick={async () => {
            if (!notifications[0]) return;
            await markReadBefore(metaverseId, notifications[0]._id);
          }}
        >
          Dismiss All
        </div>
      </div>
      <div className={clsx(styles['panel-body'], styles['list-items'])}>
        {
          notifications?.length ?
            notifications.map((n: IDisplayNotification, i: number) => (
              <div
                className={styles['list-item']}
                onClick={(e) => onNotificationClick(e, n)}
                key={`notification_${i}_${n.header}`}
              >
                <div className={styles.header}>
                  {!n.read && <div className={styles['unread-dot']} />}
                  <div className={styles.text}>{n.header}</div>
                  <Times
                    className={styles['dismiss-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      dismissNotification(metaverseId, n);
                    }}
                  />
                </div>
                <div className={styles.body}>{n.body}</div>
                {metaverseDetails && <div className={styles.time}>{shortDateTimeFromUTC(n.createdAt.toString(), Intl.DateTimeFormat().resolvedOptions().timeZone)}</div>}
              </div>
            )) : <div className={styles.filler}>No new notifications to show</div>
        }
      </div>
    </div>
  );
};

export default NotificationsPanel;
