import { Modal } from '@material-ui/core';
import { Button, MainContent, TextInputField } from '@pixelcanvas/ui';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ImageCarousel from 'src/components/ImageCarousel/ImageCarousel';
import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import PageType from 'src/enums/PageType';
import TooltipButton from 'src/features/Dashboard/components/TooltipButton/TooltipButton';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import ContentDetails from 'src/features/Dashboard/layouts/ContentDetails/ContentDetails';
import CtaDetails from 'src/features/Dashboard/layouts/CtaDetails/CtaDetails';
import { setTitle } from 'src/features/Dashboard/redux/editMetaverse';
import { IContent } from 'src/interfaces/IContent';
import { ICta } from 'src/interfaces/ICta';
import { IPage } from 'src/interfaces/IPage';
import { usePage, useUpdatePage } from 'src/queries/pages';
import { addExhibit, addPage, updateExhibit, updatePage as updatePageRedux } from 'src/redux/pages';
import { createPage, deleteContent, deleteCta, getPageContents, getPageCta } from 'src/services/ContentService';
import { timeFromDate } from 'src/utils/TimeHelper';
import AddContentTooltip from '../../../../../../assets/images/stage.png';
import { ReactComponent as PlusCircle } from '../../../../../../assets/svg/plus-circle.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/svg/trash-alt.svg';

import styles from './StageAddOn.module.scss';

export default function StageAddOn() {
  const { pageId } = useParams();
  const { metaverse } = useCurrentMetaverse();
  const { data: page } = usePage(metaverse?._id, pageId);
  if (!page) return null;
  return <StageDetails key={pageId} type={PageType.Stage} page={page} />;
}

interface StageDetailsProps {
  type: PageType;
  page: IPage;
  onSave?: Function;
}

function StageDetails({ type, page, onSave }: StageDetailsProps) {
  const dispatch = useDispatch();
  const { metaverse } = useCurrentMetaverse();
  const metaverseId = metaverse?._id;
  const [contents, setContents] = useState<IContent[]>([]);
  const [ctaItems, setCtaItems] = useState<ICta[]>([]);
  const [selectedContent, setSelectedContent] = useState<IContent | undefined>(undefined);
  const [selectedCta, setSelectedCta] = useState<ICta | undefined>(undefined);
  const [showAddContent, setShowAddContent] = useState(false);
  const [showAddCta, setShowAddCta] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [update, setUpdate] = useState<Partial<IPage>>(page);
  const { addToast } = useToastMessageContext();

  useEffect(() => {
    dispatch(setTitle(`Add-On: ${page.title}`));
  }, [page.title]);

  const pageId = page._id;

  useEffect(() => {
    fetchContents();
  }, [pageId]);

  const {
    mutate: updatePage,
    isSuccess,
    reset,
    data: updatedPage,
  } = useUpdatePage(metaverse?._id, page?._id);

  useEffect(() => {
    if (!isSuccess) return;
    reset();
    addToast('Your changes were successfully saved.');

    switch (type) {
      case PageType.Stage: {
        dispatch(updatePageRedux(updatedPage));
        break;
      }
      case PageType.Exhibit: {
        dispatch(updateExhibit(updatedPage));
        break;
      }
      default: break;
    }
  }, [isSuccess]);

  async function handleSave() {
    if (!update.title) {
      setShowRequired(true);
      return;
    }
    updatePage(update);
  }

  async function createStageDetails() {
    if (!update.title) {
      setShowRequired(true);
      return;
    }

    const createdPage = await createPage(page);
    addToast('The page was successfully created.');

    switch (type) {
      case PageType.Stage: {
        dispatch(addPage(createdPage));
        break;
      }
      case PageType.Exhibit: {
        dispatch(addExhibit(createdPage));
        break;
      }
      case PageType.VideoOnDemand: {
        dispatch(addPage(createdPage));
        break;
      }
      default: break;
    }
    if (onSave) onSave();
  }

  async function fetchContents() {
    if (!pageId) return;
    const contents = await getPageContents(metaverseId ?? '', pageId);
    setContents(contents);
    if (type === PageType.Exhibit) {
      const cta = await getPageCta(metaverseId ?? '', pageId);
      setCtaItems(cta);
    }
  }

  function closeAddContent() {
    setShowAddContent(false);
    setSelectedContent(undefined);
  }

  function closeAddCta() {
    setShowAddCta(false);
    setSelectedCta(undefined);
  }

  function contentEdited(content: IContent, createAnother: boolean) {
    if (!createAnother) setShowAddContent(false);
    if (selectedContent) {
      // Update existing content
      setContents(contents.map((curContent) => {
        if (curContent._id === content._id) {
          return content;
        }
        return curContent;
      }));
    } else {
      // Add to existing content
      setContents([...contents, content]);
    }
    setSelectedContent(undefined);
  }

  function ctaEdited(cta: ICta, createAnother: boolean) {
    if (!createAnother) setShowAddCta(false);
    if (selectedCta) {
      // Update existing content
      setCtaItems(ctaItems.map((curItem) => {
        if (curItem._id === cta._id) {
          return cta;
        }
        return curItem;
      }));
    } else {
      // Add to existing content
      setCtaItems([...ctaItems, cta]);
    }
    setSelectedCta(undefined);
  }

  function getHeader() {
    switch (type) {
      case PageType.Stage:
        return 'Stage Details';
      case PageType.Exhibit:
        return 'Exhibit Details';
      case PageType.VideoOnDemand:
        return 'Videos On Demand Page';
      default:
        return null;
    }
  }

  async function contentDeleted(content: IContent) {
    setContents(contents.filter((curContent) => curContent._id !== content._id));
  }

  async function ctaDeleted(cta: ICta) {
    setCtaItems(ctaItems.filter((curItem) => curItem._id !== cta._id));
  }

  const handleUpdateTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUpdate((previousUpdate) => ({ ...previousUpdate, title: e.target.value }));
  };

  const handleUpdateBackgroundImage = (image: string) => {
    setUpdate((previousUpdate) => ({ ...previousUpdate, backgroundImage: image }));
  };

  return (
    <>
      <MainContent className={styles['stage-details']}>
        <div className={styles['section-label']}>
          { getHeader() }
        </div>
        <div className={styles['top-side']}>
          <div className={styles['page-info']}>
            <TextInputField
              label="TITLE"
              required
              placeholder="Page Title"
              value={update.title}
              onChange={handleUpdateTitle}
              error={showRequired && !update.title && 'Title is required'}
            />
            <ImageCarousel
              selectedImage={update.backgroundImage}
              setSelectedImage={handleUpdateBackgroundImage}
              showRequiredFields={false}
              imageInfo="BACKGROUND"
            />
            {
              !pageId &&

              <Button color="secondary" onClick={() => createStageDetails()}>
                Create Page
              </Button>
            }
          </div>
        </div>
        {
        pageId && type !== PageType.VideoOnDemand &&
        <div className={styles['add-content-section']}>
          <div className={styles['add-content-column']}>
            <div id="add-content-header" className={styles['add-content']} onClick={() => {}}>
              <div className={styles['section-label']}>
                Content
              </div>
              <TooltipButton
                className={styles['add-content-tooltip']}
                title="Page Videos"
                description="You can have multiple videos play on this stage. They just can't play during the same time."
                sourceUrl={AddContentTooltip}
                placement="bottom"
              />
              <PlusCircle
                className={styles['add-button']}
                onClick={() => {
                  setShowAddContent(true);
                }}
              />
            </div>
            <div className={styles.contents}>
              {
                contents.map((content: IContent) => (
                  <div
                    key={content._id}
                    className={styles['list-item']}
                    onClick={() => {
                      setSelectedContent(content);
                      setShowAddContent(true);
                    }}
                  >
                    <div className={styles.titles}>
                      <div className={styles['content-title']}>{content.title}</div>
                      {
                        content.timeFrame &&
                        <div className={styles['content-time']}>
                          {timeFromDate(content.timeFrame.startTime, 'UTC')} - {timeFromDate(content.timeFrame.endTime, 'UTC')}
                        </div>
                      }
                    </div>
                    <TrashIcon
                      className={styles['trash-icon']}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteContent(metaverseId ?? '', content._id as string);
                        contentDeleted(content);
                      }}
                    />
                  </div>
                ))
              }
            </div>
          </div>
          {
            type === PageType.Exhibit &&
            <div className={styles['add-content-column']}>
              <div className={styles['add-content']} onClick={() => {}}>
                <div className={styles['section-label']}>
                  Call to Action Items
                </div>
                <PlusCircle className={styles['add-button']} onClick={() => setShowAddCta(true)} />
              </div>
              <div className={styles.contents}>
                {
                  ctaItems.map((cta: ICta) => (
                    <div
                      key={cta._id}
                      className={styles['list-item']}
                      onClick={() => {
                        setSelectedCta(cta);
                        setShowAddCta(true);
                      }}
                    >
                      <div className={styles.titles}>
                        <div className={styles['content-title']}>{cta.title}</div>
                      </div>
                      <TrashIcon
                        className={styles['trash-icon']}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteCta(metaverseId ?? '', cta._id as string);
                          ctaDeleted(cta);
                        }}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
      }
        <Modal
          className={styles['content-details-modal-container']}
          container={() => document.getElementById('pixel-platform')}
          open={showAddContent}
          onBackdropClick={() => closeAddContent()}
          onEscapeKeyDown={() => closeAddContent()}
        >
          <ContentDetails page={page} content={selectedContent} onCancel={closeAddContent} onSave={(content: IContent, createAnother: boolean) => contentEdited(content, createAnother)} />
        </Modal>
        <Modal
          className={styles['content-details-modal-container']}
          container={() => document.getElementById('pixel-platform')}
          open={showAddCta}
          onClose={() => closeAddCta()}
        >
          <CtaDetails page={page} cta={selectedCta} onCancel={closeAddCta} onSave={(cta: ICta, createAnother: boolean) => ctaEdited(cta, createAnother)} />
        </Modal>
      </MainContent>
      <SaveFooter saveClicked={handleSave} updatedAt={page.updatedAt?.toString() ?? ''} />
    </>
  );
}
