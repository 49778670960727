import { TextInputField, UploadButton } from '@pixelcanvas/ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import defaultAvatar from '../../../../assets/images/profile-pic.png';
import { setUserProfile } from '../../../../redux/userProfile';
import { saveUserProfilePic } from '../../../../services/MetaverseService';
import { readURL } from '../../../../utils/FileUtil';

import styles from './ProfileSignup.module.scss';

interface Props {
  showRequiredFields: boolean;
}

export default function ProfileSignup({ showRequiredFields }: Props) {
  const userProfile = useSelector((state: any) => state.userProfile, shallowEqual);
  const dispatch = useDispatch();

  async function uploadUserImage(image: any) {
    const response: any = await saveUserProfilePic(userProfile.userId, image);
    dispatch(setUserProfile({ ...userProfile, picture: response.location }));
  }

  return (
    <div className={styles['profile-signup']}>
      <div className={styles['profile-title']}>
        Setup Your Profile
      </div>
      <div className={styles['details-container']}>
        <div className={styles['profile-description']}>
          Please fill out your profile so people can know more about you.
        </div>
        <div className={styles['profile-picture-section']}>
          <div className={styles['profile-picture-container']}>
            PROFILE PHOTO
            <img className={styles['profile-picture-img']} src={userProfile.picture ? userProfile.picture : defaultAvatar} alt={userProfile?.displayName} />
          </div>
          <div className={styles['upload-container']}>
            <UploadButton
              color="secondary"
              id="file-upload-profile-signup"
              type="file"
              accept="image/*"
              onChange={(e) => readURL(e.target, (file: any) => uploadUserImage(file))}
            >
              Upload
            </UploadButton>
          </div>
        </div>
        <TextInputField
          label="DISPLAY NAME"
          required
          placeholder="Enter Display Name"
          maxLength={50}
          value={userProfile.displayName}
          onChange={(e) => dispatch(setUserProfile({ ...userProfile, displayName: e.target.value }))}
          error={showRequiredFields && !userProfile.displayName && 'Please enter a display name'}
        />
        <TextInputField
          label="EMAIL"
          disabled
          placeholder="Enter Email"
          value={userProfile.email}
          onChange={(e) => dispatch(setUserProfile({ ...userProfile, email: e.target.value }))}
          error={showRequiredFields && !userProfile.email && 'Please enter an email'}
        />
        <div className={styles['email-help']}>
          Contact Pixel Canvas if you wish to update your email
        </div>
        <TextInputField
          label="COMPANY"
          placeholder="Enter your company name"
          maxLength={50}
          value={userProfile.company}
          onChange={(e) => dispatch(setUserProfile({ ...userProfile, company: e.target.value }))}
        />
      </div>
    </div>
  );
}
