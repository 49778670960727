import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShowcaseLinkContentDocument } from 'src/interfaces/IShowcaseContent';
import ContentCard from './ContentCard';

import styles from './MediaContentCard.module.scss';

interface LinkContentCardProps {
  content: ShowcaseLinkContentDocument;
}

export default function LinkContentCard({ content }: LinkContentCardProps) {
  const handleClick = () => window.open(content.href, '_blank')?.focus();
  return (
    <ContentCard className={styles.span} content={content} onClick={handleClick}>
      <FontAwesomeIcon className={styles.icon} icon={faArrowUpRightFromSquare} />
    </ContentCard>
  );
}
