import { Button, InputFieldsGroup, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, TextInputField } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { useCreateShowcaseTab } from 'src/queries/showcase';

import styles from './AddShowcaseTabModal.module.scss';

interface AddShowcaseTabModalProps {
  open: boolean;
  onClose: () => void;
}

export default function AddShowcaseTabModal({ open, onClose }: AddShowcaseTabModalProps) {
  const { guid } = useParams();
  const { metaverse } = useCurrentMetaverse();
  const [name, setName] = useState('');
  const [showRequired, setShowRequired] = useState(false);

  const { mutate, isSuccess, isLoading, reset } = useCreateShowcaseTab(metaverse?._id, guid);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      reset();
    }
  });

  const handleAdd = () => {
    if (!guid || !name) {
      setShowRequired(true);
      return;
    }
    mutate(name);
    onClose();
  };

  return (
    <TaskModal className={styles.modal} open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>ADD NEW TAB</TaskModalTitle>
      <TaskModalContent>
        <InputFieldsGroup>
          <TextInputField
            label="Name"
            placeholder="Enter name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showRequired && 'Name is required'}
          />
        </InputFieldsGroup>
        <PlatformSpinner visible={isLoading} />
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleAdd}>Add</Button>
      </TaskModalActions>
    </TaskModal>
  );
}
