import React from 'react';
import InternalAuthContextProvider from './PlatformAuthContextProvider';

type Props = {
  children?: React.ReactNode
};

window.env = window.env || {};
const AUTH_STRATEGY = window.env.REACT_APP_AUTH_STRATEGY || 'platform';

const AuthProviderWithHistory = ({ children }: Props) => {
  const getProvider = () => {
    switch (AUTH_STRATEGY) {
      case 'platform':
      default:
        return (
          <InternalAuthContextProvider>
            {children}
          </InternalAuthContextProvider>
        );
    }
  };

  return (
    <>
      { getProvider() }
    </>
  );
};

export default AuthProviderWithHistory;
