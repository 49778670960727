import { useMatch, useNavigate } from 'react-router-dom';

import { SubNavButton } from '@pixelcanvas/ui';

type Props = {
  label: string;
  to: string;
  end?: boolean;
  onDelete?: () => void;
};

export default function SideNavLink({ label, to, end = false, onDelete }: Props) {
  const match = useMatch({ path: to, end });
  const navigate = useNavigate();
  const handleClick = () => navigate(to);
  return (
    <SubNavButton
      label={label}
      selected={!!match}
      onClick={handleClick}
      onDelete={onDelete}
    />
  );
}
