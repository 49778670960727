import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectMetaverseId } from 'src/redux/metaverse';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import { SocketEvent } from '../../../../interfaces';
import { getUnreadNotifications, markRead } from '../../../../services/NotificationService';
import { getFormattedDisplayNotification } from './NotificationHelper';

export interface IDisplayNotification {
  header: string;
  body: string;
  action?: SocketEvent;
  actionPayload?: any;
  read: boolean;
  _id: string;
  createdAt: Date;
}
export interface IUserNotification {
  type: SocketEvent;
  payload: any;
  userId: string;
  read: boolean;
  _id: string;
  createdAt: Date;
}

export interface INotificationsContext {
  notifications: IDisplayNotification[];
  dismissNotification: Function;
  readNotification: Function;
}

interface NotificationsProviderProps {
  children: React.ReactNode;
}

const NotificationsContext = React.createContext<INotificationsContext>(null!);

export function useNotificationsContext() {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('Context can only be used within NotificationsContextProvider');
  }
  return context;
}

export default function NotificationsContextProvider({ children }: NotificationsProviderProps) {
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const { socket } = useSocketContext();
  const [notifications, setNotifications] = useState<IDisplayNotification[]>([]);

  const addNotification = (item: IUserNotification) => {
    const formatted = getFormattedDisplayNotification(item);
    if (formatted) setNotifications((prevNotifications) => [...prevNotifications, formatted]);
  };

  const onInvitedToRoom = (userNotification: IUserNotification) => {
    addNotification(userNotification);
  };

  async function fetchUnreadNotifications(id: string) {
    const unreads = await getUnreadNotifications(id);
    const displayNotifications = unreads
      .map((n) => getFormattedDisplayNotification(n))
      .filter((d) => d !== null) as IDisplayNotification[];
    setNotifications(displayNotifications);
  }

  async function dismissNotification(id: string, notification: IDisplayNotification) {
    await markRead(id, notification._id);
    setNotifications((prevNotifications) => prevNotifications.filter((n) => n._id !== notification._id));
  }

  async function readNotification(id: string, notification: IDisplayNotification) {
    await markRead(id, notification._id);
    setNotifications((prevNotifications) => prevNotifications.map((n) => (n._id === notification._id ? { ...n, read: true } : n)));
  }

  useEffect(() => {
    if (!metaverseId) return;
    fetchUnreadNotifications(metaverseId);
  }, [metaverseId]);

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvent.InvitedToRoom, onInvitedToRoom);
    }
    return () => {
      socket?.off(SocketEvent.InvitedToRoom, onInvitedToRoom);
    };
  }, [socket]);

  return (
    <NotificationsContext.Provider value={{
      notifications,
      dismissNotification,
      readNotification,
    }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
