import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { RootState } from 'src/store';

type MetaverseState = {
  metaverseId: string;
  details?: MetaverseResponse;
};

const initialState: MetaverseState = {
  metaverseId: '',
};

export const metaverseSlice = createSlice({
  name: 'metaverse',
  initialState,
  reducers: {
    setMetaverseId: (state, action: PayloadAction<string>) => {
      state.metaverseId = action.payload;
    },
    setMetaverseDetails: (state, action: PayloadAction<MetaverseResponse>) => {
      state.details = action.payload;
    },
    setLocalMetaverseDetails: (state, action: PayloadAction<MetaverseResponse>) => {
      state.details = action.payload;
    },
  },
});

export const { setMetaverseId, setMetaverseDetails, setLocalMetaverseDetails } = metaverseSlice.actions;

export const selectMetaverseId = (state: RootState) => state.metaverse.metaverseId;
export const selectMetaverseDetails = (state: RootState) => state.metaverse.details;

export default metaverseSlice.reducer;
