import { MainContent, NotificationBanner, Page, PageContent } from '@pixelcanvas/ui';
import { CSSProperties, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { IToastMessage, useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import { OrganizationResponse } from 'src/interfaces/IOrganization';
import OrganizationRole from 'src/interfaces/OrganizationRole';
import { postOrganizationImage, usePatchOrganization } from 'src/queries/organization';
import { setOrganizationDetails } from 'src/redux/organization';
import { RootState } from 'src/store';

import OrganizationTeamPage from './components/OrganizationTeam/OrganizationTeamPage';
import MainHeader from '../../components/MainHeader/MainHeader';
import OrganizationProfile from './components/OrganizationProfile/OrganizationProfile';
import OrganizationSideNav from './components/OrganizationSideNav/OrganizationSideNav';
import OrganizationBillingPage from './components/OrganizationBillingPage/OrganizationBillingPage';
import { useOrganizationContext } from './context/OrganizationContextProvider';

import styles from './OrganizationPage.module.scss';

export default function AppRoutes() {
  const dispatch = useDispatch();
  const organization = useSelector((state: RootState) => state.organization, shallowEqual);
  const [localOrganizationDetails, setLocalOrganizationDetails] = useState<OrganizationResponse>(organization.details as OrganizationResponse);
  const { currentOrganization, userMembership } = useOrganizationContext();
  const { mutate: patchOrganization, data, isSuccess: patchOrganizationSuccess } = usePatchOrganization(currentOrganization?._id as string);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const { toasts, removeToast, addToast } = useToastMessageContext();
  const [referenceElment, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState<IToastMessage | null>(toasts[0]);
  const [displayedToast, setDisplayedToast] = useState<IToastMessage | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElment, popperElement,
    {
      strategy: 'fixed',
      placement: 'bottom',
      modifiers: [{ name: 'offset' }],
    },
  );

  useEffect(() => {
    setToast(toasts[0]);
  }, [toasts]);

  useEffect(() => {
    if (displayedToast !== toast) {
      setShowToast(false);
      const timeout = setTimeout(() => setDisplayedToast(toast), 200);
      return () => clearTimeout(timeout);
    }
    setShowToast(true);
    return () => {};
  }, [displayedToast, toast]);

  useEffect(() => {
    if (patchOrganizationSuccess) {
      addToast('Organization updated successfully');
    }
  }, [patchOrganizationSuccess]);

  const isOwner = userMembership?.role === OrganizationRole.Owner;

  useEffect(() => {
    setLocalOrganizationDetails(organization.details as OrganizationResponse);
  }, [organization]);

  useEffect(() => {
    if (!data) return;
    dispatch(setOrganizationDetails(data));
  }, [data]);

  async function uploadLogo(image: string) {
    if (currentOrganization) {
      try {
        const response = await postOrganizationImage(currentOrganization._id, image);
        setLocalOrganizationDetails({
          ...localOrganizationDetails,
          logo: response.location,
        });
      } catch (e: any) {
        console.error(e);
      } finally {
        setShowSpinner(false);
      }
    }
  }

  async function handleSave() {
    if (!currentOrganization) {
      return;
    }
    if (!localOrganizationDetails.name) {
      setShowRequired(true);
      return;
    }
    try {
      setShowSpinner(true);
      patchOrganization({
        ...localOrganizationDetails,
        name: localOrganizationDetails.name.trim(),
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      setShowSpinner(false);
    }
  }

  return (
    <>
      <Page variant="vertical" className={styles.page}>
        <div ref={setReferenceElement} className={styles.header}>
          <MainHeader />
        </div>
        <PageContent background="bg1" variant="horizontal" className={styles.content}>
          <OrganizationSideNav />
          <Routes>
            <Route
              path="profile"
              element={
                <MainContent className={styles.mainContent}>
                  <OrganizationProfile
                    localOrganizationDetails={localOrganizationDetails}
                    setLocalOrganizationDetails={setLocalOrganizationDetails}
                    isOwner={isOwner}
                    showSpinner={showSpinner}
                    showRequired={showRequired}
                    uploadLogo={uploadLogo}
                  />
                </MainContent>
                }
            />
            <Route
              path="team"
              element={
                <MainContent className={styles.mainContent} variant="table">
                  <OrganizationTeamPage />
                </MainContent>

              }
            />
            <Route
              path="billing"
              element={
                <MainContent className={styles.mainContent}>
                  <OrganizationBillingPage organization={localOrganizationDetails} />
                </MainContent>

              }
            />
            <Route
              path="*"
              element={<Navigate to="profile" />}
            />
          </Routes>
        </PageContent>
        <Routes>
          <Route
            path="profile"
            element={
              <SaveFooter
                className={styles.save}
                updatedAt={localOrganizationDetails?.updatedAt?.toString()}
                saveClicked={handleSave}
                disabled={!isOwner}
              />
}
          />
          <Route
            path="*"
            element={<SaveFooter
              className={styles.save}
              disabled
            />}
          />
        </Routes>
      </Page>
      <Portal>
        <NotificationBanner
          ref={setPopperElement}
          show={Boolean(displayedToast) && showToast}
          type={displayedToast?.type}
          onClose={() => displayedToast && removeToast(displayedToast)}
          style={{ ...popperStyles.popper } as CSSProperties}
          {...attributes.popper}
        >
          {displayedToast && displayedToast.message}
        </NotificationBanner>
      </Portal>
    </>
  );
}
