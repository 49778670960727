import { useEffect, useState } from 'react';
import clsx from 'clsx';
import DropdownOptions from './DropdownOptions';
import { ReactComponent as TriangleError } from '../../../../assets/svg/exclamation-triangle.svg';
import { ReactComponent as CaretDown } from '../../../../assets/svg/caret-down-solid.svg';
import { ReactComponent as CaretUp } from '../../../../assets/svg/caret-up-solid.svg';
import styles from './Dropdown.module.scss';

interface Props {
  title: string;
  options: Array<string>;
  value?: string;
  onValueChange?: Function;
  showRequired?: boolean;
  onOpen?: Function;
}

export default function Dropdown({
  title,
  options,
  value,
  onValueChange,
  showRequired,
  onOpen,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Select');

  const arrowIcon = isOpen ? <CaretUp className={styles['arrow-icon']} /> : <CaretDown className={styles['arrow-icon']} />;

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isOpen) onOpen?.();
  }, [isOpen]);

  function valueChanged(newValue: string, index: number) {
    setSelectedValue(newValue);
    onValueChange?.(newValue, index);
  }

  const isRequired = showRequired && selectedValue === 'Select';

  return (
    <DropdownOptions
      options={options}
      onOpenChanged={setOpen}
      onValueChanged={valueChanged}
    >
      <div className={styles.dropdown}>
        <div
          className={clsx(styles.title, {
            required: isRequired,
          })}
        >
          {title}
        </div>
        <div
          className={clsx(styles['dropdown-option'], {
            selected: isOpen,
            required: isRequired,
            placeholder: selectedValue === 'Select',
          })}
        >
          {selectedValue}
          {arrowIcon}
        </div>
        {isRequired && (
          <div className={styles['input-footer']}>
            <TriangleError className={styles['triangle-error']} />
            Please fill out this section
          </div>
        )}
      </div>
    </DropdownOptions>
  );
}
