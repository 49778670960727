/* eslint-disable class-methods-use-this */
import { subject } from '@casl/ability';
import { Autocomplete } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { useState } from 'react';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';

import { ReactComponent as RemoveIcon } from '../../../../assets/svg/times.svg';
import { RoomType } from '../../../../interfaces';
import IUser from '../../../../interfaces/IUser';
import { getDisplayName, getUserDisplayName } from '../../../../utils/DisplayNameUtil';

import styles from './CreateChannel.module.scss';

interface Props {
  createChannel: Function;
  getUsers: Function;
}

const SEARCH_DELAY = 300; // ms to wait before making a query

export default function CreateChannel(props: Props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [inviteQuery, setInviteQuery] = useState('');
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [textEnabled, setTextEnabled] = useState(false);
  const [type, setType] = useState(RoomType.Private);
  const [invitees, setInvitees] = useState<IUser[]>([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTimer, setSearchTimer] = useState<any>(null);
  const { metaverse: metaverseDetails, ability } = useAccessMetaverse();

  const onSearchChange = async (inviteQuery: string) => {
    setInviteQuery(inviteQuery);
    clearTimeout(searchTimer);
    if (inviteQuery === '') {
      setSearchResults([]);
    } else {
      setSearchTimer(setTimeout(async () => {
        const { getUsers } = props;
        const users = (await getUsers(inviteQuery)).map((user: any) => ({
          ...user.participant,
          userId: user.userId,
        }));
        setSearchResults(users);
      }, SEARCH_DELAY));
    }
  };

  const onClickCreateChannel = async () => {
    const { createChannel } = props;
    const channel = {
      name,
      description,
      invite: invitees.map((i: IUser) => i.userId),
      capabilities: {
        video: videoEnabled,
        text: textEnabled,
      },
      type,
    };
    await createChannel(channel);
  };

  const iconColor = metaverseDetails?.ui.colors.pixelConnectIcon;
  const canCreatePublicRoom = ability.can(Action.Create, subject(Subject.Room, { type: RoomType.Public, metaverseId: metaverseDetails?._id }));

  return (
    <div className={styles['create-channel-panel']}>
      <div className={styles['panel-heading']}>
        Create a Room
        <div className={styles['panel-subtext']}>
          You won&apos;t be able to edit room information after it&apos;s created.
        </div>
      </div>
      <div className={clsx(styles['panel-body'], styles['form-items'])}>
        {/* Channel Name */}
        <div className={styles['section-heading']}>
          NEW GROUP CHAT NAME*
        </div>
        <input
          className={clsx(styles['channel-name'], styles['input-field'])}
          placeholder="Enter group title..."
          type="text"
          required
          onChange={(event) => setName(event.target.value)}
        />
        {/* Description */}
        <div className={styles['section-heading']}>
          DESCRIPTION
        </div>
        <textarea
          className={clsx(styles['channel-description'], styles['input-field'])}
          placeholder="Describe your room..."
          maxLength={140}
          onChange={(event) => setDescription(event.target.value)}
        />

        {/* Privacy */}
        <div className={styles['section-heading']}>PRIVACY SETTINGS*</div>
        <div className={styles['privacy-settings']} onChange={() => setType(type === RoomType.Public ? RoomType.Private : RoomType.Public)}>
          <div>
            <input defaultChecked className={styles.radiobutton} type="radio" name="privacy" value="private" />
            <label className={styles['choice-label']} htmlFor="private">Private</label>
          </div>
          <div>
            <input disabled={!canCreatePublicRoom} className={styles.radiobutton} type="radio" name="privacy" value="public" />
            <label className={styles['choice-label']} htmlFor="public">Public</label>
          </div>
        </div>
        {/* Capabilities */}
        <div className={styles['section-heading']}>CAPABILITIES*</div>
        <div className={styles['capabilities-settings']}>
          <input
            className={styles.checkbox}
            onChange={() => setTextEnabled(!textEnabled)}
            type="checkbox"
            name="text"
            value="Text Chat"
          />
          <label className={styles['choice-label']} htmlFor="text"> Text Chat</label>
        </div>
        <div className={styles['capabilities-settings']}>
          <input
            className={styles.checkbox}
            onChange={() => setVideoEnabled(!videoEnabled)}
            type="checkbox"
            name="video"
            value="Video Chat"
          />
          <label className={styles['choice-label']} htmlFor="video"> Video Chat</label>
        </div>
        {/* Invite */}
        <div className={styles['section-heading']}>
          Send Invites to:
        </div>
        <Autocomplete
          inputProps={{ className: styles['channel-invite'], placeholder: 'Enter one name at a time' }}
          getItemValue={(user: IUser) => getUserDisplayName(user)}
          value={inviteQuery}
          items={searchResults}
          onSelect={(value, item) => {
          setInvitees(invitees.concat(item));
          }}
          onChange={(event, value) => onSearchChange(value)}
          renderItem={(user, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
              key={user.userId}
            >
              {getDisplayName(user)}
            </div>
          )}
          menuStyle={{ background: '#DEDEDE', margin: '0 2rem', userSelect: 'none' }}
        />

        {/* List of Invitees */}
        <div className={styles['invitee-chips']}>
          {invitees && invitees.map((invitee: IUser) => (
            <div key={invitee.userId} className={styles['invitee-chip']}>
              <div className={styles.name}>{getUserDisplayName(invitee)}</div>
              <div
                className={styles.remove}
                onClick={() => {
                  setInvitees(invitees.filter((i: IUser) => i.userId !== invitee.userId));
                }}
              >
                <RemoveIcon className={styles['remove-icon']} />
              </div>
            </div>
          ))}
        </div>

        {/* Create Channel */}
        <button
          type="button"
          className={styles['create-channel-button']}
          style={{ backgroundColor: iconColor }}
          onClick={onClickCreateChannel}
          disabled={!name || (!textEnabled && !videoEnabled)}
        >
          Create
        </button>
      </div>
    </div>
  );
}
