/* eslint-disable class-methods-use-this */
import React from 'react';
import clsx from 'clsx';
import { RoomsSocketContext } from '../../../../contexts/RoomsSocketContextProvider';
import ChatBubble from './ChatBubble/ChatBubble';
import styles from './ChatBubbles.module.scss';

interface ChatBubblesProps {
  onRoomClicked: Function;
  getRoom: Function;
  getRoomParticipants: Function;
}

class ChatBubbles extends React.Component<ChatBubblesProps> {
  static contextType = RoomsSocketContext;

  render() {
    const { onRoomClicked, getRoom, getRoomParticipants } = this.props;
    const { recentRooms, focusedTextChatRoomId, setFocusedTextChatRoomId } = this.context;
    return (
      <div className={styles['bubble-stack']}>
        {
          recentRooms &&
          recentRooms.map((roomId: string) => (
            <div
              className={clsx(styles.bubble, { [styles.focused]: focusedTextChatRoomId === roomId })}
              key={roomId}
              onClick={() => {
                setFocusedTextChatRoomId(roomId);
                onRoomClicked(roomId);
              }}
            >
              <ChatBubble
                roomId={roomId}
                getRoom={getRoom}
                getRoomParticipants={getRoomParticipants}
              />
            </div>
          ))
        }
      </div>
    );
  }
}

export default ChatBubbles;
