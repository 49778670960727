import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import Dropdown from '../../../../Visitor/components/Dropdown/Dropdown';

import useVideoContext from '../useVideoContext';
import { SELECTED_AUDIO_OUTPUT_KEY } from '../VideoContextProvider';

export default function AudioOutputList() {
  const { activeSinkId, setActiveSinkId } = useVideoContext();

  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);

  useAsyncEffect(async () => {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    setAudioOutputDevices(mediaDevices.filter((device) => device.kind === 'audiooutput'));
  }, []);

  const activeOutputLabel = audioOutputDevices.find((device) => device.deviceId === activeSinkId)?.deviceId;

  return (
    <div>
      {audioOutputDevices.length > 1 ? (
        <div>
          <Dropdown
            title="Audio Output"
            options={audioOutputDevices.map((device) => (device.label))}
            onValueChange={(value: string) => {
              const newDevice = audioOutputDevices.find((device) => device.label === value);
              if (newDevice) {
                console.log('Setting Sink: ', newDevice?.deviceId);
                window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, newDevice?.deviceId);
                setActiveSinkId(newDevice?.deviceId);
              }
            }}
            value={audioOutputDevices.find((device) => device.deviceId === activeSinkId)?.label || audioOutputDevices[0].label}
            showRequired={false}
          />
        </div>
      ) : (
        <>
          <div>Audio Output</div>
          <div>{activeOutputLabel || 'System Default Audio Output'}</div>
        </>
      )}
    </div>
  );
}
