import { Modal } from '@material-ui/core';
import { useCallback, useState } from 'react';

import { ReactComponent as TriangleError } from '../../../../../assets/svg/exclamation-triangle.svg';
import { ReactComponent as Times } from '../../../../../assets/svg/times.svg';
import { SocketEvent } from '../../../../../interfaces';
import useSocketContext from '../../../../../contexts/useRoomsSocketContext';
import useVideoContext from '../useVideoContext';

import styles from './PresenterSettingsModal.module.scss';
import clsx from 'clsx';

interface PresenterSettingsModalProps {
  visible: boolean;
  onClose: Function;
  videoRoomId: string;
}

const PresenterSettingsModal = (({ visible, onClose, videoRoomId }: PresenterSettingsModalProps) => {
  const {
    presenter, videoRoom, createLocalScreenTracks, createLocalVideoTrack, removeLocalVideoTrack,
  } = useVideoContext();
  const { socket } = useSocketContext();
  const [screenShare, setScreenShare] = useState(false);
  const [cameraShare, setCameraShare] = useState(false);

  const startPresenting = useCallback(async () => {
    socket?.emit(SocketEvent.EndPresentation, videoRoomId);
    if (!presenter || (presenter && presenter.identity !== videoRoom?.localParticipant.identity)) {
      if (screenShare) {
        console.log('Presenter: creating localScreenTracks');
        await createLocalScreenTracks();
      }
      if (cameraShare) {
        console.log('Presenter: replacing video track for high quality');
        createLocalVideoTrack(true);
      } else {
        removeLocalVideoTrack();
      }
      socket?.emit(SocketEvent.StartPresentation, videoRoomId);
    }
    onClose();
  }, [screenShare, cameraShare]);

  return (
    <Modal
      className={styles['presenter-settings-modal-container']}
      container={() => document.getElementById('pixel-platform')}
      open={visible}
      onClose={() => onClose()}
    >
      <div className={styles['presenter-settings-modal']}>
        <Times className={styles['close-button']} onClick={() => onClose()} />
        <div className={styles['text-container']}>
          PRESENTER MODE
          <div className={styles.content}>
            {presenter && <div className={styles.warning}> <TriangleError className={styles['triangle-error']} /> Taking over will end the current presentation.</div>}
            <div className={styles['presenter-option']}>
              <input
                className={styles.checkbox}
                onChange={() => setScreenShare((prevScreenShare) => !prevScreenShare)}
                type="checkbox"
                name="text"
                value="Share Screen"
              />
              <label className={styles['choice-label']} htmlFor="text"> Share Screen</label>
            </div>
            <div className={styles['presenter-option']}>
              <input
                className={styles.checkbox}
                onChange={() => setCameraShare((prevCameraShare) => !prevCameraShare)}
                type="checkbox"
                name="text"
                value="Share Video"
              />
              <label className={styles['choice-label']} htmlFor="text"> Share Video</label>
            </div>
          </div>
        </div>
        <div className={styles['modal-controls']}>
          <div className={clsx(styles['platform-button-secondary'], styles['cancel-button'])} onClick={() => onClose()}>
            Cancel
          </div>
          <div className={clsx(styles['platform-button'], styles['present-button'])} onClick={() => startPresenting()}>
            Start Presenting
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default PresenterSettingsModal;
