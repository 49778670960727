import { Button, Cards, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle } from '@pixelcanvas/ui';
import { useState } from 'react';
import { useWindowSize } from 'react-use';
import { AddOn } from 'src/features/Dashboard/domain/AddOn';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import landingThumbnail from 'src/assets/images/addon-landing-thumbnail.jpg';
import stageThumbnail from 'src/assets/images/stage.png';

import AddOnCard from './AddOnCard';

import styles from './SelectAddOnModal.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelect: (addOn: AddOn) => void;
}

export default function SelectAddOnModal({ open, onClose, onSelect }: Props) {
  const addOns = useAvailableAddOns();
  const [selectedAddOn, setSelectedAddOn] = useState<AddOn>();
  const { width: windowWidth } = useWindowSize();

  const variant = addOns.length * 400 > windowWidth * 0.75
    ? 'grid'
    : 'flex';

  const handleConfirm = () => {
    if (!selectedAddOn) return;
    onSelect(selectedAddOn);
  };

  return (
    <TaskModal className={styles.modal} open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>SELECT AN ADD-ON</TaskModalTitle>
      <TaskModalContent>
        <Cards variant={variant}>
          {
            addOns.map((addOn) => (
              <AddOnCard
                key={addOn.title}
                addOn={addOn}
                selected={addOn.title === selectedAddOn?.title}
                onClick={() => setSelectedAddOn(addOn)}
              />
            ))
          }
        </Cards>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button color="primary" disabled={!selectedAddOn} onClick={handleConfirm}>Confirm</Button>
      </TaskModalActions>
    </TaskModal>
  );
}

function useAvailableAddOns() {
  const { metaverse, isLoading } = useCurrentMetaverse();
  const addOns: AddOn[] = [];
  if (!isLoading && !metaverse?.landingPage) {
    addOns.push({
      title: 'Landing Page',
      description: 'This is the first page visitors will see when they get on your metaverse',
      image: landingThumbnail,
    });
  }
  addOns.push({
    title: 'Stage',
    description: 'For livestreams and single video content. Visitors can use the page\'s group chat or participate in the ask questions tab. You can add more than one Stage Page.',
    image: stageThumbnail,
  });
  return addOns;
}
