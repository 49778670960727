import { ConfirmationModal } from '@pixelcanvas/ui';

interface DeleteMetaverseConfirmModalProps {
  name: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function DeleteMetaverseConfirmModal({
  name,
  open,
  onClose,
  onConfirm,
}: DeleteMetaverseConfirmModalProps) {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title="DELETE YOUR METAVERSE"
      confirm="Delete"
    >
      <ConfirmationModal.Description>Are you sure you want to delete {name}?</ConfirmationModal.Description>
      <ConfirmationModal.Description>Please note that this cannot be undone.</ConfirmationModal.Description>
    </ConfirmationModal>
  );
}
