import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SidepanelContent } from 'src/features/PixelConnect/components/PixelConnectOverlay/Sidepanel/Sidepanel';
import { RootState } from 'src/store';

type PixelConnectState = {
  selectedTab?: SidepanelContent;
};

const initialState: PixelConnectState = {};

export const pixelConnectSlice = createSlice({
  name: 'pixelconnect',
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<SidepanelContent>) => {
      state.selectedTab = action.payload;
    },
    unsetSelectedTab: (state) => {
      state.selectedTab = undefined;
    },
  },
});

export const { setSelectedTab, unsetSelectedTab } = pixelConnectSlice.actions;

export const selectSelectedTab = (state: RootState) => state.pixelconnect.selectedTab;

export default pixelConnectSlice.reducer;
