import { useQuery } from 'react-query';
import { PureWebConfigurationResponse } from 'src/interfaces/IPurewebConfiguration';
import api from '../services/api';

export const queryKey = 'purewebconfigurations';

export const usePureWebConfigurations = () => useQuery<PureWebConfigurationResponse[]>(
  queryKey,
  () => api
    .get('/api/purewebconfigurations/')
    .then((res) => res.data),
);
