import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectMetaverseId } from 'src/redux/metaverse';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import { PlatformRole, Room } from '../../../../interfaces';
import { getMessagesBefore, getRecentMessages } from '../../../../services/RoomService';
import LivestreamChat from '../LivestreamChat/LivestreamChat';

import styles from './LivestreamChatContainer.module.scss';

interface LivestreamChatContainerProps {
  rooms: Room[];
}

enum LivestreamChatTabs {
  Chat = 'Chat',
  QA = 'QA',
}

export default function LivestreamChatContainer({ rooms }: LivestreamChatContainerProps) {
  const { user } = usePxAuthContext();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [selectedTab, setSelectedTab] = useState<LivestreamChatTabs>(LivestreamChatTabs.Chat);
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

  useEffect(() => {
    switch (selectedTab) {
      case LivestreamChatTabs.QA:
        setSelectedRoom(rooms.find((room) => room?.uniqueId?.includes('livestream_qa')) || null);
        break;
      case LivestreamChatTabs.Chat:
      default:
        setSelectedRoom(rooms.find((room) => room?.uniqueId?.includes('livestream_chat')) || null);
        break;
    }
  }, [selectedTab, rooms]);

  return (
    <div className={styles['livestream-chat-container']}>
      <div className={styles['chat-area']}>
        {
          selectedRoom &&
          <LivestreamChat
            key={selectedRoom?._id}
            getPreviousMessages={(roomId: string, refMessageId: string) => getMessagesBefore(metaverseId, roomId, refMessageId)}
            getMessages={(roomId: string) => getRecentMessages(metaverseId, roomId)}
            room={selectedRoom}
            roles={[PlatformRole.Attendee]}
            userInfo={user}
            isQuestionsOnly={selectedTab === LivestreamChatTabs.QA}
          />
        }
      </div>
    </div>
  );
}
