import { Button } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';

import usePxAuthContext from '../../authentication/usePxAuthContext';
import { setMetaverseVisitor } from '../../redux/metaverseVisitor';
import { acceptEmailInvite } from '../../services/MetaverseService';

import styles from './JoinPage.module.scss';

export default function JoinPage() {
  const subdomain = window.location.hostname.split('.')[0];
  const navigate = useNavigate();
  const { logout } = usePxAuthContext();
  const [error, setError] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  if (!query || !query.get('invite')) {
    return <Navigate to="/" />;
  }

  const inviteCode = query.get('invite') as string;
  const dispatch = useDispatch();

  useEffect(() => {
    sendAcceptance(inviteCode);
  }, [inviteCode]);

  async function sendAcceptance(code: string) {
    try {
      const metaverseVisitor = await acceptEmailInvite(code);
      dispatch(setMetaverseVisitor(metaverseVisitor));
      if (subdomain === 'localhost') {
        navigate(`/landing?id=${metaverseVisitor.metaverseId}`);
      } else {
        navigate('/landing');
      }
    } catch (e) {
      setError(true);
    }
  }

  const handleLogOut = (e: any) => {
    e.preventDefault();

    logout();
  };

  return (
    <div className={styles['event-join-page']}>
      { !error && <PlatformSpinner visible /> }
      { error &&
        <>
          <div className={styles['error-message']}>
            Something went wrong activating your invite. Please make sure you&apos;ve signed in using the invited email address.
          </div>
          <Button color="secondary" onClick={handleLogOut}>
            Sign Out
          </Button>
        </>}
    </div>
  );
}
