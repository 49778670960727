import { useEffect, useState } from 'react';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import PureWebAvatarModal from 'src/components/ReadyPlayerMe/PureWebAvatarModal';
import Background from 'src/features/PixelStreaming/components/Background/Background';
import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import LaunchContainer from 'src/features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import LoadingInfo from 'src/features/PixelStreaming/components/LoadingInfo/LoadingInfo';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import { usePixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinGameSession from 'src/features/PixelStreaming/hooks/useJoinGameSession';
import useJoinSpace from 'src/features/PixelStreaming/hooks/useJoinSpace';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenMap from 'src/features/PixelStreaming/hooks/useOpenMap';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useSendResizeMessages from 'src/features/PixelStreaming/hooks/useSendResizeMessages';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import ErrorOverlay from 'src/features/ScalablePixelStreaming/components/ErrorOverlay';
import PlayOverlay from 'src/features/ScalablePixelStreaming/components/PlayOverlay';
import SpsContextProvider, { useSpsContext } from 'src/features/ScalablePixelStreaming/components/SpsContextProvider';
import TimeoutModal from 'src/features/ScalablePixelStreaming/components/SpsTimeoutModal';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import UsageContextProvider, { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import { IMetaverse3DPage } from 'src/interfaces/IPage';
import VisualSource from 'src/interfaces/VisualSource';
import { useGetRandomGameLiftSession } from 'src/queries/gamelift';

import defaultBackground from '../../../assets/images/world_map_bg.jpg';
import UploadContentModalContainer from '../components/UploadContentModal/UploadContentModalContainer';
import useAccessMetaverse from '../hooks/useAccessMetaverse';

import styles from './SpsMetaversePage.module.scss';
import useEmojisBinding from 'src/features/PixelStreaming/hooks/useEmojisBinding';
import EmojiGestureSelection from 'src/features/NewVisitor/layouts/EmojiGestureSelection';

interface SpsMetaversePageProps {
  page: IMetaverse3DPage;
}

function SpsMetaversePage({ page }: SpsMetaversePageProps) {
  const { metaverse, space } = page;
  const { authToken, user } = usePxAuthContext();
  const {
    playerRef,
    status,
    launchStatus,
  } = useSpsContext();
  const { connect } = usePixelStreamingContext();
  const { ref: resizeRef } = useSendResizeMessages<HTMLDivElement>();
  const { mapName, purewebConfiguration } = space.environment;
  const { aliasId } = purewebConfiguration;
  const metaverseId = metaverse?._id;

  const {
    data: randomGameLiftSession,
    error: getRandomGameLiftSessionError,
  } = useGetRandomGameLiftSession(metaverse?._id, aliasId, mapName);

  useEffect(() => {
    if (!randomGameLiftSession) return;
    setGameSessionId(randomGameLiftSession.GameSessionId);
  }, [randomGameLiftSession]);

  const [gameSessionId, setGameSessionId] = useState('');
  useJoinGameSession(gameSessionId);
  useInitialPacket(metaverse?._id, undefined, authToken, user, gameSessionId);
  useRefreshToken();
  useOpenExternalLink();
  useJoinSpace(space._id);
  useOpenModal();
  useProfileUpdate();
  useUserJoinAndExit();
  useEmojisBinding(!!metaverseId && !!authToken && !!user);

  const openMap = mapName && (!aliasId || getRandomGameLiftSessionError);
  useOpenMap(openMap ? mapName : undefined);

  const { hasUsage, isLoadingUsage } = useUsageContext();

  const defaultBackgroundSource: VisualSource = { srcType: 'image', src: defaultBackground };

  const attachRefs = (ref: HTMLDivElement) => {
    playerRef?.(ref);
    resizeRef(ref);
  };

  return (
    <>
      {status !== 'streaming' && <Background source={space.background ?? defaultBackgroundSource} />}
      <div ref={attachRefs} className={styles.player} />
      {!isLoadingUsage && hasUsage && <UsageLimitReachedScreen isOwner={false} logo={metaverse?.logo} />}
      {status === 'ready' && <LaunchContainer logo={metaverse?.logo} onEnter={connect} />}
      {status === 'connecting' && <LoadingInfo statuses={[launchStatus]} />}
      {status === 'connected' && <PlayOverlay />}
      {status === 'error' && <ErrorOverlay />}
      <HUD />
      <EmojiGestureSelection />
      <PointsOfInterest />
      <ContentViewer metaverse={metaverse} />
      <SettingsOverlay />
      <UploadContentModalContainer isSiteAdmin={false} metaverseId={metaverseId} />
      <TimeoutModal />
      <PureWebAvatarModal />
      <UsageLimitReachedModal isOwner={false} />
    </>
  );
}

export default function SpsMetaversePageContainer({ page }: SpsMetaversePageProps) {
  const { metaverse } = useAccessMetaverse();

  const signallingServerURL = page.space.environment.purewebConfiguration.spsSignallingServerURL;

  if (!metaverse || !signallingServerURL) return null;

  return (
    <UsageContextProvider metaverseId={metaverse._id}>
      <SpsContextProvider signallingServerURL={signallingServerURL}>
        <SpsMetaversePage page={page} />
      </SpsContextProvider>
    </UsageContextProvider>
  );
}
