import { useState } from 'react';

import usePxContext from '../../../../../authentication/usePxAuthContext';
import { isValidEmail, sanitizeText } from '../../../../../utils/StringUtils';
import Button from '../../Button';
import VisitorInput from '../../VisitorInput/VisitorInput';
import Actions from '../Actions';
import { useAuthModalContext } from '../AuthModalContextProvider';
import InputFields from '../InputFields';
import Title from '../Title';
import Description from '../Description/Description';

import styles from './Modal.module.scss';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'src/static/strings';

interface Props {
  onSwitchFormClick: Function;
  returnTo?: string; // Email verification returnTo
}

function toHumanReadableErrorMessage(error: any): string | null {
  if (!error) return null;
  if (error?.message === 'Passwords do not match') {
    return 'Passwords do not match';
  }
  if (error?.response?.data?.message === 'User already exists') {
    return 'User already exists';
  }
  if (error?.message === 'Invalid email') {
    return 'Invalid email';
  }
  return 'Register failed.';
}

export default function RegisterForm({
  onSwitchFormClick, returnTo,
}: Props) {
  const { hasEmailLoginForm } = useAuthModalContext();
  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { signup } = usePxContext();
  const [error, setError] = useState<Error>();

  function cleanUp() {
    setDisplayName('');
    setEmail('');
    setPassword('');
    setError(undefined);
  }

  async function completeUserRegister() {
    try {
      if (!displayName || !email || !password) {
        setShowRequiredFields(true);
        return;
      }
      if (!isValidEmail(email)) {
        setError(new Error('Invalid email'));
        return;
      }
      if (password !== passwordConfirm) {
        setError(new Error('Passwords do not match'));
        return;
      }
      await signup(
        email, password, { displayName, returnTo },
      );
      cleanUp();
    } catch (e: any) {
      setError(e);
    }
  }

  return (
    <>
      <Title>Register with Email</Title>
      {
        hasEmailLoginForm &&
        <Description>
          Already registered?&nbsp;
          <button type="button" onClick={() => onSwitchFormClick('login')}>Sign In</button>
        </Description>
      }
      <InputFields>
        <VisitorInput
          title="Display Name*"
          placeholder="Click to type..."
          maxLength={50}
          value={displayName}
          onValueChange={(value: string) => setDisplayName(sanitizeText(value))}
          showRequired={showRequiredFields}
        />
        <VisitorInput
          title="Email*"
          placeholder="Click to type..."
          value={email}
          onValueChange={(value: string) => setEmail(value)}
          showRequired={showRequiredFields}
        />
        <VisitorInput
          type="password"
          title="Password*"
          placeholder="Click to type..."
          value={password}
          onValueChange={(value: string) => setPassword(value)}
          showRequired={showRequiredFields}
        />
        <VisitorInput
          type="password"
          title="Confirm your password*"
          placeholder="Click to type..."
          value={passwordConfirm}
          onValueChange={(value: string) => setPasswordConfirm(value)}
          showRequired={showRequiredFields}
        />
        {
          error !== undefined &&
          <div className={styles.error}>
            {toHumanReadableErrorMessage(error)}
          </div>
        }
      </InputFields>
      <Actions>
        <Description>
          By registering, I agree to the <a target="_blank" rel="noopener noreferrer" href={PRIVACY_POLICY_LINK}>Privacy Policy</a> and the <a target="_blank" rel="noopener noreferrer" href={TERMS_OF_USE_LINK}>Terms of Use</a>
        </Description>
        <Button
          color="primary"
          onClick={completeUserRegister}
        >
          Register
        </Button>
      </Actions>
    </>
  );
}
