import { faArrowUpArrowDown, faStreetView, faVolume } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

import { selectFirstPersonCameraView, selectInvertYAxisControls, selectMusicVolume, selectOpenSettings, setFirstPersonCameraView, setInvertYAxisControls, setMusicVolume, setOpenSettings } from '../redux/hud';

import HUDButton from './HUDButton';
import HUDCheckBox from './HUDCheckBox';
import HUDSlider from './HUDSlider';

import styles from './SettingsOverlay.module.scss';

interface SettingsOverlayProps {
  className?: string;
}

export default function SettingsOverlay({
  className,
}: SettingsOverlayProps) {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenSettings);
  const musicVolume = useSelector(selectMusicVolume);
  const invertYAxisControls = useSelector(selectInvertYAxisControls);
  const firstPersonCameraView = useSelector(selectFirstPersonCameraView);
  const { emitUIInteraction } = usePixelStreamingContext();

  const handleMusicVolumeChange = useDebouncedCallback(
    (volume: number) => {
      if (!emitUIInteraction) return;
      emitUIInteraction({
        type: 'settings:music-volume',
        volume,
      });
      dispatch(setMusicVolume(volume));
    },
    100,
    { maxWait: 200 },
  );

  if (!open) return null;

  const handleInvertYAxisChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!emitUIInteraction) return;
    emitUIInteraction({
      type: 'settings:invert-y-axis',
      enabled: e.target.checked,
    });
    dispatch(setInvertYAxisControls(e.target.checked));
  };

  const handleFirstPersonCameraChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!emitUIInteraction) return;
    emitUIInteraction({
      type: 'settings:first-person-camera',
      enabled: e.target.checked,
    });
    dispatch(setFirstPersonCameraView(e.target.checked));
  };

  const handleClose = () => dispatch(setOpenSettings(false));

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.maxwidth}>
        <div className={styles.text}>SETTINGS</div>
        <div className={styles.inputfield}>
          <FontAwesomeIcon className={styles.icon} icon={faVolume} />
          <span className={styles.label}>Music Volume</span>
          <HUDSlider
            className={styles.slider}
            onChange={handleMusicVolumeChange}
            defaultValue={musicVolume}
          />
        </div>
        <div className={styles.smtext}>To optimize your experience, click on the preferred location instead of sliding to it.</div>
        <div className={styles.inputfield}>
          <FontAwesomeIcon className={styles.icon} icon={faArrowUpArrowDown} />
          Invert Y-Axis Controls
          <HUDCheckBox
            className={styles.checkbox}
            onChange={handleInvertYAxisChange}
            defaultChecked={invertYAxisControls}
          />
        </div>
        <div className={styles.inputfield}>
          <FontAwesomeIcon className={styles.icon} icon={faStreetView} />
          First-Person Camera View
          <HUDCheckBox
            className={styles.checkbox}
            onChange={handleFirstPersonCameraChange}
            defaultChecked={firstPersonCameraView}
          />
        </div>
        <HUDButton className={styles.button} onClick={handleClose}>Close</HUDButton>
      </div>
    </div>
  );
}
