import { ActionModal, Button, Typography } from '@pixelcanvas/ui';
import { useState } from 'react';

import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';

import { exportMetaverseVisitors } from '../../../../../services/MetaverseService';

import styles from './ExportParticipantsModal.module.scss';

interface ExportParticipantsModalProps {
  visible: boolean;
  onClose: Function;
}

const ExportParticipantsModal = (({ visible, onClose }: ExportParticipantsModalProps) => {
  const { metaverse } = useCurrentMetaverse();
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [csvLinks, setCSVLinks] = useState<string[] | null>(null);

  const metaverseId = metaverse?._id;

  const getContent = () => {
    if (loadingList) {
      return (
        <PlatformSpinner visible />
      );
    } if (csvLinks) {
      return (
        <>
          {csvLinks.map((link: string, ix: number) => (
            <a key={link} href={link} download>
              Attendee Batch {ix + 1}
            </a>))}
        </>
      );
    }
    return (
      <Typography variant="subtitle2" emphasis="high">
        Export your attendee list in CSV format.
      </Typography>
    );
  };

  const getCSVLinks = async () => {
    if (!metaverseId) return;
    setLoadingList(true);
    const csvLinks = await exportMetaverseVisitors(metaverseId);
    setCSVLinks(csvLinks.metaverseVisitors);
    setLoadingList(false);
  };

  const downloadAll = () => {
    csvLinks?.map((link: string) => window.open(link));
  };

  return (
    <ActionModal
      open={visible}
      onClose={() => onClose()}
    >
      <ActionModal.Title>
        EXPORT YOUR ATTENDEE LIST
      </ActionModal.Title>
      <ActionModal.Content className={styles.content}>
        {getContent()}
      </ActionModal.Content>
      <ActionModal.Actions>
        <Button color="tertiary" onClick={() => onClose()}>Cancel</Button>
        {!csvLinks &&
        <Button color="primary" onClick={() => getCSVLinks()}>Export</Button>}
        {csvLinks &&
        <Button color="primary" onClick={() => downloadAll()}>Download All</Button>}
      </ActionModal.Actions>
    </ActionModal>
  );
});

export default ExportParticipantsModal;
