import { useMutation, useQuery, useQueryClient } from 'react-query';
import PageType from 'src/enums/PageType';
import { PageResponse } from 'src/interfaces/IPage';
import type { PartialDeep } from 'type-fest';
import api from '../services/api';

export const queryKey = 'pages';

export const pageKeys = {
  all: [queryKey] as const,
  lists: () => [queryKey, 'list'] as const,
  list: (filters: string | undefined) => [...pageKeys.lists(), { filters }] as const,
  items: () => [queryKey, 'item'] as const,
  item: (id: string | undefined) => [...pageKeys.items(), id] as const,
};

export const usePages = (metaverseId: string | undefined) => useQuery<PageResponse[]>(
  pageKeys.list(metaverseId),
  () => api
    .get<PageResponse[]>('/api/page/pages', { params: { metaverseId } })
    .then((res) => res.data),
  { enabled: !!metaverseId },
);

export const usePage = (metaverseId: string | undefined, pageId: string | undefined) => useQuery<PageResponse>(
  pageKeys.item(pageId),
  () => api
    .get<PageResponse>('/api/page', { params: { metaverseId, pageId } })
    .then((res) => res.data),
  { enabled: Boolean(metaverseId && pageId) },
);

export function useUpdatePage(
  metaverseId: string | undefined,
  pageId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (update: PartialDeep<PageResponse>) => api
      .patch('/api/page', { metaverseId, pageId, ...update })
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pageKeys.lists());
        queryClient.invalidateQueries(pageKeys.item(pageId));
      },
    },
  );
}

export function useCreateStagePage(metaverseId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    () => api
      .post('/api/page', { metaverseId, type: PageType.Stage })
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pageKeys.lists());
      },
    },
  );
}

export function useDeletePage(metaverseId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    (pageId: string) => api
      .delete('/api/page', { params: { metaverseId, pageId } })
      .then((res) => res.data),
    {
      onSuccess: (_data, pageId) => {
        queryClient.invalidateQueries(pageKeys.item(pageId));
        queryClient.invalidateQueries(pageKeys.lists());
      },
    },
  );
}
