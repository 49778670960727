import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ShowcaseSlideshowContentDocument } from 'src/interfaces/IShowcaseContent';

import styles from './SlideshowContent.module.scss';

interface SlideshowContentProps {
  content: ShowcaseSlideshowContentDocument;
}

export default function SlideshowContent({ content }: SlideshowContentProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClickRight = () => {
    setCurrentImageIndex((i) => i + 1);
  };

  const handleClickLeft = () => {
    setCurrentImageIndex((i) => i - 1);
  };

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div className={styles.controls}>
          <button
            className={styles.button}
            type="button"
            onClick={handleClickLeft}
            disabled={currentImageIndex === 0}
          >
            <FontAwesomeIcon icon={faCircleArrowLeft} />
          </button>
          <p>Image {currentImageIndex + 1} of {content.images.length}</p>
          <button
            className={styles.button}
            type="button"
            onClick={handleClickRight}
            disabled={currentImageIndex === content.images.length - 1}
          >
            <FontAwesomeIcon icon={faCircleArrowRight} />
          </button>
        </div>
        <img
          className={styles.image}
          src={content.images[currentImageIndex]}
          alt=""
        />
      </div>
    </main>
  );
}
