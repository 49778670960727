import { Button, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, Typography } from '@pixelcanvas/ui';
import { useState } from 'react';
import isEmail from 'validator/es/lib/isEmail';

import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { ReactComponent as TriangleError } from '../../assets/svg/exclamation-triangle.svg';
import { ReactComponent as RemoveIcon } from '../../assets/svg/times.svg';
import { PlatformRole } from '../../interfaces';
import { sendEmailInvites } from '../../services/MetaverseService';
import ChipInput from '../ChipInput/ChipInput';

import styles from './EmailInviteModal.module.scss';

interface EmailInviteModalProps {
  visible: boolean;
  onClose: Function;
}

export default function EmailInviteModal({ visible, onClose }: EmailInviteModalProps) {
  const { metaverse } = useCurrentMetaverse();
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [attendee] = useState(true);
  const metaverseId = metaverse?._id;

  const onAdd = (email: string) => {
    setEmailAddresses((prevEmailAddresses) => [...prevEmailAddresses, email]);
  };

  const validation = (email: string) => {
    if (!isEmail(email)) {
      setError(true);
      return false;
    }
    if (error) setError(false);
    return true;
  };

  async function closeModal() {
    setEmailAddresses([]);
    setError(false);
    onClose();
  }

  async function sendInvites() {
    if (!metaverseId) return;
    try {
      const roles: PlatformRole[] = [];
      if (attendee) roles.push(PlatformRole.Attendee);
      await sendEmailInvites(emailAddresses, roles, metaverseId);
      closeModal();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <TaskModal
      open={visible}
      onClose={() => onClose()}
      className={styles.modal}
    >
      <TaskModalTitle onClose={() => onClose()}>
        INVITE ATTENDEES
      </TaskModalTitle>
      <TaskModalContent>
        <ChipInput
          title="EMAIL ADDRESS"
          placeholder="Enter Email Address"
          onAdd={onAdd}
          validation={validation}
          helperText="Enter a space or comma after each email address"
        />
        {
          error &&
          <div className={styles['invalid-address']}>
            <TriangleError className={styles['triangle-error']} />
            Invalid Email Address
          </div>
        }
        <div className={styles.content}>
          {
            emailAddresses.map((address: string) => (
              <div key={address} className={styles['email-chip']}>
                <Typography variant="body1"> {address} </Typography>
                <div>
                  <RemoveIcon
                    className={styles['remove-icon']}
                    onClick={() => {
                      setEmailAddresses(emailAddresses.filter((e: string) => e !== address));
                    }}
                  />
                </div>
              </div>
            ))
          }
        </div>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={() => closeModal()}>Cancel</Button>
        <Button onClick={() => sendInvites()} disabled={emailAddresses.length === 0}>Send</Button>
      </TaskModalActions>
    </TaskModal>
  );
}
