import { Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';

import { OrganizationResponse } from 'src/interfaces/IOrganization';

import DefaultOrganizationIcon from '../../pages/OrganizationPage/components/DefaultOrganizationIcon';

import styles from './OrganizationSelect.module.scss';

type OrganizationSelectProps = {
  className?: string;
  selected: boolean;
  organization: OrganizationResponse;
  onClick: () => void;
};

export default function OrganizationSelect({ className, selected, organization, onClick }: OrganizationSelectProps) {
  return (
    <button
      key={organization._id}
      className={clsx(className, styles.button, { [styles.selected]: selected })}
      type="button"
      onClick={onClick}
    >
      {organization?.logo ?
        <img
          className={styles.picture}
          src={organization?.logo}
          alt={organization?.name}
        />
        :
        <DefaultOrganizationIcon className={styles.defaultIcon} />}
      <Typography variant="h5" emphasis="high">
        {organization?.name}
      </Typography>
    </button>
  );
}
