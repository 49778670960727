import api from './api';
import { TagType } from '../enums/TagType';
import { IContent } from '../interfaces/IContent';
import { ICta } from '../interfaces/ICta';
import { IPage } from '../interfaces/IPage';
import { PureWebConfigurationResponse } from '../interfaces/IPurewebConfiguration';

export async function createPage(page: IPage) {
  const res = await api.post<IPage>('/api/page', page);
  return res.data;
}

export async function getPages(metaverseId: string) {
  const res = await api.get<IPage[]>(`/api/page/pages?metaverseId=${metaverseId}`);
  return res.data;
}

export async function getPageContents(metaverseId: string, pageId: string) {
  const res = await api.get<IContent[]>(`/api/content/page?metaverseId=${metaverseId}&pageId=${pageId}`);
  return res.data;
}

export async function patchPage(page: IPage) {
  const res = await api.patch<IPage>('/api/page', page);
  return res.data;
}

export async function deletePage(metaverseId: string, pageId: string) {
  const res = await api.delete<{ message: 'OK' }>(`/api/page?metaverseId=${metaverseId}&pageId=${pageId}`);
  return res.data;
}

export async function createContent(metaverseId: string, pageId: string, content: IContent) {
  content.metaverseId = metaverseId;
  content.pageId = pageId;
  content.button = { text: '', link: '' };
  const res = await api.post<IContent>('/api/content', content);
  return res.data;
}

export async function patchContent(content: IContent) {
  const res = await api.patch<IContent>('/api/content', content);
  return res.data;
}

export async function deleteContent(metaverseId: string, contentId: string) {
  const res = await api.delete<{ message: 'OK' }>(`/api/content?metaverseId=${metaverseId}&contentId=${contentId}`);
  return res.data;
}

export async function getContent(metaverseId: string, contentId = '', tags: string[] = [], vod = false) {
  const tagsString = tags.toString().replace('[', '').replace(']', '');
  const res = await api.get<IContent[]>(`/api/content?metaverseId=${metaverseId}&contentId=${contentId}&tags=${tagsString}&vod=${vod}`);
  return res.data;
}

export async function getTags(metaverseId: string, tagType: TagType, agendaOnly: boolean, timezone = '') {
  let res;
  if (timezone) {
    res = await api.get<any[]>(`/api/content/tags?metaverseId=${metaverseId}&tagType=${tagType}&agendaOnly=${agendaOnly}&timezone=${timezone}`);
  } else {
    res = await api.get<any[]>(`/api/content/tags?metaverseId=${metaverseId}&tagType=${tagType}&agendaOnly=${agendaOnly}`);
  }

  return res.data;
}

export async function getPageCta(metaverseId: string, pageId: string) {
  const res = await api.get<ICta[]>(`/api/content/cta?metaverseId=${metaverseId}&pageId=${pageId}`);
  return res.data;
}

export async function createCta(metaverseId: string, pageId: string, cta: ICta) {
  cta.metaverseId = metaverseId;
  cta.pageId = pageId;
  const res = await api.post<ICta>('/api/content/cta', cta);
  return res.data;
}

export async function patchCta(cta: ICta) {
  const res = await api.patch<ICta>('/api/content/cta', cta);
  return res.data;
}

export async function deleteCta(metaverseId: string, ctaId: string) {
  const res = await api.delete<{ message: string }>(`/api/content/cta?metaverseId=${metaverseId}&ctaId=${ctaId}`);
  return res.data;
}

export async function getPurewebConfigs(metaverseId: string) {
  const res = await api.get<PureWebConfigurationResponse[]>(`/api/content/purewebConfigs?metaverseId=${metaverseId}`);
  return res.data;
}

export async function getPurewebConfigForPage(metaverseId: string, pageId: string) {
  const res = await api.get<PureWebConfigurationResponse>(`/api/content/purewebConfigs?metaverseId=${metaverseId}&pageId=${pageId}`);
  return res.data;
}
export async function createPurewebConfig(config: PureWebConfigurationResponse) {
  const res = await api.post<PureWebConfigurationResponse>('/api/content/purewebConfigs', config);
  return res.data;
}

export async function patchPurewebConfig(config: PureWebConfigurationResponse) {
  const res = await api.patch<PureWebConfigurationResponse>('/api/content/purewebConfigs', config);
  return res.data;
}

export async function getAgendaContentForTag(metaverseId: string, tagType: TagType, tag: string, timezone = 'UTC') {
  const res = await api.get<any>(`/api/content/agenda?metaverseId=${metaverseId}&tagType=${tagType}&tag=${tag}&timezone=${timezone}`);
  return res.data;
}

export async function uploadImageContent(metaverseId: string, image: string) {
  try {
    const formData = new FormData();
    formData.append('picture', image);
    const response = await api.post(`/api/content/image?metaverseId=${metaverseId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e?.response?.data?.detail || 'Image upload failed');
  }
}

export async function uploadVideoContent(metaverseId: string, video: string) {
  try {
    const formData = new FormData();
    formData.append('video', video);
    const response = await api.post(`/api/content/video?metaverseId=${metaverseId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e?.response?.data?.detail || 'Video upload failed');
  }
}
