import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TextInputField, Typography, UploadButton,
} from '@pixelcanvas/ui';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import { OrganizationResponse } from 'src/interfaces/IOrganization';
import { readURL } from '../../../../../../utils/FileUtil';
import styles from './OrganizationProfile.module.scss';

interface OrganizationProfileProps {
  localOrganizationDetails: OrganizationResponse;
  setLocalOrganizationDetails: (organization: OrganizationResponse) => void;
  isOwner: boolean;
  showSpinner: boolean;
  showRequired: boolean;
  uploadLogo: (image: string) => void;
}

export default function OrganizationProfile({
  localOrganizationDetails,
  setLocalOrganizationDetails,
  isOwner,
  showSpinner,
  showRequired,
  uploadLogo,
}: OrganizationProfileProps) {
  return (
    <>
      <Typography variant="h2" emphasis="high" className={styles.heading}>Organization Profile</Typography>
      {
        isOwner ? <TextInputField
          className={styles.inputField}
          label="ORGANIZATION NAME"
          value={localOrganizationDetails?.name}
          onChange={(e) => setLocalOrganizationDetails({ ...localOrganizationDetails, name: e.target.value })}
          disabled={!isOwner}
          maxLength={30}
          required
          error={showRequired && !localOrganizationDetails.name ? 'Organization name cannot be empty' : undefined}
        />
          :
        <>
          <Typography variant="h6" className={styles.fieldName}>Organization Name</Typography>
          <Typography variant="body1" emphasis="high" className={styles.field}>{localOrganizationDetails?.name}</Typography>
        </>
      }
      <Typography variant="h6" className={styles.field}>
        ORGANIZATION PROFILE PICTURE
      </Typography>
      <div className={styles.uploadContainer}>
        <div className={styles.uploadRendered}>
          <PlatformSpinner visible={showSpinner} />
          <div className={styles.dashedBorder}>
            {
              localOrganizationDetails?.logo ?
                <img
                  className={styles.picture}
                  src={localOrganizationDetails.logo}
                  alt={localOrganizationDetails.name}
                />
                :
                <FontAwesomeIcon icon={faUsers} className={styles.icon} />
            }
          </div>
        </div>
        { isOwner &&
        <div className={styles.uploadActionsContainer}>
          <UploadButton
            color="secondary"
            id="file-upload-organization-profile"
            type="file"
            accept="image/*"
            onChange={(e) => readURL(e.target, (file: any) => uploadLogo(file))}
          >
            Upload
          </UploadButton>
          <Typography variant="body2" className={styles.uploadDescription}>
            Profile uploads cannot exceed 2MB.
          </Typography>
        </div>}
      </div>
    </>
  );
}
