import { useEffect } from 'react';
import { getGAClientId } from 'src/utils/DataLayerUtil';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

interface InitialPacketMessage {
  type: 'initial';
  status: string;
}

export default function useInitialPacket(
  metaverseId: string | undefined,
  organizationId: string | undefined,
  authToken: string | undefined,
  user: any,
  gameSessionId: string | undefined | null,
) {
  const { descriptor, emitUIInteraction } = usePixelStreamingContext();
  useEffect(() => {
    if (!emitUIInteraction || !descriptor || !metaverseId || !authToken || !user) {
      return () => {};
    }

    const initListener = (message: InitialPacketMessage) => {
      if (message.status !== 'done' || !metaverseId || !authToken || !user) return;
      emitUIInteraction({
        type: 'initial',
        userId: user.userId,
        token: authToken,
        metaverseId,
        organizationId,
        apiUrl: window.location.origin,
        gaClientId: getGAClientId(),
      });

      if (!gameSessionId) return;
      const joinSessionMessage = {
        type: 'join_session',
        gameSessionId,
      };
      emitUIInteraction(joinSessionMessage);
    };

    descriptor.on('init', initListener);
    return () => {
      descriptor.off('init', initListener);
    };
  }, [descriptor, emitUIInteraction, metaverseId, authToken, user, gameSessionId]);
}
