enum OrganizationRole {
  Owner = 'Owner',
  Administrator = 'Administrator',
  Moderator = 'Moderator',
  Viewer = 'Viewer',
}

const OrganizationRoleRank = [
  OrganizationRole.Owner,
  OrganizationRole.Administrator,
  OrganizationRole.Moderator,
  OrganizationRole.Viewer,
];

export const OrganizationEditableRoles = [
  OrganizationRole.Owner,
  OrganizationRole.Administrator,
];

export function organizationRoleCompare(a: OrganizationRole, b: OrganizationRole) {
  return (OrganizationRoleRank.indexOf(a) ?? -1) - (OrganizationRoleRank.indexOf(b) ?? -1);
}

export default OrganizationRole;
