import UsageWarningContainer from 'src/features/Visitor/components/Usage/UsageWarningContainer/UsageWarningContainer';
import { useUsageContext } from '../contexts/UsageContextProvider';

const REQUEST_TIME_URL = window.env.REACT_APP_SUPPORT_MAILTO_LINK ?? 'mailto:support@pixelcanvas.com';

interface UsageLimitReachedScreenProps {
  isOwner: boolean;
  logo: string | undefined;
}

export default function UsageLimitReachedScreen({ isOwner, logo }: UsageLimitReachedScreenProps) {
  const { isLoadingUsage, hasUsage } = useUsageContext();
  if (isLoadingUsage || hasUsage) return null;
  return (
    <UsageWarningContainer
      logo={logo}
      isOwner={isOwner}
      onRequestTime={() => window.open(REQUEST_TIME_URL, '_blank')}
    />
  );
}
