import EventEmitter from 'events';
import { createContext, useContext } from 'react';

export interface IPixelStreamingContext {
  emitUIInteraction: ((value: unknown) => void) | null,
  descriptor: EventEmitter,
  isStreaming: boolean,
  connect: () => void,
}

export const PixelStreamingContext = createContext<IPixelStreamingContext>({
  emitUIInteraction: null,
  descriptor: new EventEmitter(),
  isStreaming: false,
  connect: () => {},
});

export function usePixelStreamingContext() {
  const context = useContext(PixelStreamingContext);
  if (!context) {
    throw new Error('Context can only be used within a PixelStreamingContextProvider');
  }
  return context;
}
