enum ModerationAction {
  BanUser = 'banUser',
  DeleteMessage = 'deleteMessage',
  DeleteRoom = 'deleteRoom',
  HideVideo = 'hideVideo',
  RemoveUser = 'removeUser',
  MuteAudio = 'muteAudio',
  SeeInterests = 'seeInterests',
}

export default ModerationAction;
