import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDiamond } from '@fortawesome/sharp-solid-svg-icons';
import clsx from 'clsx';

import { IMetaverse } from '../../../../interfaces/IMetaverse';

import styles from './MetaverseStatus.module.scss';

interface Props {
  metaverse: IMetaverse,
}

export default function MetaverseStatus({ metaverse }: Props) {
  const status = metaverse.published ? 'LIVE' : 'DRAFT';
  const icon = metaverse.published ? faCircle : faDiamond;

  return (
    <div className={clsx(styles.pill, styles[status])}>
      <FontAwesomeIcon className={styles.logo} icon={icon} />
      <div className={styles.label}>{status}</div>
    </div>
  );
}
