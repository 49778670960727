import React, { createContext, useContext, useEffect, useState } from 'react';
import { NotificationType } from '@pixelcanvas/ui';

interface AddToastOptions {
  type?: NotificationType;
  expiresIn?: number;
}

export interface IToastMessageContext {
  toasts: IToastMessage[];
  addToast: (message: string, options?: AddToastOptions) => void;
  removeToast: (item: IToastMessage) => void;
  clearToasts: () => void;
}

export interface IToastMessage {
  type: NotificationType;
  message: string;
  expiresIn: number;
  createdAt: Date;
}

export const ToastMessageContext = createContext<IToastMessageContext>(null!);

export function useToastMessageContext() {
  const context = useContext(ToastMessageContext);

  if (!context) {
    throw new Error('Context can only be used within ToastMessageContextProvider');
  }
  return context;
}

interface ToastMessageProviderProps {
  children: React.ReactNode;
}

const ToastMessageContextProvider = ({ children }: ToastMessageProviderProps) => {
  const [toasts, setToasts] = useState<IToastMessage[]>([]);

  useEffect(() => {
    if (!toasts[0]) return () => {};
    const timeout = setTimeout(() => removeToast(toasts[0]), toasts[0].expiresIn);
    return () => clearTimeout(timeout);
  }, [toasts[0]]);

  const addToast = (message: string, options: AddToastOptions = {}) => {
    const newToast = {
      type: options.type ?? 'success',
      message,
      expiresIn: options.expiresIn ?? 5000,
      createdAt: new Date(),
    };
    setToasts((prev) => [...prev, newToast]);
  };

  const clearToasts = () => setToasts([]);

  const removeToast = (item: IToastMessage) => {
    setToasts((prev) => {
      if (!prev.includes(item)) {
        // console.log('unable to remove toast', item, prev);
        return prev;
      }
      const updatedToasts = [...prev];
      updatedToasts.splice(updatedToasts.indexOf(item), 1);
      // console.log('removing toast', item);
      return updatedToasts;
    });
  };

  return (
    <ToastMessageContext.Provider
      value={{
        toasts,
        addToast,
        removeToast,
        clearToasts,
      }}
    >
      {children}
    </ToastMessageContext.Provider>
  );
};

export default ToastMessageContextProvider;
