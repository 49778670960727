import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import isURL from 'validator/es/lib/isURL';

import { readURL } from 'src/utils/FileUtil';
import { useUploadVideoContent } from 'src/queries/content';

import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import { ClipLoader } from 'react-spinners';
import Button from '../Button';
import VisitorInput from '../VisitorInput/VisitorInput';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';

import styles from './UploadVideoModal.module.scss';

interface Props {
  metaverseId: string | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: (fileUrl: string) => void;
}

export default function UploadVideoModal({
  metaverseId, open, onClose, onConfirm,
}: Props) {
  const [error, setError] = useState('');
  const [url, setUrl] = useState('');

  const {
    mutate: uploadVideo,
    data,
    isSuccess,
    isLoading,
  } = useUploadVideoContent(metaverseId);

  useEffect(() => {
    if (!isSuccess || !data) return;
    setUrl(`${window.location.origin}${data.location}`);
  }, [isSuccess, data]);

  const handleValueChange = (value: string) => {
    setUrl(value);
    if (value && !isURL(value)) {
      setError('Please enter a valid URL');
      return;
    }
    if (value && !value.endsWith('.mp4') && !value.endsWith('m3u8')) {
      setError('Only .mp4 or .m3u8 files are supported');
    } else {
      setError('');
    }
  };

  const handleConfirm = () => {
    onConfirm(url);
    closeModal();
  };

  const closeModal = () => {
    setUrl('');
    onClose();
  };

  const handleUpload = (fileURL: string) => {
    uploadVideo(fileURL);
  };

  return (
    <VisitorModal
      className={styles.modal}
      open={open}
      onClose={closeModal}
    >
      <VisitorModalContent>
        <Typography variant="h2" className={styles.title}>
          ADD YOUR VIDEO
        </Typography>
        <div className={styles.inputs}>
          <VisitorInput
            className={styles.link}
            title="Direct Link"
            placeholder="Direct link to .mp4 or .m3u8"
            value={url}
            onValueChange={handleValueChange}
            error={error}
          />
          <IconInput
            type="file"
            accept="video/mp4"
            onChange={(e) => readURL(e.target, handleUpload)}
          />
        </div>
        <PlatformSpinner visible={isLoading} />
      </VisitorModalContent>
      <VisitorModalActions className={styles['modal-actions']}>
        <Button color="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!!error}
          onClick={handleConfirm}
        >
          Save
        </Button>
      </VisitorModalActions>
    </VisitorModal>
  );
}

function IconInput({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <label className={clsx(styles.upload, className)}>
      <FontAwesomeIcon className={styles.icon} icon={faUpload} />
      <input className={styles.input} {...props} />
    </label>
  );
}
