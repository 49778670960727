import { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/search.svg';
import styles from './SearchBar.module.scss';

interface SearchBarProps {
  className?: string;
  label?: string;
  onChange: Function;
}

const SearchBar = ({ className, label = 'Search...', onChange }: SearchBarProps) => {
  const [text, setText] = useState('');

  const onInputChange = (e:any) => {
    setText(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <div className={clsx(className, styles.container)}>
      <input
        className={styles.input}
        placeholder={label}
        onChange={onInputChange}
        type="search"
      />
      { !text && <SearchIcon className={styles.icon} /> }
    </div>
  );
};

export default SearchBar;
