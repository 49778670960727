import clsx from 'clsx';
import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';
import { ReactComponent as CoinIcon } from '../../../assets/svg/coin.svg';
import { useGameSocket } from '../../../contexts/GameSocketContextProvider';
import PointsNotification from './PointsNotification';

import styles from './PointsDisplay.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPointsDisplayEnabled, setPointsDisplayEnabled } from '../redux/hud';

const POINTS_NOTIFICATION_DURATION = 3000;

interface PointsDisplayProps {
  className?: string;
}

export default function PointsDisplay({
  className,
}: PointsDisplayProps) {
  const dispatch = useDispatch();
  const pointsDisplayEnabled = useSelector(selectPointsDisplayEnabled);
  const { descriptor } = usePixelStreamingContext();
  const { points, itemCompleted } = useGameSocket();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (!descriptor) return () => { };
    const enabledListener = ({ enabled: isEnabled }: { enabled: boolean }) => {
      dispatch(setPointsDisplayEnabled(isEnabled));
    };
    descriptor.on('hud:points', enabledListener);
    return () => {
      descriptor.off('hud:points', enabledListener);
    };
  }, [descriptor]);

  useEffect(() => {
    if (itemCompleted) {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, POINTS_NOTIFICATION_DURATION);
    }
  }, [itemCompleted]);

  if (!pointsDisplayEnabled) return null;

  return (
    <>
      <div className={clsx(className, styles.container)}>
        <CoinIcon className={styles.coinIcon} />
        <span className={styles.points}>{points.toString().padStart(5, "0")}</span>
      </div>
      {
        itemCompleted && itemCompleted.pointsEarned > 0 &&
        <PointsNotification points={itemCompleted.pointsEarned} show={showNotification} />
      }
    </>
  );
}
