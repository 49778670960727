import { Typography } from '@pixelcanvas/ui';
import axios from 'axios';
import { useEffect, useState } from 'react';

import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import Button from 'src/features/Visitor/components/Button';
import NFTSelectionModal from 'src/features/Visitor/components/NFTSelectionModal/NFTSelectionModal';
import VisitorModal from 'src/features/Visitor/components/VisitorModal/VisitorModal';
import VisitorModalActions from 'src/features/Visitor/components/VisitorModalActions/VisitorModalActions';
import VisitorModalContent from 'src/features/Visitor/components/VisitorModalContent/VisitorModalContent';
import { useGetImageSlot, useSetImageSlot } from 'src/queries/metaverse';
import { uploadImageContent, uploadVideoContent } from 'src/services/ContentService';
import { compressImage, readURL } from 'src/utils/FileUtil';
import imagePlaceholder from '../../../../assets/images/placeholder-image-event.png';
import { usePixelStreamingContext } from '../../contexts/PixelStreamingContext';
import { ImageSlotMessageType } from './enums/ImageSlotMessageType';
import VisitorInput from 'src/features/Visitor/components/VisitorInput/VisitorInput';
import isURL from 'validator/es/lib/isURL';

import styles from './ImageSlotUploadModal.module.scss';

interface ImageSlotUploadMessage {
  type: ImageSlotMessageType.Upload;
  slotId: string;
  src: string;
}

interface ImageSlotUploadModalProps {
  metaverseId: string;
  spaceId: string;
  isSiteAdmin?: boolean;
}

export default function ImageSlotUploadModal({
  metaverseId,
  spaceId,
  isSiteAdmin,
}: ImageSlotUploadModalProps) {
  const [slotId, setSlotId] = useState('');
  const [content, setContent] = useState('');
  const [link, setLink] = useState<string | undefined>('');
  const [linkText, setLinkText] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileType, setFileType] = useState('image');
  const [openNFTModal, setOpenNFTModal] = useState(false);
  const [error, setError] = useState('');
  const [linkError, setLinkError] = useState('');
  const { data: imageSlot } = useGetImageSlot(metaverseId, spaceId, slotId);

  const { descriptor } = usePixelStreamingContext();

  const {
    mutate: setImageSlot,
    isSuccess,
  } = useSetImageSlot(metaverseId, spaceId, slotId);

  useEffect(() => {
    closeModal();
  }, [isSuccess]);

  useEffect(() => {
    if (!descriptor) return;
    const listener = ({ slotId: id }: ImageSlotUploadMessage) => {
      setSlotId(id);
    };
    descriptor.on(ImageSlotMessageType.Upload, listener);
    return () => {
      descriptor.off(ImageSlotMessageType.Upload, listener);
    }
  }, [descriptor])

  useEffect(() => {
    if (!imageSlot) {
      setContent('');
      setLink('');
      setLinkText('');
      setDescription('');
      return;
    }
    setContent(imageSlot.src);
    setLink(imageSlot.href);
    setLinkText(imageSlot.linkText);
    setDescription(imageSlot.description);
  }, [imageSlot]);

  const closeModal = () => {
    setContent('');
    setSlotId('');
  };

  const onNFTSelected = async (selectedNFT: string) => {
    const file = await axios
      .get(selectedNFT, { responseType: 'blob' })
      .then((res) => new File([res.data], 'nft', { type: res.headers['content-type'] }));

    const updatedPicture = file.type === 'image/gif' ? file : await compressImage(file);
    const response: any = await uploadImageContent(metaverseId, updatedPicture as any);
    setContent(response.location);
    setOpenNFTModal(false);
  };

  async function uploadSpaceContent(fileUrl: string, localFileType: string) {
    try {
      if (localFileType === 'image') {
        const response: any = await uploadImageContent(metaverseId, fileUrl);
        setContent(response.location);
        setFileType('image');
      } else {
        const response: any = await uploadVideoContent(metaverseId, fileUrl);
        setContent(response.location);
        setFileType('video');
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setShowSpinner(false);
    }
  }

  const handleConfirm = () => {
    setImageSlot({ slotId: slotId, spaceId, src: content, href: link, linkText, description });
  };

  const handleLinkChange = (value: string) => {
    const trimmedValue = value.trim();
    setLink(trimmedValue);
    if (trimmedValue && !isURL(trimmedValue)) {
      setLinkError('Please enter a valid URL');
      return;
    } else {
      setLinkError('');
    }
  };

  const handleLinkTextChange = (value: string) => {
    setLinkText(value);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const open = !!slotId;

  return (
    <VisitorModal
      className={styles.modal}
      open={open}
      onClose={closeModal}
    >
      <VisitorModalContent>
        <Typography variant="h2" className={styles.title}>
          UPLOAD YOUR IMAGE
        </Typography>
        <div className={styles['upload-container']}>
          <div className={styles['upload-rendered']}>
            <PlatformSpinner visible={showSpinner} />
            {
              fileType === 'image' ?
                <img className={styles['content-preview']} src={content !== '' ? content : imagePlaceholder} alt="" />
                :
                <video className={styles['content-preview']} src={content} controls />
            }
          </div>
          <div className={styles.uploadActionsContainer}>
            <div className={styles.uploadActions}>
              {isSiteAdmin &&
                <label
                  className={styles.uploadButton}
                  htmlFor="content-upload-modal"
                >
                  Upload
                </label>}
              <Button
                className={styles.selectnft}
                color="secondary"
                onClick={() => setOpenNFTModal(true)}
              >
                Select NFT
              </Button>
              <Button
                className={styles.selectnft}
                color="secondary"
                onClick={() => setContent('')}
              >
                Clear
              </Button>
            </div>
            <div className={styles.uploadDescription}>
              Image uploads cannot exceed 20MB.
            </div>
            {error &&
              <div className={styles.error}>
                {error}
              </div>}
            <input
              className={styles.fileInput}
              id="content-upload-modal"
              type="file"
              accept="image/*"
              onChange={(e) => readURL(e.target, (file: any, localFileType: string) => uploadSpaceContent(file, localFileType), () => setShowSpinner(true))}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <VisitorInput
            title="Link"
            value={link}
            onValueChange={handleLinkChange}
            error={linkError}
          />
          <VisitorInput
            title="Link Text"
            value={linkText}
            onValueChange={handleLinkTextChange}
          />
          <VisitorInput
            title="Description"
            value={description}
            onValueChange={handleDescriptionChange}
          />
        </div>
      </VisitorModalContent>
      <VisitorModalActions className={styles['modal-actions']}>
        <Button color="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </VisitorModalActions>
      <NFTSelectionModal
        open={openNFTModal}
        onNFTSelected={onNFTSelected}
        onClose={() => setOpenNFTModal(false)}
      />
    </VisitorModal>
  );
}
