export const SET_ATTENDEES = 'SET_ATTENDEES';
export const ADD_ATTENDEES = 'ADD_ATTENDEES';
export const ADD_ATTENDEE = 'ADD_ATTENDEE';

const initialState = [];

export const attendeesReducer = (state = initialState, action = {}) => {
  let newState;
  switch (action.type) {
    case SET_ATTENDEES: {
      newState = [...action.payload];
      return newState;
    }
    case ADD_ATTENDEES: {
      newState = state.concat(action.payload);
      return newState;
    }
    case ADD_ATTENDEE: {
      newState = [...state];
      if (!action.payload.isOnline) {
        newState.push(action.payload);
        return newState;
      }
      // If user is online, add user after online users
      for (let i = 0; i < newState.length; i++) {
        const curAttendee = newState[i];
        if (i === newState.length - 1) {
          newState.push(action.payload);
          break;
        } else if (!curAttendee.isOnline) {
          newState.splice(i, 0, action.payload);
          break;
        }
      }
      return newState;
    }
    default: return state;
  }
};

export function setAttendees(data) {
  return { type: SET_ATTENDEES, payload: data };
}

export function addAttendees(data) {
  return { type: ADD_ATTENDEES, payload: data };
}

export function addAttendee(data) {
  return { type: ADD_ATTENDEE, payload: data };
}
