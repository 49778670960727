import { useQuery } from 'react-query';
import { SpaceResponse } from '../interfaces/ISpace';
import api from '../../../services/api';

export const queryKey = 'spaces';

export const useGetSpaces = (usage?: boolean) => useQuery<SpaceResponse[]>(
  queryKey,
  () => api
    .get<SpaceResponse[]>('/api/spaces', { params: { usage } })
    .then((res) => res.data),
);

export const useGetSpace = (id: string) => useQuery<SpaceResponse>(
  [queryKey, id],
  () => api
    .get<SpaceResponse>(`/api/spaces/${id}`)
    .then((res) => res.data),
);

export async function uploadSpaceImage(spaceId: string, image: string) {
  try {
    const formData = new FormData();
    formData.append('picture', image);
    const response = await api.post(`/api/spaces/image?spaceId=${spaceId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e?.response?.data?.detail || 'Image upload failed');
  }
}

export async function uploadSpaceVideo(spaceId: string, video: string) {
  try {
    const formData = new FormData();
    formData.append('video', video);
    const response = await api.post(`/api/spaces/video?spaceId=${spaceId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e?.response?.data?.detail || 'Video upload failed');
  }
}
