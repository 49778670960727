import { AxiosResponse } from 'axios';
import api from './api';
import { Room } from '../interfaces';
import RoomParticipant from '../interfaces/RoomParticipant';
import { dataLayer } from '../utils/DataLayerUtil';

export async function getVideoToken(metaverseId: string, roomId: string): Promise<AxiosResponse<any>> {
  const response = await api.get(`/api/rooms/videotoken?metaverseId=${metaverseId}&roomId=${roomId}`);
  return response.data;
}

export async function getRecentMessages(metaverseId: string, roomId: string): Promise<AxiosResponse<any>> {
  const response = await api.get(`/api/rooms/messages/recent?metaverseId=${metaverseId}&roomId=${roomId}`);
  return response.data;
}

export async function getMessagesBefore(metaverseId: string, roomId: string, refMessageId: string): Promise<AxiosResponse<any>> {
  const response = await api.get(`/api/rooms/messages/before?metaverseId=${metaverseId}&roomId=${roomId}&messageId=${refMessageId}&limit=10`);
  return response.data;
}

export async function getRoom(metaverseId: string, roomId: string): Promise<Room> {
  const response = await api.get(`/api/rooms?metaverseId=${metaverseId}&roomId=${roomId}`);
  return response.data;
}

export async function getRoomByUniqueId(metaverseId: string, uniqueId: string): Promise<Room> {
  const response = await api.get(`/api/rooms?metaverseId=${metaverseId}&uniqueId=${uniqueId}`);
  return response.data;
}

export async function getRoomParticipants(metaverseId: string, roomId: string): Promise<AxiosResponse<any>> {
  const response = await api.get(`/api/rooms/participants?metaverseId=${metaverseId}&roomId=${roomId}`);
  return response.data;
}

export async function getRoomParticipant(metaverseId: string, roomId: string, userId: string): Promise<RoomParticipant> {
  const response = await api.get(`/api/rooms/participants/user?metaverseId=${metaverseId}&roomId=${roomId}&userId=${userId}`);
  return response.data;
}

export async function getRooms(metaverseId: string) {
  const response = await api.get(`/api/rooms/available?metaverseId=${metaverseId}&types=private,public`);
  return response.data;
}

export async function getRoomsForContent(metaverseId: string, contentId: string) {
  const response = await api.get(`/api/rooms/contentlivestream?metaverseId=${metaverseId}&contentId=${contentId}`);
  return response.data;
}

export async function postRoom(room: any) {
  const response = await api.post('/api/rooms', room);
  if (response.status === 201) {
    dataLayer.push({ event: 'room_create', px_event_id: response.data.event, px_room_id: response.data._id });
  }
  return { room: response.data, status: response.status };
}

export async function postDirectMessageRoom(metaverseId: string, targetUserId: string) {
  const response = await api.post('/api/rooms/dm', { metaverseId, targetUserId });
  if (response.status === 201) {
    dataLayer.push({ event: 'room_create', px_event_id: response.data.event, px_room_id: response.data._id });
  }
  return { room: response.data, status: response.status };
}

export async function patchRoom(room: any) {
  const response = await api.patch(`/api/rooms?roomId=${room._id}`, room);
  return response.data;
}

export async function patchPrivateStreamingRoom(room: any) {
  const response = await api.patch('/api/rooms/live-stream-room', room);
  return response.data;
}

export async function inviteToRoom(metaverseId: string, roomId: string, invite: string[]) {
  const response = await api.patch('/api/rooms/invite', { metaverseId, roomId, invite });
  return response.data;
}

export async function joinRoom(metaverseId: string, roomId: string) {
  const response = await api.patch('/api/rooms/join', { metaverseId, roomId });
  dataLayer.push({ event: 'room_join', px_event_id: metaverseId, px_room_id: roomId });
  return response.data;
}

export async function deleteRoom(metaverseId: string, roomId: string) {
  const response = await api.delete(`/api/rooms?metaverseId=${metaverseId}&roomId=${roomId}`);
  dataLayer.push({ event: 'room_delete', px_event_id: metaverseId, px_room_id: roomId });
  return response.data;
}

export async function getConnectedParticipants(metaverseId: string) {
  const response = await api.get(`/api/rooms/connectedparticipants/metaverse?metaverseId=${metaverseId}`);
  return response.data;
}

export async function startTwilioStream(metaverseId: string, roomId: string) {
  const response = await api.get(`/api/rooms/start-stream?metaverseId=${metaverseId}&roomId=${roomId}`);
  return response.data;
}

export async function endTwilioStream(metaverseId: string, roomId: string) {
  const response = await api.get(`/api/rooms/end-stream?metaverseId=${metaverseId}&roomId=${roomId}`);
  return response.data;
}

export async function getAudienceToken(metaverseId: string, uniqueId: string) {
  const response = await api.get(`/api/rooms/audience-token?metaverseId=${metaverseId}&uniqueId=${uniqueId}`);
  return response.data;
}
