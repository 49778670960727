import { Ability } from '@casl/ability';
import { MoreOptions, Table, Typography } from '@pixelcanvas/ui';
import {
  createColumnHelper, flexRender, getCoreRowModel,
  getSortedRowModel, SortingState, useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import clsx from 'clsx';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import { OrganizationMemberStatus, OrganizationTeamMemberResponse } from 'src/interfaces/IOrganizationMember';
import OrganizationRole from 'src/interfaces/OrganizationRole';
import { useOrganizationAbility } from 'src/queries/organization';
import { useOrganizationContext } from '../../context/OrganizationContextProvider';

import styles from './OrganizationTeamTable.module.scss';

const statusMap = {
  [OrganizationMemberStatus.Active]: 'Active User',
  [OrganizationMemberStatus.Pending]: 'Invite Pending',
  [OrganizationMemberStatus.Canceled]: 'Invite Canceled',
  [OrganizationMemberStatus.Expired]: 'Invite Expired',
  [OrganizationMemberStatus.Accepted]: 'Invite Accepted',
};

export interface OrganizationTeamTableProps {
  data: OrganizationTeamMemberResponse[];
  onEditRole: (member: OrganizationTeamMemberResponse) => void;
  onDelete: (member: OrganizationTeamMemberResponse) => void;
  onCancelInvite: (member: OrganizationTeamMemberResponse) => void;
  onResendInvite: (member: OrganizationTeamMemberResponse) => void;
}

export default function OrganizationTeamTable({
  data,
  onEditRole,
  onDelete,
  onCancelInvite,
  onResendInvite,
}: OrganizationTeamTableProps) {
  const { userMembership, currentOrganizationId } = useOrganizationContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data: ability = new Ability() } = useOrganizationAbility(currentOrganizationId);

  const columnHelper = createColumnHelper<OrganizationTeamMemberResponse>();
  const canEdit = ability.can(Action.Update, Subject.OrganizationMember);

  const actionCell = (cell: any) => {
    if (!canEdit) return null;

    const [actionOpen, setActionOpen] = useState(false);
    const member = cell.row.original as OrganizationTeamMemberResponse;
    const isSelf = member.memberId && member.memberId === userMembership?._id;

    const handleOpen = () => {
      setActionOpen(true);
    };

    const handleClose = () => {
      setActionOpen(false);
    };

    if (member.role === OrganizationRole.Owner) {
      return null;
    }

    if (isSelf) {
      return null;
    }

    const options = () => {
      switch (member.status) {
        case OrganizationMemberStatus.Active:
          return [
            'Edit Role',
            'Remove',
          ];
        case OrganizationMemberStatus.Pending:
          return [
            'Cancel',
          ];
        case OrganizationMemberStatus.Expired:
        case OrganizationMemberStatus.Canceled:
          return [
            'Resend Invite',
          ];
        default:
          return [];
      }
    };

    const handleSelect = (option: any) => {
      if (option === 'Edit Role') {
        onEditRole(member);
      }
      if (option === 'Remove') {
        onDelete(member);
      }
      if (option === 'Cancel') {
        onCancelInvite(member);
      }
      if (option === 'Resend Invite') {
        onResendInvite(member);
      }
    };
    return (
      <div className={styles.action}>
        <MoreOptions
          open={actionOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          onSelect={handleSelect}
          options={options()}
        />
      </div>
    );
  };

  const defaultColumns = [
    columnHelper.accessor((row) => row.displayName, {
      id: 'displayName',
      header: 'NAME',
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      header: 'EMAIL',
    }),
    columnHelper.accessor((row) => row.role, {
      id: 'role',
      header: 'ROLE',
      // eslint-disable-next-line react/display-name
      cell: (cell: any) => <Typography variant="h7" emphasis="high" className={styles.pill}>{cell.row.original.role}</Typography>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: 'STATUS',
      // eslint-disable-next-line react/display-name
      cell: (cell: any) => (
        statusMap[cell.row.original.status as OrganizationMemberStatus]
      ),
    }),
    columnHelper.display({
      id: 'actions',
      cell: actionCell,
      size: 24,
      minSize: 24,
      maxSize: 24,
    }),
  ];

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  const toWidth = (width: number) => {
    if (width < 0) {
      return `${-width}%`;
    }
    if (width > 0) {
      return `${width}px`;
    }
    return 'auto';
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 0,
      minSize: 0,
      maxSize: 0,
    },
  });

  return (
    <div className={styles.container}>
      <Table.Table className={styles.table}>
        <Table.Thead>
          {
          table.getHeaderGroups().map((headerGroup: any) => (
            <Table.HeaderTr key={headerGroup.id}>
              {
                headerGroup.headers.map((header: any) => (
                  <Table.Th
                    key={header.id}
                    colSpan={header.colSpan}
                    width={toWidth(header.getSize())}
                  >
                    { header.id !== 'actions' &&
                    <button
                      type="button"
                      className={styles.header}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      <SortIcon isSorted={header.column.getIsSorted()} />
                    </button>}
                  </Table.Th>
                ))
              }
            </Table.HeaderTr>
          ))
        }
        </Table.Thead>
        <Table.Tbody>
          {
          table.getRowModel().rows.map((row: any, i: number) => (
            <Table.Tr className={styles.tr} key={row.id} odd={(i + 1) % 2 === 1}>
              {
                row.getVisibleCells().map((cell: any) => (
                  <Table.Td className={styles.td} key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Td>
                ))
              }
            </Table.Tr>
          ))
        }
        </Table.Tbody>
      </Table.Table>
    </div>

  );
}

interface SortIconProps {
  isSorted?: string;
}

function SortIcon({ isSorted }: SortIconProps) {
  return (
    <div className={styles.sortIcon}>
      <FontAwesomeIcon icon={faSortUp} className={clsx(styles.icon, { [styles.enabled]: isSorted === 'asc' })} />
      <FontAwesomeIcon icon={faSortDown} className={clsx(styles.icon, { [styles.enabled]: isSorted === 'desc' })} />
    </div>
  );
}
