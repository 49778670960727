import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectBooth } from 'src/features/Dashboard/redux/editMetaverse';
import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

interface BoothOpenMessage {
  type: 'booth:open';
  boothId: string;
}

export default function useOpenBooth() {
  const dispatch = useDispatch();
  const { descriptor } = usePixelStreamingContext();

  useEffect(() => {
    if (!descriptor) return () => {};
    const listener = ({ boothId }: BoothOpenMessage) => dispatch(selectBooth(boothId));
    descriptor.on('booth:open', listener);
    return () => descriptor.off('booth:open', listener);
  }, [descriptor]);
}
