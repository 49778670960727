import { Button, CheckBoxInput, InputFieldsGroup, TextInputField, UploadButton } from '@pixelcanvas/ui';
import { useRef, useState } from 'react';

import imagePlaceholder from '../../../../assets/images/placeholder-image-platform.png';
import { ReactComponent as TriangleError } from '../../../../assets/svg/exclamation-triangle.svg';
import ErrorDialog from '../../../../components/ErrorDialog/ErrorDialog';
import PlatformSpinner from '../../../../components/PlatformSpinner/PlatformSpinner';
import PageType from '../../../../enums/PageType';
import { RoomType } from '../../../../interfaces';
import { IContent } from '../../../../interfaces/IContent';
import { IPage } from '../../../../interfaces/IPage';
import IUser from '../../../../interfaces/IUser';
import {
  createContent, patchContent, uploadImageContent, uploadVideoContent,
} from '../../../../services/ContentService';
import { patchPrivateStreamingRoom } from '../../../../services/RoomService';
import { readURL } from '../../../../utils/FileUtil';
import DateTime from '../../components/DateTime/DateTime';
import WYSIWYGEditor, { WYSIWYGEditorInterface } from '../../components/WYSIWYGEditor/WYSIWYGEditor';

import styles from './ContentDetails.module.scss';

export interface ContentDetailsProps {
  page: IPage;
  content?: IContent;
  onSave?: (content: IContent, createAnother: boolean) => void;
  onCancel?: () => void;
}

export default function ContentDetails({
  page, content, onSave, onCancel,
}: ContentDetailsProps) {
  const [localContent, setLocalContent] = useState<any>(content || {});
  const [createAnother, setCreateAnother] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [invitees] = useState([]);
  const [error, setError] = useState<Error>();
  const editorRef = useRef<WYSIWYGEditorInterface>();
  const { metaverseId } = page;

  async function uploadThumbnailImage(image: string) {
    try {
      const response: any = await uploadImageContent(metaverseId, image);
      setLocalContent((curContent:any) => ({ ...curContent, thumbnail: response.location }));
    } catch (e: any) {
      setError(e);
    } finally {
      setShowSpinner(false);
    }
  }

  async function uploadVideo(video: string) {
    try {
      const response: any = await uploadVideoContent(metaverseId, video);
      setLocalContent({ ...localContent, onDemandSource: response.location });
    } catch (e: any) {
      setError(e);
    } finally {
      setShowSpinner(false);
    }
  }

  async function createPrivateRoom(c: IContent) {
    const channel: any = {
      metaverseId,
      name: `${c.title}`,
      description: `${c.description}`,
      invite: invitees.map((invitee) => (invitee as IUser).userId),
      capabilities: {
        video: true,
        text: true,
      },
      capacity: invitees.length,
      type: RoomType.Private,
      uniqueId: `${c._id}_livestream_table`,
      upsert: true,
    };
    try {
      await patchPrivateStreamingRoom(channel); // Using patch with upsert to handle cases where the livestream toggle has changed
    } catch (e: any) {
      setError(e);
    }
  }

  async function createOrSaveContent() {
    try {
      if (
        !localContent.title ||
        (!localContent.timeFrame?.startTime && page.type !== PageType.Exhibit) ||
        (!localContent.timeFrame?.endTime && page.type !== PageType.Exhibit)
      ) {
        setShowRequired(true);
        return;
      }

      let contentResult;
      if (content) {
        contentResult = await patchContent(localContent);
      } else {
        contentResult = await createContent(metaverseId, page._id as string, localContent);
      }
      onSave?.(contentResult, createAnother);
      createPrivateRoom(contentResult);

      if (createAnother) {
        setLocalContent({});
        editorRef?.current?.clear();
        const fileUpload = document.getElementById('file-upload-content-details');
        if (fileUpload) (fileUpload as any).value = null;
        const dates = document.getElementsByClassName('datetime-input');
        for (let i = 0; i < dates.length; i++) (dates[i] as any).value = null;
      }
    } catch (e: any) {
      setError(e);
    }
  }

  return (
    <InputFieldsGroup className={styles['content-details']}>
      <PlatformSpinner visible={showSpinner} />
      <div className={styles['page-title']}>
        {page.title}
      </div>
      <div className={styles['detail-label']}>
        Thumbnail
      </div>
      <div className={styles['upload-container']}>
        <img className={styles['logo-preview']} src={localContent.thumbnail ? localContent.thumbnail : imagePlaceholder} alt="" />
        <div className={styles['upload-button-container']}>
          <UploadButton
            color="secondary"
            id="file-upload-content-details"
            type="file"
            accept="image/*"
            onChange={(e) => readURL(e.target, (file: any) => uploadThumbnailImage(file), () => setShowSpinner(true))}
          >
            Upload
          </UploadButton>
        </div>
      </div>
      <TextInputField
        label="Title"
        required
        placeholder="Content Title"
        value={localContent.title}
        onChange={(e) => setLocalContent({ ...localContent, title: e.target.value })}
        error={showRequired && 'Please fill out this section'}
      />
      <WYSIWYGEditor
        ref={editorRef}
        title="Description"
        initialValue={localContent.description}
        onChange={(value: string) => setLocalContent({ ...localContent, description: value })}
      />
      {
        page.type !== PageType.Exhibit &&
        <TextInputField
          label="Live Stream Source"
          required
          placeholder="Live Stream URL"
          value={localContent.liveSource}
          onChange={(e) => setLocalContent({ ...localContent, liveSource: e.target.value })}
          error={showRequired && 'Please fill out this section'}
        />
      }
      <TextInputField
        label="VOD Source"
        placeholder="VOD URL"
        value={localContent.onDemandSource}
        onChange={(e) => setLocalContent({ ...localContent, onDemandSource: e.target.value })}
      />
      <div className={styles['upload-container']}>
        <UploadButton
          color="secondary"
          id="vod-video-upload"
          type="file"
          accept=".mp4"
          onChange={(e) => readURL(e.target, (file: any) => uploadVideo(file), () => setShowSpinner(true))}
        >
          Upload Video
        </UploadButton>
      </div>
      {
        page.type !== PageType.Exhibit &&
        <>
          <div>
            <div id="time-controls" className={styles['detail-label']}>
              Start Time*
            </div>
            <DateTime
              dateTime={localContent?.timeFrame?.startTime}
              onChange={(value: string) => {
                if (localContent) setLocalContent({ ...localContent, timeFrame: { ...localContent.timeFrame, startTime: value } });
              }}
            />
          </div>
          {
            !localContent?.timeFrame?.startTime && showRequired &&
            <div className={styles['required-error']}>
              <TriangleError className={styles['triangle-error']} />
              Please fill out this section
            </div>
          }
          <div>
            <div className={styles['detail-label']}>
              End Time*
            </div>
            <DateTime
              dateTime={localContent?.timeFrame?.endTime}
              onChange={(value: string) => {
                if (localContent) setLocalContent({ ...localContent, timeFrame: { ...localContent.timeFrame, endTime: value } });
              }}
            />
          </div>
          {
          !localContent?.timeFrame?.endTime && showRequired &&
          <div className={styles['required-error']}>
            <TriangleError className={styles['triangle-error']} />
            Please fill out this section
          </div>
        }
        </>
      }
      <TextInputField
        label="Tags"
        placeholder="tag1, tag2, tag3"
        value={localContent?.tags?.toString().replace('[', '').replace(']', '')}
        onChange={(e) => { setLocalContent({ ...localContent, tags: e.target.value.split(',') }); }}
      />
      <div className={styles['buttons-wrapper']}>
        {!content &&
        <CheckBoxInput
          classes="add-another"
          checked={createAnother}
          onChange={() => setCreateAnother(!createAnother)}
          label="Add Another"
        />}
        <Button color="tertiary" onClick={() => onCancel?.()}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => createOrSaveContent()}>
          { content ? 'Save' : 'Create' }
        </Button>
      </div>
      <ErrorDialog open={!!error} onClose={() => setError(undefined)} error={error} />
    </InputFieldsGroup>
  );
}
