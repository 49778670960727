import { Header } from '@pixelcanvas/ui';
import UserPill from '../../../../components/UserPill/UserPill';
import logo from '../../../../assets/images/pixel-canvas-logo.png';

import styles from './CheckoutHeader.module.scss';

export default function CheckoutHeader() {
  return (
    <Header variant="short" className={styles.header}>
      <img className={styles.logo} src={logo} alt="Pixel Canvas Logo" />
      <UserPill />
    </Header>
  );
}
