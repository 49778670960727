import React, { ChangeEventHandler, HTMLProps } from 'react';
import clsx from 'clsx';

import styles from './HUDCheckBox.module.scss';

export interface HUDCheckBoxProps extends HTMLProps<HTMLInputElement> {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  indeterminate?: boolean;
}

export default function HUDCheckBox({
  className,
  indeterminate,
  onChange,
  ...props
}: HUDCheckBoxProps) {
  return (
    <input
      className={clsx(className, styles.input)}
      type="checkbox"
      onChange={onChange}
      {...props}
    />
  );
}
