import {
  Cards,
  FilterBy,
  MainContent,
  Page,
  PageContent,
  Search,
  SortBy,
} from '@pixelcanvas/ui';
import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';

import { useMe } from 'src/queries/me';
import { useOrganizationSpaces } from 'src/queries/organization';
import { toTitleCase } from 'src/utils/StringUtils';
import Tier, { tierCompare } from '../../domain/Tier';
import { SpaceResponse } from '../../interfaces/ISpace';

import SpaceCard from './components/SpaceCard/SpaceCard';

import MainHeader from '../../components/MainHeader/MainHeader';
import SubheaderNav from '../../components/SubheaderNav/SubheaderNav';
import { useOrganizationContext } from '../OrganizationPage/context/OrganizationContextProvider';
import styles from './SpacesPage.module.scss';

enum SortByOptions {
  TierAscending = 'Tier: Ascending',
  TierDescending = 'Tier: Descending',
  NameAscending = 'Name: Ascending',
  NameDescending = 'Name: Descending',
  HoursUsed = 'Hours Used',
}

const sortByOptions = [...Object.values(SortByOptions)];
const filterByOptions = [...Object.values(Tier)];

const fuseOptions = {
  keys: ['name', 'tier'],
};

export default function SpacesPage() {
  const { currentOrganizationId } = useOrganizationContext();
  const { data: spaces = [] } = useOrganizationSpaces(currentOrganizationId);
  const { data: userInfo } = useMe();

  const [sortBy, setSortBy] = useState<string>(sortByOptions[0]);
  const [filterBy, setFilterBy] = useState<string[]>([]);
  const [pattern, setPattern] = useState('');

  const fuseRef = useRef(new Fuse<SpaceResponse>([], fuseOptions));

  const fuse = fuseRef.current;

  useEffect(() => {
    if (!spaces) return;
    fuse.setCollection(spaces);
  }, [spaces]);

  const spaceElements = !userInfo?.userId
    ? []
    : (pattern.trim() ? fuse.search(pattern.trim()).map((result) => result.item) : spaces)
      .filter((space) => {
        if (filterBy.length === 0) return true;
        return filterBy.includes(space.tier);
      })
      .sort((a, b) => {
        switch (sortBy) {
          case SortByOptions.TierAscending:
            return tierCompare(a.tier, b.tier);
          case SortByOptions.TierDescending:
            return -tierCompare(a.tier, b.tier);
          case SortByOptions.NameAscending:
            return a.name.localeCompare(b.name);
          case SortByOptions.NameDescending:
            return -a.name.localeCompare(b.name);
          default:
            return 1;
        }
      })
      .map((space) => (
        <SpaceCard
          key={space._id}
          space={space}
          userId={userInfo.userId}
          hoursUsed={space.usage ? (space.usage / (60 * 60 * 1000)) : undefined}
        />
      ));

  return (
    <Page>
      <PageContent>
        <MainHeader />
        <SubheaderNav />
        <MainContent variant="cards">
          <div className={styles.controls}>
            <Search onChange={setPattern} />
            <SortBy
              className={styles.sort}
              options={sortByOptions}
              onChange={setSortBy}
            />
            <FilterBy
              options={filterByOptions}
              onChange={setFilterBy}
              getOptionLabel={(o) => `Tier: ${toTitleCase(o)}`}
            />
          </div>
          <Cards>
            {spaceElements}
          </Cards>
        </MainContent>
      </PageContent>
    </Page>
  );
}
