import clsx from 'clsx';

import styles from './Title.module.scss';

interface TitleProps {
  className?: string;
  children: React.ReactNode;
}

export default function Title({ className, children }: TitleProps) {
  return (
    <h2 className={clsx(styles.title, className)}>
      {children}
    </h2>
  );
}
