import usePxAuthContext from 'src/authentication/usePxAuthContext';
import RoomsSocketContextProvider from 'src/contexts/RoomsSocketContextProvider';
import GameSocketContextProvider from 'src/contexts/GameSocketContextProvider';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import PureWebContextProvider from 'src/features/PureWeb/contexts/PureWebContextProvider';
import SpsContextProvider from 'src/features/ScalablePixelStreaming/components/SpsContextProvider';
import UsageContextProvider from 'src/features/Usage/contexts/UsageContextProvider';

import Metaverse3DEditorPureWebSession from './Metaverse3DEditorPureWebSession';
import Metaverse3DEditorSpsSession from './Metaverse3DEditorSpsSession';

export default function WrappedMetaverse3DEditorSession() {
  const { user, authToken } = usePxAuthContext();
  const { metaverse } = useCurrentMetaverse();
  if (!user || !authToken || !metaverse || !metaverse.spaces[0]) return null;
  const space = metaverse.spaces[0];
  const { projectId, modelId, spsSignallingServerURL } = space.environment.purewebConfiguration;
  return (
    <RoomsSocketContextProvider
      userId={user._id}
      authToken={authToken}
      metaverseId={metaverse._id}
    >
      <GameSocketContextProvider
        authToken={authToken}
        metaverseId={metaverse._id}
      >
        <UsageContextProvider metaverseId={metaverse?._id}>
          {
            spsSignallingServerURL &&
            <SpsContextProvider signallingServerURL={spsSignallingServerURL}>
              <Metaverse3DEditorSpsSession metaverse={metaverse} space={space} />
            </SpsContextProvider>
          }
          {
            !spsSignallingServerURL &&
            <PureWebContextProvider
              projectId={projectId}
              modelId={modelId}
            >
              <Metaverse3DEditorPureWebSession metaverse={metaverse} space={metaverse.spaces[0]} />
            </PureWebContextProvider>
          }
        </UsageContextProvider>
      </GameSocketContextProvider>
    </RoomsSocketContextProvider>
  );
}
