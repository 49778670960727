import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import api from '../services/api';

export const queryKey = 'content';

export const contentKeys = {
  all: [queryKey] as const,
  lists: () => [queryKey, 'list'] as const,
  list: (filters: string | undefined) => [...contentKeys.lists(), { filters }] as const,
  items: () => [queryKey, 'item'] as const,
  item: (id: string | undefined) => [...contentKeys.items(), id] as const,
};

interface UploadResponse {
  success: boolean,
  location: string,
}

export function useUploadImageContent(metaverseId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation<UploadResponse, AxiosError, string>(
    (image: string) => uploadImageContent(metaverseId, image),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contentKeys.lists());
      },
    },
  );
}

export async function uploadImageContent(metaverseId: string | undefined, image: string) {
  const formData = new FormData();
  formData.append('picture', image);
  const response = await api.post<UploadResponse>(
    `/api/content/image?metaverseId=${metaverseId}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return response.data;
}

export function useUploadVideoContent(metaverseId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation<UploadResponse, AxiosError, string>(
    (image: string) => uploadVideoContent(metaverseId, image),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contentKeys.lists());
      },
    },
  );
}

export async function uploadVideoContent(metaverseId: string | undefined, video: string) {
  const formData = new FormData();
  formData.append('video', video);
  const response = await api.post<UploadResponse>(
    `/api/content/video?metaverseId=${metaverseId}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return response.data;
}
