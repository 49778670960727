import { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Button, TextInputField } from '@pixelcanvas/ui';
import isFQDN from 'validator/es/lib/isFQDN';

import { ReactComponent as Times } from '../../../../../assets/svg/times.svg';
import PlatformSpinner from '../../../../../components/PlatformSpinner/PlatformSpinner';

import styles from './AddDomainModal.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onAdd: (domain: string) => void;
}

export default function AddDomainModal({ open, onClose, onAdd }: Props) {
  const [error, setError] = useState('');
  const [domain, setDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onCloseModal = () => {
    setError('');
    setDomain('');
    onClose();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (!isFQDN(domain)) {
      setError('Invalid domain');
      return;
    }
    try {
      await onAdd(domain);
      onCloseModal();
    } catch (e: any) {
      setError(e?.response?.data?.error || e.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onEnterPress = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Modal
      className={styles.modal}
      container={() => document.getElementById('pixel-platform')}
      open={open}
      onClose={onCloseModal}
    >
      <div className={styles.container}>
        <PlatformSpinner fixed visible={isLoading} />
        <div className={styles.header}>
          <h2 className={styles.title}>Add Domain to Whitelist</h2>
          <Times className={styles['close-icon']} onClick={onCloseModal} />
        </div>
        <div className={styles.main}>
          <TextInputField
            label="DOMAIN NAME"
            required
            placeholder="Enter Domain Name"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            onKeyPress={onEnterPress}
            maxLength={255}
            error={error}
          />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.cancel}
            color="tertiary"
            disabled={isLoading}
            onClick={(e: any) => { e.stopPropagation(); onCloseModal(); }}
          >
            Cancel
          </Button>
          <Button
            className={styles.confirm}
            color="primary"
            disabled={isLoading}
            onClick={(e: any) => { e.stopPropagation(); onSubmit(); }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}
