import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUpdateMetaverse } from 'src/queries/metaverse';
import { useCreateStagePage } from 'src/queries/pages';
import { AddOn } from '../../domain/AddOn';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { selectAddNewAddOn, setAddNewAddOn, setTitle } from '../../redux/editMetaverse';
import SelectAddOnModal from './components/SelectAddOnModal';

export default function EditMetaverseAddOnsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metaverse, isLoading: isMetaverseLoading } = useCurrentMetaverse();

  useEffect(() => {
    dispatch(setTitle('Add-Ons'));
  }, []);

  const {
    mutate: updateMetaverse,
    isSuccess,
    isLoading: isUpdateMetaverseLoading,
    reset,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess || isMetaverseLoading || isUpdateMetaverseLoading) return;
    reset();
    navigate('/edit/addons/landing-page');
  }, [isSuccess, isMetaverseLoading, isUpdateMetaverseLoading]);

  const {
    mutate: createStagePage,
    isSuccess: isStagePageSuccess,
    reset: resetStagePage,
    data: newStagePage,
  } = useCreateStagePage(metaverse?._id);

  useEffect(() => {
    if (!isStagePageSuccess || !newStagePage) return;
    resetStagePage();
    navigate(`./stage/${newStagePage._id}`);
  }, [isStagePageSuccess]);

  const addNewAddOn = useSelector(selectAddNewAddOn);

  const handleSelect = (addOn: AddOn) => {
    dispatch(setAddNewAddOn(false));
    switch (addOn.title) {
      case 'Landing Page': {
        updateMetaverse({
          landingPage: {
            private: false,
            hasAgenda: false,
            hasCountdown: false,
            colors: {},
          },
        });
        break;
      }
      case 'Stage': {
        createStagePage();
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Outlet />
      <SelectAddOnModal
        open={addNewAddOn}
        onClose={() => dispatch(setAddNewAddOn(false))}
        onSelect={handleSelect}
      />
    </>
  );
}
