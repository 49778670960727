import { configureStore } from '@reduxjs/toolkit';

import { attendeesReducer } from './redux/attendees';
import { auth0Reducer } from './redux/auth0';
import { channelsReducer } from './redux/channels';
import { userProfileReducer } from './redux/userProfile';
import { pagesReducer } from './redux/pages';
import metaverseReducer from './redux/metaverse';
import { agendaReducer } from './redux/agenda';
import metaverseVisitorReducer from './redux/metaverseVisitor';
import pixelConnectReducer from './redux/pixelconnect';
import { organizationReducer } from './redux/organization';
import customizeTabNavReducer from './features/Dashboard/redux/customizeTabNav';
import metaversePageReducer from './features/Visitor/redux/metaversePageSlice';
import metaverseErrorReducer from './features/Visitor/redux/metaverseError';
import editMetaverseReducer from './features/Dashboard/redux/editMetaverse';
import hudReducer from './features/PixelStreaming/redux/hud';
import gameliftReducer from './features/PixelStreaming/redux/gamelift';
import contentViewerReducer from './features/PixelStreaming/components/ContentViewer/redux/contentViewer';

const store = configureStore({
  reducer: {
    auth0: auth0Reducer,
    userProfile: userProfileReducer,
    metaverseVisitor: metaverseVisitorReducer,
    editMetaverse: editMetaverseReducer,
    channels: channelsReducer,
    attendees: attendeesReducer,
    pages: pagesReducer,
    metaverse: metaverseReducer,
    agenda: agendaReducer,
    pixelconnect: pixelConnectReducer,
    customizeTabNav: customizeTabNavReducer,
    metaversePage: metaversePageReducer,
    metaverseError: metaverseErrorReducer,
    organization: organizationReducer,
    hud: hudReducer,
    contentViewer: contentViewerReducer,
    gamelift: gameliftReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
