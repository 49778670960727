import debounce from 'debounce';
import React from 'react';

import { ReactComponent as ArrowIcon } from '../../../../../assets/svg/arrow-right.svg';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/ellipsis-v.svg';
import { ReactComponent as PrivacyIcon } from '../../../../../assets/svg/pixel-connect/eye-slash-solid.svg';
import { ReactComponent as Text } from '../../../../../assets/svg/pixel-connect/text-chat.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/svg/pixel-connect/user.svg';
import { ReactComponent as Video } from '../../../../../assets/svg/pixel-connect/video-solid.svg';
import ContextMenu, { ContextMenuItem } from '../../../../../components/ContextMenu/ContextMenu';
import { ModerationAction } from '../../../../../interfaces';
import VisitorPrimaryButton from '../../../../Visitor/components/VisitorButton/VisitorPrimaryButton';

import styles from './ChannelListItem.module.scss';

interface ChannelListItemProps {
  channel: any;
  isAdministrator: boolean;
  onClickChannelJoin: Function;
  onContextActionClicked: Function | undefined;
  participantCount: number;
}

interface ChannelListItemState {
  contextActions: ContextMenuItem[];
  anchorEl: EventTarget | null;
}

export default class ChannelListItem extends React.Component<ChannelListItemProps, ChannelListItemState> {
  state = {
  contextActions: [] as ContextMenuItem[],
  anchorEl: null,
  };

  componentDidMount() {
    const {
      channel, isAdministrator, onContextActionClicked,
    } = this.props;
    const contextActions = []; // Add default actions here
    if (isAdministrator && onContextActionClicked) {
      contextActions.push({
        label: 'Delete',
        onClick: () => {
          onContextActionClicked(ModerationAction.DeleteRoom, channel._id);
          this.handleDropdownClose();
        },
      });
      this.setState({ contextActions });
    }
  }

  handleDropdownClose() {
    this.setState({ anchorEl: null });
  }

  handleDropdownOpen(event: Event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  render() {
    const {
      channel, onClickChannelJoin, onContextActionClicked,
    } = this.props;
    const {
      contextActions, anchorEl,
    } = this.state;

    const debouncedJoin = debounce((e: any) => {
      onClickChannelJoin(channel._id);
    }, 450);

    return (
      <div className={styles['channel-list-item']}>
        <div className={styles['channel-list-item-header']}>
          <div className={styles.title}>{channel.name}</div>
          {
            onContextActionClicked && contextActions.length > 0 &&
            <div className={styles.menu}>
              <MenuIcon className={styles['menu-icon']} onClick={(e: any) => this.handleDropdownOpen(e)} />
              <ContextMenu items={contextActions} visible={Boolean(anchorEl)} handleClose={(e: any) => this.handleDropdownClose()} anchorEl={anchorEl} />
            </div>
          }
        </div>
        <div className={styles.description}>{channel.description}</div>
        <div className={styles.footer}>
          <div className={styles.icons}>
            <div className={styles['participants-chip']}>
              <UserIcon className={styles['user-icon']} />
              {/* {`${participantCount}/${MAX_PARTICIPANTS}`} */}
            </div>
            {channel.capabilities.text && <Text className={styles.icon} />}
            {channel.capabilities.video && <Video className={styles.icon} />}
            {channel.type === 'private' && <PrivacyIcon className={styles.icon} />}
          </div>
          {(channel.capabilities.text || channel.capabilities.video) &&
            <VisitorPrimaryButton
              inputClassName={styles['join-button']}
              onClick={(e:any) => { debouncedJoin(e); }}
              buttonText="Join"
              decoration={<ArrowIcon />}
            />}
        </div>
      </div>
    );
  }
}
