/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState, useRef } from 'react';
import {
  Participant,
  AudioTrack,
  AudioTrackPublication,
  RemoteTrack,
  LocalAudioTrack,
  RemoteAudioTrack,
} from 'twilio-video';
import { TrackType } from '../../../../interfaces/TrackType';
import useVideoContext from './useVideoContext';

interface ParticipantAudioProps {
  participant: Participant;
}

const ParticipantAudioTrack = (props: ParticipantAudioProps) => {
  const { activeSinkId } = useVideoContext();
  const [audioTrack, setAudioTrack] = useState<(LocalAudioTrack | RemoteAudioTrack | null)>(null);
  const containerRef = useRef<any>();

  useEffect(() => {
    props.participant.on('trackSubscribed', trackSubscribed);
    props.participant.on('trackUnsubscribed', trackUnsubscribed);
    return () => {
      props.participant.off('trackSubscribed', trackSubscribed);
      props.participant.off('trackUnsubscribed', trackUnsubscribed);
    };
  }, []);
  useEffect(() => {
    audioTrack?.attach(containerRef.current);
  }, [audioTrack]);

  useEffect(() => {
    containerRef.current.setSinkId?.(activeSinkId).catch((error: any) => {
      console.error(`Failed to set active sink ${activeSinkId}`, error);
    });
  }, [activeSinkId]);

  useEffect(() => {
    const existingPublications = Array.from(props.participant.audioTracks.values());
    const existingTracks = existingPublications.map((publication: AudioTrackPublication) => publication.track);
    const availableTrack = existingTracks.find((track: AudioTrack | null) => track !== null) || null;
    setAudioTrack(availableTrack);
  }, [props.participant.audioTracks]);

  const trackSubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Audio) {
      setAudioTrack(track);
    }
  };
  const trackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Audio) {
      setAudioTrack(null);
    }
  };

  return (
    <audio ref={containerRef} />
  );
};

export default ParticipantAudioTrack;
