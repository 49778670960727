import { MainContent } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useOutletContext } from 'react-router-dom';

import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { useUpdateMetaverse } from 'src/queries/metaverse';

import SaveFooter from '../../../../components/SaveFooter/SaveFooter';
import { useToastMessageContext } from '../../../../components/ToastMessage/ToastMessageContextProvider';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { setTitle } from '../../redux/editMetaverse';
import PrivacySettingsTabNav from './components/PrivacySettingsTabNav';

import styles from './EditMetaversePrivacySettingsPage.module.scss';

export default function EditMetaversePrivacySettingsPage() {
  const dispatch = useDispatch();
  const { metaverse } = useCurrentMetaverse();

  useEffect(() => {
    dispatch(setTitle('Privacy Settings'));
  }, []);

  if (!metaverse) return null;

  return <PrivacySettings metaverse={metaverse} />;
}

interface PrivacySettingsProps {
  metaverse: MetaverseResponse;
}

function PrivacySettings({ metaverse }: PrivacySettingsProps) {
  const {
    mutate: updateMetaverse,
    isSuccess,
    reset,
  } = useUpdateMetaverse(metaverse?._id);
  const { addToast } = useToastMessageContext();

  const [inviteOnly, setInviteOnly] = useState(!!metaverse.privacy.inviteOnly);
  const [privateLandingPage, setPrivateLandingPage] = useState(!!metaverse.landingPage?.private);
  const [autosave, setAutosave] = useState(true);

  useEffect(() => {
    if (!isSuccess) return;
    addToast('Your changes have been saved.');
    reset();
  }, [isSuccess, reset]);

  const handleSaveClicked = () => {
    const update = {
      privacy: { inviteOnly },
      landingPage: metaverse.landingPage ? { private: privateLandingPage } : undefined,
    };
    updateMetaverse(update);
  };

  return (
    <>
      <MainContent className={styles.container}>
        <div className={styles.description}>
          Here is where you can control who can access your metaverse. You can do this via invitation, domain whitelisting, and making the landing page private.
        </div>
        <PrivacySettingsTabNav />
        <Outlet
          context={{
            inviteOnly,
            setInviteOnly,
            privateLandingPage,
            setPrivateLandingPage,
            setAutosave,
          }}
        />
      </MainContent>
      <SaveFooter
        className={styles.footer}
        saveClicked={handleSaveClicked}
        updatedAt={metaverse.updatedAt.toString()}
        disabled={autosave}
      />
    </>
  );
}

type PrivacySettingsUpdateContext = {
  inviteOnly: boolean;
  setInviteOnly: (value: boolean) => void;
  privateLandingPage: boolean;
  setPrivateLandingPage: (value: boolean) => void;
  setAutosave: (value: boolean) => void;
};

export function usePrivacySettingsUpdate() {
  const {
    inviteOnly,
    setInviteOnly,
    privateLandingPage,
    setPrivateLandingPage,
    setAutosave,
  } = useOutletContext<PrivacySettingsUpdateContext>();
  return {
    inviteOnly,
    setInviteOnly,
    privateLandingPage,
    setPrivateLandingPage,
    setAutosave,
  };
}
