import clsx from 'clsx';
import ListItem from './ListItem';
import styles from './WalletListItem.module.scss';

interface Props {
  className?: string;
  src?: string;
  text?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function WalletListItem({
  className,
  src,
  text,
  disabled = false,
  onClick,
}: Props) {
  return (
    <ListItem
      className={clsx(className, 'button')}
      disabled={disabled}
      onClick={onClick}
    >
      <img className={styles.icon} src={src} alt={text} />
      <span className={styles.text}>{text}</span>
    </ListItem>
  );
}
