import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { usePixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';

import styles from './Overlay.module.scss';

export default function PlayOverlay() {
  const { connect } = usePixelStreamingContext();
  return (
    <div className={clsx(styles.overlay, styles.play)}>
      <button className={styles.button} type="button" onClick={connect}>
        <FontAwesomeIcon icon={faCirclePlay} />
      </button>
    </div>
  );
}
