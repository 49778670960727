import clsx from 'clsx';
import { ReactComponent as Spinner } from '../../assets/svg/spinner-third.svg';
import styles from './PlatformSpinner.module.scss';

export type Props = {
  className?: string;
  visible: boolean,
  fixed?: boolean,
};

export default function PlatformSpinner({ className, visible, fixed = false }: Props) {
  if (!visible) return null;
  return (
    <div className={clsx(className, styles.container, { [styles.fixed]: fixed })}>
      <Spinner className={styles.icon} />
    </div>
  );
}
