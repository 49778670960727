import debounce from 'debounce';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';

import { selectMetaverseVisitor } from 'src/redux/metaverseVisitor';

import AvatarIcon from '../../../../../assets/images/profile-pic.png';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/ellipsis-v.svg';
import ContextMenu, { ContextMenuItem } from '../../../../../components/ContextMenu/ContextMenu';
import useSocketContext from '../../../../../contexts/useRoomsSocketContext';
import Contact from '../../../../../interfaces/Contact';
import ModerationAction from '../../../../../interfaces/ModerationAction';
import { getContactDisplayName } from '../../../../../utils/DisplayNameUtil';
import ContactPopover from '../ContactPopover/ContactPopover';

import styles from './ContactListItem.module.scss';

interface ContactListItemProps {
  contact: Contact;
  onClick: Function;
  onContextActionClicked: Function | undefined;
}

const ContactListItem = ({
  contact, onClick, onContextActionClicked,
}: ContactListItemProps) => {
  const { onlineUsers } = useSocketContext();
  const metaverseVisitor = useSelector(selectMetaverseVisitor, shallowEqual);
  const src = contact.picture || AvatarIcon;
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null);
  const [contextActions, setContextActions] = useState<ContextMenuItem[]>([]);
  const { ability } = useAccessMetaverse();

  const hasModeratePermission = ability.can(Action.Manage, Subject.ModerationAction);

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOpen = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverOpen = (event: any) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    let actions: any[] = []; // Add  default actions here
    const adminContextActions: ContextMenuItem[] = [
      {
        label: 'Ban User',
        onClick: () => {
          if (onContextActionClicked) {
            onContextActionClicked(ModerationAction.BanUser, contact.userId);
          }
          handleDropdownClose();
        },
      },
    ];
    if (hasModeratePermission) {
      actions = actions.concat(adminContextActions);
    }
    setContextActions(actions);
  }, [metaverseVisitor.roles]);

  const debouncedJoin = debounce((e: any) => {
    onClick(e, contact);
  }, 450);

  return (
    <>
      <div key={contact.userId} className={styles['contact-list-item']} onClick={(e) => debouncedJoin(e)} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <div className={styles['contact-avatar']}>
          <img className={styles['contact-img']} src={src} alt="" />
        </div>
        <div className={styles['contact-name']}>
          {getContactDisplayName(contact)}
        </div>
        {
          onContextActionClicked && contextActions.length > 0 &&
          <div className={styles.menu}>
            <MenuIcon className={styles['menu-icon']} onClick={(e: any) => handleDropdownOpen(e)} />
            <ContextMenu items={contextActions} visible={Boolean(anchorEl)} handleClose={handleDropdownClose} anchorEl={anchorEl} />
          </div>
        }
      </div>
      <ContactPopover anchorEl={popoverAnchorEl} contact={contact} />
    </>
  );
};

export default ContactListItem;
