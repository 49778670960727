import clsx from 'clsx';
import styles from './VisitorModalContent.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function VisitorModalContent({
  className,
  children,
}: Props) {
  return (
    <div className={clsx(className, styles['modal-content'])}>
      {children}
    </div>
  );
}
