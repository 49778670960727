export enum SceneSlotType {
  Scenery = 'scenery',
  MiniGame = 'minigame',
  Empty = 'empty',
}

export default interface SceneSlot {
  metaverseId: string;
  spaceId: string;
  slotId: string;
  type: SceneSlotType;
  sceneryId: string;
}
