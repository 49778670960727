import { useEffect, useState } from 'react';
import clsx from 'clsx';
import useAsyncEffect from 'use-async-effect';
import Dropdown from '../../../../Visitor/components/Dropdown/Dropdown';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import useVideoContext from '../useVideoContext';
import { SELECTED_AUDIO_INPUT_KEY } from '../VideoContextProvider';
import styles from './AudioInputList.module.scss';

export default function AudioInputList() {
  const { localAudioTrack } = useVideoContext();

  const [localAudioInputDeviceId, setLocalAudioInputDeviceId] = useState<string | undefined>(
    window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY) || undefined,
  );
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);

  useAsyncEffect(async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    const inputDevices = mediaDevices.filter((device) => device.kind === 'audioinput');
    setAudioInputDevices(inputDevices);
  }, []);

  useEffect(() => {
    if (localAudioInputDeviceId && localAudioInputDeviceId !== window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY)) {
      window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, localAudioInputDeviceId);
    }
  }, [localAudioInputDeviceId]);

  useEffect(() => {
    if (localAudioTrack) {
      const mediaStream = localAudioTrack.mediaStreamTrack;
      const currentDeviceId = mediaStream.getSettings().deviceId;
      if (currentDeviceId !== localAudioInputDeviceId) {
        setLocalAudioInputDeviceId(currentDeviceId);
      }
    }
  }, [localAudioTrack]);

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId }, noiseSuppression: true });
  }

  return (
    <div className={styles.container}>
      {audioInputDevices.length > 1 ? (
        <Dropdown
          title="Audio Input"
          options={audioInputDevices.map((device) => (device.label))}
          onValueChange={(value: string) => {
            const newDevice = audioInputDevices.find((device) => device.label === value);
            if (newDevice) {
              replaceTrack(newDevice.deviceId);
            }
          }}
          value={audioInputDevices.find((device) => device.deviceId === localAudioInputDeviceId)?.label || ''}
          showRequired={false}
        />
      ) : (
        <div>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</div>
      )}
      <div className={styles['audio-level-indicator']}>
        <AudioLevelIndicator id="local-audio-input" audioTrack={localAudioTrack} color="black" />
      </div>
    </div>
  );
}
