import { IMetaverse } from '../../../../../interfaces/IMetaverse';
import SocialMediaLink from '../SocialMediaLink/SocialMediaLink';

import { ReactComponent as TwitterIcon } from '../../../../../assets/svg/twitter-logo.svg';
import { ReactComponent as DiscordIcon } from '../../../../../assets/svg/discord.svg';

type Props = {
  className: string;
  metaverseDetails: IMetaverse;
};

export default function SocialMediaLinks({ className, metaverseDetails }: Props) {
  if (!metaverseDetails.social) return null;
  const twitterHref = metaverseDetails.social.twitter
    ? `https://twitter.com/${metaverseDetails.social.twitter}`
    : null;
  const discordHref = metaverseDetails.social.discord ?? null;
  return (
    <div className={className}>
      {twitterHref && <SocialMediaLink Icon={TwitterIcon} href={twitterHref} />}
      {discordHref && <SocialMediaLink Icon={DiscordIcon} href={discordHref} />}
    </div>
  );
}
