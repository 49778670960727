import {
  createContext, ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';
import Video, {
  connect,
  ConnectOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Logger,
  MediaStreamTrackPublishOptions,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
} from 'twilio-video';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import ModerationAction from '../../../../interfaces/ModerationAction';
import ModerationRequest from '../../../../interfaces/ModerationRequest';
import SocketEvent from '../../../../interfaces/SocketEvent';
import { TrackType } from '../../../../interfaces/TrackType';
import { dataLayer } from '../../../../utils/DataLayerUtil';
import ParticipantAudioTrack from './ParticipantAudioTrack';

const logger = Logger.getLogger('twilio-video');

logger.setLevel('debug');

export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

export interface IVideoContext {
  videoRoom: Room | undefined;
  videoRoomName: string;
  remoteParticipants: RemoteParticipant[];
  localAudioTrack: LocalAudioTrack | undefined;
  localVideoTrack: LocalVideoTrack | undefined;
  localScreenTrack: LocalVideoTrack | undefined;
  localScreenTrackPublication: LocalTrackPublication | undefined;
  localScreenAudioTrack: LocalAudioTrack | undefined;
  createLocalAudioTrack: Function;
  createLocalVideoTrack: Function;
  createLocalScreenTracks: Function;
  removeLocalAudioTrack: Function;
  removeLocalVideoTrack: Function;
  removeLocalScreenTracks: Function;
  presenter: LocalParticipant | RemoteParticipant | null;
  inLiveRoom: boolean;
  connectVideoChat: (roomId: string, roomName: string, videoToken: string) => Promise<void>;
  devices: MediaDeviceInfo[];
  activeSinkId: string | null;
  setActiveSinkId: Function;
  disposeRoom: Function;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  children: ReactNode;
}

const VideoContextProvider = ({ children }: VideoProviderProps) => {
  const { socket, disconnectSocketRoom } = useSocketContext();
  const [videoRoom, setVideoRoom] = useState<Room>();
  const [videoRoomName, setVideoRoomName] = useState<string>('');
  const [remoteParticipants, setRemoteParticipants] = useState<RemoteParticipant[]>([]);
  const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack>();
  const [localScreenTrack, setLocalScreenTrack] = useState<LocalVideoTrack>();
  const [localScreenTrackPublication, setLocalScreenTrackPublication] = useState<LocalTrackPublication>();
  const [localScreenAudioTrack, setLocalScreenAudioTrack] = useState<LocalAudioTrack>();
  const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack>();
  const [presenter, setPresenter] = useState<LocalParticipant | RemoteParticipant | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeSinkId, setActiveSinkId] = useState<string | null>(window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY));
  const [inLiveRoom, setInLiveRoom] = useState(false);

  const connectionOptions: ConnectOptions = {
    audio: true,
    video: { height: 720, frameRate: 24, width: 852 },
    bandwidthProfile: {
      video: {
        mode: 'grid',
        maxTracks: 50,
        renderDimensions: {
          high: { height: 720, width: 1280 },
          standard: { height: 360, width: 640 },
          low: { height: 176, width: 144 },
        },
      },
    },
    maxAudioBitrate: 16000, // For music remove this line
    // For multiparty rooms (participants>=3) uncomment the line below
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    networkQuality: { local: 1, remote: 1 },
  };

  const removeLocalVideoTrack = useCallback(() => {
    if (localVideoTrack) {
      videoRoom?.localParticipant?.unpublishTrack(localVideoTrack as LocalTrack);
      localVideoTrack.stop();
    }
    setLocalVideoTrack(undefined);
    console.log('VideoContextProvider: removeLocalVideoTrack');
  }, [videoRoom, localVideoTrack]);

  const removeLocalScreenTracks = useCallback(() => {
    if (localScreenTrack) {
      videoRoom?.localParticipant?.unpublishTrack(localScreenTrack as LocalTrack);
      // Necessary because twilio won't emit it for us
      videoRoom?.localParticipant?.emit('trackUnpublished', localScreenTrackPublication);
      localScreenTrack.stop();
    }
    if (localScreenAudioTrack) {
      videoRoom?.localParticipant?.unpublishTrack(localScreenAudioTrack as LocalTrack);
      localScreenAudioTrack.stop();
    }
    setLocalScreenTrack(undefined);
    setLocalScreenAudioTrack(undefined);
    console.log('VideoContextProvider: removeLocalScreenTrack');
  }, [videoRoom, localScreenAudioTrack, localScreenTrack, localScreenTrackPublication]);

  const removeLocalAudioTrack = useCallback(() => {
    if (localAudioTrack) {
      videoRoom?.localParticipant?.unpublishTrack(localAudioTrack as LocalTrack);
      localAudioTrack.stop();
    }
    setLocalAudioTrack(undefined);
    console.log('VideoContextProvider: removeLocalAudioTrack');
  }, [localAudioTrack, videoRoom]);

  const createLocalVideoTrack = useCallback(async (isPresenting = false) => {
    console.log('create local video track');
    let videoMediaTrack;
    let videoDevices;
    try {
      videoMediaTrack = await navigator.mediaDevices.getUserMedia({ video: true });
      videoDevices = (await navigator.mediaDevices.enumerateDevices()).filter((e) => e.kind === 'videoinput');
    } catch (e) {
      console.error('Failed to get local video media', e);
      return;
    }
    const options: CreateLocalTrackOptions = {};
    const videoInputDeviceId = window.localStorage
      .getItem(SELECTED_VIDEO_INPUT_KEY) ?? undefined;
    const deviceIdExists = videoDevices
      .filter((device) => videoInputDeviceId && device.deviceId === videoInputDeviceId).length > 0;
    if (deviceIdExists && videoInputDeviceId) {
      options.deviceId = { exact: videoInputDeviceId };
    } else {
      const newDeviceId = videoMediaTrack.getVideoTracks()[0].getSettings().deviceId;
      options.deviceId = { exact: newDeviceId };
      window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId as string);
    }

    if (isPresenting) {
      options.name = `${videoRoom?.localParticipant.identity}-presenter-camera`;
    }
    if (localVideoTrack) {
      removeLocalVideoTrack();
    }
    return Video.createLocalVideoTrack(options).then((newVideoTrack: LocalVideoTrack) => {
      setLocalVideoTrack(newVideoTrack);
      if (videoRoom) {
        videoRoom.localParticipant?.publishTrack(newVideoTrack, {
          priority: isPresenting ? 'high' : 'standard',
        });
        console.log('VideoContextProvider: Publish Video track createLocalVideoTrack');
      }
    });
  }, [videoRoom, localVideoTrack]);

  const createLocalScreenTracks = useCallback(async () => {
    console.log('create local screen track');
    const promise = new Promise<void>(async (resolve) => {
      const mediaStream = await (navigator.mediaDevices as any).getDisplayMedia({ audio: true, video: true });
      const mediaStreamTracks = mediaStream.getTracks();
      const screenMST = mediaStreamTracks.find((mst: any) => mst.kind === 'video');
      const screenAudioMST: MediaStreamTrack = mediaStreamTracks.find((mst: any) => mst.kind === 'audio');
      if (screenMST) {
        const screenTrackPublication = await videoRoom?.localParticipant?.publishTrack(screenMST, {
          name: `${videoRoom.localParticipant.identity}-presenter-screen-video`,
          priority: 'high',
        } as MediaStreamTrackPublishOptions);
        setLocalScreenTrackPublication(screenTrackPublication);
        screenMST.onended = () => {
          removeLocalScreenTracks();
          videoRoom?.localParticipant?.unpublishTrack(screenMST);
          // Necessary because twilio won't emit it for us
          videoRoom!.localParticipant.emit('trackUnpublished', screenTrackPublication);
          screenMST.stop();
        };
        if (screenTrackPublication) {
          setLocalScreenTrack(screenTrackPublication.track as LocalVideoTrack);
          console.log('newScreenTrack: ', screenTrackPublication.track);
        }
      }
      if (screenAudioMST) {
        const screenAudioTrackPublication = await videoRoom?.localParticipant?.publishTrack(screenAudioMST, {
          name: `${videoRoom.localParticipant.identity}-presenter-screen-audio`,
          priority: 'high',
        } as MediaStreamTrackPublishOptions);
        screenAudioMST.onended = () => {
          videoRoom!.localParticipant.unpublishTrack(screenAudioMST);
          // Necessary because twilio won't emit it for us
          videoRoom!.localParticipant.emit('trackUnpublished', screenAudioTrackPublication);
          screenAudioMST.stop();
        };
        if (screenAudioTrackPublication) {
          setLocalScreenAudioTrack(screenAudioTrackPublication.track as LocalAudioTrack);
          console.log('newScreenAudioTrack: ', screenAudioTrackPublication.track);
        }
      }
      resolve();
    });
    return promise;
  }, [videoRoom]);

  const createLocalAudioTrack = useCallback(async () => {
    console.log('create local audio track');

    const options: CreateLocalTrackOptions = {};
    const audioInputDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY) ?? undefined;
    const audioMediaTrack = await navigator.mediaDevices
      .getUserMedia({ audio: { deviceId: audioInputDeviceId } });
    const deviceIdExists = audioMediaTrack
      .getAudioTracks()
      .filter((audioTrack) => audioInputDeviceId && audioTrack.id === audioInputDeviceId).length > 0;
    if (deviceIdExists && audioInputDeviceId) {
      options.deviceId = { exact: audioInputDeviceId };
    } else {
      const newDeviceId = audioMediaTrack.getAudioTracks()[0].getSettings().deviceId;
      options.deviceId = { exact: newDeviceId };
      window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId as string);
    }
    return Video.createLocalAudioTrack(options).then((newAudioTrack: LocalAudioTrack) => {
      setLocalAudioTrack(newAudioTrack);
      if (videoRoom) {
        videoRoom.localParticipant?.publishTrack(newAudioTrack);
        console.log('VideoContextProvider: Publish Audio track createLocalAudioTrack');
      }
    });
  }, [videoRoom]);

  const connectVideoChat = async (roomId: string, roomName: string, videoToken: string) => {
    console.log('Connecting Video Chat');
    let audioMediaTrack;
    let videoMediaTrack;
    let videoDevices;
    let audioInputId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY) ?? undefined;
    let videoInputId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY) ?? undefined;
    try {
      audioMediaTrack = await navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: audioInputId } });
      const deviceIdExists = audioMediaTrack
        ?.getAudioTracks()
        .filter((audioTrack) => audioInputId && audioTrack.id === audioInputId).length > 0;
      if (!deviceIdExists) {
        audioInputId = audioMediaTrack.getAudioTracks()[0].getSettings().deviceId as string;
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, audioInputId);
      }
      audioMediaTrack.getTracks().forEach((track) => track.stop());
    } catch (e) {
      audioInputId = undefined;
    }

    try {
      videoMediaTrack = await navigator.mediaDevices
        .getUserMedia({ video: { deviceId: videoInputId } });
      videoDevices = (await navigator.mediaDevices.enumerateDevices()).filter((e) => e.kind === 'videoinput');

      // const deviceIdExists = videoMediaTrack?.getVideoTracks().filter((videoTrack) => videoTrack.id === videoInputId).length > 0;
      const deviceIdExists = videoDevices
        .filter((device) => videoInputId && device.deviceId === videoInputId).length > 0;
      if (!deviceIdExists) {
        videoInputId = videoMediaTrack.getVideoTracks()[0].getSettings().deviceId as string;
        window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, videoInputId);
      }
      videoMediaTrack.getTracks().forEach((track) => track.stop());
    } catch (e) {
      videoInputId = undefined;
    }
    return Video.createLocalTracks({
      audio: audioInputId ? { deviceId: { exact: audioInputId } } as CreateLocalTrackOptions : false,
      video: videoInputId ? { deviceId: { exact: videoInputId } } as CreateLocalTrackOptions : false,
    }).then(async (tracks: LocalTrack[]) => {
      console.log('VideoContextProvider: connectVideoChat: create Audio and Video track');
      setLocalAudioTrack(tracks.find((track) => track.kind === 'audio') as LocalAudioTrack);
      setLocalVideoTrack(tracks.find((track) => track.kind === 'video') as LocalVideoTrack);
      const connectedRoom = await connect(videoToken, {
        name: roomId,
        tracks,
        ...connectionOptions,
      });
      setVideoRoom(connectedRoom);
      setVideoRoomName(roomName);
      setRemoteParticipants(Array.from(connectedRoom.participants.values()));
      const disconnect = () => connectedRoom.disconnect();
      window.addEventListener('beforeunload', disconnect);
      dataLayer.push({ event: 'video_chat_connect', px_room_id: roomId });
    }).catch((e) => {
      console.error(e);
    });
  };

  const addRemoteParticipant = (participant: RemoteParticipant) => {
    setRemoteParticipants(remoteParticipants?.concat(participant));
  };

  const removeRemoteParticipant = (participant: RemoteParticipant) => {
    setRemoteParticipants(remoteParticipants?.filter((p) => p.identity !== participant.identity));
  };

  const disposeRoom = (room: Room) => {
    if (videoRoom) {
      videoRoom.disconnect();
    }
    if (presenter && videoRoom?.localParticipant.identity === presenter?.identity) {
      socket?.emit(SocketEvent.EndPresentation, videoRoom?.name);
      setPresenter(null);
    }
    dataLayer.push({ event: 'video_chat_disconnect', px_room_id: videoRoom?.name });
    setVideoRoom(undefined);
    removeLocalAudioTrack();
    removeLocalVideoTrack();
    removeLocalScreenTracks();
    setRemoteParticipants([]);
  };

  const handleModerationRequest = (request: ModerationRequest) => {
    switch (request.action) {
      case ModerationAction.RemoveUser:
        if (videoRoom) {
          disconnectSocketRoom(videoRoom.name);
          videoRoom.disconnect();
        }
        break;
      case ModerationAction.HideVideo:
        removeLocalVideoTrack();
        break;
      case ModerationAction.MuteAudio:
        localAudioTrack?.disable();
        break;
      default:
        break;
    }
  };

  const handlePresentationStarted = ({ roomId, userId }: { roomId: string, userId: string }) => {
    if (!videoRoom || roomId !== videoRoom.name) return;
    if (videoRoom.localParticipant.identity === userId) {
      setPresenter(videoRoom.localParticipant);
    } else {
      const presenterParticipant = remoteParticipants
        .find((participant: RemoteParticipant) => participant.identity === userId);
      if (presenterParticipant) {
        removeLocalScreenTracks();
        setPresenter(presenterParticipant);
      }
    }
  };
  const handlePresentationEnded = ({ roomId }: { roomId: string }) => {
    if (!videoRoom || roomId !== videoRoom.name) return;
    console.log('Presentation Ended');
    if (videoRoom.localParticipant.identity === presenter?.identity) {
      try {
        if (localScreenTrack) {
          console.log('removing local screen tracks');
          removeLocalScreenTracks();
        }
        if (localVideoTrack) {
          console.log('replacing video track for standard quality');
          createLocalVideoTrack();
        }
      } catch (e) {
        console.error('Failed to restart video tracks.');
      }
    }
    setPresenter(null);
  };

  const handleTrackSubscribed = (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (track.kind === TrackType.Video && publication.trackName.includes('presenter')) {
      setPresenter(participant);
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices) {
      const getDevices = () => {
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            console.log('Devices :', devices);
            setDevices(devices);
          }).catch((e) => {
            console.log('device error', e);
          });
      };
      getDevices();
      navigator.mediaDevices.addEventListener('devicechange', getDevices);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
      };
    }
  }, []);

  useEffect(() => {
    if (videoRoom) {
      videoRoom.on('participantConnected', addRemoteParticipant);
      videoRoom.on('participantDisconnected', removeRemoteParticipant);
      videoRoom.on('disconnected', disposeRoom);
      videoRoom.on('trackSubscribed', handleTrackSubscribed);
    }

    return () => {
      videoRoom?.off('participantConnected', addRemoteParticipant);
      videoRoom?.off('participantDisconnected', removeRemoteParticipant);
      videoRoom?.off('disconnected', disposeRoom);
      videoRoom?.off('trackSubscribed', handleTrackSubscribed);
    };
  }, [videoRoom, remoteParticipants]);

  useEffect(() => {
    let videoComposerFound = false;
    for (let i = 0; i < remoteParticipants.length; i++) {
      const curParticipant = remoteParticipants[i];
      if (curParticipant.identity === 'video-composer-v1') {
        videoComposerFound = true;
        break;
      }
    }

    setInLiveRoom(videoComposerFound);
  }, [remoteParticipants]);

  useEffect(() => {
    if (videoRoom && socket) {
      socket.on(SocketEvent.ModerationEnforced, handleModerationRequest);
      socket.on(SocketEvent.PresentationStarted, handlePresentationStarted);
      socket.on(SocketEvent.PresentationEnded, handlePresentationEnded);
    }

    return () => {
      socket?.off(SocketEvent.ModerationEnforced, handleModerationRequest);
      socket?.off(SocketEvent.PresentationStarted, handlePresentationStarted);
      socket?.off(SocketEvent.PresentationEnded, handlePresentationEnded);
    };
  }, [socket, videoRoom, remoteParticipants, presenter]);

  useEffect(() => {
    const selectedSinkId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
    const audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput');
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      (device) => selectedSinkId && device.deviceId === selectedSinkId,
    );
    if (hasSelectedAudioOutputDevice) {
      console.log(selectedSinkId);
      setActiveSinkId(selectedSinkId!);
    }
  }, [devices]);

  return (
    <VideoContext.Provider
      value={{
        videoRoom,
        videoRoomName,
        remoteParticipants,
        localAudioTrack,
        localVideoTrack,
        localScreenTrack,
        localScreenTrackPublication,
        localScreenAudioTrack,
        createLocalAudioTrack,
        createLocalVideoTrack,
        createLocalScreenTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        removeLocalScreenTracks,
        connectVideoChat,
        presenter,
        inLiveRoom,
        devices,
        activeSinkId,
        setActiveSinkId,
        disposeRoom,
      }}
    >
      {children}
      {
        videoRoom?.participants &&
        // @ts-ignore
        Array.from(videoRoom.participants.values()).map((participant) => <ParticipantAudioTrack key={participant.identity} participant={participant} />)
      }
    </VideoContext.Provider>
  );
};

export default VideoContextProvider;
