export const VISUAL_SOURCE_TYPE = {
  Video: 'video',
  Image: 'image',
} as const;

type ObjectValues<T> = T[keyof T];
export type VisualSourceType = ObjectValues<typeof VISUAL_SOURCE_TYPE>;

export default interface VisualSource {
  srcType: VisualSourceType;
  src: string;
}
