import { Button, ColorInputField, InputFieldsGroup, SideNav, SideNavBackButton, SideNavBody, SideNavFooter, SideNavSection, Typography } from '@pixelcanvas/ui';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { selectBoothId, setSidePanel } from 'src/features/Dashboard/redux/editMetaverse';
import { useBooth, useDeleteBooth, useSetBooth } from 'src/queries/metaverse';
import { useShowcase } from 'src/queries/showcase';

import styles from './MetaverseBoothEditorSidePanel.module.scss';
import AddShowcaseModal from '../../../EditMetaverseShowcase/components/AddShowcaseModal';

export default function MetaverseBoothEditorSidePanel() {
  const dispatch = useDispatch();
  const { metaverse } = useCurrentMetaverse();
  const metaverseId = metaverse?._id;
  const space = metaverse?.spaces[0];
  const spaceId = space?._id;

  const boothId = useSelector(selectBoothId);

  const [openAddShowcaseModal, setOpenAddShowcaseModal] = useState(false);

  const { mutate: setBooth } = useSetBooth(metaverse?._id, space?._id, boothId);
  const { data: showcase } = useShowcase(metaverse?._id, boothId);

  const {
    data: booth,
    remove,
    error: getBoothError,
  } = useBooth(metaverse?._id, space?._id, boothId);

  useEffect(() => {
    if (getBoothError?.response?.status !== 404 || !spaceId || !metaverseId) {
      return;
    }
    setBooth({
      metaverseId,
      spaceId,
      boothId,
      colors: {},
    });
  }, [getBoothError]);

  const {
    mutate: deleteBooth,
    isSuccess: deleteBoothSuccessful,
    reset: resetDeleteBooth,
  } = useDeleteBooth(metaverse?._id, space?._id, boothId);

  useEffect(() => {
    if (!deleteBoothSuccessful || !resetDeleteBooth) return;
    resetDeleteBooth();
    dispatch(setSidePanel('editor'));
    remove();
  }, [deleteBoothSuccessful, resetDeleteBooth]);

  if (!metaverse || !space || !spaceId) return null;

  const handlePrimaryColorChange = (color: string) => {
    setBooth(merge(booth, { spaceId, boothId }, { colors: { primary: color } }));
  };

  const handleSecondaryColorChange = (color: string) => {
    setBooth(merge(booth, { spaceId, boothId }, { colors: { secondary: color } }));
  };

  const handleBackTo3DEditor = () => {
    dispatch(setSidePanel('editor'));
  }

  const handleDeleteClick = () => deleteBooth();

  return (
    <SideNav>
      <SideNavBackButton text="Back to 3D Editor" onClick={handleBackTo3DEditor} />
      <SideNavBody>
        <SideNavSection className={styles.titleSection}>
          <Typography variant="h2" emphasis="high">Booth Editor</Typography>
        </SideNavSection>
        <SideNavSection>
          <InputFieldsGroup title="Branding">
            <ColorInputField
              label="PRIMARY COLOR"
              color={booth?.colors.primary ?? ''}
              onChange={handlePrimaryColorChange}
            />
            <ColorInputField
              label="SECONDARY COLOR"
              color={booth?.colors.secondary ?? ''}
              onChange={handleSecondaryColorChange}
            />
          </InputFieldsGroup>
          <InputFieldsGroup className={styles.actions}>
            {!showcase && (
              <Button
                color="tertiary"
                className={styles.button}
                onClick={() => setOpenAddShowcaseModal(true)}
                disabled={!booth}
              >
                Create Showcase
              </Button>
            )}
            <Button
              color="secondary"
              className={styles.button}
              onClick={handleDeleteClick}
              disabled={!booth}
            >
              Delete Booth
            </Button>
          </InputFieldsGroup>
        </SideNavSection>
      </SideNavBody>
      <SideNavFooter>
        <Typography className={styles.autosave} variant="body2">
          The changes on this page are automatically saved
        </Typography>
      </SideNavFooter>
      <AddShowcaseModal
        key={boothId}
        open={openAddShowcaseModal}
        onClose={() => setOpenAddShowcaseModal(false)}
        forceGuid={boothId}
      />
    </SideNav>
  );
}
