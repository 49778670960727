import { SocketEvent } from '../../../../interfaces';
import { IDisplayNotification, IUserNotification } from './NotificationsContextProvider';

export function getFormattedDisplayNotification(notification: IUserNotification): IDisplayNotification | null {
  switch (notification.type) {
    case SocketEvent.InvitedToRoom:
      return {
        header: notification.payload.roomName,
        body: `${notification.payload.inviterName} invited you`,
        action: SocketEvent.JoinRoom,
        actionPayload: notification.payload.room,
        read: notification.read,
        _id: notification._id,
        createdAt: notification.createdAt,
      };
    default:
      return null;
  }
}
