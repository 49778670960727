import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TaskModal, TaskModalContent, TaskModalTitle, TextInputField, TooltipButton, Typography } from '@pixelcanvas/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import { IMetaverse } from 'src/interfaces/IMetaverse';
import { getMetaverseVisitorURL } from 'src/utils/URLHelper';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { selectOpenShareModal, setOpenShareModal } from '../../redux/editMetaverse';
import MetaverseStatus from '../MetaverseStatus/MetaverseStatus';

import styles from './ShareModal.module.scss';

export default function ShareModal() {
  const dispatch = useDispatch();
  const { metaverse } = useCurrentMetaverse();
  const open = useSelector(selectOpenShareModal);
  const onClose = () => dispatch(setOpenShareModal(false));
  const { addToast } = useToastMessageContext();
  if (!metaverse) return null;
  const visitorURL = getMetaverseVisitorURL(metaverse);
  return (
    <TaskModal className={styles.modal} open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>Share</TaskModalTitle>
      <TaskModalContent className={styles.content}>
        <div className={styles.link}>
          <TextInputField className={styles.url} readOnly value={visitorURL} label="METAVERSE URL" />
          <Button
            className={styles.copy}
            color="primary"
            onClick={async () => {
              if (!navigator.clipboard) return;
              await navigator.clipboard.writeText(getMetaverseVisitorURL(metaverse));
              addToast('Link copied!');
            }}
          >
            <FontAwesomeIcon className={styles.icon} icon={faLink} />
            Copy Link
          </Button>
        </div>
        <Typography className={styles.details} variant="subtitle3">Details</Typography>
        <div className={styles.status}>
          <Typography className={styles.label} variant="h5">METAVERSE STATUS</Typography>
          <TooltipButton className={styles.tooltip}>Visitors cannot access your metaverse until you publish it.</TooltipButton>
          <MetaverseStatus metaverse={metaverse} />
        </div>
        <div className={styles.status}>
          <Typography className={styles.label} variant="h5">VISITOR STATUS</Typography>
          <TooltipButton className={styles.tooltip}>To change visitor access, go to the &quot;Privacy Settings&quot; tab.</TooltipButton>
          <HumanReadableVisitorStatus metaverse={metaverse} />
        </div>
      </TaskModalContent>
    </TaskModal>
  );
}

interface HumanReadableVisitorStatusProps {
  metaverse: IMetaverse;
}

function HumanReadableVisitorStatus({ metaverse }: HumanReadableVisitorStatusProps) {
  if (!metaverse.published) {
    return <Typography variant="body1">Anyone <strong>who is in the organization</strong> can visit</Typography>;
  }
  if (metaverse.privacy.inviteOnly) {
    return <Typography variant="body1">Anyone <strong>who is invited</strong> can visit</Typography>;
  }
  return <Typography variant="body1">Anyone can visit</Typography>;
}
