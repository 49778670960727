import ConfirmationModal from 'src/features/Visitor/components/ConfirmationModal/ConfirmationModal';
import { useSpsContext } from './SpsContextProvider';
import { useEffect, useRef, useState } from 'react';
import { AfkTimedOutEvent, AfkWarningActivateEvent, AfkWarningDeactivateEvent, AfkWarningUpdateEvent } from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.2';

interface TimeoutModalProps {
  onTimeout?: () => void;
}

export default function TimeoutModal({ onTimeout }: TimeoutModalProps) {
  const { enabled, countdown, dismiss } = useAfkWarning(onTimeout);

  return (
    <ConfirmationModal
      title="Are you still there?"
      instructions1={`Your session will stop in ${countdown} seconds.`}
      open={enabled}
      confirmText="Continue"
      onConfirm={dismiss}
      onClose={dismiss}
    />
  );
}

function useAfkWarning(onTimeout: () => void = () => window.location.reload()) {
  const { pixelStreaming } = useSpsContext();
  const [enabled, setEnabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const dismissAfkRef = useRef(() => {});

  useEffect(() => {
    if (!pixelStreaming) return;
    const afkWarningActiveListener = ({ data }: AfkWarningActivateEvent) => {
      const { countDown, dismissAfk } = data;
      setEnabled(true);
      setCountdown(countDown);
      dismissAfkRef.current = dismissAfk;
    };
    pixelStreaming.addEventListener<AfkWarningActivateEvent["type"], AfkWarningActivateEvent>(
      'afkWarningActivate', afkWarningActiveListener,
    );

    const afkWarningUpdateListener = ({ data }: AfkWarningUpdateEvent) => {
      const { countDown } = data;
      setCountdown(countDown);
    };
    pixelStreaming.addEventListener<AfkWarningUpdateEvent["type"], AfkWarningUpdateEvent>(
      'afkWarningUpdate', afkWarningUpdateListener,
    );

    const afkWarningDeactivateListener = () => setEnabled(false)
    pixelStreaming.addEventListener<AfkWarningDeactivateEvent["type"], AfkWarningDeactivateEvent>(
      'afkWarningDeactivate', afkWarningDeactivateListener,
    );

    const afkTimedOutListener = () => {
      setEnabled(false);
      onTimeout();
    };
    pixelStreaming.addEventListener<AfkTimedOutEvent["type"], AfkTimedOutEvent>(
      'afkTimedOut', afkTimedOutListener,
    );

    return () => {
      pixelStreaming.removeEventListener<AfkWarningActivateEvent["type"], AfkWarningActivateEvent>(
        'afkWarningActivate', afkWarningActiveListener
      );
      pixelStreaming.removeEventListener<AfkWarningUpdateEvent["type"], AfkWarningUpdateEvent>(
        'afkWarningUpdate', afkWarningUpdateListener,
      );
      pixelStreaming.removeEventListener<AfkWarningDeactivateEvent["type"], AfkWarningDeactivateEvent>(
        'afkWarningDeactivate', afkWarningDeactivateListener,
      );
      pixelStreaming.removeEventListener<AfkTimedOutEvent["type"], AfkTimedOutEvent>(
        'afkTimedOut', afkTimedOutListener,
      );
    }
  }, [pixelStreaming]);

  return { enabled, countdown, dismiss: dismissAfkRef.current };
}
