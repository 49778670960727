export enum WalletLoginMethod {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  CoinbaseWallet = 'CoinbaseWallet',
}

enum LoginMethod {
  EmailPassword = 'Email Password',
  GoogleOAuth2 = 'Google OAuth2',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  CoinbaseWallet = 'CoinbaseWallet',
}

export default LoginMethod;
