import { useState } from 'react';
import clsx from 'clsx';

import VisitorInput from '../../VisitorInput/VisitorInput';
import VisitorModalActions from '../../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../../VisitorModalContent/VisitorModalContent';
import api from '../../../../../services/api';
import Button from '../../Button';

import styles from './Modal.module.scss';
import Actions from '../Actions';
import Title from '../Title';
import Description from '../Description';
import InputFields from '../InputFields';

interface Props {
  onClose: () => void;
  onSwitchFormClick : Function;
}

export default function SignInForm({
  onClose, onSwitchFormClick,
}: Props) {
  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const [email, setEmail] = useState('');

  async function completeUserReset() {
    try {
      if (!email) {
        setShowRequiredFields(true);
        return;
      }
      const response = await api.post('/api/v2/auth/reset/send', { email });
      onClose();
    } catch (e) { }
  }

  return (
    <>
      <Title>Reset Password with Email</Title>
      <Description>
        Already registered? <button type="button" onClick={() => onSwitchFormClick('login')}>Sign In</button>
      </Description>
      <InputFields>
        <VisitorInput
          title="Email*"
          placeholder="Click to type..."
          value={email}
          onValueChange={(value: string) => setEmail(value)}
          showRequired={showRequiredFields}
        />
      </InputFields>
      <Actions>
        <Button color="primary" onClick={completeUserReset}>
          Reset
        </Button>
      </Actions>
    </>
  );
}
