import clsx from 'clsx';
import Button from '../Button';

import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';

import styles from './ConfirmationModal.module.scss';

interface Props {
  title: string;
  subtext?: string;
  instructions1: string;
  instructions2?: string;
  open: boolean;
  onClose: Function;
  onCancel?: () => void;
  confirmText: string;
  onConfirm: Function;
  showCancel?: boolean;
}

export default function ConfirmationModal({
  title,
  subtext,
  instructions1,
  instructions2,
  open,
  onClose,
  onCancel,
  confirmText,
  onConfirm,
  showCancel = true,
}: Props) {
  return (
    <VisitorModal
      className={styles.modal}
      open={open}
      onClose={() => onClose()}
    >
      <VisitorModalContent>
        <div className={styles.title}>
          {title}
        </div>
        {
          subtext &&
          <div className={styles.subtext}>
            {subtext}
          </div>
        }
        <div className={styles.instructions}>
          {instructions1}
        </div>
        {
          instructions2 &&
          <div className={styles.instructions}>
            {instructions2}
          </div>
        }
      </VisitorModalContent>
      <VisitorModalActions className={clsx(styles['actions-container'], styles['actions-container'])}>
        {
          onCancel &&
          <Button
            className={styles.cancel}
            color="tertiary"
            onClick={(e: any) => { e.stopPropagation(); onCancel(); }}
          >
            Cancel
          </Button>
        }
        <Button
          className={styles.confirm}
          color="primary"
          onClick={(e: any) => { e.stopPropagation(); onConfirm(); }}
        >
          {confirmText}
        </Button>
      </VisitorModalActions>
    </VisitorModal>
  );
}
