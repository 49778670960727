import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { BackgroundVariant } from '@pixelcanvas/ui';
import type { RootState } from '../../../store';

type SidePanel = 'sidenav' | 'editor' | 'booth';

type EditMetaverseState = {
  metaverseId?: string;
  title: string;
  addNewAddOn: boolean;
  pageContentBackground: BackgroundVariant;
  openShareModal: boolean;
  showUsage: boolean;
  sidePanel: SidePanel;
  selectedBoothId: string;
};

const initialState: EditMetaverseState = {
  metaverseId: undefined,
  title: '',
  addNewAddOn: false,
  pageContentBackground: 'default',
  openShareModal: false,
  showUsage: false,
  sidePanel: 'sidenav',
  selectedBoothId: '',
};

export const editMetaverseSlice = createSlice({
  name: 'editMetaverse',
  initialState,
  reducers: {
    setEditMetaverseId: (state, action: PayloadAction<string>) => {
      state.metaverseId = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setAddNewAddOn: (state, action: PayloadAction<boolean>) => {
      state.addNewAddOn = action.payload;
    },
    setPageContentBackground: (state, action: PayloadAction<BackgroundVariant>) => {
      state.pageContentBackground = action.payload;
    },
    setOpenShareModal: (state, action: PayloadAction<boolean>) => {
      state.openShareModal = action.payload;
    },
    setShowUsage: (state, action: PayloadAction<boolean>) => {
      state.showUsage = action.payload;
    },
    setSidePanel: (state, action: PayloadAction<SidePanel>) => {
      state.sidePanel = action.payload;
    },
    selectBooth: (state, action: PayloadAction<string>) => {
      state.selectedBoothId = action.payload;
      state.sidePanel = 'booth'
    },
    unselectBooth: (state) => {
      state.selectedBoothId = '';
      state.sidePanel = 'sidenav';
    },
  },
});

export const {
  setEditMetaverseId,
  setTitle,
  setAddNewAddOn,
  setPageContentBackground,
  setOpenShareModal,
  setShowUsage,
  setSidePanel,
  selectBooth,
  unselectBooth,
} = editMetaverseSlice.actions;

export const selectEditMetaverseId = (state: RootState) => state.editMetaverse.metaverseId;
export const selectTitle = (state: RootState) => state.editMetaverse.title;
export const selectAddNewAddOn = (state: RootState) => state.editMetaverse.addNewAddOn;
export const selectPageContentBackground = (state: RootState) => state.editMetaverse.pageContentBackground;
export const selectOpenShareModal = (state: RootState) => state.editMetaverse.openShareModal;
export const selectShowUsage = (state: RootState) => state.editMetaverse.showUsage;
export const selectSidePanel = (state: RootState) => state.editMetaverse.sidePanel;
export const selectBoothId = (state: RootState) => state.editMetaverse.selectedBoothId;

export default editMetaverseSlice.reducer;
