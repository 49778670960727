enum Tier {
  Diamond = 'diamond',
  Platinum = 'platinum',
  Gold = 'gold',
  Silver = 'silver',
}

const tierRank = [
  Tier.Silver,
  Tier.Gold,
  Tier.Platinum,
  Tier.Diamond,
];

export function tierCompare(a: Tier, b: Tier) {
  return (tierRank.indexOf(a) ?? -1) - (tierRank.indexOf(b) ?? -1);
}

export default Tier;
