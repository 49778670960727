import clsx from 'clsx';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';

import styles from './AuthModal.module.scss';
import AuthModalContextProvider from './AuthModalContextProvider';
import Title from './Title';

interface Props {
  modalClassName?: string;
  className?: string;
  open: boolean;
  onClose: () => void;
  closable?: boolean;
  children?: React.ReactNode;
}

function AuthModal({
  modalClassName,
  className,
  open,
  onClose = () => {},
  closable = true,
  children,
}: Props) {
  return (
    <VisitorModal
      modalClassName={modalClassName}
      className={clsx(className, styles.modal)}
      open={open}
      onClose={onClose}
      closable={closable}
    >
      <div className={styles.content}>
        <Title>Sign in or Create an Account</Title>
        {children}
      </div>
    </VisitorModal>
  );
}

export default function WrappedAuthModal({ onClose, ...props }: Props) {
  return (
    <AuthModalContextProvider onClose={onClose}>
      <AuthModal onClose={onClose} {...props} />
    </AuthModalContextProvider>
  );
}
