import { ReactNode } from 'react';

import styles from './PureWebContainer.module.scss';

type Props = {
  children?: ReactNode;
};

export default function PureWebContainer({ children }: Props) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}
