import { useEffect } from "react";
import { usePixelStreamingContext } from "../contexts/PixelStreamingContext";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { setAvailableEmojis } from "src/features/PixelStreaming/redux/hud";

const MESSAGE_TYPE = "emoji:available" as const;

const emojiAvailableDescriptorMessageSchema = z.object({
  type: z.literal(MESSAGE_TYPE),
  emojis: z.string().array(),
});

export type EmojiAvailableDescriptorMessage = z.infer<typeof emojiAvailableDescriptorMessageSchema>;

export default function useEmojisBinding(ready: boolean) {
  const dispatch = useDispatch();
  const { descriptor } = usePixelStreamingContext();

  useEffect(() => {
    if (!descriptor || !ready) return () => {};
    const emojisAvailableListener = (data: any) => {
      const result = emojiAvailableDescriptorMessageSchema.safeParse(data);
      if (result.success === false) {
        console.error(result.error);
        return;
      }
      dispatch(setAvailableEmojis(result.data.emojis));
    };
    descriptor.on(MESSAGE_TYPE, emojisAvailableListener);
    return () => {
      descriptor.off(MESSAGE_TYPE, emojisAvailableListener);
    };
  }, [descriptor, ready]);
}
