import { UploadButton } from '@pixelcanvas/ui';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import { useRef, useState } from 'react';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { getDefaultBackgrounds } from 'src/static/defaultBackgrounds';

import imagePlaceholder from '../../assets/images/placeholder-image-platform.png';
import { ReactComponent as AngleLeft } from '../../assets/svg/angle-left.svg';
import { ReactComponent as AngleRight } from '../../assets/svg/angle-right.svg';
import { uploadImageContent } from '../../services/ContentService';
import { readURL } from '../../utils/FileUtil';
import PlatformSpinner from '../PlatformSpinner/PlatformSpinner';

import styles from './ImageCarousel.module.scss';

export type ImageCarouselProps = {
  showRequiredFields: boolean,
  imageInfo: string,
  selectedImage: string | undefined,
  setSelectedImage: (image: string) => void,
  onError?: (error: Error) => void,
};

const ImageCarousel = ({
  selectedImage = '',
  setSelectedImage = () => {},
  imageInfo,
  onError,
}: ImageCarouselProps) => {
  const [imageTab, setImageTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileInputId] = useState(uniqueId('image-carousel-file-upload-'));
  const [defaultBackgrounds] = useState(getDefaultBackgrounds());
  const { metaverse } = useCurrentMetaverse();
  const carouselRef = useRef<any>();
  const metaverseId = metaverse?._id ?? '';

  async function uploadContentThumbnail(image: string) {
    try {
      setShowSpinner(true);
      const response: any = await uploadImageContent(metaverseId, image);
      setSelectedImage(response.location);
    } catch (e: any) {
      onError?.(e);
    } finally {
      setShowSpinner(false);
    }
  }

  async function defaultRenderSelected(imageUrl: string) {
    setSelectedImage(imageUrl);
  }

  const imgElements = defaultBackgrounds.map((src, index) => (
    <img
      key={src}
      className={clsx(styles['carousel-image'], { [styles.selected]: selectedImage === src })}
      src={src}
      onClick={() => defaultRenderSelected(src)}
      onKeyDown={() => defaultRenderSelected(src)}
      alt={`Default background ${index + 1}`}
    />
  ));

  return (
    <div className={styles.container}>
      <div className={styles['image-info']}>
        {imageInfo}
      </div>
      <div className={styles['choose-image']}>
        <img className={styles['chosen-image']} src={selectedImage || imagePlaceholder} />
        <div className={styles['options-tab']}>
          <div className={styles.tabs}>
            <div className={clsx(styles.tab, { [styles.selected]: imageTab === 0 })} onClick={() => setImageTab(0)}>
              Upload Your Own
            </div>
            <div className={clsx(styles.tab, styles.renders, { [styles.selected]: imageTab === 1 })} onClick={() => setImageTab(1)}>
              Defaults
            </div>
          </div>
          {
            imageTab === 0 &&
            <div className={styles['upload-your-own']}>
              <div className={styles['upload-button-container']}>
                <UploadButton
                  color="secondary"
                  id={fileInputId}
                  type="file"
                  accept="image/*"
                  onChange={(e) => readURL(e.target, (file: any) => uploadContentThumbnail(file))}
                >
                  Upload
                </UploadButton>
              </div>
              <PlatformSpinner visible={showSpinner} />
            </div>
          }
          {
            imageTab === 1 &&
            <div className={styles['renders-carousel']}>
              <AngleLeft className={clsx(styles['angle-icon'], styles.left)} onClick={() => { carouselRef.current.scrollLeft -= 180; }} />
              <div ref={carouselRef} className={styles.carousel}>
                {imgElements}
              </div>
              <AngleRight className={clsx(styles['angle-icon'], styles.right)} onClick={() => { carouselRef.current.scrollLeft += 180; }} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
