import { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

type ButtonColor = 'primary' | 'secondary' | 'tertiary';
type ButtonSize = 'sm' | 'md';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color: ButtonColor;
  size?: ButtonSize;
  children: React.ReactNode;
}

export default function Button({
  className,
  color,
  size = 'md',
  children,
  ...props
}: ButtonProps) {
  return (
    <button
      className={clsx(className, styles.button, styles[color], styles[size])}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
