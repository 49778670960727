import { createMongoAbility } from '@casl/ability';
import { Can } from '@casl/react';
import { Button, Cards, FilterBy, MainContent, Page, PageContent, Search, SortBy } from '@pixelcanvas/ui';
import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';

import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import { useMe } from 'src/queries/me';
import { useCreateOrganizationMetaverse, useOrganizationMetaverses } from 'src/queries/metaverse';
import { useOrganizationAbility } from 'src/queries/organization';
import { getMetaverseEditURL } from 'src/utils/URLHelper';

import { ReactComponent as PlusCircle } from '../../../../assets/svg/plus-circle.svg';
import { IMetaverse, MetaverseResponse } from '../../../../interfaces/IMetaverse';
import MainHeader from '../../components/MainHeader/MainHeader';
import MetaverseCard from '../../components/MetaverseCard/MetaverseCard';
import SelectEnvironmentModal from '../../components/SelectEnvironmentModal/SelectEnvironmentModal';
import SubheaderNav from '../../components/SubheaderNav/SubheaderNav';
import { useOrganizationContext } from '../OrganizationPage/context/OrganizationContextProvider';

import styles from './MetaversesDashboardPage.module.scss';

enum SortByOption {
  TimeCreated = 'Time Created',
  NameAscending = 'Name: A to Z',
  NameDescending = 'Name: Z to A',
}

export const sortByCompareFn = (sortBy: SortByOption) => (
  a: MetaverseResponse, b: MetaverseResponse,
) => {
  switch (sortBy) {
    case SortByOption.TimeCreated:
      return timeCompareFn(b, a);
    case SortByOption.NameAscending:
      return nameCompareFn(a, b);
    case SortByOption.NameDescending:
      return nameCompareFn(b, a);
    default:
      return 0;
  }
};

const fuseOptions = {
  keys: ['name'],
  threshold: 0.3,
};

enum FilterByOption {
  Live = 'Live',
  Draft = 'Draft',
  CreatedByMe = 'Created By Me',
}

export function filterByPredicateFn(
  metaverse: MetaverseResponse, filterBy: FilterByOption[], userId: string | undefined,
) {
  if (filterBy.length === 0) return true;
  return filterBy.every((by) => {
    switch (by) {
      case FilterByOption.CreatedByMe:
        return metaverse.createdBy === userId;
      case FilterByOption.Draft:
        return metaverse.published === false;
      case FilterByOption.Live:
        return metaverse.published === true;
      default:
        return true;
    }
  });
}

export default function MetaversesDashboardPage() {
  const [sortOption, setSortOption] = useState<SortByOption>(SortByOption.TimeCreated);
  const [filterBy, setFilterBy] = useState<FilterByOption[]>([]);
  const { currentOrganizationId } = useOrganizationContext();
  const { data: ability = createMongoAbility() } = useOrganizationAbility(currentOrganizationId);
  const {
    data: metaverses = [],
    isLoading: organizationMetaverseIsLoading,
  } = useOrganizationMetaverses(currentOrganizationId);
  const [pattern, setPattern] = useState('');
  const [isCreatingMetaverse, setIsCreatingMetaverse] = useState(false);
  const {
    mutate: createMetaverse,
    isLoading: createMetaverseIsLoading,
    isSuccess: createMetaverseIsSuccess,
    data: createdMetaverse,
    error: createMetaverseError,
    reset: resetCreateMetaverse,
  } = useCreateOrganizationMetaverse(currentOrganizationId);
  const { data: me } = useMe();
  const userId = me?._id;

  const isLoading = organizationMetaverseIsLoading && createMetaverseIsLoading;

  const { addToast } = useToastMessageContext();

  const fuseRef = useRef(new Fuse<MetaverseResponse>([], fuseOptions));
  const fuse = fuseRef.current;

  useEffect(() => {
    fuse.setCollection(metaverses);
  }, [metaverses]);

  useEffect(() => {
    document.title = 'Pixel Canvas - Dashboard';
  }, []);

  useEffect(() => {
    if (!createMetaverseIsSuccess || !createdMetaverse) return;
    window.location.href = getMetaverseEditURL(createdMetaverse);
  }, [createMetaverseIsSuccess, createdMetaverse]);

  useEffect(() => {
    if (!createMetaverseError) return;
    addToast('Failed to create metaverse', { type: 'error' });
    resetCreateMetaverse();
    setIsCreatingMetaverse(false);
  }, [createMetaverseError]);

  function handleCreateMetaverseClick() {
    setIsCreatingMetaverse(true);
  }

  const filteredSortedMetaverses = (
    pattern.trim()
      ? fuse.search(pattern.trim()).map((result) => result.item)
      : metaverses
  )
    .filter((metaverse) => filterByPredicateFn(metaverse, filterBy, userId))
    .sort(sortByCompareFn(sortOption));

  return (
    <Page>
    <PageContent>
        <MainHeader />
        <SubheaderNav />
        <MainContent variant="cards">
          <div className={styles.actions}>
            <Search onChange={setPattern} />
            <SortBy options={Object.values(SortByOption)} onChange={setSortOption} />
            <FilterBy options={Object.values(FilterByOption)} onChange={setFilterBy} />
            <Can I={Action.Create} a={Subject.Metaverse} ability={ability} passThrough>
              {
                (allowed) => (
                  <Button color="primary" onClick={handleCreateMetaverseClick} disabled={!allowed}>
                    Create Metaverse
                    <PlusCircle className={styles.icon} />
                  </Button>
                )
              }
            </Can>
          </div>
          <Cards>
            {
              filteredSortedMetaverses
                .map((metaverse: any) => (
                  <MetaverseCard
                    key={metaverse._id}
                    metaverse={metaverse}
                  />
                ))
            }
            {
              metaverses.length === 0 && !isLoading &&
              <div className={styles.noEvents}>
                You don&apos;t have any metaverse yet
              </div>
            }
          </Cards>
        </MainContent>
      </PageContent>
      <SelectEnvironmentModal
        open={isCreatingMetaverse}
        onClose={() => setIsCreatingMetaverse(false)}
        onSelect={(environment) => createMetaverse(environment._id)}
      />
      <PlatformSpinner visible={isLoading} />
    </Page>
  );
}

function timeCompareFn(a: IMetaverse, b: IMetaverse) {
  // Use toUpperCase() to ignore character casing
  const timeA = a.createdAt!;
  const timeB = b.createdAt!;

  if (timeA > timeB) return 1;
  if (timeA < timeB) return -1;
  return 0;
}

function nameCompareFn(a: IMetaverse, b: IMetaverse) {
  // Use toUpperCase() to ignore character casing
  const widgetA = a.name.toUpperCase();
  const widgetB = b.name.toUpperCase();

  if (widgetA > widgetB) return 1;
  if (widgetA < widgetB) return -1;
  return 0;
}
