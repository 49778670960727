import { Button } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useMetaverseUsage } from 'src/queries/metaverse';
import { RootState } from 'src/store';
import { selectMetaverseId } from 'src/redux/metaverse';

import background from '../../../../assets/images/world_map_bg.jpg';
import { ReactComponent as PageFirstIcon } from '../../../../assets/svg/page-first.svg';
import { ReactComponent as PageLastIcon } from '../../../../assets/svg/page-last.svg';
import { ReactComponent as PageNextIcon } from '../../../../assets/svg/page-next.svg';
import { ReactComponent as PagePreviousIcon } from '../../../../assets/svg/page-previous.svg';
import Background from '../../../PixelStreaming/components/Background/Background';
import { isWorldMap3DPage, WorldMap3DPage } from '../../../../interfaces/IPage';
import { SpaceResponse } from '../../../Dashboard/interfaces/ISpace';
import { useMetaverseWorldMap } from '../../../Dashboard/queries/metaverse';
import UsageWarningContainer from '../Usage/UsageWarningContainer/UsageWarningContainer';
import MapItemContainer from '../MapsView/components/MapItem/MapItemContainer';
import MapsView from '../MapsView/MapsView';
import useAccessMetaverse from '../../hooks/useAccessMetaverse';

import styles from './WorldMapScreen.module.scss';

const REQUEST_TIME_URL = window.env.REACT_APP_SUPPORT_MAILTO_LINK ?? 'mailto:support@pixelcanvas.com';

export interface WorldMapScreenProps {
  page: WorldMap3DPage;
  show?: boolean;
  closable?: boolean;
  onClose?: () => void;
  onSelectServerClick: (space: SpaceResponse) => void;
  onPlayNowClick: (space: SpaceResponse) => void;
}

const SPACES_PER_PAGE = 8;

export default function WorldMapScreen({
  page,
  show = true,
  closable,
  onClose,
  onSelectServerClick,
  onPlayNowClick,
}: WorldMapScreenProps) {
  const { metaverse: metaverseDetails } = useAccessMetaverse();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const userId = useSelector((state: RootState) => state.userProfile.userId, shallowEqual);
  const { data: worldMap } = useMetaverseWorldMap(
    metaverseDetails?._id ?? '',
    page && isWorldMap3DPage(page) ? (page.worldMap as any)._id : '',
  );
  const [currentPage, setCurrentPage] = useState(0);
  const { data: usageResponse, isSuccess } = useMetaverseUsage(metaverseId);

  const isOwner = worldMap?.spaces[0].ownerOf === userId;

  const spaces = worldMap?.spaces.map((space) => (
    <MapItemContainer
      key={space._id}
      spaceId={space._id}
      onSelectServerClick={() => onSelectServerClick(space)}
      onPlayNowClick={() => onPlayNowClick(space)}
    />
  )) ?? [];

  const shownSpaces = spaces
    .slice(currentPage * SPACES_PER_PAGE, (currentPage + 1) * SPACES_PER_PAGE);

  const totalPages = worldMap?.spaces.length
    ? Math.ceil(worldMap.spaces.length / SPACES_PER_PAGE)
    : 1;

  const pageNumbers = [...new Array(totalPages)].map((_, i) => (
    <button
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      type="button"
      disabled={i === currentPage}
      className={clsx(styles.pageNumber, { [styles.current]: currentPage === i })}
      onClick={() => setCurrentPage(i)}
    >
      {i + 1}
    </button>
  ));

  useEffect(() => console.log({ currentPage, totalPages, disabled: currentPage === totalPages - 1 }), [currentPage, totalPages]);

  return (
    <>
      { isSuccess && usageResponse && (usageResponse.remainingUsage > 0 || usageResponse.remainingUsage === null) &&
        <>
          <img src={background} className={styles.background} alt="" style={{ display: show ? undefined : 'none' }} />
          <div className={styles.container} style={{ display: show ? undefined : 'none' }}>
            <div className={styles.head}>
              <div className={styles.header}>
                {
           closable &&
           <div className={styles.actions}>
             <Button color="tertiary" onClick={() => onClose?.()}>Close</Button>
           </div>
         }
                <h1 className={styles.title}>{page?.title ?? ''}</h1>
                <div className={styles.descriptions}>
                  <p className={styles.description}>Select the space you want to explore. Clicking &quot;Play Now&quot; will automatically sort you into server to play with others.</p>
                  <p className={styles.description}>Choose &quot;Select a Server&quot; to choose your server yourself so you can be in the same instance as your friends.</p>
                </div>
              </div>
              <div className={styles.nav}>
                <NavButton
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(0)}
                >
                  <PageFirstIcon className={styles.navIcon} />
                </NavButton>
                <NavButton
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <PagePreviousIcon className={styles.navIcon} />
                </NavButton>
                <div className={styles.pageNumbers}>{pageNumbers}</div>
                <NavButton
                  disabled={currentPage === totalPages - 1}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <PageNextIcon className={styles.navIcon} />
                </NavButton>
                <NavButton
                  disabled={currentPage === totalPages - 1}
                  onClick={() => setCurrentPage(totalPages - 1)}
                >
                  <PageLastIcon className={styles.navIcon} />
                </NavButton>
              </div>
            </div>
            <div className={styles.content}>
              <MapsView className={styles.mapView}>{shownSpaces}</MapsView>
            </div>
          </div>
        </>}
      { isSuccess && usageResponse && usageResponse.remainingUsage <= 0 && usageResponse.remainingUsage !== null &&
      <>
        <Background show={!!page?.preloadSource} source={{ srcType: page?.preloadSourceType, src: page?.preloadSource }} />
        <UsageWarningContainer
          logo={metaverseDetails?.logo}
          isOwner={isOwner}
          onRequestTime={() => window.open(REQUEST_TIME_URL, '_blank')}
        />
      </>}

    </>
  );
}

interface NavButtonProps {
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

function NavButton({ disabled = false, onClick, children }: NavButtonProps) {
  return (
    <button
      type="button"
      className={clsx(styles.navButton, { [styles.disabled]: disabled })}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={styles.navButtonContainer}>
        {children}
      </span>
    </button>
  );
}
