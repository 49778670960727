import { useEffect } from 'react';
import usePxAuthContext from 'src/authentication/usePxAuthContext';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export default function useRefreshToken() {
  const { refreshToken } = usePxAuthContext();
  const { descriptor, emitUIInteraction } = usePixelStreamingContext();
  useEffect(() => {
    if (!emitUIInteraction || !descriptor) return () => {};
    const listener = () => refreshToken().catch((e) => console.error(e));
    descriptor.on('refresh-auth-token', listener);
    return () => {
      descriptor.off('refresh-auth-token', listener);
    };
  }, [descriptor, emitUIInteraction, refreshToken]);
}
