import { MainContent, Typography } from '@pixelcanvas/ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { setTitle } from 'src/features/Dashboard/redux/editMetaverse';
import { usePages } from 'src/queries/pages';

export default function AddOnsIndexPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metaverse, isLoading } = useCurrentMetaverse();

  useEffect(() => {
    dispatch(setTitle('Add-Ons'));
  }, []);

  const {
    data: pages = [],
  } = usePages(metaverse?._id);

  useEffect(() => {
    if (isLoading) return;
    if (metaverse?.landingPage) {
      navigate('/edit/addons/landing-page');
      return;
    }
    if (pages.length > 0) {
      navigate(`/edit/addons/stage/${pages[0]._id}`);
    }
  }, [metaverse?.landingPage, pages]);

  return (
    <>
      <MainContent>
        <Typography variant="body1">You don&apos;t have any add-ons yet. Click on &quot;Add New&quot; to include additional features.</Typography>
      </MainContent>
      <SaveFooter disabled />
    </>
  );
}
