import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { GamesSessionResponse } from 'src/features/Dashboard/interfaces/GameSession';
import { GameLiftSessionQuery, getGameliftSessions } from '../../../../services/GameService';
import ServerBrowserModal from './ServerBrowser';

interface Props {
  aliasId: string;
  query: GameLiftSessionQuery;
  open: boolean;
  onClose: () => void;
  onConfirm: (gameSessionId: string) => void;
}

export default function ServerBrowserContainer({
  aliasId, query, open, onClose, onConfirm,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [gameSessions, setGameSessions] = useState<GamesSessionResponse[]>([]);
  const [, setError] = useState<any>();

  const processGameSessions = (rawGameSessions: GamesSessionResponse[]) => {
    setGameSessions(rawGameSessions
      .sort((a: any, b: any) => (
        (a.CurrentPlayerSessionCount / a.MaximumPlayerSessionCount) <
         (b.CurrentPlayerSessionCount / b.MaximumPlayerSessionCount)
          ? -1 : 1
      )));
  };

  const updateGameSessions = async () => {
    try {
      if (!aliasId) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      processGameSessions(await getGameliftSessions(aliasId, query));
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) updateGameSessions();
  }, [open]);

  return (
    <div>
      <ServerBrowserModal
        open={open}
        onClose={onClose}
        onConfirm={onConfirm}
        onRefresh={updateGameSessions}
        gameSessions={gameSessions}
        isLoading={isLoading}
      />
    </div>
  );
}
