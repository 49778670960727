import { Button, InputFieldsGroup, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, TextInputField, Typography } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { useCreateShowcase } from 'src/queries/showcase';

import styles from './AddShowcaseModal.module.scss';

interface AddShowcaseModalProps {
  open: boolean;
  onClose: () => void;
  forceGuid?: string;
}

export default function AddShowcaseModal({ open, onClose, forceGuid }: AddShowcaseModalProps) {
  const { metaverse } = useCurrentMetaverse();
  const { mutate, isLoading, isSuccess, reset } = useCreateShowcase(metaverse?._id);
  const [guid, setGuid] = useState(forceGuid ?? '');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showRequired, setShowRequired] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      reset();
    }
  });

  const handleAdd = () => {
    if (!name) {
      setShowRequired(true);
      return;
    }
    mutate({ guid, name, description });
    onClose();
  };

  return (
    <TaskModal className={styles.modal} open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>ADD NEW SHOWCASE</TaskModalTitle>
      <TaskModalContent>
        <InputFieldsGroup>
          <TextInputField
            label="Name"
            placeholder="Enter name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showRequired && 'Name is required'}
            maxLength={32}
          />
          <TextInputField
            label="GUID"
            placeholder="Enter GUID"
            value={guid}
            onChange={(e) => setGuid(e.target.value)}
            disabled={!!forceGuid}
          />
          <Typography variant="subtitle4" emphasis="high">
            The GUID is an unique identifier for the showcase.
            It <b>needs</b> to match the showcase GUID in the Unreal editor.
            If left blank, a GUID will be generated and you have to add this to the showcase in the Unreal editor.
          </Typography>
          <TextInputField
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={96}
          />
        </InputFieldsGroup>
        <PlatformSpinner visible={isLoading} />
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleAdd}>Add</Button>
      </TaskModalActions>
    </TaskModal>
  );
}
