import debounce from 'debounce';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setSelectedTab, unsetSelectedTab, selectSelectedTab } from 'src/redux/pixelconnect';
import { selectMetaverseId } from 'src/redux/metaverse';
import CreateChannel from '../../CreateChannel/CreateChannel';
import ContactList from '../../ContactList/ContactList';
import ChannelList from '../../ChannelList/ChannelList';
import NotificationsPanel from '../../Notifications/NotificationsPanel';
import VideoChatList from '../../VideoChat/VideoChatList/VideoChatList';
import {
  ModerationAction, Room, Contact, IUser, PlatformRole, ModerationRequest, SocketEvent,
} from '../../../../../interfaces';
import useVideoContext from '../../VideoChat/useVideoContext';
import useSocketContext from '../../../../../contexts/useRoomsSocketContext';
import {
  getMetaverseVisitor, getMetaverseVisitorsPaginated, getMetaverseVisitorsPaginatedSearch, updateMetaverseVisitorAccess,
} from '../../../../../services/MetaverseService';
import {
  getRooms, getVideoToken, postRoom, joinRoom, deleteRoom, getRoomParticipant, getRoomParticipants, postDirectMessageRoom,
} from '../../../../../services/RoomService';

export enum SidepanelContent {
  NotificationsTab = 'notificationsTab',
  VideoChatTab = 'videoChatListTab',
  ChannelListTab = 'channelListTab',
  ContactListTab = 'contactListTab',
  CreateChannelTab = 'createChannelTab',
}

interface Props {
  roles: PlatformRole[],
  localUser: IUser,
  isAdministrator: boolean,
}

export default function Sidepanel({
  roles, localUser, isAdministrator,
}: Props) {
  const {
    connectVideoChat, videoRoom, videoRoomName, disposeRoom,
  } = useVideoContext();
  const {
    socket, joinSocketRoom, inviteUsersToRoom, disconnectSocketRoom, currentChannel,
  } = useSocketContext();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const selectedTab = useSelector(selectSelectedTab, shallowEqual);
  const dispatch = useDispatch();

  const onContextActionClicked = async (action: ModerationAction, moderationTargetId: string) => {
    switch (action) {
      case ModerationAction.DeleteRoom: {
        await deleteRoom(metaverseId, moderationTargetId);
        break;
      }
      case ModerationAction.BanUser: {
        const banRequest: ModerationRequest = { action, moderationTargetId, sender: localUser.userId };
        await updateMetaverseVisitorAccess(metaverseId, moderationTargetId, 'banned');
        socket?.emit(SocketEvent.ControlAccess, banRequest);
        break;
      }
      case ModerationAction.MuteAudio:
      case ModerationAction.RemoveUser: {
        const moderationRequest: ModerationRequest = { action, moderationTargetId, sender: localUser.userId };
        socket?.emit(SocketEvent.ControlAccess, moderationRequest);
        break;
      }

      default:
        break;
    }
  };

  const onClickChannelJoin = async (channelId: string) => {
    const channel: Room = await joinRoom(metaverseId, channelId);
    if (currentChannel) disconnectSocketRoom(currentChannel._id);
    joinSocketRoom(channel);
    if (channel.capabilities.video) {
      disposeRoom();
      const videoToken = (await getVideoToken(metaverseId, channel._id) as any).accessToken;
      await connectVideoChat(channel._id, channel.name, videoToken);
      dispatch(setSelectedTab(SidepanelContent.VideoChatTab));
    }
  };

  const debouncedOnClickChannelJoin = useCallback(debounce(onClickChannelJoin, 1000), [videoRoom, currentChannel]);

  const onContactSelected = async (contact: Contact) => {
    try {
      const { room, status } = await postDirectMessageRoom(metaverseId, contact.userId);
      joinSocketRoom(room);
      if (status === 201) {
        inviteUsersToRoom(room._id, [contact.userId]);
      }
    } catch (e) {

    }
  };
  const onContactsChanged = () => { };

  async function getUsersPaginatedCallback(skip: number, limit: number, useDbQuery: boolean) {
    const users = await getMetaverseVisitorsPaginated(metaverseId, skip, limit, useDbQuery);
    return users;
  }

  async function getUsersPaginatedSearchCallback(skip: number, limit: number, query: string) {
    const users = await getMetaverseVisitorsPaginatedSearch(metaverseId, skip, limit, query);
    return users;
  }

  async function getUsersForRoomInvite(searchText: string) {
    const users = await getMetaverseVisitorsPaginatedSearch(metaverseId, 0, 10, searchText);
    return users;
  }

  async function getUser(userId: string) {
    const user = await getMetaverseVisitor(metaverseId, userId);
    return user;
  }

  async function getChannels() {
    const channels = await getRooms(metaverseId);
    return channels.sort((a: any, b: any) => {
      if (a.type === 'public' && b.type === 'private') return -1;
      if (a.type === 'private' && b.type === 'public') return 1;
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }

  async function getChannelParticipant(roomId: string, userId: string) {
    return getRoomParticipant(metaverseId, roomId, userId);
  }

  async function getChannelParticipants(roomId: string) {
    return getRoomParticipants(metaverseId, roomId);
  }

  async function createChannel(channelConfiguration: any) {
    channelConfiguration.metaverseId = metaverseId;
    const { room } = await postRoom(channelConfiguration);
    inviteUsersToRoom(room._id, channelConfiguration.invite);
    dispatch(setSelectedTab(SidepanelContent.ChannelListTab));
  }

  switch (selectedTab) {
    case SidepanelContent.VideoChatTab:
      return (
        <>
          {
            videoRoom &&
            <VideoChatList
              roles={roles}
              roomName={videoRoomName}
              videoRoomId={videoRoom.name}
              onExitVideoRoom={(videoRoomId: string) => {
                disconnectSocketRoom(videoRoomId);
                dispatch(unsetSelectedTab());
              }}
              onContextActionClicked={onContextActionClicked}
              getUser={getUser}
              getChannelParticipant={getChannelParticipant}
              getChannelParticipants={getChannelParticipants}
            />
          }
        </>
      );
    case SidepanelContent.ChannelListTab:
      return (
        <ChannelList
          onClickChannelJoin={(channelId: string) => debouncedOnClickChannelJoin(channelId)}
          onContextActionClicked={onContextActionClicked}
          isAdministrator={isAdministrator}
          getChannels={getChannels}
        />
      );
    case SidepanelContent.ContactListTab:
      return (
        <ContactList
          onContactSelected={onContactSelected}
          onContactsChanged={onContactsChanged}
          onContextActionClicked={onContextActionClicked}
          localUser={localUser}
          roles={roles}
          getUsersPaginated={getUsersPaginatedCallback}
          getUsersPaginatedSearch={getUsersPaginatedSearchCallback}
        />
      );
    case SidepanelContent.CreateChannelTab:
      return (
        <CreateChannel
          createChannel={createChannel}
          getUsers={getUsersForRoomInvite}
        />
      );
    case SidepanelContent.NotificationsTab:
      return (
        <NotificationsPanel onClickChannelJoin={(channelId: string) => debouncedOnClickChannelJoin(channelId)} />
      );
    default:
      return null;
  }
}
