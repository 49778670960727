import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import api from '../../../../services/api';

export default function useGetNFTs(active: boolean) {
  const [nfts, setNfts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [cursor, setCursor] = useState<string>();

  useAsyncEffect(async () => {
    if (!loading || !active) return;
    try {
      const res = await api.get('/api/nfts', { params: { cursor } });
      setNfts((currentNfts) => {
        const fetchedNfts = res.data.result
          ?.filter((nft: any) => nft.token?.image) || [];
        return currentNfts.concat(fetchedNfts);
      });
      if (res.data.cursor) {
        setCursor(res.data.cursor);
      } else {
        setLoading(false);
        setCursor(undefined);
      }
    } catch (e: any) {
      setLoading(false);
    }
  }, [loading, cursor, active]);

  return { nfts, loading };
}
