/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */

window.dataLayer = window.dataLayer || [];
export const dataLayer = window.dataLayer;

export function gtag(...args: any[]) {
  dataLayer.push(arguments);
}

let clientId: string | null = null;

function setClientId(cid: string) {
  clientId = cid;
}

export function getGAClientId(): string | null {
  return clientId;
}

if (window.env.REACT_APP_GA_MEASUREMENT_ID) {
  gtag('js', new Date());
  gtag('config', window.env.REACT_APP_GA_MEASUREMENT_ID);
  gtag('get', window.env.REACT_APP_GA_MEASUREMENT_ID, 'client_id', setClientId);
}
