import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import Button from 'src/features/Visitor/components/Button';

import VideoInputList from '../VideoInputList/VideoInputList';
import useVideoContext from '../useVideoContext';
import AudioInputList from '../AudioInputList/AudioInputList';
import AudioOutputList from '../AudioOutputList/AudioOutputList';
import VisitorModal from '../../../../Visitor/components/VisitorModal/VisitorModal';
import VisitorModalActions from '../../../../Visitor/components/VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../../../../Visitor/components/VisitorModalContent/VisitorModalContent';

import styles from './DeviceSelectionDialog.module.scss';

interface DeviceSelectionDialogProps {
  open: boolean;
  setOpen: Function;
}

const DeviceSelectionDialog = ({ open, setOpen }: DeviceSelectionDialogProps) => {
  const {
    localAudioTrack, localVideoTrack, createLocalAudioTrack, createLocalVideoTrack,
    removeLocalAudioTrack, removeLocalVideoTrack, videoRoom,
  } = useVideoContext();
  const [mediaError, setMediaError] = useState(false);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef<any>();

  const handleDialogClose = () => {
    if (!videoRoom) {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    }
    setOpen(false);
  };

  useEffect(() => {
    console.log('Creating tracks for Device selection');
    if (mediaError) return;
    if (!localAudioTrack) {
      createLocalAudioTrack()
        .then(() => {
          if (!localVideoTrack) {
            createLocalVideoTrack();
          }
        })
        .catch((e: any) => {
          console.log('Error acquiring audio track');
          setMediaError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!localVideoTrack) {
      createLocalVideoTrack()
        .catch(() => {
          console.log('Error acquiring video track');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [createLocalAudioTrack, createLocalVideoTrack]);

  useEffect(() => {
    if (mediaError) return;
    if (localVideoTrack && localAudioTrack) setLoading(false);
  }, [localAudioTrack, localVideoTrack, mediaError]);

  let content = loading ? (<div>Loading...</div>) : (
    <>
      <div className={styles.section}>
        <div className={styles['section-label']}>
          VIDEO
        </div>
        <VideoInputList />
      </div>
      <div className={styles.section}>
        <div className={styles['section-label']}>
          AUDIO
        </div>
        <AudioInputList />
        <AudioOutputList />
      </div>
    </>
  );

  content = !mediaError ? content : (
    <div>
      <div>
        Unable to acquire webcam and microphone.
      </div>
      <div>
        Please ensure device permissions are enabled in your browser,
        and that no other video applications are running.
      </div>
    </div>
  );

  return (
    <VisitorModal
      className={styles.modal}
      open={open}
      onClose={handleDialogClose}
    >
      <>
        <VisitorModalContent className={styles['modal-content']}>
          <div className={styles.title}>
            Device Settings
          </div>
          {content}
        </VisitorModalContent>
        <VisitorModalActions className={clsx(styles['actions-container'], styles['actions-container'])}>
          <Button
            className={styles.done}
            color="primary"
            onClick={handleDialogClose}
          >
            Done
          </Button>
        </VisitorModalActions>
        <audio className={styles['audio-track']} ref={audioRef} />
      </>
    </VisitorModal>
  );
};

export default DeviceSelectionDialog;
