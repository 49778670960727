/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';

import { selectMetaverseId } from 'src/redux/metaverse';

import SearchBar from '../SearchBar/SearchBar';
import ChannelListItem from './ChannelListItem/ChannelListItem';
import { setChannels } from '../../../../redux/channels';
import { getConnectedParticipants } from '../../../../services/RoomService';
import { RoomsSocketContext } from '../../../../contexts/RoomsSocketContextProvider';
import { Contact, SocketEvent } from '../../../../interfaces';

import styles from './ChannelList.module.scss';
import clsx from 'clsx';

interface ChannelListProps {
  onClickChannelJoin: Function;
  getChannels: Function;
  onContextActionClicked: Function | undefined,
  isAdministrator: boolean;
  dispatch: Function;
  channels: any[];
  metaverseId: string;
}

interface ChannelListState {
  searchText: string;
  connectedParticipants: any,
}

class ChannelList extends React.Component<ChannelListProps, ChannelListState> {
  static contextType = RoomsSocketContext;

  state = {
    searchText: '',
    connectedParticipants: null as any,
  };

  static self: any;

  componentDidMount() {
    const { socket } = this.context;
    ChannelList.self = this;
    this.loadChannels();
    socket.on(SocketEvent.GlobalUserJoinedRoom, this.onGlobalUserJoinedRoom);
    socket.on(SocketEvent.GlobalUserExitedRoom, this.onGlobalUserExitedRoom);
  }

  componentWillUnmount() {
    const { socket } = this.context;
    socket.off(SocketEvent.GlobalUserJoinedRoom, this.onGlobalUserJoinedRoom);
    socket.off(SocketEvent.GlobalUserExitedRoom, this.onGlobalUserExitedRoom);
  }

  async loadChannels() {
    const { getChannels, dispatch, metaverseId } = this.props;
    const participants: any = await getConnectedParticipants(metaverseId);
    const retrievedChannels: any = await getChannels();
    this.setState({ connectedParticipants: participants });
    dispatch(setChannels(retrievedChannels));
  }

  onSearchChange(e: any) {
    (ChannelList.self as any).setState({ searchText: e.target.value });
  }

  onGlobalUserJoinedRoom({ roomId, user }: { roomId: string, user: Contact }) {
    const { connectedParticipants } = ChannelList.self.state;
    const newConnectedParticipants = { ...connectedParticipants } || {};
    if (!newConnectedParticipants[roomId]) {
      newConnectedParticipants[roomId] = [];
    }
    newConnectedParticipants[roomId].push(user);
    ChannelList.self.setState({ newConnectedParticipants });
  }

  onGlobalUserExitedRoom({ roomId, userId }: { roomId: string, userId: string }) {
    const { connectedParticipants } = ChannelList.self.state;
    const newConnectedParticipants = { ...connectedParticipants } || {};
    const updated = { ...newConnectedParticipants, [roomId]: ((newConnectedParticipants[roomId] as Contact[]) || []).filter((contact) => contact.userId !== userId) };
    ChannelList.self.setState({
      connectedParticipants: updated,
    });
  }

  render() {
    const {
      onClickChannelJoin, isAdministrator, onContextActionClicked, channels,
    } = this.props;
    const { searchText, connectedParticipants } = this.state;
    const lowerSearchText = searchText.toLowerCase();
    return (
      <div className={styles['channel-list']}>
        <div className={styles['panel-heading']}>
          Chat Rooms
          <div className={styles['search-bar']}>
            <SearchBar onChange={this.onSearchChange} label="Type a name" />
          </div>
        </div>
        <div className={clsx(styles['panel-body'], styles['list-items'])}>
          {
            channels && connectedParticipants &&
            channels
              .filter((channel) => channel.name.toLowerCase().includes(lowerSearchText) || channel.description.toLowerCase().includes(lowerSearchText))
              .map((channel: any) => (
                <div
                  className={styles['list-item']}
                  key={channel._id}
                >
                  <ChannelListItem
                    channel={channel}
                    isAdministrator={isAdministrator}
                    onClickChannelJoin={onClickChannelJoin}
                    onContextActionClicked={onContextActionClicked}
                    participantCount={connectedParticipants[(channel._id as string)]?.length || 0}
                  />
                  <div className={styles['spacer-line']} />
                </div>
              ))
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    channels: state.channels,
    metaverseId: selectMetaverseId(state),
  };
}

export default connect(mapStateToProps)(ChannelList);
