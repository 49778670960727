import { debounce } from 'debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { DataProvider, LayoutProvider, RecyclerListView } from 'recyclerlistview/web';
import { selectMetaverseId } from 'src/redux/metaverse';
import useAsyncEffect from 'use-async-effect';
import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import {
  Contact, IUser, PlatformRole, SocketEvent,
} from '../../../../interfaces';
import IMetaverseVisitor from '../../../../interfaces/IMetaverseVisitor';
import { addAttendee, addAttendees, setAttendees } from '../../../../redux/attendees';
import SearchBar from '../SearchBar/SearchBar';
import ContactListItem from './ContactListItem/ContactListItem';
import styles from './ContactList.module.scss';

const ROW_HEIGHT = 60; // Needs to be specified for proper virtualized list rendering
const SEARCH_DELAY = 450; // ms to wait before making a query
const BATCH_SIZE = 40; // How many items to fetch at once

interface ContactListProps {
  onContactSelected: Function,
  onContactsChanged: Function,
  onContextActionClicked: Function | undefined,
  localUser: IUser,
  roles: PlatformRole[],
  getUsersPaginated: Function,
  getUsersPaginatedSearch: Function,
}

const ContactList = ({
  onContactSelected = () => { },
  onContactsChanged = () => { },
  onContextActionClicked = undefined,
  localUser,
  roles = [PlatformRole.Attendee],
  getUsersPaginated = () => { },
  getUsersPaginatedSearch = () => { },
}: ContactListProps) => {
  const {
    socket, onlineUsers, addOnlineUsers, removeOnlineUsers,
  } = useSocketContext();
  const dispatch = useDispatch();
  const attendees: IMetaverseVisitor[] = useSelector((state: any) => state.attendees);
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const attendeesIds = useRef(new Set<string>()); // Keep track of already pulled attendees to avoid duplicates
  const paginationSkip = useRef(0);
  const useDbQuery = useRef(false);
  const [dataProvider, setDataProvider] = useState<DataProvider>(
    new DataProvider(
      (p1: IMetaverseVisitor, p2: IMetaverseVisitor) => {
        const value = p1.userId !== p2.userId;
        return value;
      },
    ).cloneWithRows([]),
  );
  const [query, setQuery] = useState('');
  const listRef = useRef(null);
  const { ability } = useAccessMetaverse();

  const hasModerationPermission = ability.can(Action.Manage, Subject.ModerationAction);

  const layoutProvider: LayoutProvider = new LayoutProvider(() => 0, (type, dim) => {
    dim.width = listRef?.current ? (listRef.current as any).clientWidth : 0;
    dim.height = ROW_HEIGHT;
  });

  useAsyncEffect(async () => {
    attendeesIds.current = new Set<string>();
    let results;
    if (query.trim().length !== 0) {
      const newOnlineUsers: string[] = [];
      results = await getUsersPaginatedSearch(0, BATCH_SIZE, query);
      results.forEach((result: any) => {
        if (result.isOnline) {
          newOnlineUsers.push(result.participant.userId);
          attendeesIds.current.add(result.participant.userId);
        }
      });
      addOnlineUsers(newOnlineUsers);
    } else {
      results = await getUsersPaginated(paginationSkip.current, BATCH_SIZE, false);
      results.forEach((newUser: any) => {
        const newUserId = newUser.participant.userId;
        attendeesIds.current.add(newUserId);
      });
      addOnlineUsers(Array.from(attendeesIds.current)); // All users returned from redis have to be online
      useDbQuery.current = false;
    }
    dispatch(setAttendees(results));
  }, [query]);

  useEffect(() => () => {
    dispatch(setAttendees([]));
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvent.UserStatusChanged, onUserStatusChanged);
    }
    return () => {
      socket?.off(SocketEvent.UserStatusChanged, onUserStatusChanged);
    };
  }, [socket]);

  useEffect(() => {
    if (onContactsChanged) {
      onContactsChanged(attendees);
    }

    const displayedAttendees = attendees.filter((attendee) => attendee.userId !== localUser.userId);

    if (displayedAttendees.length > 0) {
      setDataProvider(dataProvider?.cloneWithRows(displayedAttendees));
    } else {
      loadMoreRows();
    }
  }, [attendees, onlineUsers]);

  const onUserStatusChanged = ({ participant, online }: { participant: IMetaverseVisitor, online: boolean }) => {
    if (online) {
      addOnlineUsers([participant.userId]);
      if (!attendeesIds.current.has(participant.userId)) {
        dispatch(addAttendee({
          metaverseId,
          participant,
          status: 'active',
          userId: participant.userId,
          isOnline: true,
        }));
      }
    } else {
      removeOnlineUsers([participant.userId]);
    }
  };

  const loadMoreRows = async () => {
    let moreUsers;
    if (query.trim()) {
      moreUsers = await getUsersPaginatedSearch(attendees.length, BATCH_SIZE, query);
    } else {
      moreUsers = await getUsersPaginated(paginationSkip.current, BATCH_SIZE, useDbQuery.current);
    }

    if (moreUsers.length > 0) {
      const newContacts: any[] = [];
      const newOnlineUsers: string[] = [];
      moreUsers.forEach((newUser: any) => {
        const newUserId = newUser.participant.userId;
        if (!attendeesIds.current.has(newUserId)) {
          attendeesIds.current.add(newUserId);
          newContacts.push(newUser);
          if (newUser.isOnline) {
            newOnlineUsers.push(newUserId);
          }
        }
      });
      dispatch(addAttendees(newContacts));
      addOnlineUsers(newOnlineUsers);
      paginationSkip.current = attendees.length + newContacts.length;

      if (newContacts.length === 0 && moreUsers.length > 0) {
        paginationSkip.current = attendees.length + moreUsers.length;
      }
    }

    if (moreUsers.length < BATCH_SIZE && useDbQuery.current === false) {
      paginationSkip.current = 0;
      useDbQuery.current = true;
      loadMoreRows();
    }
  };

  const debouncedSearch = debounce((e: any) => {
    setQuery(e.target.value.trim());
  }, SEARCH_DELAY);

  const rowRenderer = useCallback((type: any, data: IMetaverseVisitor, index: number) => {
    const dataParticipant = {
      ...data.participant,
      userId: data.userId,
    };
    return (
      <div
        key={data.userId}
        style={{
          height: ROW_HEIGHT,
        }}
      >
        <ContactListItem
          contact={dataParticipant}
          onClick={(e: Event, contact: Contact) => onContactSelected(contact)}
          onContextActionClicked={onContextActionClicked}
        />
      </div>
    );
  }, []);

  return (
    <div className={styles['contact-list']}>
      <div className={styles['panel-heading']}>
        Attendee List
        <div className={styles['search-bar']}>
          <SearchBar onChange={debouncedSearch} label="Type a name" />
        </div>
      </div>
      {(dataProvider as DataProvider)?.getSize() > 0 ?
        <div className={clsx(styles['panel-body'], styles['list-items'])} ref={listRef}>
          <RecyclerListView
            style={{
              border: '0 none #FFF',
              outline: 'none',
              width: '100%',
              flex: 1,
              overflowY: 'auto',
            }}
            layoutProvider={layoutProvider}
            dataProvider={dataProvider!}
            rowRenderer={rowRenderer}
            onEndReached={() => loadMoreRows()}
          />
        </div>
        :
        <div className={clsx(styles['panel-body'], styles['no-attendees-placeholder'])}>
          {
            hasModerationPermission ?
              'No attendees found' : 'No online attendees found'
          }
        </div>}
    </div>
  );
};

export default ContactList;
