import { useEffect } from 'react';
import { WalletLoginMethod } from '../../../../../../authentication/LoginMethod';
import useWalletLogin from '../../../../../../authentication/hooks/useWalletLogin';
import WalletListItem from '../ListItem/WalletListItem';

type Props = {
  method: WalletLoginMethod,
  src: string,
  text: string,
  onError: (error: Error) => void,
  onConnecting?: (connecting: boolean) => void,
};

export default function WalletLogin({
  method,
  src,
  text,
  onError,
  onConnecting,
}: Props) {
  const { login, connecting, error } = useWalletLogin();

  const onClick = async () => {
    try {
      await login(method);
    } catch (e: any) {
      onError?.(e);
    }
  };

  useEffect(() => {
    if (!error) return;
    onError?.(error);
  }, [error]);

  useEffect(() => onConnecting?.(connecting), [connecting]);

  return (
    <WalletListItem
      src={src}
      text={text}
      onClick={onClick}
      disabled={connecting}
    />
  );
}
