import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectMetaverseId } from 'src/redux/metaverse';
import { selectSelectedTab } from 'src/redux/pixelconnect';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import { Room } from '../../../../interfaces';
import { IContent } from '../../../../interfaces/IContent';
import { IPage } from '../../../../interfaces/IPage';
import { getPageContents } from '../../../../services/ContentService';
import { getRoomsForContent, joinRoom } from '../../../../services/RoomService';
import LivestreamChatContainer from '../../components/LivestreamChatContainer/LivestreamChatContainer';
import StageContent from '../../components/StageContent/StageContent';
import styles from './Stage.module.scss';

interface StageProps {
  page: IPage;
}

export default function Stage({ page }: StageProps) {
  const { disconnectSocketRoom, joinSocketRoom } = useSocketContext();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const selectedTab = useSelector(selectSelectedTab, shallowEqual);
  const [contents, setContents] = useState<IContent[] | null>(null);
  const [currentContent, setCurrentContent] = useState<IContent | null>(null);
  const [upNextContent, setUpNextContent] = useState<IContent | null>(null);
  const [livestreamRooms, setLivestreamRooms] = useState<Room[]>([]);

  useEffect(() => {
    fetchContents();
  }, [page._id]);

  async function fetchContents() {
    if (!page._id) return;
    const pageContents = await getPageContents(metaverseId, page._id);
    setContents(pageContents);
    updateContent(pageContents);
  }

  useEffect(() => {
    const now = DateTime.utc();
    const sortedPlaylist = getSortedPlaylistCopy(contents || []);
    const nextStartingStream = sortedPlaylist.find((item: IContent) => DateTime.fromISO(item.timeFrame?.startTime || '') > now);
    const nextEndingStream = sortedPlaylist.find((item: IContent) => DateTime.fromISO(item.timeFrame?.endTime || '') > now);
    const startTimeRefreshInterval = nextStartingStream ? DateTime.fromISO(nextStartingStream?.timeFrame?.startTime || '').diff(now).milliseconds : 60 * 60 * 1000;
    const endTimeRefreshInterval = nextEndingStream ? DateTime.fromISO(nextEndingStream?.timeFrame?.endTime || '').diff(now).milliseconds : 60 * 60 * 1000;
    const refreshTimeout = setTimeout(() => {
      if (contents) updateContent(contents);
    }, Math.min(startTimeRefreshInterval, endTimeRefreshInterval));
    console.log('Next Refresh (ms): ', Math.min(startTimeRefreshInterval, endTimeRefreshInterval));
    return () => clearTimeout(refreshTimeout);
  }, [currentContent, contents]);

  useEffect(() => {
    if (!currentContent) return () => { };
    joinLivestreamRooms(currentContent._id!);
    return () => {
      disconnectSocketRoom(livestreamRooms?.[0]);
      disconnectSocketRoom(livestreamRooms?.[1]);
      setLivestreamRooms([]);
    };
  }, [currentContent]);

  function getSortedPlaylistCopy(playlist: IContent[]) {
    return [...playlist].sort((a, b) => {
      if (!a?.timeFrame?.startTime) return 1;
      if (!b?.timeFrame?.startTime) return -1;
      return DateTime.fromISO(a.timeFrame.startTime).diff(DateTime.fromISO(b.timeFrame.startTime)).milliseconds;
    });
  }

  function updateContent(pageContents: IContent[]) {
    const now = DateTime.utc();
    const sortedPlaylist = getSortedPlaylistCopy(pageContents || []);
    const current = sortedPlaylist.find((content) => {
      if (!content?.timeFrame?.startTime || !content?.timeFrame?.endTime) return false;
      const contentStartTime = DateTime.fromISO(content.timeFrame.startTime);
      const contentEndTime = DateTime.fromISO(content.timeFrame.endTime);
      if (contentStartTime < now && contentEndTime > now) {
        return true;
      }
      return false;
    });
    const upNext = sortedPlaylist.find((content) => {
      if (!content?.timeFrame?.startTime) return false;
      const contentStartTime = DateTime.fromISO(content.timeFrame.startTime);
      if (contentStartTime > now) {
        return true;
      }
      return false;
    });
    if (current) {
      setCurrentContent(current);
    } else {
      setCurrentContent(null);
    }
    if (upNext) {
      setUpNextContent(upNext);
    } else {
      setUpNextContent(null);
    }
  }

  async function joinLivestreamRooms(contentId: string) {
    const rooms: Room[] = await getRoomsForContent(metaverseId, contentId);
    setLivestreamRooms(rooms);
    for (const room of rooms) {
      const joinedRoom = await joinRoom(metaverseId, room._id);
      joinSocketRoom(joinedRoom);
    }
  }

  return (
    <>
      <img className={styles['page-background-image']} src={page.backgroundImage} alt="" />
      <div className={styles['page-header']}>{page.title}</div>
      <div className={styles.stage}>
        {
          contents &&
          <div className={styles['stage-contents']} style={selectedTab ? { width: 'calc(100% - 425px' } : {}}>
            <div className={styles.content}>
              <StageContent
                content={currentContent}
                contentSource={currentContent?.liveSource as string}
                upNextContent={upNextContent}
                livestreamRooms={livestreamRooms}
              />
            </div>
            {
              livestreamRooms.length > 0 &&
              <div className={styles.chat}>
                <LivestreamChatContainer rooms={livestreamRooms} />
              </div>
            }
          </div>
        }
      </div>
    </>
  );
}
