import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';

export interface ContextMenuItem {
  label: string;
  onClick: Function;
  disabled?: boolean;
}

interface ContextMenuProps {
  anchorEl: any;
  items: ContextMenuItem[];
  visible: boolean;
  handleClose: Function;
}

const ContextMenu = ({
  items, visible, handleClose, anchorEl,
}: ContextMenuProps) => (
  <Menu
    anchorEl={anchorEl}
    open={visible}
    keepMounted
    onClose={(event: any) => {
      event.stopPropagation();
      handleClose();
    }}
  >
    {
      items.map((item: ContextMenuItem, i) => (
        <MenuItem
          key={`${item.label}.${i}`}
          disabled={item.disabled}
          onClick={(event) => {
            event.stopPropagation();
            item.onClick();
          }}
        >
          {item.label}
        </MenuItem>
      ))
    }
  </Menu>
);

export default ContextMenu;
