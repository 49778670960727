import { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Times } from '../../../../assets/svg/times.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/plus.svg';
import { ReactComponent as Minus } from '../../../../assets/svg/minus.svg';
import styles from './FAQModal.module.scss';

interface FAQModalProps {
  onClose?: Function;
}

export type FAQModalInterface = {
  open: Function,
  close: Function
};

const FAQModal = forwardRef((props: FAQModalProps, ref) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<any>(null);

  function _onClose() {
    const { onClose } = (props as any);
    if (onClose) {
      onClose();
    }
  }

  function open() {
    setModalVisible(true);
  }

  function close() {
    _onClose();
    setModalVisible(false);
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const faqs: any[] = [
    {
      question: 'What is the recommended browser and device?',
      answers: [
        { details: 'For the best experience, we recommend using Google Chrome as the web browser, and using laptop or desktop as the main device.' },
      ],
    },
    {
      question: 'What are the 3D buttons functionalities?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/08.png',
      answers: [
        { header: 'Menu', details: 'Adjust your Avatar, volume settings, and access your Inventory of things you have collected in the game!' },
        { header: 'Website', details: 'Click this little house icon will take you back to the website and leave the 3D island.' },
        { header: 'View', details: 'Change your camera angle here! You can switch to first person view or top down view.' },
        { header: 'Map', details: "Click to see the whole island map. Click on the area you're interested in to quickly travel to the location." },
      ],
    },
    {
      question: 'How do I move in the 3D space?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/09.png',
      answers: [
        { details: "You can use the arrow keys on your keyboard to move around or, like Google Maps Street View, you can point and click with your mouse. For point and click movement, hover over the spot you'd like to move to then click to confirm. For mobile users, tap where you would like to move to." },
        { details: 'The blue light is your new cursor!' },
      ],
    },
    {
      question: 'How do I look around?',
      answers: [
        { details: 'Click and drag on your screen to look around. You can change your camera settings by clicking on the “Settings” button on the lower left hand corner of your screen.' },
      ],
    },
    {
      question: 'How can I use Text Chat?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/15.png',
      answers: [
        { header: 'Group Chat', details: 'Each video chat room has a corresponding group text chat.  Messages sent in this group chat can be seen by everyone in the chat room.' },
        { header: 'Direct Messaging', details: 'You can send messages to individuals.  To start messaging an individual, open “Attendee List” and click on the name of the person to whom you wish to send a direct message.' },
      ],
    },
    {
      question: 'How do I leave the Chat Room?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/17.png',
      answers: [
        { details: "<b>Use the chat room navigation bar on the right side</b><br/>In your current chat room, click the Phone Icon that's on the bottom right.</br>When you jump to a different room by using the “Chat Rooms” button on the right side bar, you'll be automatically disconnected from the current chat room while connecting to the new one." },
      ],
    },
    {
      question: 'How do I control the video chat camera and microphone?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/18.png',
      answers: [
        { details: 'While in a video chat, you will see three buttons present on the bottom center of the screen. From left to right the buttons:<br/><b>Microphone input</b>: turn on and off the microphone,<br/><b>Webcam visibility</b>: turn on and off the camera,<br/><b>End call</b>: leave the video chat and disconnect microphone and webcam' },
      ],
    },
    {
      question: "How do I see the chat room I'm currently in?",
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/19.png',
      answers: [
        { details: 'The “Current Call” button minimizes and expands the video chat room you are currently in. If you are not in a video chat this panel will be deactivated.' },
      ],
    },
    {
      question: 'How do I know who is currently online?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/20.png',
      answers: [
        { details: "The “Attendee List” shows you a list of everyone participating in the event.  Clicking on someone's name from this list will initiate a direct text chat with that person." },
      ],
    },
    {
      question: 'How do I adjust video and microphone settings for my Video Chat?',
      image: 'https://pixelevents-public.s3.us-west-1.amazonaws.com/pixelcanvas/faq/21.png',
      answers: [
        { details: '“Camera Settings” is where you can control your video and microphone inputs.  If you are having trouble with video calls, check here to make sure you are using the right camera and microphone.' },
      ],
    },
    {
      question: 'Why am I not hearing audio?',
      answers: [
        { header: 'Check Other Tabs', details: "Check to be sure your tab is not muted, and also check that you're not sharing audio in another program (might be in another tab or another app)." },
      ],
    },
  ];

  return (
    <Modal
      className={styles['faq-modal-container']}
      container={() => document.getElementById('pixel-platform')}
      open={modalVisible}
      onClose={close}
    >
      <div className={styles['faq-modal']}>
        <Times className={styles['close-button']} onClick={close} />
        <div className={styles['modal-heading']}>
          <div className={styles['modal-header']}>FREQUENTLY ASKED QUESTIONS</div>
        </div>
        <div className={styles['questions-container']}>
          {
            faqs.map((faq, index) => (
              <div key={`${faq.question}${index}`}>
                <div
                  className={clsx(styles['question-header'], { [styles.expanded]: expandedIndex === index })}
                  onClick={() => {
                    setExpandedIndex((prevIndex: any) => (prevIndex === index ? null : index));
                  }}
                >
                  <div className={styles['question-text']}>{faq.question}</div>
                  {expandedIndex === index ? <Minus className={styles['expand-button']} /> : <Plus className={styles['expand-button']} />}
                </div>
                {
                    expandedIndex === index &&
                    <div className={styles.answer}>
                      {faq.image && <img className={styles['answer-image']} src={faq.image} />}
                      <div className={styles['answer-text']}>
                        {
                          faq.answers?.map((answer: any) => (
                            <div>
                              {answer.header && <div className={styles['answer-header']}>{answer.header}</div>}
                              {answer.details && <div className={styles['answer-details']} dangerouslySetInnerHTML={{ __html: answer.details }} />}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  }
              </div>
            ))
          }
        </div>
      </div>
    </Modal>
  );
});

export default FAQModal;
