import { Button, MainContent, Typography } from '@pixelcanvas/ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { setPageContentBackground, setTitle } from '../../redux/editMetaverse';

import styles from './EditMetaverseHomePage.module.scss';

export default function EditMetaverseHomePage() {
  const dispatch = useDispatch();
  const { metaverse, isLoading } = useCurrentMetaverse();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setTitle('Home'));
    dispatch(setPageContentBackground('transparent'));
    return () => {
      dispatch(setPageContentBackground('default'));
    };
  }, []);

  if (isLoading) {
    return (
      <MainContent classes={{ main: styles.main, content: styles.content }}>
        <PlatformSpinner visible={isLoading} />
      </MainContent>
    );
  }

  if (!metaverse?.spaces || metaverse?.spaces.length === 0) {
    return (
      <MainContent variant="table" classes={{ main: styles.main, content: styles.content }}>
        <Typography variant="h3">No Live Editable Enviroment Found</Typography>
        <Typography variant="subtitle1">This usually occurs with an outdated metaverse</Typography>
        <Typography variant="subtitle1">Your Metaverse will continue to work,</Typography>
        <Typography variant="subtitle1">but recreate your metaverse for the full live editing experience</Typography>
      </MainContent>
    );
  }

  const space = metaverse.spaces[0];

  return (
    <main className={styles.main}>
      {space && space.environment.previewImage && <img className={styles.previewImage} src={space.environment.previewImage} alt="" />}
      <Button className={styles.enter} onClick={() => navigate('/edit/3d')}>Enter 3D Editor</Button>
      <Typography className={styles.note} variant="body2" emphasis="high">Please note that the 3D editor will use your 3D streaming time</Typography>
    </main>
  );
}
