import { Button, Typography } from '@pixelcanvas/ui';
import axios from 'axios';

import { useState } from 'react';

import { uploadImageContent, uploadVideoContent } from 'src/services/ContentService';
import PlatformSpinner from '../../../../components/PlatformSpinner/PlatformSpinner';
import { compressImage, readURL } from '../../../../utils/FileUtil';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';

import imagePlaceholder from '../../../../assets/images/placeholder-image-event.png';

import styles from './UploadContentModal.module.scss';
import NFTSelectionModal from '../NFTSelectionModal/NFTSelectionModal';

interface Props {
  metaverseId: string;
  isSiteAdmin?: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: (fileUrl: string) => void;
}

export default function UploadImageModal({
  open, onClose, onConfirm, metaverseId, isSiteAdmin,
}: Props) {
  const [content, setContent] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileType, setFileType] = useState('image');
  const [openNFTModal, setOpenNFTModal] = useState(false);
  const [error, setError] = useState('');

  const closeModal = () => {
    setContent('');
    onClose();
  };

  const onNFTSelected = async (selectedNFT: string) => {
    const file = await axios
      .get(selectedNFT, { responseType: 'blob' })
      .then((res) => new File([res.data], 'nft', { type: res.headers['content-type'] }));

    const updatedPicture = file.type === 'image/gif' ? file : await compressImage(file);
    const response: any = await uploadImageContent(metaverseId, updatedPicture as any);
    setContent(response.location);
    setOpenNFTModal(false);
  };

  async function uploadSpaceContent(fileUrl: string, localFileType: string) {
    try {
      if (localFileType === 'image') {
        const response: any = await uploadImageContent(metaverseId, fileUrl);
        setContent(response.location);
        setFileType('image');
      } else {
        const response: any = await uploadVideoContent(metaverseId, fileUrl);
        setContent(response.location);
        setFileType('video');
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setShowSpinner(false);
    }
  }

  const handleConfirm = () => {
    onConfirm(content);
    closeModal();
  };

  return (
    <>
      <VisitorModal
        className={styles.modal}
        open={open}
        onClose={closeModal}
      >
        <VisitorModalContent>
          <Typography variant="h2" className={styles.title}>
            UPLOAD YOUR IMAGE
          </Typography>
          <div className={styles['upload-container']}>
            <div className={styles['upload-rendered']}>
              <PlatformSpinner visible={showSpinner} />
              {
              fileType === 'image' ?
                <img className={styles['content-preview']} src={content !== '' ? content : imagePlaceholder} alt="" />
                :
                <video className={styles['content-preview']} src={content} controls />
              }
            </div>
            <div className={styles.uploadActionsContainer}>
              <div className={styles.uploadActions}>
                {isSiteAdmin &&
                <label
                  className={styles.uploadButton}
                  htmlFor="content-upload-modal"
                >
                  Upload
                </label>}
                <Button
                  className={styles.selectnft}
                  color="secondary"
                  onClick={() => setOpenNFTModal(true)}
                >
                  Select NFT
                </Button>
              </div>
              <div className={styles.uploadDescription}>
                Image uploads cannot exceed 20MB.
              </div>
              {error &&
              <div className={styles.error}>
                {error}
              </div>}
              <input
                className={styles.fileInput}
                id="content-upload-modal"
                type="file"
                accept="image/*"
                onChange={(e) => readURL(e.target, (file: any, localFileType: string) => uploadSpaceContent(file, localFileType), () => setShowSpinner(true))}
              />
            </div>
          </div>
        </VisitorModalContent>
        <VisitorModalActions className={styles['modal-actions']}>
          <Button color="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!content}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </VisitorModalActions>
      </VisitorModal>
      <NFTSelectionModal
        open={openNFTModal}
        onNFTSelected={onNFTSelected}
        onClose={() => setOpenNFTModal(false)}
      />
    </>

  );
}
