import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageType from 'src/enums/PageType';
import { usePages } from 'src/queries/pages';
import { IMetaverse, ISpace } from '../../../../interfaces/IMetaverse';
import { IMetaverse3DPage, IPage } from '../../../../interfaces/IPage';
import useAccessMetaverse from '../../hooks/useAccessMetaverse';
import { selectSelectedPage, setSelectedPage } from '../../redux/metaversePageSlice';
import CollapseButton from '../CollapseButton/CollapseButton';
import PageButton from '../PageButton/PageButton';
import styles from './PagesSidebar.module.scss';

type Props = {
  onPageClick: (page: IPage) => void;
};

export default function PagesSidebar({
  onPageClick,
}: Props) {
  const { metaverse } = useAccessMetaverse();
  const [collapsed, setCollapsed] = useState(false);
  const selectedPage = useSelector(selectSelectedPage);
  const dispatch = useDispatch();

  const { data: pages = [] } = usePages(metaverse?._id);

  if (!metaverse) return null;

  const exhibits = pages.filter((page) => page.type === PageType.Exhibit);
  const regularPages = pages.filter((page) => page.type !== PageType.Exhibit);

  const metaversePages: IMetaverse3DPage[] = metaverse.spaces.map((space) => ({
    type: PageType.Metaverse3D,
    metaverseId: metaverse._id,
    spaceId: space._id,
    space,
    title: space.title,
    metaverse,
    createdAt: metaverse.createdAt,
    updatedAt: metaverse.updatedAt,
  }));

  const uiColors = parseUIColors(metaverse);

  const pageButtonElements = regularPages.map((page) => (
    <PageButton
      key={page._id}
      active={selectedPage === page}
      navBarColor={uiColors.navBarColor}
      tabColor={uiColors.navBarAccentColor}
      onClick={() => onPageClick(page)}
    >
      {page.title}
    </PageButton>
  ));

  const metaversePageButtonElements = metaversePages.map((page) => (
    <Metaverse3DButton
      key={page.spaceId}
      metaverse={metaverse}
      space={page.space}
      selectedPage={selectedPage}
      onClick={() => onPageClick(page)}
    />
  ));

  const allPages = [...metaversePages, ...regularPages];

  useEffect(() => {
    if (selectedPage || allPages.length === 0) return;
    dispatch(setSelectedPage(allPages[0]));
  }, [selectedPage, allPages]);

  const width = `${collapsed ? 0 : 10}rem`;

  return (
    <div className={styles.container} style={{ width, minWidth: width }}>
      <div className={styles.sidebar} style={{ background: uiColors.navBarColor }}>
        {metaverse.logo && <MetaverseLogo logo={metaverse.logo} />}
        {metaversePageButtonElements}
        {pageButtonElements}
        {
          exhibits.length > 0 &&
          <ExhibitsButton
            metaverse={metaverse}
            selectedPage={selectedPage}
            onPageClick={onPageClick}
          />
        }
      </div>
      <CollapseButton
        className={clsx(styles.collapseButton, { [styles.collapsed]: collapsed })}
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      />
    </div>
  );
}

type ExhibitsButtonProps = {
  metaverse: IMetaverse;
  selectedPage: IPage | undefined;
  onPageClick: (page: IPage) => void;
};

function ExhibitsButton({ metaverse, selectedPage, onPageClick }: ExhibitsButtonProps) {
  const uiColors = parseUIColors(metaverse);
  return (
    <PageButton
      key="previews"
      active={selectedPage?.type === PageType.Exhibit}
      navBarColor={uiColors.navBarColor}
      tabColor={uiColors.navBarAccentColor}
      onClick={() => onPageClick({ title: 'Previews', type: PageType.Exhibit } as IPage)}
    >
      Previews
    </PageButton>
  );
}

type MetaverseLogoProps = {
  logo: string;
};

function MetaverseLogo({ logo }: MetaverseLogoProps) {
  return <img className={styles.logo} src={logo} alt="Metaverse Logo" />;
}

type Metaverse3DButtonProps = {
  metaverse: IMetaverse;
  space: ISpace;
  selectedPage: IPage | undefined;
  onClick: () => void;
};

function Metaverse3DButton({ metaverse, space, selectedPage, onClick }: Metaverse3DButtonProps) {
  const uiColors = parseUIColors(metaverse);
  return (
    <PageButton
      key={space._id}
      active={selectedPage?.type === PageType.Metaverse3D}
      navBarColor={uiColors.navBarColor}
      tabColor={uiColors.navBarAccentColor}
      onClick={onClick}
    >
      {space.title}
    </PageButton>
  );
}

function parseUIColors(metaverse: IMetaverse) {
  return {
    navBarColor: metaverse.ui.colors.navBar || 'white',
    navBarAccentColor: metaverse.ui.colors.navBarAccent || undefined,
    pixelConnectIconColor: metaverse.ui.colors.pixelConnectIcon || 'white',
  };
}
