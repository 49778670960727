import clsx from 'clsx';
import { useState } from 'react';
import { Button } from '@pixelcanvas/ui';

import pixelLogo from '../../assets/images/pixel-canvas-logo.png';
import usePxAuthContext from '../../authentication/usePxAuthContext';
import AuthModal from '../../features/Visitor/components/Authentication/StandardAuthModal';
import UserPill from '../UserPill/UserPill';

import styles from './LandingHeader.module.scss';

interface LandingHeaderProps {
  className?: string;
}

export default function LandingHeader({ className }: LandingHeaderProps) {
  const { user, isAuthenticated } = usePxAuthContext();
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const BrandLogo = () => (
    isAuthenticated
      ? <img className={styles.brandLogoText} src={pixelLogo} alt="" />
      : <img className={styles.brandLogo} src={pixelLogo} alt="" />
  );

  return (
    <div className={clsx(className, styles.header, { [styles.isAuthenticated]: isAuthenticated })}>
      <BrandLogo />
      {
        !user
          ?
            <>
              <a
                className={styles.contactButton}
                href="mailto:support@pixelcanvas.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
              <Button
                className={styles.loginButton}
                color="tertiary"
                onClick={() => setAuthModalOpen(true)}
              >
                Log In
              </Button>
              <AuthModal
                open={authModalOpen}
                onClose={() => setAuthModalOpen(false)}
              />
            </>
          :
            <div className={styles.headerUser}>
              <UserPill />
            </div>
      }
    </div>
  );
}
