import { OrganizationResponse } from 'src/interfaces/IOrganization';

export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID';
export const SET_ORGANIZATION_DETAILS = 'SET_ORGANIZATION_DETAILS';
export const SET_LOCAL_ORGANIZATION_DETAILS = 'SET_LOCAL_ORGANIZATION_DETAILS';

type OrganizationState = {
  organizationId?: string;
  details?: OrganizationResponse;
};

type OrganizationAction = {
  type: string;
  payload?: any;
};

const initialState: OrganizationState = {};

export const organizationReducer = (state: OrganizationState = initialState, action: OrganizationAction = { type: 'unknown' }) => {
  let newState;
  switch (action.type) {
    case SET_ORGANIZATION_ID: {
      newState = { ...state };
      newState.organizationId = action.payload;
      return newState;
    }
    case SET_ORGANIZATION_DETAILS: {
      newState = { ...state };
      newState.details = action.payload;
      return newState;
    }
    case SET_LOCAL_ORGANIZATION_DETAILS: {
      newState = { ...state };
      newState.details = action.payload;
      return newState;
    }
    default: return state;
  }
};

export function setOrganizationId(organizationId: string): OrganizationAction {
  return { type: SET_ORGANIZATION_ID, payload: organizationId };
}

export function setOrganizationDetails(details: OrganizationResponse): OrganizationAction {
  return { type: SET_ORGANIZATION_DETAILS, payload: details };
}

export function setLocalOrganizationDetails(details: OrganizationResponse): OrganizationAction {
  return { type: SET_LOCAL_ORGANIZATION_DETAILS, payload: details };
}
