import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { shallowEqual, useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { selectMetaverseId } from 'src/redux/metaverse';

import { ReactComponent as SearchIcon } from '../../../../assets/svg/search.svg';
import { TagType } from '../../../../enums/TagType';
import { IContent } from '../../../../interfaces/IContent';
import { IPage } from '../../../../interfaces/IPage';
import { getContent, getTags } from '../../../../services/ContentService';
import { getURLVideoID } from '../../../../utils/YoutubeUrlUtil';

import styles from './VODPage.module.scss';

interface VODPageProps {
  page: IPage;
}

const VODPage = ({ page }: VODPageProps) => {
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [contentArray, setContentArray] = useState<IContent[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>('All');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedVod, setSelectedVod] = useState<IContent>();
  const searchInputRef = useRef<any>();

  useAsyncEffect(async () => {
    const contentResult = await getContent(metaverseId, '', [], true);
    setContentArray(contentResult);
    const tagsResult = await getTags(metaverseId, TagType.Tags, false);
    if (tagsResult.length > 0) {
      setTags([{ _id: 'All' }].concat(tagsResult));
    }
  }, []);

  function searchInputKeyPress(e: any) {
    if (e.key === 'Enter') {
      setSearchQuery(searchInputRef.current.value);
    }
  }

  function parseContentSource(contentSource: string|undefined) {
    const src = contentSource || '';
    try {
      // Try parsing for youtube url
      const videoID = getURLVideoID(src);
      return `https://www.youtube.com/embed/${videoID}`;
    } catch (e) {
      return contentSource;
    }
  }

  return (
    <>
      <img className={styles['page-background-image']} src={page.backgroundImage} alt="" />
      <div className={styles['vod-page']}>
        <div className={styles['search-bar']}>
          <input ref={searchInputRef} className={styles['search-input']} type="text" placeholder="Search" onKeyPress={searchInputKeyPress} />
          <SearchIcon className={styles['search-icon']} onClick={() => setSearchQuery(searchInputRef.current.value)} />
        </div>
        <div className={styles.tags}>
          {
            tags.map((tag) => (
              <div key={tag._id} className={clsx(styles.tag, { [styles.selected]: selectedTag === tag._id })} onClick={() => setSelectedTag(tag._id)}>
                {(tag as any)._id}
              </div>
            ))
          }
        </div>
        <div className={styles['content-grid']}>
          {
            contentArray
              .filter((content) => content.title.toLowerCase().includes(searchQuery.toLowerCase()) && (selectedTag !== 'All' ? content.tags.includes(selectedTag) : true))
              .map((content) => (
                <div key={content._id} className={styles['vod-item']} onClick={() => setSelectedVod(content)}>
                  <img className={styles.thumbnail} src={content.thumbnail} alt="thumbnail" />
                  <div className={styles['content-info']}>
                    <div className={styles['content-title']}>
                      {content.title}
                    </div>
                    <div className={styles['content-description']}>
                      <ReactMarkdown children={content.description} linkTarget="_blank" />
                    </div>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
      <Modal
        className={styles['vod-modal']}
        container={() => document.getElementById('pixel-platform')}
        open={!!selectedVod}
        onClose={() => setSelectedVod(undefined)}
      >
        <div className={styles['vod-iframe']}>
          <iframe
            className={styles.video}
            frameBorder="0"
            width="100%"
            height="100%"
            src={parseContentSource(selectedVod?.onDemandSource)}
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modal>
    </>
  );
};

export default VODPage;
