export const videoPlaylistDescription = "Manage attendees and export attendees' information. Accounts with moderation capabilities can also manage attendees on metaverse site after the metaverse is published.";
export const livestreamDescription = 'Display one live stream video with a title, a text description and buttons. Typically built for webinar sessions. This can be connected to a Live Stream Space or Main Stage in "Customize Metaverse."';
export const singleContentDescription = 'Display one image with a title, a text description and CTA buttons. Typically built for sponsor booths. This can be connected to a Video and Copy Space or Main Stage in "Customize Metaverse."';

export const videoPlaylistTemplateDescription = 'Display a list of videos with titles and brief descriptions. Typically built for video on demand content.';
export const livestreamTemplateDescription = 'Display one live stream video with a title, a text description and a CTA button. Typically built for webinar sessions.';
export const singleContentTemplateDescription = 'Display one image with a title, a text description, and a CTA button. Typically built for sponsor booths.';

export const contentButtonDescription = 'This controls the visibility of the button on this page. Enabling it will place it on the current page and allow you to link out to an external website or document.';

export const defaultbg1 = 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/default-backgrounds/bg-1.jpg';
export const defaultbg2 = 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/default-backgrounds/bg-2.jpg';
export const defaultbg3 = 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/default-backgrounds/bg-3.jpg';
export const defaultbg4 = 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/default-backgrounds/bg-4.jpg';
export const defaultbg5 = 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/default-backgrounds/bg-5.jpg';
export const defaultbg6 = 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/default-backgrounds/bg-6.jpg';

export const editLandingPageInfo = 'Give your visitors a preview of what your metaverse will look like with the main image for your metaverse. You can upload your own image or use one of our renders.';
export const mainStageImageInfo = 'Upload an image to use as the background for this page or use one of our renders to give your metaverse a sense of place.';

// Keys for FTUE tooltips
export const firstPageFtueKey = 'first-page-ftue';
export const createStageFtueKey = 'create-stage-ftue';
export const createContentFtueKey = 'create-content-ftue';
export const addToAgendaFtueKey = 'add-to-agenda-ftue';
export const timeControlFtueKey = 'time-control-ftue';

// Defaults for when metaverse has none configured
export const DEFAULT_PRIMARY_COLOR = '#CC28D3';
export const DEFAULT_SECONDARY_COLOR = '#735DDD';

// Default email use permission text
export const DEFAULT_EMAIL_USE_PERMISSION_TEXT = 'I agree to allow metaverse organizers to contact me through email.';

export const PRIVACY_POLICY_LINK = 'https://pixelevents-public.s3.us-west-1.amazonaws.com/Pixel-Canvas-Privacy-Policy.pdf';
export const TERMS_OF_USE_LINK = 'https://pixelevents-public.s3.us-west-1.amazonaws.com/Pixel-Canvas-Terms-of-Use.pdf';
export const PIXELCANVAS_COM_LINK = 'https://www.pixelcanvas.com/?utm_source=pixelcanvas&utm_medium=referral';
