export const SET_USER_PROFILE = 'SET_USER_PROFILE';

const initialState = { };

export const userProfileReducer = (state = initialState, action = {}) => {
  let newState;
  switch (action.type) {
    case SET_USER_PROFILE: {
      newState = { ...state, ...action.payload };
      return newState;
    }
    default: return state;
  }
};

export function setUserProfile(data) {
  return { type: SET_USER_PROFILE, payload: data };
}
