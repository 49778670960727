import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

import { selectPointsOfInterest, setPointsOfInterest } from '../redux/hud';

import styles from './PointsOfInterest.module.scss';

interface PointsOfInterestProps {
  className?: string;
}

export default function PointsOfInterest({
  className,
}: PointsOfInterestProps) {
  const dispatch = useDispatch();
  const pointsOfInterest = useSelector(selectPointsOfInterest);
  const [openTeleportPoints, setOpenTeleportPoints] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const { descriptor, emitUIInteraction } = usePixelStreamingContext();

  useEffect(() => {
    if (!descriptor) return () => {};
    const poiListener = ({ locations }: { locations: string[] }) => {
      dispatch(setPointsOfInterest(locations));
    };
    const enabledListener = ({ enabled: isEnabled }: { enabled: boolean }) => {
      setEnabled(isEnabled);
    };
    descriptor.on('poi', poiListener);
    descriptor.on('hud:poi', enabledListener);
    return () => {
      descriptor.off('poi', poiListener);
      descriptor.off('hud:poi', enabledListener);
    };
  }, [descriptor]);

  const handleTeleportClick = (location: string) => () => {
    if (!emitUIInteraction) return;
    emitUIInteraction({
      type: 'teleport',
      location,
    });
    setOpenTeleportPoints(false);
  };

  if (!enabled || pointsOfInterest.length === 0) return null;

  const handlePointsOfInterestClick = () => {
    setOpenTeleportPoints(!openTeleportPoints);
  };

  return (
    <div className={clsx(className, styles.container)}>
      <PointOfInterestButton onClick={handlePointsOfInterestClick} />
      {openTeleportPoints && (
        <div className={styles.teleportpoints}>
          {
            pointsOfInterest.map((location) => (
              <button
                key={location}
                type="button"
                className={styles.teleportpoint}
                onClick={handleTeleportClick(location)}
              >
                {location}
              </button>
            ))
          }
        </div>
      )}
    </div>
  );
}

interface PointOfInterestButtonProps {
  onClick: () => void;
}

function PointOfInterestButton({ onClick }: PointOfInterestButtonProps) {
  return (
    <button type="button" className={styles.button} onClick={onClick}>
      <span className={styles.shadow}>
        <span className={styles.span}>
          <FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
          Points of Interest
        </span>
      </span>
    </button>
  );
}
