import VisualSource, { VISUAL_SOURCE_TYPE } from 'src/interfaces/VisualSource';

import styles from './Background.module.scss';

type BackgroundProps = {
  show?: boolean;
  source: VisualSource | undefined;
};

export default function Background({ show = true, source }: BackgroundProps) {
  if (!source || !show) return null;

  const { srcType, src } = source;

  switch (srcType) {
    case VISUAL_SOURCE_TYPE.Video:
      return <BackgroundVideo src={src} />;
    case VISUAL_SOURCE_TYPE.Image:
    default:
      return <img className={styles.image} src={src} alt="" />;
  }
}

type BackgroundVideoProps = {
  src: string;
};

const BackgroundVideo = ({ src }:BackgroundVideoProps) => (
  <div className={styles.container}>
    <video className={styles.video} src={src} muted autoPlay loop />
  </div>
);
