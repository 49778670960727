import { ColorInputField, InputFieldsGroup, MainContent, TextInputField, Typography } from '@pixelcanvas/ui';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';

import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { useSubdomainAvailbility, useUpdateMetaverse } from 'src/queries/metaverse';
import { getMetaverseEditURL } from 'src/utils/URLHelper';
import ButtonGatedTextInptField from '../../components/ButtonGatedTextInputField';

import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { setTitle } from '../../redux/editMetaverse';

import styles from './EditMetaverseKeyDetailsPage.module.scss';

export default function EditMetaverseKeyDetailsPage() {
  const dispatch = useDispatch();
  const { metaverse, ability } = useCurrentMetaverse();
  const [name, setName] = useState<string>(metaverse?.name ?? '');

  useEffect(() => {
    dispatch(setTitle('Key Details'));
  }, []);

  useEffect(() => {
    if (!metaverse?.name) return;
    setName(metaverse.name);
  }, [metaverse?.name]);

  const {
    mutate: updateMetaverse,
    isSuccess,
    reset,
    data: updatedMetaverse,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess) return;
    reset();
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== 'localhost' && updatedMetaverse && subdomain !== updatedMetaverse.subdomain) {
      window.location.href = getMetaverseEditURL(updatedMetaverse);
    }
  }, [isSuccess, reset]);

  const handleNameOnBlur = () => {
    if (!name || name === metaverse?.name) {
      setName(metaverse?.name ?? '');
      return;
    }
    updateMetaverse({ name });
  };

  const handleNavBarColorChange = (color: string) => {
    updateMetaverse({ ui: { colors: { navBar: color } } });
  };

  const handleNavBarAccentColorChange = (color: string) => {
    updateMetaverse({ ui: { colors: { navBarAccent: color } } });
  };

  const handlePixelConnectIconColorChange = (color: string) => {
    updateMetaverse({ ui: { colors: { pixelConnectIcon: color } } });
  };

  const hasAdvancedUIControls = ability.can(Action.Manage, Subject.AdvancedUIControls);

  return (
    <>
      <MainContent>
        <InputFieldsGroup title="Basics">
          <TextInputField
            label="METAVERSE NAME"
            required
            maxLength={80}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleNameOnBlur}
          />
          <SubdomainInputField />
          <Typography className={styles.contact} variant="body2">Contact Pixel Canvas if you wish to connect your own domain</Typography>
        </InputFieldsGroup>
        {
          hasAdvancedUIControls && (
            <InputFieldsGroup title="UI Theme">
              <ColorInputField
                label="NAV BAR COLOR"
                color={metaverse?.ui.colors.navBar}
                onChange={handleNavBarColorChange}
              />
              <ColorInputField
                label="NAV BAR ACCENT COLOR"
                color={metaverse?.ui.colors.navBarAccent}
                onChange={handleNavBarAccentColorChange}
              />
              <ColorInputField
                label="PIXEL CONNECT ICON COLOR"
                color={metaverse?.ui.colors.pixelConnectIcon}
                onChange={handlePixelConnectIconColorChange}
              />
            </InputFieldsGroup>
          )
        }
      </MainContent>
      <SaveFooter disabled />
    </>
  );
}

function SubdomainInputField() {
  const { metaverse } = useCurrentMetaverse();
  const [subdomain, setSubdomain] = useState<string>(metaverse?.subdomain ?? '');
  const [value, setValue] = useState(metaverse?.subdomain ?? '');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!metaverse?.subdomain) return;
    setSubdomain(metaverse.subdomain);
  }, [metaverse?.subdomain]);

  const {
    mutate: updateMetaverse,
    isSuccess,
    data: updatedMetaverse,
    reset,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess || !updatedMetaverse) return;
    reset();
    window.location.href = getMetaverseEditURL(updatedMetaverse);
  }, [isSuccess, reset]);

  const enabled = subdomain !== metaverse?.subdomain;

  const {
    isSuccess: isSubdomainAvailable,
    error: subdomainAvailbilityError,
  } = useSubdomainAvailbility(subdomain, enabled);

  useEffect(() => {
    if (!isSubdomainAvailable) return;
    updateMetaverse({ subdomain });
    setError('');
  }, [subdomain, isSubdomainAvailable]);

  useEffect(() => {
    if (!subdomainAvailbilityError) return;
    if (subdomain === metaverse?.subdomain) return;
    setError(
      subdomainAvailbilityError?.response?.status === 404
        ? 'URL is already taken'
        : 'Error checking URL availability',
    );
  }, [subdomain, subdomainAvailbilityError]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(sanitizeSubdomain(e.target.value));
    if (error) setError('');
  };

  const handleCancel = () => {
    setValue(metaverse?.subdomain ?? '');
  };

  const handleConfirm = () => {
    setSubdomain(value);
  };

  return (
    <ButtonGatedTextInptField
      label="METAVERSE ID"
      maxLength={63}
      value={value}
      onChange={handleChange}
      error={error}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmDisabled={!metaverse?.subdomain || value === metaverse.subdomain}
    />
  );
}

function sanitizeSubdomain(subdomain: string) {
  return subdomain.toLowerCase().replace(/[^a-z0-9-]/gi, '');
}
