import { useEffect } from 'react';
import isURL from 'validator/lib/isURL';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

interface OpenExternalLinkMessage {
  type: 'hyperlink';
  url: string;
}

export default function useOpenExternalLink() {
  const { descriptor, emitUIInteraction } = usePixelStreamingContext();
  const listener = (message: OpenExternalLinkMessage) => {
    if (!isURL(message.url)) {
      console.error(`Invalid URL: ${message.url}`);
      return;
    }
    window.open(message.url, '_blank')?.focus();
  };

  useEffect(() => {
    if (!emitUIInteraction) return () => {};
    descriptor.on('hyperlink', listener);
    return () => {
      descriptor.off('hyperlink', listener);
    };
  }, [descriptor, emitUIInteraction]);
}
