import { useEffect } from 'react';
import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export default function useDebugEmitUIInteract() {
  const { emitUIInteraction } = usePixelStreamingContext();

  // debug only for sending messages to the streamer
  useEffect(() => {
    (window as any).emitUIInteraction = emitUIInteraction;
  }, [emitUIInteraction]);
}
