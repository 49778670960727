/* eslint-disable jsx-a11y/media-has-caption */
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import {
  AudioTrack, AudioTrackPublication, LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteTrack,
  RemoteTrackPublication, RemoteVideoTrack, VideoTrack,
  VideoTrackPublication,
} from 'twilio-video';
import useAsyncEffect from 'use-async-effect';

import { ReactComponent as MenuIcon } from '../../../../../assets/svg/ellipsis-v.svg';
import ContextMenu, { ContextMenuItem } from '../../../../../components/ContextMenu/ContextMenu';
import { PlatformRole } from '../../../../../interfaces';
import IMetaverseVisitor from '../../../../../interfaces/IMetaverseVisitor';
import ModerationAction from '../../../../../interfaces/ModerationAction';
import { TrackType } from '../../../../../interfaces/TrackType';
import { getUserDisplayName } from '../../../../../utils/DisplayNameUtil';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';

import styles from './ParticipantVideoTrack.module.scss';

interface ParticipantVideoProps {
  videoWidth?: number;
  participant: Participant;
  onContextActionClicked?: Function | undefined;
  roomRoles: PlatformRole[];
  metaverseRoles: PlatformRole[];
  getUser: Function;
}

const ParticipantVideoTrack = ({
  videoWidth,
  participant,
  onContextActionClicked,
  roomRoles,
  metaverseRoles,
  getUser,
}: ParticipantVideoProps) => {
  const [videoTrack, setVideoTrack] = useState<(LocalVideoTrack | RemoteVideoTrack | null)>(null);
  const [audioTrack, setAudioTrack] = useState<(LocalAudioTrack | RemoteAudioTrack | null)>(null);
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [metaverseVisitor, setMetaverseVisitor] = useState<IMetaverseVisitor | null>(null);
  const [contextActions, setContextActions] = useState<ContextMenuItem[]>([]);
  const [audioVolume, setAudioVolume] = useState<number>(0);
  const containerRef = useRef<any>();
  const { ability } = useAccessMetaverse();

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContextAction = (action: ModerationAction) => {
    if (onContextActionClicked) {
      onContextActionClicked(action, participant.identity);
    }
    handleDropdownClose();
  };

  const trackSubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Video &&
      !track.name?.includes('presenter')) {
      console.log('videoTrackSubscribed');
      setVideoTrack(track);
    }
    if (track.kind === TrackType.Audio) {
      console.log('audioTrackSubscribed');
      setAudioTrack(track);
    }
  };
  const trackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Video &&
      !track.name?.includes('presenter')) {
      console.log('videoTrackUnsubscribed');
      setVideoTrack(null);
    }
    if (track.kind === TrackType.Audio) {
      console.log('audioTrackUnsubscribed');
      setAudioTrack(null);
      setAudioVolume(0);
    }
  };
  const trackPublished = (publication: RemoteTrackPublication) => {
    if (publication?.track?.kind === TrackType.Video &&
      !publication?.trackName?.includes('presenter')) {
      console.log('videoTrackPublished');
      setVideoTrack(publication.track);
    }
    if (publication?.track?.kind === TrackType.Audio) {
      console.log('audioTrackPublished');
      setAudioTrack(publication.track);
    }
  };
  const trackUnpublished = (publication: RemoteTrackPublication) => {
    if (publication?.track?.kind === TrackType.Video &&
      !publication?.trackName?.includes('presenter')) {
      console.log('videoTrackUnpublished');
      setVideoTrack(null);
    }
    if (publication?.track?.kind === TrackType.Audio) {
      console.log('audioTrackUnpublished');
      setAudioTrack(null);
    }
  };

  useEffect(() => {
    let actions: any[] = []; // Add  default actions here
    const adminContextActions: ContextMenuItem[] = [
      /* {
        label: 'Hide Video',
        onClick: () => {
          handleContextAction(ModerationAction.HideVideo);
        },
      }, */
      {
        label: 'Mute',
        onClick: () => {
          handleContextAction(ModerationAction.MuteAudio);
        },
      },
      {
        label: 'Remove',
        onClick: () => {
          handleContextAction(ModerationAction.RemoveUser);
        },
      },
      /* {
        label: 'Ban',
        onClick: () => {
          handleContextAction(ModerationAction.BanUser);
        },
      }, */
    ];
    if (ability.can(Action.Manage, Subject.ModerationAction)) {
      actions = actions.concat(adminContextActions);
    }
    setContextActions(actions);
  }, []);

  useEffect(() => {
    participant.on('trackPublished', trackPublished);
    participant.on('trackUnpublished', trackUnpublished);
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    return () => {
      participant.off('trackSubscribed', trackSubscribed);
      participant.off('trackUnsubscribed', trackUnsubscribed);
      participant.off('trackPublished', trackPublished);
      participant.off('trackUnpublished', trackUnpublished);
    };
  }, []);

  useAsyncEffect(async () => {
    try {
      const user = await getUser(participant.identity);
      setMetaverseVisitor(user);
    } catch (e) {
      console.error('Error getting user', e);
    }
  }, []);

  useEffect(() => {
    videoTrack?.attach(containerRef.current);
    console.log('Attaching track', videoTrack);
  }, [videoTrack]);

  useEffect(() => {
    const existingPublications = Array.from(participant.videoTracks.values());
    const screenFilteredPublications = existingPublications
      .filter((publication: VideoTrackPublication) => !publication.trackName.includes('presenter'));
    const availableVideoTrack = screenFilteredPublications
      .map((publication: VideoTrackPublication) => publication.track)
      .find((track: VideoTrack | null) => track !== null) || null;
    setVideoTrack(availableVideoTrack);
    console.log('setting existing video publications', existingPublications);
  }, [participant.videoTracks]);

  useEffect(() => {
    const existingPublications = Array.from(participant.audioTracks.values());
    const screenFilteredPublications = existingPublications
      .filter((publication: AudioTrackPublication) => !publication.trackName.includes('presenter-screen-audio'));
    const availableAudioTrack = screenFilteredPublications
      .map((publication: AudioTrackPublication) => publication.track)
      .find((track: AudioTrack | null) => track !== null) || null;
    setAudioTrack(availableAudioTrack);
    console.log('setting existing audio publications', existingPublications);
  }, [participant.audioTracks]);

  return (
    <div className={clsx(styles['participant-video'], { [styles['is-talking']]: audioVolume > 1 })} style={{ width: videoWidth }}>
      <video className={styles['video-track']} ref={containerRef} />
      {metaverseVisitor?.participant && <div className={styles['participant-name']}>{getUserDisplayName(metaverseVisitor.participant)}</div>}
      {
        (contextActions.length > 0 && onContextActionClicked) &&
        <div>
          <MenuIcon className={styles['menu-icon']} onClick={(e: any) => handleDropdownOpen(e)} />
          <ContextMenu items={contextActions} visible={Boolean(anchorEl)} handleClose={handleDropdownClose} anchorEl={anchorEl} />
        </div>
      }
      <div className={styles['audio-level-indicator']}>
        <AudioLevelIndicator id={participant.identity} onVolumeChange={setAudioVolume} audioTrack={audioTrack} />
      </div>
      {
        roomRoles?.includes(PlatformRole.Moderator) &&
        <div className={styles['participant-label']}>HOST</div>
      }
    </div>
  );
};

export default ParticipantVideoTrack;
