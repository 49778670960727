import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAcceptInvite } from 'src/queries/invites';
import Button from 'src/features/Visitor/components/Button/Button';
import VisitorModal from 'src/features/Visitor/components/VisitorModal/VisitorModal';
import VisitorModalTitle from 'src/features/Visitor/components/VisitorModalTitle/VisitorModalTitle';
import VisitorModalContent from 'src/features/Visitor/components/VisitorModalContent/VisitorModalContent';
import VisitorModalActions from 'src/features/Visitor/components/VisitorModalActions/VisitorModalActions';

import heroImage from '../../assets/images/hero-image.png';
import landingBg from '../../assets/images/landing-bg.png';
import usePxAuthContext from '../../authentication/usePxAuthContext';
import PxFooter from '../../components/PxFooter/PxFooter';
import AuthModal from '../../features/Visitor/components/Authentication/EmailAndGooglePreferRegisterModal';
import pixelLogo from '../../assets/images/pixel-canvas-logo.png';

import styles from './InviteLandingPage.module.scss';

const clientSuggestions = ['INNOVATION', 'IMMERSION', 'AGENCIES', 'PRODUCT DEMOS', 'SKOS'];

export default function InviteLandingPage() {
  const { user } = usePxAuthContext();
  const navigate = useNavigate();
  const { clientSuggestionText } = useTypewriterEffect(clientSuggestions);
  const [query] = useState(new URLSearchParams(window.location.search));
  const {
    mutate: acceptInvite,
    isSuccess: inviteAccepted,
    error: acceptInviteError,
    reset: resetInviteError,
  } = useAcceptInvite();
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const token = query.get('invite');

  useEffect(() => {
    if (!user || !token) return;
    acceptInvite(token);
    if (user.email_verified === false) {
      navigate('/verification');
    }
  }, [user]);

  useEffect(() => {
    if (inviteAccepted) navigate('/');
  }, [inviteAccepted]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.topGradient} />
        <div className={styles.centerGradient} />
        <img className={styles.brandLogoText} src={pixelLogo} alt="logo" />
        <div className={styles.headerUser}>
          <a className={styles.helpButton} href="mailto:support@pixelcanvas.com" target="_blank" rel="noopener noreferrer">
            Contact Us
          </a>
        </div>
      </div>
      <div className={styles['landing-bg']} style={{ backgroundImage: `url(${landingBg})` }} />
      <div className={styles['landing-page']}>
        <div className={styles['landing-title']}>
          Welcome to Pixel Platform Closed BETA
        </div>
        <div className={styles['landing-title']}>
          A Metaverse Platform Made For
        </div>
        <div className={clsx(styles['client-suggestion'], styles.typewriter)}>
          {clientSuggestionText}
        </div>
        <p className={styles.instructions}>
          Click on &quot;Get Started&quot; to create a new account
        </p>
        <Button
          className={clsx(styles['platform-button'], styles['get-started'])}
          color="primary"
          onClick={() => setAuthModalOpen(true)}
        >
          Get Started
        </Button>
        <img className={styles['hero-image']} src={heroImage} alt="hero" />
      </div>
      <PxFooter />
      <AuthModal
        open={authModalOpen}
        onClose={() => setAuthModalOpen(false)}
        emailVerificationReturnTo="/"
      />
      <ErrorModal error={acceptInviteError} reset={resetInviteError} />
    </>
  );
}

interface ErrorModalProps {
  error: any;
  reset: () => void;
}

function ErrorModal({ error, reset }: ErrorModalProps) {
  const navigate = useNavigate();
  const inviteAlreadyAccepted = error?.response?.data?.title === 'Invite already accepted';
  const title = inviteAlreadyAccepted ? 'Invite already accepted' : 'Unable to process invite!';
  const description = inviteAlreadyAccepted ? 'Click OK to proceed to the dashboard.' : 'Please contact support@pixelcanvas.com for assistance.';
  const handleOk = () => {
    if (inviteAlreadyAccepted) {
      navigate('/');
    } else {
      reset();
    }
  };
  return (
    <VisitorModal className={styles.modal} open={!!error} onClose={reset}>
      <VisitorModalTitle>{title}</VisitorModalTitle>
      <VisitorModalContent>
        {description}
      </VisitorModalContent>
      <VisitorModalActions>
        <Button color="primary" onClick={handleOk}>OK</Button>
      </VisitorModalActions>
    </VisitorModal>
  );
}

function useTypewriterEffect(values: string[]) {
  const [clientSuggestionText, setClientSuggestionText] = useState('');
  const clientTextRef = useRef(clientSuggestionText);

  useEffect(() => {
    clientTextRef.current = clientSuggestionText;
  }, [clientSuggestionText]);

  useEffect(() => {
    updateClientText();
  }, []);

  let curTextIndex = 0;
  let curSuggestionIndex = 0;

  function updateClientText() {
    const curSuggestionText = values[curSuggestionIndex];
    if (curTextIndex < curSuggestionText.length) {
      // Add next character
      setClientSuggestionText(`${clientTextRef.current}${curSuggestionText.charAt(curTextIndex)}`);
      curTextIndex += 1;
      if (curTextIndex !== curSuggestionText.length) {
        setTimeout(updateClientText, 75);
      } else {
        setTimeout(updateClientText, 1500);
      }
    } else {
      setClientSuggestionText('');
      curTextIndex = 0;
      if (curSuggestionIndex !== values.length - 1) {
        curSuggestionIndex += 1;
      } else {
        curSuggestionIndex = 0;
      }
      setTimeout(updateClientText, 50);
    }
  }

  return { clientSuggestionText };
}
