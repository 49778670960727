import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../store';

export interface MetaverseErrorState {
  error: any | null;
}

const initialState: MetaverseErrorState = {
  error: null,
};

export const metaversePageSlice = createSlice({
  name: 'metaverseError',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<any | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = metaversePageSlice.actions;

export const selectError = (state: RootState) => state.metaverseError.error;

export default metaversePageSlice.reducer;
