import { useEffect, useState } from 'react';
import RegisterForm from './RegisterForm';
import SignInForm from './SignInForm';
import ResetForm from './ResetForm';
import { useAuthModalContext } from '../AuthModalContextProvider';
import VisitorModal from '../../VisitorModal/VisitorModal';

import styles from './EmailModal.module.scss';

const FORM_LOGIN = 'login';
const FORM_REGISTER = 'register';
const FORM_RESET = 'reset';

export type FormType = typeof FORM_LOGIN | typeof FORM_REGISTER | typeof FORM_RESET;

interface Props {
  hasLoginForm?: boolean;
  hasRegisterForm?: boolean;
  hasResetForm?: boolean;
  defaultForm?: FormType;
  returnTo?: string;
}

export default function EmailModal({
  hasLoginForm = true,
  hasRegisterForm = true,
  hasResetForm = true,
  defaultForm = FORM_LOGIN,
  returnTo,
}: Props) {
  const {
    openEmailModal,
    setOpenEmailModal,
    setHasEmailLoginForm,
    setHasEmailRegisterForm,
    setHasEmailResetForm,
  } = useAuthModalContext();
  const [currentForm, setCurrentForm] = useState(defaultForm);

  useEffect(() => setHasEmailLoginForm(hasLoginForm), [hasLoginForm]);
  useEffect(() => setHasEmailRegisterForm(hasRegisterForm), [hasRegisterForm]);
  useEffect(() => setHasEmailResetForm(hasResetForm), [hasResetForm]);

  const handleClose = () => setOpenEmailModal(false);

  return (
    <VisitorModal
      className={styles.modal}
      open={openEmailModal}
      onClose={handleClose}
    >
      <CurrentForm
        onClose={handleClose}
        onSwitchFormClick={setCurrentForm}
        currentForm={currentForm}
        returnTo={returnTo}
      />
    </VisitorModal>
  );
}

interface CurrentFormProps {
  onClose: () => void;
  onSwitchFormClick: (form: FormType) => void;
  currentForm: FormType;
  returnTo?: string;
}

function CurrentForm({ onClose, onSwitchFormClick, currentForm, returnTo }: CurrentFormProps) {
  switch (currentForm) {
    case FORM_LOGIN:
      return (
        <SignInForm
          onSwitchFormClick={onSwitchFormClick}
          onClose={onClose}
        />
      );
    case FORM_REGISTER:
      return (
        <RegisterForm
          onSwitchFormClick={onSwitchFormClick}
          returnTo={returnTo}
        />
      );
    case FORM_RESET:
      return (
        <ResetForm
          onSwitchFormClick={onSwitchFormClick}
          onClose={onClose}
        />
      );
    default:
      console.error('Unknown form type', currentForm);
      return null;
  }
}
