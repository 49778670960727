import { DateTime } from 'luxon';

export function formDateTime(date: string, time: string): string | null {
  if (!date || date === 'Invalid DateTime') {
    return null;
  }
  if (!time || time === 'Invalid DateTime') {
    return null;
  }

  return `${date}T${time}`;
}

export function dateTimeToUTC(isoString: string, zoneString: string): string {
  if (isoString) {
    const dateTime = DateTime.fromISO(isoString, { zone: zoneString });
    const utcTime = dateTime.toUTC();
    return utcTime.toISO();
  }
  return '';
}

export function isoStringFromUTC(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toISO();
}

export function isoDateFromUTC(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toFormat("y'-'MM'-'dd");
}

export function shortDateFromUTC(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toLocaleString(DateTime.DATE_SHORT);
}

export function shortDateTimeFromUTC(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toLocaleString(DateTime.DATETIME_SHORT);
}

export function shortDateWithDayFromUTC(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toFormat("ccc MM'/'dd'/'yyyy");
}

export function isoDetailDateFromUTC(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  const detailFormat = Object.assign(DateTime.DATE_MED_WITH_WEEKDAY, { month: 'short' });
  return rezoned.toLocaleString(detailFormat);
}

export function isoTimeFromUTC(isoString: string, zoneString: string, militaryTime = false, meridiem = false): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  if (militaryTime) return rezoned.toFormat(`HH':'mm${meridiem ? ' a' : ''}`);

  return rezoned.toFormat(`hh':'mm${meridiem ? ' a' : ''}`);
}

export function timeFromDate(isoString: string, zoneString: string): string {
  if (isoString) {
    const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
    const rezoned = dateTime.setZone(zoneString);
    return rezoned.toFormat("h':'mma ZZZZ");
  }
  return '';
}

export function dateTimeToLocaleString(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
}

export function dateTimeToWeekdayAndDate(isoString: string, zoneString = 'UTC'): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toFormat('EEEE, MMM d');
}
export function dateTimeToWeekdayAndFullDate(isoString: string, zoneString: string): string {
  const dateTime = DateTime.fromISO(isoString, { zone: 'UTC' });
  const rezoned = dateTime.setZone(zoneString);
  return rezoned.toFormat('EEEE MMMM d, y');
}

export function validateTimeFrame(startTime: string, endTime: string): string {
  if (startTime && endTime && endTime <= startTime) {
    return 'End time must be after start time';
  }
  return '';
}

export function validateMetaverseTimes(timezone: string | undefined, startTime: string | undefined, endTime: string | undefined): string {
  if (timezone && startTime) {
    const curDate = DateTime.now();
    if (DateTime.fromISO(startTime, { zone: timezone }) < curDate) {
      return 'Start date must be in the future';
    }
  }
  if (startTime && endTime && endTime <= startTime) {
    return 'Metaverse end time must be after start time';
  }
  return '';
}

export function validateRegistrationTimes(
  startTime: string | undefined,
  endTime: string | undefined,
  regStartTime: string | undefined,
  regEndTime: string | undefined,
): string {
  if (!startTime || !endTime || !regStartTime || !regEndTime) return '';
  if (regStartTime > startTime) {
    return 'Registration start time must be before metaverse start time';
  }
  if (regEndTime <= regStartTime) {
    return 'Registration end time must be after registration start time';
  }
  if (regEndTime > endTime) {
    return 'Registration end time cannot be after metaverse end time';
  }
  return '';
}

export function defaultEndTime(isoStringStart: string, timezone?: string): string {
  const startTime = DateTime.fromISO(isoStringStart, { zone: timezone ?? 'UTC' });
  const endDateTime = startTime.plus({ hours: 2 });
  return isoStringFromUTC(endDateTime.toISO(), timezone ?? 'UTC');
}
