import { Ability } from '@casl/ability';
import { Button, ConfirmationModal, FilterBy, Search, Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';

import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import { OrganizationMemberStatus, OrganizationTeamMemberResponse } from 'src/interfaces/IOrganizationMember';
import { useCancelOrganizationInvite, useCreateInvite, useDeleteOrganizationMember, useOrganizationAbility } from 'src/queries/organization';
import { useOrganizationContext } from '../../context/OrganizationContextProvider';
import OrganizationInviteModal from '../OrganizationInviteModal/OrganizationInviteModal';
import OrganizationTeamTable from './OrganizationTeamTable';

import OrganizationEditRoleModal from '../OrganizationEditRoleModal/OrganizationEditRoleModal';
import styles from './OrganizationTeamPage.module.scss';

const fuseOptions = {
  keys: ['displayName', 'email', 'role', 'status'],
  threshold: 0.3,
};

enum FilterByOption {
  ActiveMembers = 'Active Members',
  PendingMembers = 'Pending Invites',
  CanceledInvites = 'Canceled Invites',
}

const filterByOptions = [...Object.values(FilterByOption)];

export function filterByPredicateFn(
  filterBy: FilterByOption[], member: OrganizationTeamMemberResponse,
) {
  if (filterBy.length === 0) return true;
  return filterBy.some((by) => {
    switch (by) {
      case FilterByOption.ActiveMembers:
        return member.status === OrganizationMemberStatus.Active;
      case FilterByOption.PendingMembers:
        return member.status === OrganizationMemberStatus.Pending;
      case FilterByOption.CanceledInvites:
        return member.status === OrganizationMemberStatus.Canceled ||
          member.status === OrganizationMemberStatus.Expired;
      default:
        return true;
    }
  });
}

const inactiveStatuses = [
  OrganizationMemberStatus.Canceled,
  OrganizationMemberStatus.Expired,
];

export default function OrganizationTeamPage() {
  const { currentOrganization, organizationTeam, userMembership, currentOrganizationId } = useOrganizationContext();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [editRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState<OrganizationTeamMemberResponse>();
  const { mutate: deleteOrganizationMember, isSuccess: deleteOrganizationMemberSuccess } = useDeleteOrganizationMember(currentOrganization?._id as string);
  const { mutate: createInvite, isSuccess: createInviteSuccess } = useCreateInvite(currentOrganization?._id as string);
  const { mutate: cancelOrganizationInvite, isSuccess: cancelOrganizationInviteSuccess } = useCancelOrganizationInvite();
  const [pattern, setPattern] = useState('');
  const [filterBy, setFilterBy] = useState<FilterByOption[]>([FilterByOption.ActiveMembers]);
  const { addToast } = useToastMessageContext();
  const { data: ability = new Ability() } = useOrganizationAbility(currentOrganizationId);

  const fuseRef = useRef(new Fuse<OrganizationTeamMemberResponse>([], fuseOptions));

  const fuse = fuseRef.current;

  useEffect(() => {
    if (deleteOrganizationMemberSuccess) {
      addToast('Team member removed');
    }
  }, [deleteOrganizationMemberSuccess]);

  useEffect(() => {
    if (createInviteSuccess) {
      addToast('Team member invited');
    }
  }, [createInviteSuccess]);

  useEffect(() => {
    if (cancelOrganizationInviteSuccess) {
      addToast('Invite canceled');
    }
  }, [cancelOrganizationInviteSuccess]);

  useEffect(() => {
    if (!organizationTeam) return;
    fuse.setCollection(organizationTeam);
  }, [organizationTeam]);

  const filteredSortedMembers = !organizationTeam ? [] :
    (pattern.trim()
      ? fuse.search(pattern.trim()).map((result) => result.item)
      : organizationTeam)
      .filter((member) => filterByPredicateFn(filterBy, member));

  const handleEditMember = (member: OrganizationTeamMemberResponse) => {
    setSelectedMember(member);
    setEditRoleModalOpen(true);
  };

  const handleInviteMember = () => {
    setSelectedMember(undefined);
    setInviteModalOpen(true);
  };

  const handleDeleteMember = (member: OrganizationTeamMemberResponse) => {
    deleteOrganizationMember(member.memberId!);
    setSelectedMember(undefined);
  };

  const handleCancelInvite = (member: OrganizationTeamMemberResponse) => {
    cancelOrganizationInvite(member.inviteId!);
  };

  const handleResendInvite = (member: OrganizationTeamMemberResponse) => {
    createInvite({
      email: member.email,
      role: member.role,
    });
  };

  const handleConfirmDelete = (member: OrganizationTeamMemberResponse) => {
    setSelectedMember(member);
    setShowDeleteModal(true);
  };

  const inactiveInvites = organizationTeam
    .filter((member) => inactiveStatuses.includes(member.status))
    .length;
  const activeMemberCount = organizationTeam.length - inactiveInvites;
  const isFull = currentOrganization?.membersLimit
    ? organizationTeam.length - inactiveInvites >= currentOrganization.membersLimit
    : false;

  return (
    <>
      <Typography variant="h2" emphasis="high" className={styles.heading}>Team
        {
          currentOrganization?.membersLimit &&
          <MemberCounter
            activeMemberCount={activeMemberCount}
            membersLimit={currentOrganization.membersLimit}
          />
        }
      </Typography>
      <div className={styles.controls}>
        <Search onChange={setPattern} />
        <FilterBy
          options={filterByOptions}
          onChange={setFilterBy}
          defaultOption={FilterByOption.ActiveMembers}
        />
        {
          ability.can(Action.Create, Subject.OrganizationInvite) &&
          <Button
            color="secondary"
            onClick={handleInviteMember}
            disabled={isFull}
          >
            Invite
          </Button>
        }
      </div>
      { userMembership &&
      <OrganizationTeamTable
        data={filteredSortedMembers}
        onEditRole={handleEditMember}
        onDelete={handleConfirmDelete}
        onCancelInvite={handleCancelInvite}
        onResendInvite={handleResendInvite}
      />}
      <OrganizationInviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      {selectedMember &&
      <OrganizationEditRoleModal
        open={editRoleModalOpen}
        onClose={() => {
          setSelectedMember(undefined);
          setEditRoleModalOpen(false);
        }}
        member={selectedMember}
      />}
      {selectedMember &&
      <RemoveMemberModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        member={selectedMember}
        onConfirm={handleDeleteMember}
      />}
    </>
  );
}

interface MemberCounterProps {
  activeMemberCount: number;
  membersLimit: number;
}

function MemberCounter({ activeMemberCount, membersLimit }: MemberCounterProps) {
  const isFull = activeMemberCount === membersLimit;
  return (
    <div className={styles.memberCounter}>
      <Typography variant="h2" emphasis="high" className={clsx({ [styles.error]: isFull })}>
        {activeMemberCount}
      </Typography>
      <Typography variant="h2" emphasis="high" className={clsx({ [styles.error]: isFull })}>
        /
      </Typography>
      <Typography variant="h2" emphasis="high" className={clsx({ [styles.error]: isFull })}>
        {membersLimit}
      </Typography>
    </div>
  );
}

interface RemoveMemberModalProps {
  open: boolean;
  onClose: () => void;
  member: OrganizationTeamMemberResponse;
  onConfirm: (member: OrganizationTeamMemberResponse) => void;
}

function RemoveMemberModal({ open, onClose, member, onConfirm }: RemoveMemberModalProps) {
  const { displayName } = member;
  return (
    <ConfirmationModal
      title="REMOVE TEAM MEMBER"
      description={`Are you sure you want to remove ${displayName}? \n\nPlease note that this action cannot be undone.`}
      open={open}
      onClose={onClose}
      confirm="Delete"
      onConfirm={() => {
        onConfirm(member);
        onClose();
      }}
    />
  );
}
