import {
  Button, RadioButton, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, TextInputField, TooltipButton, Typography,
} from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import isEmail from 'validator/es/lib/isEmail';
import OrganizationRole from '../../../../../../interfaces/OrganizationRole';
import { useCreateInvite } from '../../../../../../queries/organization';
import { errorAlert } from '../../../../../../utils/SimpleErrorUtil';
import { useOrganizationContext } from '../../context/OrganizationContextProvider';

import styles from './OrganizationInviteModal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function OrganizationInviteModal({
  open,
  onClose,
}: Props) {
  const [email, setEmail] = useState('');
  const { currentOrganization } = useOrganizationContext();
  const [showEmailRequired, setShowEmailRequired] = useState(false);
  const [role, setRole] = useState<OrganizationRole>(OrganizationRole.Administrator);
  const { mutate, error, reset, isSuccess } = useCreateInvite(currentOrganization?._id as string);
  const { addToast } = useToastMessageContext();

  useEffect(() => {
    if (error) {
      errorAlert(error);
      reset();
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      addToast('Team member invited');
      onClose();
    }
  }, [isSuccess]);

  const onConfirmClick = () => {
    if (!email) {
      setShowEmailRequired(true);
      return;
    }
    if (!isEmail(email)) {
      setShowEmailRequired(true);
      return;
    }
    if (!role) {
      errorAlert('Please select a role.');
      return;
    }
    mutate({ email, role });
  };

  return (
    <TaskModal
      className={styles.modal}
      open={open}
      onClose={onClose}
    >
      <TaskModalTitle onClose={() => onClose()}>INVITE A TEAM MEMBER</TaskModalTitle>
      <TaskModalContent className={styles.content}>
        <TextInputField
          label="EMAIL ADDRESS*"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={showEmailRequired ? 'Valid email is required' : undefined}
        />
        <Typography variant="h6" className={styles.header}>
          ROLE*
        </Typography>
        <div className={styles.roleRadioGroup}>
          <div className={styles.roleRow}>
            <RadioButton
              key={OrganizationRole.Administrator}
              label={OrganizationRole.Administrator}
              name="roles"
              onChange={() => setRole(OrganizationRole.Administrator)}
              defaultChecked
            />
            <Typography variant="body1" emphasis="high">Administrator</Typography>
            <TooltipButton>
              Has full access but can’t manage billing
            </TooltipButton>
          </div>
          <div className={styles.roleRow}>
            <RadioButton
              key={OrganizationRole.Moderator}
              label={OrganizationRole.Moderator}
              name="roles"
              onChange={() => setRole(OrganizationRole.Moderator)}
            />
            <Typography variant="body1" emphasis="high">Moderator</Typography>
            <TooltipButton>
              Has viewing access and can moderate chats and metaverse visitors
            </TooltipButton>
          </div>
          <div className={styles.roleRow}>
            <RadioButton
              key={OrganizationRole.Viewer}
              label={OrganizationRole.Viewer}
              name="roles"
              onChange={() => setRole(OrganizationRole.Viewer)}
            />
            <Typography variant="body1" emphasis="high">Viewer</Typography>
            <TooltipButton>
              Can view the dashboard and download documents
            </TooltipButton>

          </div>
        </div>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button onClick={() => onConfirmClick()}>Send</Button>
      </TaskModalActions>
    </TaskModal>
  );
}
