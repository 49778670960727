import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import { SocketEvent } from '../../../../../interfaces';
import { ReactComponent as VideoOffIcon } from '../../../../../assets/svg/pixel-connect/cam-slash-sq.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/svg/pixel-connect/cam-sq.svg';
import { ReactComponent as MicOffIcon } from '../../../../../assets/svg/pixel-connect/mic-slash.svg';
import { ReactComponent as MicIcon } from '../../../../../assets/svg/pixel-connect/mic.svg';
import { ReactComponent as LeaveRoomIcon } from '../../../../../assets/svg/pixel-connect/phone-slash.svg';
import { ReactComponent as PresenterModeIcon } from '../../../../../assets/svg/podcast.svg';
import useSocketContext from '../../../../../contexts/useRoomsSocketContext';
import PresenterSettingsModal from '../PresenterSettingsModal/PresenterSettingsModal';
import useVideoContext from '../useVideoContext';

import styles from './Controls.module.scss';

interface ControlsProps {
  videoRoomId: string
  onExitVideoRoom: Function;
  isRoomPresenter: boolean;
}

// @ts-ignore
const Controls = ({ videoRoomId, isRoomPresenter, onExitVideoRoom }: ControlsProps) => {
  const {
    videoRoom,
    localAudioTrack,
    localVideoTrack,
    presenter,
    createLocalVideoTrack,
    removeLocalVideoTrack,
    createLocalScreenTracks,
    removeLocalScreenTracks,
  } = useVideoContext();
  const {
    socket,
  } = useSocketContext();

  const [audioTrackEnabled, setAudioTrackEnabled] = useState(false);
  const [videoTrackEnabled, setVideoTrackEnabled] = useState(false);
  const [presenterModalVisible, setPresenterModalVisible] = useState(false);

  const enableAudioTrack = () => {
    console.log('Controls: enableAudioTrack');
    setAudioTrackEnabled(true);
  };
  const disableAudioTrack = () => {
    console.log('Controls: disableAudioTrack');
    setAudioTrackEnabled(false);
  };

  const enableVideoTrack = () => {
    setVideoTrackEnabled(true);
  };
  const disableVideoTrack = () => {
    setVideoTrackEnabled(false);
  };

  useEffect(() => {
    console.log('Controls: localAudioTrack ', localAudioTrack);
    if (localAudioTrack) {
      console.log('Controls: setting audioTrack listeners');
      localAudioTrack.on('enabled', enableAudioTrack);
      localAudioTrack.on('disabled', disableAudioTrack);
      setAudioTrackEnabled(localAudioTrack.isEnabled);
      console.log(`localaudiotrack is enabled: ${localAudioTrack.isEnabled}`);
    }

    return () => {
      console.log('Controls: removing audioTrack listeners');
      localAudioTrack?.off('enabled', enableAudioTrack);
      localAudioTrack?.off('disabled', disableAudioTrack);
    };
  }, [localAudioTrack]);

  useEffect(() => {
    if (localVideoTrack) {
      localVideoTrack.on('started', enableVideoTrack);
      localVideoTrack.on('stopped', disableVideoTrack);
      setVideoTrackEnabled(localVideoTrack.isStarted);
      console.log(`localVideoTrack is started: ${localVideoTrack.isStarted}`);
    }

    return () => {
      localVideoTrack?.off('started', enableVideoTrack);
      localVideoTrack?.off('stopped', disableVideoTrack);
    };
  }, [localVideoTrack]);

  function micButtonClicked() {
    if (!localAudioTrack) return;

    if (!audioTrackEnabled) {
      console.log('audio track is enabling');
      localAudioTrack.enable();
    } else {
      console.log('audio track is disabling');
      localAudioTrack.disable();
    }
  }

  function videoButtonClicked() {
    if (localVideoTrack) {
      console.log('removing local video track');
      removeLocalVideoTrack();
    } else {
      console.log('creating local video track');
      const isPresenting = (presenter && presenter.identity === videoRoom?.localParticipant.identity);
      createLocalVideoTrack(isPresenting);
    }
  }

  const presenterModeButtonClicked = useCallback(async () => {
    if (presenter && (presenter.identity === videoRoom?.localParticipant.identity)) {
      socket?.emit(SocketEvent.EndPresentation, videoRoomId);
    } else {
      setPresenterModalVisible(true);
    }
  }, [presenter]);

  function leaveRoomClicked() {
    videoRoom?.disconnect();
    if (onExitVideoRoom) onExitVideoRoom(videoRoomId);
  }

  return (
    <div className={styles.controls}>
      {audioTrackEnabled ?
        <MicIcon className={styles.microphone} onClick={() => micButtonClicked()} /> :
        <MicOffIcon className={styles.microphone} onClick={() => micButtonClicked()} />}
      {videoTrackEnabled ?
        <VideoIcon className={styles.video} onClick={() => videoButtonClicked()} /> :
        <VideoOffIcon className={styles.video} onClick={() => videoButtonClicked()} />}
      {
        isRoomPresenter &&
        <PresenterModeIcon
          className={clsx(styles.presenter, { [styles['presenter-mode-active']]: presenter && presenter.identity === videoRoom?.localParticipant.identity })}
          onClick={() => presenterModeButtonClicked()}
        />
      }
      <LeaveRoomIcon className={styles.leave} onClick={() => leaveRoomClicked()} />
      {
        isRoomPresenter && presenterModalVisible &&
        <PresenterSettingsModal visible={presenterModalVisible} videoRoomId={videoRoomId} onClose={() => setPresenterModalVisible(false)} />
      }
    </div>
  );
};

export default Controls;
