import { useGetSpace } from 'src/features/Dashboard/queries/spaces';
import MapItem, { MapIcon } from './MapItem';

import showroomImageDefault from '../../assets/images/Showroom_default.png';
import showroomImageHover from '../../assets/images/Showroom_hover.png';
import showroomImageSelected from '../../assets/images/Showroom_selected.png';

interface MapItemContainerProps {
  spaceId: string;
  selected?: boolean;
  onSelectServerClick: () => void;
  onPlayNowClick: () => void;
}

export default function MapItemContainer({
  spaceId,
  selected = false,
  onSelectServerClick,
  onPlayNowClick,
}: MapItemContainerProps) {
  const { data: space } = useGetSpace(spaceId);

  if (!space) return null;

  const mapIcon: MapIcon = {
    default: space.environment.icon?.default ?? showroomImageDefault,
    hover: space.environment.icon?.hover ?? showroomImageHover,
    selected: space.environment.icon?.selected ?? showroomImageSelected,
  };

  return (
    <MapItem
      name={space.name}
      mapIcon={mapIcon}
      selected={selected}
      onSelectServerClick={onSelectServerClick}
      onPlayNowClick={onPlayNowClick}
    />
  );
}
