import LoadingSpinner from '../LoadingSpinner';
import styles from './LoadingInfo.module.scss';

type Props = {
  statuses: string[]
};

export default function LoadingInfo({ statuses }: Props) {
  return (
    <div className={styles.container}>
      <LoadingSpinner className={styles.info} />
      {statuses.map((status) => (<p key={status} className={styles.info}>{status}</p>))}
    </div>
  );
}
