import UploadImageModal from './UploadImageModal';
import UploadVideoModal from './UploadVideoModal';

export enum ContentType {
  Image = 'image',
  Video = 'video',
}
interface UploadContentModalProps {
  open: boolean;
  onClose: ()=> void;
  onConfirm: (fileUrl: string) => void;
  metaverseId: string;
  isSiteAdmin?: boolean;
  type: ContentType;
}

export default function UploadContentModal({
  open, onClose, onConfirm, metaverseId, isSiteAdmin, type,
}: UploadContentModalProps) {
  switch (type) {
    case ContentType.Image:
      return (
        <UploadImageModal
          open={open}
          onClose={onClose}
          onConfirm={onConfirm}
          metaverseId={metaverseId}
          isSiteAdmin={isSiteAdmin}
        />
      );
    case ContentType.Video:
      if (!isSiteAdmin) {
        return null;
      }
      return (
        <UploadVideoModal
          open={open}
          onClose={onClose}
          onConfirm={onConfirm}
          metaverseId={metaverseId}
        />
      );
    default:
      return null;
  }
}
