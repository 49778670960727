import { Button, Typography } from '@pixelcanvas/ui';
import background from '../../../../assets/images/access_denied_background.png';
import building from '../../../../assets/images/access_denied_building.png';
import styles from './MetaverseNoAccessPage.module.scss';

const REDIRECT_URL = window.env.REACT_APP_MAIN_URL || 'https://www.pixelcanvas.com/?utm_source=pixelcanvas&utm_medium=referral';

export default function MetaverseNoAccessPage() {
  return (
    <>
      <div className={styles.container} style={{ backgroundImage: `url(${background})`, backgroundSize: 'auto', height: '100%' }}>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={building} alt="no access" />
          </div>
          <div className={styles['text-container']}>
            <Typography variant="h1" className={styles.title}>
              Access Denied
            </Typography>
            <Typography variant="h4" className={styles.description}>
              You don&apos;t have permission to access this page. If you think you should, double check the email you used to sign up, or contact the metaverse organizer.
            </Typography>
            <Button
              className={styles.button}
              color="secondary"
              onClick={() => location.replace(REDIRECT_URL)}
            >
              Go Back
            </Button>
          </div>
        </div>
      </div>
    </>

  );
}
