import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import styles from './OmniyaPrimaryButton.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

export default function OmniyaPrimaryButton({
  className,
  disabled = false,
  onClick,
  children,
}: Props) {
  return (
    <button
      className={clsx(className, styles.button)}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <span className={styles.text}>{children}</span>
      <div className={styles.outline2} />
      <div className={styles.outline1} />
    </button>
  );
}
