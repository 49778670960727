export const SET_AGENDA_TAGS = 'SET_AGENDA_TAGS';
export const SET_CONTENT_FOR_TAG = 'SET_CONTENT_FOR_TAG';

const initialState = {};

export const agendaReducer = (state = initialState, action = {}) => {
  let newState;
  switch (action.type) {
    case SET_AGENDA_TAGS: {
      newState = { ...state };
      action.payload.forEach((tag) => {
        if (newState.hasOwnProperty(tag._id)) return;
        newState[tag._id] = [];
      });
      return newState;
    }
    case SET_CONTENT_FOR_TAG: {
      newState = { ...state };
      newState[action.payload.tag] = action.payload.content;
      return newState;
    }
    default: return state;
  }
};

export function setAgendaTags(tags) {
  return { type: SET_AGENDA_TAGS, payload: tags };
}
export function setContentForTag(tag, content) {
  return { type: SET_CONTENT_FOR_TAG, payload: { tag, content } };
}
