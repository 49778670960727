import { useMutation, useQuery, useQueryClient } from 'react-query';
import IShowcase, { ShowcaseDocument, ShowcaseTabDocument } from 'src/interfaces/IShowcase';
import { IShowcaseImageContent, IShowcaseLinkContent, IShowcaseSlideshowContent, IShowcaseVideoContent, ShowcaseContentDocument } from 'src/interfaces/IShowcaseContent';
import api from 'src/services/api';
import { metaverseKeys } from './metaverse';

const queryKey = 'showcase';

export const showcaseKeys = {
  lists: (metaverseId: string | undefined) => [
    metaverseKeys.item(metaverseId),
    queryKey,
    'list',
  ] as const,
  list: (metaverseId: string | undefined, filters: string | undefined) => [
    ...metaverseKeys.item(metaverseId),
    queryKey,
    ...showcaseKeys.lists(metaverseId),
    { filters },
  ] as const,
  items: (metaverseId: string | undefined) => [
    metaverseKeys.item(metaverseId),
    queryKey,
    'item',
  ] as const,
  item: (metaverseId: string | undefined, id: string | undefined) => [
    ...metaverseKeys.item(metaverseId),
    queryKey,
    ...showcaseKeys.items(metaverseId),
    id,
  ] as const,
};

export function useCreateShowcase(metaverseId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Omit<IShowcase, 'metaverseId' | 'tabs' | 'createdBy' | 'updatedBy'>) => api
      .post(`/api/v2/metaverses/${metaverseId}/showcases`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.items(metaverseId));
      },
    },
  );
}

export function useUpdateShowcase(
  metaverseId: string | undefined,
  guid: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<IShowcase>) => api
      .patch(`/api/v2/metaverses/${metaverseId}/showcases/${guid}`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}

export function useDeleteShowcase(metaverseId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    (guid: string) => api
      .delete(`/api/v2/metaverses/${metaverseId}/showcases/${guid}`)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.items(metaverseId));
      },
    },
  );
}

export function useShowcases(
  metaverseId: string | undefined,
) {
  return useQuery(
    showcaseKeys.items(metaverseId),
    () => api
      .get<ShowcaseDocument[]>(`/api/v2/metaverses/${metaverseId}/showcases`)
      .then((res) => res.data),
    { enabled: !!metaverseId },
  );
}

export function useShowcase(
  metaverseId: string | undefined,
  guid: string | undefined,
) {
  return useQuery(
    showcaseKeys.item(metaverseId, guid),
    () => api
      .get<ShowcaseDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}`)
      .then((res) => res.data),
    { enabled: !!metaverseId && !!guid },
  );
}

export function useCreateShowcaseTab(
  metaverseId: string | undefined,
  guid: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (name: string) => api
      .post<ShowcaseTabDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}/tabs`, { name })
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}

type CreateShowcaseContentRequest = Partial<IShowcaseVideoContent>
| Partial<IShowcaseImageContent> | Partial<IShowcaseLinkContent>
| Partial<IShowcaseSlideshowContent>;

export function useCreateShowcaseContent(
  metaverseId: string | undefined,
  guid: string | undefined,
  tabId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (content: CreateShowcaseContentRequest) => api
      .post<ShowcaseContentDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}/tabs/${tabId}/contents`, content)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}

export function useDeleteShowcaseContent(
  metaverseId: string | undefined,
  guid: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (contentId: string) => api
      .delete<ShowcaseContentDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}/contents/${contentId}`)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}

export function useDeleteShowcaseTab(
  metaverseId: string | undefined,
  guid: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (tabId: string) => api
      .delete<ShowcaseTabDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}/tabs/${tabId}`)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}

export function useUpdateShowcaseTab(
  metaverseId: string | undefined,
  guid: string | undefined,
  tabId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<ShowcaseTabDocument>) => api
      .patch<ShowcaseTabDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}/tabs/${tabId}`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}

export function useUpdateShowcaseContent(
  metaverseId: string | undefined,
  guid: string | undefined,
  contentId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<IShowcaseImageContent | IShowcaseLinkContent | IShowcaseVideoContent | IShowcaseSlideshowContent>) => api
      .patch<ShowcaseContentDocument>(`/api/v2/metaverses/${metaverseId}/showcases/${guid}/contents/${contentId}`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(showcaseKeys.item(metaverseId, guid));
      },
    },
  );
}
