import { useState } from 'react';

import showroomImageDefault from '../../assets/images/Showroom_default.png';
import showroomImageHover from '../../assets/images/Showroom_hover.png';
import showroomImageSelected from '../../assets/images/Showroom_selected.png';
import PlayNowButton from '../PlayNowButton/PlayNowButton';
import SelectServerButton from '../SelectServerButton/SelectServerButton';

import styles from './MapItem.module.scss';

export interface MapIcon {
  default: string;
  hover: string;
  selected: string;
}

const fallbackMapIcon: MapIcon = {
  default: showroomImageDefault,
  hover: showroomImageHover,
  selected: showroomImageSelected,
};

interface MapItemProps {
  mapIcon: MapIcon,
  name: string,
  selected?: boolean,
  onSelectServerClick: () => void,
  onPlayNowClick: () => void,
}

export default function MapItem({
  mapIcon,
  name,
  selected = false,
  onSelectServerClick,
  onPlayNowClick,
}: MapItemProps) {
  const [isHover, setIsHover] = useState(false);
  const [fallback, setFallback] = useState(false);

  const currentMapIcon = fallback ? fallbackMapIcon : mapIcon;

  let src = currentMapIcon.default;
  if (selected) {
    src = currentMapIcon.selected;
  } else if (isHover) {
    src = currentMapIcon.hover;
  }

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img
        className={styles.image}
        src={src}
        onError={() => setFallback(true)}
        alt=""
      />
      <span className={styles.name}>{name}</span>
      <SelectServerButton className={styles.selectServer} onClick={onSelectServerClick} />
      <PlayNowButton className={styles.playNow} onClick={onPlayNowClick} />
    </div>
  );
}
