import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectMetaverseId } from 'src/redux/metaverse';
import { selectSelectedTab } from 'src/redux/pixelconnect';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrow-right.svg';
import defaultBackground from '../../../../assets/svg/gradient-bg.svg';
import { ReactComponent as StoreIcon } from '../../../../assets/svg/store.svg';
import Carousel from '../../../../components/Carousel/Carousel';
import { IContent } from '../../../../interfaces/IContent';
import { ICta } from '../../../../interfaces/ICta';
import { IPage } from '../../../../interfaces/IPage';
import { getPageContents, getPageCta } from '../../../../services/ContentService';
import StageContent from '../../components/StageContent/StageContent';

import styles from './ExhibitHall.module.scss';

export default function ExhibitHall() {
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const exhibits: IPage[] = useSelector((state: any) => state.pages.exhibits, shallowEqual);
  const selectedTab = useSelector(selectSelectedTab, shallowEqual);
  const [currentExhibitIndex, setCurrentExhibitIndex] = useState<number>(-1);
  const [contents, setContents] = useState<IContent[]>([]);
  const [currentContent, setCurrentContent] = useState<IContent | null>(null);
  const [ctaItems, setCtaItems] = useState<ICta[]>([]);
  const [carouselWidth, setCarouselWidth] = useState<number>();
  const exhibitLayoutRef = useRef<any>();

  function handleResize() {
    if (exhibitLayoutRef.current) {
      setCarouselWidth(exhibitLayoutRef.current.offsetWidth);
    }
  }

  async function fetchContents() {
    const fetchedContents = await getPageContents(metaverseId, exhibits[currentExhibitIndex]._id as string);
    setContents(fetchedContents);
    if (fetchedContents.length > 0) {
      setCurrentContent(fetchedContents[0]);
    }
    const fetchedCtaItems = await getPageCta(metaverseId, exhibits[currentExhibitIndex]._id as string);
    setCtaItems(fetchedCtaItems);
  }

  function prevExhibit() {
    if (currentExhibitIndex === 0) {
      setCurrentExhibitIndex(exhibits.length - 1);
    } else {
      setCurrentExhibitIndex(currentExhibitIndex - 1);
    }
  }

  function nextExhibit() {
    if (currentExhibitIndex === exhibits.length - 1) {
      setCurrentExhibitIndex(0);
    } else {
      setCurrentExhibitIndex(currentExhibitIndex + 1);
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (currentExhibitIndex >= 0) {
      fetchContents();
    }
  }, [currentExhibitIndex]);

  const showArrows = exhibits.length > 1 && currentExhibitIndex >= 0;

  const exhibitElements = exhibits.map((exhibit, index) => (
    <div className={styles['exhibit-cell']} onClick={() => setCurrentExhibitIndex(index)} key={exhibit._id}>
      <img className={styles['exhibit-image']} alt="" src={exhibit.backgroundImage} />
      <div className={styles['cell-title']}>{exhibit.title}</div>
    </div>
  ));

  const ctaElements = ctaItems.map((cta) => (
    <div className={styles.cta} key={cta._id}>
      <div className={styles['cta-title']}>{cta.title}</div>
      <div className={styles['cta-description']}>{cta.description}</div>
      <a href={cta.link} rel="noreferrer" target="_blank">Click here</a>
    </div>
  ));

  const isExhibitSelected = currentExhibitIndex >= 0;

  const ExhibitHeader = () => (
    <div className={styles['exhibit-header-items']}>
      {
        isExhibitSelected &&
        <div className={styles['all-booths']} onClick={() => setCurrentExhibitIndex(-1)}>
          <StoreIcon className={styles['store-icon']} />
          <p className={styles['all-booths-text']}>ALL Previews</p>
        </div>
      }
      <div className={styles['exhibit-header']}>
        {
          showArrows &&
          <div className={clsx(styles['exhibit-arrow'], styles.left)}>
            <ArrowLeft className={clsx(styles['exhibit-arrow-icon'], styles.left)} onClick={() => prevExhibit()} />
            <p className={clsx(styles['exhibit-arrow-text'], styles.left)}>PREVIOUS</p>
          </div>
        }
        <div className={styles['exhibit-title']}>
          <p className={styles['exhibit-title-text']}>{exhibits[currentExhibitIndex]?.title || 'Previews'}</p>
          <div className={styles['exhibit-dots']}>
            {
              isExhibitSelected &&
              exhibits.map((exhibit, index) => <div key={exhibit._id} className={clsx(styles['exhibit-dot'], { [styles.selected]: index === currentExhibitIndex })} />)
            }
          </div>
        </div>
        {
          showArrows &&
          <div className={clsx(styles['exhibit-arrow'], styles.right)}>
            <p className={clsx(styles['exhibit-arrow-text'], styles.right)}>NEXT</p>
            <ArrowRight className={clsx(styles['exhibit-arrow-icon'], styles.right)} onClick={() => nextExhibit()} />
          </div>
        }
      </div>
    </div>
  );

  const ExhibitSelectHeader = () => (
    <p className={styles['exhibit-selected-header']}>Previews</p>
  );

  return (
    <div className={styles['exhibit-hall-page']}>
      <img
        className={styles['page-background-image']}
        src={currentExhibitIndex !== -1 ? exhibits[currentExhibitIndex].backgroundImage : defaultBackground}
        style={currentExhibitIndex === -1 ? { background: '#C4C4C4' } : {}}
      />
      <div className={styles['page-header']}>
        {isExhibitSelected ? <ExhibitHeader /> : <ExhibitSelectHeader />}
      </div>
      <div className={styles['exhibit-hall']}>
        {
          currentExhibitIndex === -1
            ?
              <div className={styles['exhibit-hall-overview']}>{exhibitElements}</div>
            :
              <div className={styles['exhibit-contents']} style={selectedTab ? { width: 'calc(100% - 150px' } : {}}>
                <div ref={exhibitLayoutRef} className={styles['exhibit-layout']}>
                  <div className={styles.content}>
                    <StageContent content={currentContent} contentSource={currentContent?.onDemandSource as string} upNextContent={null} />
                  </div>
                  {ctaItems.length > 0 && <div className={styles['cta-items']}>{ctaElements}</div>}
                </div>
                {
                  contents.length > 1 &&
                  <div className={styles['carousel-container']}>
                    <Carousel contents={contents} maxWidth={Math.min(carouselWidth as number, 1300)} onItemClick={(content: IContent) => setCurrentContent(content)} />
                  </div>
                }
              </div>
        }
      </div>
    </div>
  );
}
