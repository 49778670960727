import { createMongoAbility } from '@casl/ability';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useMetaverse, useMetaverseAbility, useMetaverseBySubdomain } from 'src/queries/metaverse';
import { setMetaverseId } from 'src/redux/metaverse';
import { dataLayer } from 'src/utils/DataLayerUtil';
import { getBaseHost } from 'src/utils/URLHelper';
import { selectEditMetaverseId, setEditMetaverseId } from '../redux/editMetaverse';

export default function useCurrentMetaverse() {
  const subdomain = window.location.hostname.split('.')[0];
  const isLocalhost = subdomain === 'localhost';

  const localhostQueryResult = useLocalhostMetaverse(isLocalhost);
  const subdomainQueryResult = useSubdomainMetaverse(isLocalhost, subdomain);

  const queryResult = isLocalhost ? localhostQueryResult : subdomainQueryResult;
  const { data: metaverse, error, isLoading, isFetching, metaverseLink } = queryResult;

  const {
    data: ability = createMongoAbility(),
    error: abilityError,
    isLoading: isMetaverseAbilityLoading,
    isFetching: isMetaverseAbilityFetching,
  } = useMetaverseAbility(metaverse?._id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!metaverse) return;
    dispatch(setMetaverseId(metaverse._id));
    dataLayer.push({ px_event_id: metaverse._id });
  }, [metaverse]);

  return {
    metaverse,
    error: error ?? abilityError,
    isLoading: isLoading || isMetaverseAbilityLoading,
    isFetching: isFetching || isMetaverseAbilityFetching,
    metaverseLink,
    ability,
  };
}

function useLocalhostMetaverse(isLocalhost: boolean) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const metaverseId = useSelector(selectEditMetaverseId);

  const navigate = useNavigate();

  const queryMetaverseId = searchParams.get('id') ?? undefined;

  /*
    On mount of this provider, set metaverseId from ?id=<metaverseId>
    only if parameter is present and not on localhost.

    If metaverseId is set this means the query parameter has already been
    parsed and removed. Use the existing metaverseId.
  */

  useEffect(() => {
    if (!isLocalhost) return;
    if (queryMetaverseId) {
      dispatch(setEditMetaverseId(queryMetaverseId));
      searchParams.delete('id');
      setSearchParams(searchParams);
      return;
    }
    if (!metaverseId) navigate('/');
  }, [isLocalhost, queryMetaverseId, metaverseId]);

  const metaverseLink = `/metaverse?id=${metaverseId}`;
  const { data, error, isLoading, isFetching } = useMetaverse(metaverseId, true);
  return { data, error, isLoading, isFetching, metaverseLink };
}

function useSubdomainMetaverse(isLocalhost: boolean, subdomain: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryMetaverseId = searchParams.get('id') ?? undefined;

  useEffect(() => {
    if (isLocalhost) return;
    if (queryMetaverseId) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [subdomain, queryMetaverseId]);

  const metaverseLink = `https://${subdomain}.${getBaseHost()}`;
  const { data, error, isLoading, isFetching } = useMetaverseBySubdomain(!isLocalhost ? subdomain : undefined, { keepPreviousData: true });
  return { data, error, isLoading, isFetching, metaverseLink };
}
