import styles from './Description.module.scss';

interface DescriptionProps {
  children: React.ReactNode;
}

export default function Description({ children }: DescriptionProps) {
  return (
    <p className={styles.description}>{children}</p>
  );
}
