import clsx from 'clsx';
import { DateTime } from 'luxon';
import { ReactComponent as TrashIcon } from '../../../../../assets/svg/trash-alt.svg';
import styles from './DomainDetails.module.scss';
import { getDisplayName } from '../../../../../utils/DisplayNameUtil';

interface Props {
  domains: any[];
  onDelete: (domain: string) => void;
  searchTerm: string;
  sortOption: string;
}

export default function DomainDetails({
  domains, onDelete, searchTerm, sortOption,
}: Props) {
  const rowElements = domains
    .filter(({ domain }) => !searchTerm || domain.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      switch (sortOption) {
        case 'Alphabetical: A to Z':
          return a.domain.toLowerCase().localeCompare(b.domain.toLowerCase());
        case 'Alphabetical: Z to A':
          return b.domain.toLowerCase().localeCompare(a.domain.toLowerCase());
        case 'Date & Time Added: Newest to Oldest':
          return DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis();
        case 'Date & Time Added: Oldest to Newest':
          return DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis();
        case 'Added by: Alphabetical': {
          const aName = getDisplayName(a.creator).toLowerCase();
          const bName = getDisplayName(b.creator).toLowerCase();
          return aName.localeCompare(bName);
        }
        default:
          return a - b;
      }
    })
    .map(({
      domain, createdAt, creator, _id,
    }: any, i: number) => (
      <tr className={clsx(styles['row-data'], { [styles.odd]: (i + 1) % 2 })} key={_id}>
        <td className={styles.item}>
          {domain}
        </td>
        <td className={styles.item}>
          {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
        </td>
        <td className={styles.item}>
          {getDisplayName(creator)}
        </td>
        <td className={styles['item-trash']}>
          <button className={styles.delete} type="button" onClick={() => onDelete(domain)}>
            <TrashIcon className={styles.icon} />
          </button>
        </td>
      </tr>

    ));

  return (
    <div className={styles.container}>
      <table className={styles.registrations}>
        <thead className={styles.head}>
          <tr className={styles['row-header']}>
            <th className={clsx(styles.header, styles.domain)}>DOMAIN</th>
            <th className={clsx(styles.header, styles.date)}>DATE &amp; TIME ADDED</th>
            <th className={clsx(styles.header, styles.user)}>ADDED BY</th>
            <th className={clsx(styles.header, styles.trash)} />
          </tr>
        </thead>
        <tbody className={styles.body}>
          {rowElements}
        </tbody>
      </table>
      {
        !domains?.length &&
        <div className={styles.empty}>
          <h3 className={styles['empty-header']}>YOU DON&apos;T HAVE ANY WHITELISTED DOMAINS YET</h3>
          <p className={styles['empty-description']}>This metaverse is currently open to the public.</p>
        </div>
      }
    </div>
  );
}
