import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './VisitorModalTitle.module.scss';

interface Props {
  className?: string;
  children: ReactNode;
}

export default function VisitorModalTitle({
  className,
  children,
}: Props) {
  return (
    <div className={clsx(className, styles.title)}>{children}</div>
  );
}
