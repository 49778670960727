import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RequireAuthentication from 'src/authentication/RequireAuthentication';

import { useMetaverseCustomFields, useMetaverseJoin } from 'src/queries/metaverse';

import landingBg from '../../../../assets/images/landing-bg.png';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import OmniyaPrimaryButton from '../../../../components/Omniya/OmniyaPrimaryButton/OmniyaPrimaryButton';
import { isBright } from '../../../../utils/HexCodeUtil';
import AuthModal from '../../components/Authentication/StandardAuthModal';
import ProfileModal, { ProfileModalInterface } from '../../components/ProfileModal/ProfileModal';
import useAccessMetaverse from '../../hooks/useAccessMetaverse';
import styles from './MetaverseLandingPage.module.scss';
import SocialMediaLinks from './SocialMediaLinks/SocialMediaLinks';
import Footer from './Footer';

type BackgroundProps = {
  src: string | undefined | null;
};

function Background({ src }: BackgroundProps) {
  if (src?.endsWith('.mp4')) {
    return <video className={styles['event-landing-bg']} src={src} autoPlay muted loop />;
  }
  return <img className={styles['event-landing-bg']} src={src || landingBg} alt="" />;
}

const MetaverseLandingPage = () => {
  const {
    metaverse,
    error: accessMetaverseError,
  } = useAccessMetaverse();
  const { user } = usePxAuthContext();
  const {
    data: metaverseVisitor,
    error: joinMetaverseError,
  } = useMetaverseJoin(metaverse?._id, user?._id);
  const { data: customFields } = useMetaverseCustomFields(metaverse?._id);

  const navigate = useNavigate();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const profileModalRef = useRef<ProfileModalInterface | null>(null);

  console.log('Metaverse Landing Page', metaverse, metaverseVisitor, customFields);

  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (joinMetaverseError?.response?.status !== 403) return;
    if (user) navigate('/noaccess');
    setLogin(true);
  }, [joinMetaverseError]);

  useEffect(() => {
    if ((accessMetaverseError as any)?.response?.status !== 403) return;
    if (user) navigate('/noaccess');
    setLogin(true);
  }, [accessMetaverseError]);

  useEffect(() => {
    if (!customFields?.fields || !metaverseVisitor) return;
    const hasRequiredFields = customFields.fields
      .filter((field) => field.required)
      .every((field) => !metaverseVisitor.fields?.[field.name]);

    if (user.newProfile || !user.displayName || !hasRequiredFields) {
      profileModalRef.current?.open();
    }
  }, [customFields?.fields, metaverseVisitor]);

  useEffect(() => {
    if (!metaverse || metaverse.landingPage) return;
    console.log('Redirecting to metaverse page', metaverse);
    navigate(`/metaverse?id=${metaverse._id}`);
  }, [metaverse]);

  if (!metaverse?.landingPage) return null;

  const { landingPage, social } = metaverse;

  const dark = isBright(landingPage.colors.background);

  const showSocialMediaLinks = social?.twitter || social?.discord;

  if (!user && login) {
    return <RequireAuthentication />;
  }

  const logo = metaverse?.logo ?? metaverse?.spaces?.[0]?.logo;

  return (
    <div className={styles['event-landing-page']}>
      <div
        className={styles['left-panel']}
        style={{ background: landingPage.colors?.background ?? 'white' }}
      >
        {logo && <img className={styles.logo} src={logo} alt="" />}
        <div className={clsx(styles.name, { [styles.dark]: dark })}>
          {metaverse.name}
        </div>
        {
          landingPage.welcomeMessage &&
          <div className={clsx(styles.welcome, { [styles.dark]: dark })}>
            {landingPage.welcomeMessage}
          </div>
        }
        <div className={styles.buttons}>
          <OmniyaPrimaryButton
            className={styles.button}
            onClick={() => navigate(`/metaverse?id=${metaverse._id}`)}
          >
            Start Exploring
          </OmniyaPrimaryButton>
        </div>
        {
          showSocialMediaLinks &&
          <SocialMediaLinks
            className={styles.socials}
            metaverseDetails={metaverse}
          />
        }
        {
          landingPage.techSupportMessage &&
          <div className={clsx(styles.support, { [styles.dark]: dark })}>
            {landingPage.techSupportMessage}
          </div>
        }
      </div>
      <div className={styles['right-image']}>
        <Background src={landingPage.background} />
        <Footer />
      </div>
      <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
      <ProfileModal ref={profileModalRef} />
    </div>
  );
};

export default MetaverseLandingPage;
