import { ChangeEvent, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { ReactComponent as TriangleError } from '../../../../assets/svg/exclamation-triangle.svg';
import styles from './VisitorInput.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
  className?: string;
  title: string;
  type?: string;
  value?: string;
  readOnly?: boolean;
  onValueChange?: Function;
  showRequired?: boolean;
  error?: string;
}

export default function VisitorInput({
  className,
  title,
  type = 'text',
  value = '',
  maxLength,
  readOnly,
  onChange,
  onValueChange,
  showRequired,
  error = '',
  ...props
}: Props) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onValueChange?.(e.target.value);
    onChange?.(e);
  };

  const showRequiredInput = showRequired && value?.length === 0;

  return (
    <div className={className}>
      <div className={clsx(styles.label, { [styles.required]: showRequiredInput })}>
        {title}
      </div>
      <div className={styles.inputContainer}>
        <input
          className={clsx(styles.input, { [styles.required]: showRequiredInput })}
          type={type}
          value={value}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly}
          {...props}
        />
        <div className={styles.footer}>
          {
            showRequiredInput &&
            <>
              <TriangleError className={styles.error} />
              Please fill out this section
            </>
          }
          {
            error &&
            <>
              <TriangleError className={styles.error} />
              {error}
            </>
          }
          {
            maxLength !== undefined &&
            <div className={styles.maxLength}>
              {`${value?.length}/${maxLength}`}
            </div>
          }
        </div>
      </div>
    </div>
  );
}
