import { IUser } from 'src/interfaces';
import Contact from '../interfaces/Contact';

type SlimUser = {
  displayName?: string,
  id: string,
  sub: string,
  userId: string,
  _id: string,
};

export function getDisplayName(user: SlimUser | IUser | undefined) {
  if (user?.displayName) {
    return user.displayName;
  }
  // if (user.email) {
  //   return user.email;
  // }
  if (user?.userId) {
    return user.userId;
  }
  return 'Anonymous';
}

export function getUserDisplayName(user: IUser) {
  if (user?.displayName) {
    return user.displayName;
  }
  if (user?.email) {
    return user.email;
  }
  if (user?.userId) {
    return user.userId;
  }
  return 'Anonymous';
}

export function getContactDisplayName(user: Contact) {
  if (user?.displayName) return user.displayName;
  if (user?.email) {
    return user.email;
  }
  if (user?.userId) {
    return user.userId;
  }
  return 'Anonymous';
}
