import { createMongoAbility } from '@casl/ability';
import { Can } from '@casl/react';
import { Portal } from '@material-ui/core';
import { Subheader, NotificationBanner } from '@pixelcanvas/ui';
import { CSSProperties, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import Action from 'src/casl/Action';
import { IToastMessage, useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import { useOrganizationAbility } from 'src/queries/organization';
import { useOrganizationContext } from '../../pages/OrganizationPage/context/OrganizationContextProvider';
import SubheaderNavLink from '../SubheaderNavLink';

export default function SubheaderNav() {
  const { currentOrganizationId } = useOrganizationContext();
  const { toasts, removeToast } = useToastMessageContext();
  const { data: ability = createMongoAbility() } = useOrganizationAbility(currentOrganizationId);

  const [referenceElment, setReferenceElment] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState<IToastMessage | null>(toasts[0]);
  const [displayedToast, setDisplayedToast] = useState<IToastMessage | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElment, popperElement,
    {
      strategy: 'fixed',
      placement: 'bottom',
      modifiers: [{ name: 'offset' }],
    },
  );

  useEffect(() => {
    setToast(toasts[0]);
  }, [toasts]);

  useEffect(() => {
    if (displayedToast !== toast) {
      setShowToast(false);
      const timeout = setTimeout(() => setDisplayedToast(toast), 200);
      return () => clearTimeout(timeout);
    }
    setShowToast(true);
    return () => {};
  }, [displayedToast, toast]);

  return (
    <>
      <Subheader ref={setReferenceElment}>
        <SubheaderNavLink to="/metaverses">Metaverses</SubheaderNavLink>
        <Can I={Action.Create} a="Space" ability={ability}>
          <SubheaderNavLink to="/spaces">Spaces</SubheaderNavLink>
        </Can>
        <Can I={Action.Create} a="WorldMap" ability={ability}>
          <SubheaderNavLink to="/worldmaps">World Maps</SubheaderNavLink>
        </Can>
      </Subheader>
      <Portal>
        <NotificationBanner
          ref={setPopperElement}
          show={Boolean(displayedToast) && showToast}
          type={displayedToast?.type}
          onClose={() => displayedToast && removeToast(displayedToast)}
          style={{ ...popperStyles.popper } as CSSProperties}
          {...attributes.popper}
        >
          {displayedToast && displayedToast.message}
        </NotificationBanner>
      </Portal>
    </>
  );
}
