import { Tabs } from '@pixelcanvas/ui';
import { useState } from 'react';
import { Feature } from 'src/enums/Features';
import TabNavLink from 'src/features/Dashboard/components/TabNavLink';
import { useActiveFeature } from 'src/features/Dashboard/hooks/useActiveFeature';

export default function PrivacySettingsTabNav() {
  const isDomainWhitelistEnabled = useActiveFeature(Feature.EmailDomainWhitelist);
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <Tabs selectedIndex={selectedTab}>
      <TabNavLink
        to="/edit/privacy/general"
        onMatch={() => setSelectedTab(0)}
      >
        General
      </TabNavLink>
      {
        isDomainWhitelistEnabled &&
        <TabNavLink
          to="/edit/privacy/domain"
          onMatch={() => setSelectedTab(1)}
        >
          Domain Whitelist
        </TabNavLink>
      }
    </Tabs>
  );
}
