import { useEffect } from 'react';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export default function useOpenMap(map: string | undefined) {
  const { emitUIInteraction } = usePixelStreamingContext();
  useEffect(() => {
    if (!emitUIInteraction || !map) return;
    emitUIInteraction({ type: 'map:open', name: map });
  }, [emitUIInteraction, map]);
}
