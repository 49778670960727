import { useContext } from 'react';
import IAuthContext from './IAuthContext';
import { InternalAuthContext } from './PlatformAuthContextProvider';

export { default as LoginMethod } from './LoginMethod';

const AUTH_STRATEGY = window.env.REACT_APP_AUTH_STRATEGY || 'platform';

export default function usePxAuthContext(): IAuthContext {
  let contextProvider;
  if (AUTH_STRATEGY === 'platform') {
    contextProvider = InternalAuthContext;
  } else {
    throw new Error(`Unknown context provider ${AUTH_STRATEGY}`);
  }
  const context = useContext<IAuthContext>(contextProvider);
  if (!context) {
    throw new Error('usePxAuthContext must be used within an Px AuthContext');
  }
  return context;
}
