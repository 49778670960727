import clsx from 'clsx';

import styles from './Actions.module.scss';

interface ActionsProps {
  className?: string;
  children: React.ReactNode;
}

export default function Actions({ className, children }: ActionsProps) {
  return (
    <div className={clsx(styles.actions, className)}>
      {children}
    </div>
  );
}
