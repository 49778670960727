import { ReactNode } from 'react';

import clsx from 'clsx';
import { ShowcaseContentDocument } from 'src/interfaces/IShowcaseContent';

import styles from './ContentCard.module.scss';

interface ContentCardProps {
  className?: string;
  content: ShowcaseContentDocument;
  onClick: () => void;
  children: ReactNode;
}

export default function ContentCard({
  className,
  content,
  onClick,
  children,
}: ContentCardProps) {
  return (
    <button
      key={content._id}
      type="button"
      className={clsx(className, styles.card)}
      onClick={onClick}
    >
      <span className={styles.span}>
        <div className={styles.thumbnail}>
          <img className={styles.image} src={content.thumbnail} alt="" />
          <div className={styles.overlay}>
            {children}
          </div>
        </div>
        <div className={styles.text}>
          <h4 className={styles.name}>{content.name}</h4>
          <p className={styles.description}>{content.description}</p>
        </div>
      </span>
    </button>
  );
}
