import clsx from 'clsx';

import { ReactComponent as TableListIcon } from '../../../../../../assets/svg/table-list-solid.svg';

import styles from './SelectServerButton.module.scss';

interface SelectServerButtonProps {
  className?: string;
  onClick: () => void;
}

export default function SelectServerButton({
  className,
  onClick,
}: SelectServerButtonProps) {
  return (
    <button
      type="button"
      className={clsx(className, styles.button)}
      onClick={onClick}
    >
      <span className={styles.container}>
        <span className={styles.label}>Select Server</span>
        <div className={styles.icon}>
          <TableListIcon className={styles.svg} />
        </div>
      </span>
    </button>
  );
}
