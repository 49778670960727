import { Button, MainContent, SelectInputField, TextInputField, Typography, UploadButton } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import PageType from 'src/enums/PageType';
import SourceType from 'src/enums/SourceType';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { setTitle } from 'src/features/Dashboard/redux/editMetaverse';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { isSpace3DPage, Space3DPage } from 'src/interfaces/IPage';
import { useOrganizationSpaces } from 'src/queries/organization';
import { usePage, useUpdatePage } from 'src/queries/pages';
import { addPage, updatePage as updatePageAction } from 'src/redux/pages';
import { createPage, uploadImageContent, uploadVideoContent } from 'src/services/ContentService';
import { readURL } from 'src/utils/FileUtil';

import imagePlaceholder from '../../../../../../assets/images/placeholder-image-platform.png';
import { useOrganizationContext } from '../../../OrganizationPage/context/OrganizationContextProvider';

import styles from './LegacySpace3DAddOn.module.scss';

export default function LegacySpace3DAddOn() {
  const dispatch = useDispatch();
  const { pageId } = useParams();
  const { metaverse } = useCurrentMetaverse();
  const { data: page } = usePage(metaverse?._id, pageId);
  useEffect(() => {
    if (!page) return;
    dispatch(setTitle(`Add-On: ${page?.title}`));
  }, [page?.title]);
  if (!metaverse || !page || !isSpace3DPage(page)) return null;
  return <Space3DPageForm key={pageId} metaverse={metaverse} page={page} pageId={page._id} />;
}

export interface Space3DPageFormProps {
  metaverse: MetaverseResponse;
  page: Space3DPage;
  pageId?: string;
  onSave?: Function;
  onError?: (error: any) => void;
}

function Space3DPageForm({
  metaverse,
  page: savedPage,
  pageId,
  onSave,
  onError,
}: Space3DPageFormProps) {
  const dispatch = useDispatch();
  const metaverseId = metaverse._id;
  const [page, setPage] = useState<Space3DPage>(savedPage || {
    metaverseId, type: PageType.Space3D, title: '', backgroundImage: '',
  });
  const [showRequired, setShowRequired] = useState(false);
  const { addToast } = useToastMessageContext();
  const { currentOrganizationId } = useOrganizationContext();
  const { data: spaces } = useOrganizationSpaces(currentOrganizationId);

  const {
    mutate: updatePage,
    isSuccess,
    reset,
    data: updatedPage,
  } = useUpdatePage(metaverse?._id, page?._id);

  useEffect(() => {
    if (!isSuccess) return;
    reset();
    dispatch(updatePageAction(updatedPage));
    addToast('Your changes were successfully saved.');
  }, [isSuccess]);

  async function uploadPreloadSource(fileUrl: string, fileType: string) {
    try {
      if (fileType === 'image') {
        const response = await uploadImageContent(metaverseId, fileUrl);
        setPage({
          ...page,
          preloadSource: response.location,
          preloadSourceType: SourceType.Image,
        });
      } else {
        const response = await uploadVideoContent(metaverseId, fileUrl);
        setPage({
          ...page,
          preloadSource: response.location,
          preloadSourceType: SourceType.Video,
        });
      }
    } catch (e: any) {
      onError?.(e);
    }
  }

  async function createSpace3DPage() {
    try {
      if (!page.title || !page.spaceId) {
        setShowRequired(true);
        return;
      }
      const createdPage = await createPage(page);
      dispatch(addPage(createdPage));

      onSave?.();
    } catch (e: any) {
      onError?.(e);
    }
  }

  function handleSave() {
    if (!page.title || !page.spaceId) {
      setShowRequired(true);
      return;
    }
    updatePage(page);
  }

  const previewElement = page?.preloadSource?.endsWith('.mp4')
    ? <video className={styles.preview} src={page.preloadSource} controls />
    : <img className={styles.preview} src={page.preloadSource || imagePlaceholder} alt="" />;

  return (
    <>
      <MainContent>
        {
        spaces &&
        <>
          <div className={styles.details}>
            <TextInputField
              className={styles.input}
              label="TITLE"
              required
              placeholder="Page Title"
              value={page.title}
              onChange={(e) => setPage({ ...page, title: e.target.value })}
              error={showRequired && !page.title && 'Please enter page title'}
            />
            <SelectInputField
              className={styles.input}
              label="SPACE"
              required
              placeholder="Select a space"
              getOptionLabel={(option) => option.name}
              defaultOption={spaces.find((config) => config._id === page.spaceId)}
              getUniqueId={(space) => space._id}
              options={spaces}
              onChange={(o) => setPage({ ...page, spaceId: o._id })}
            />
            <Typography variant="h6">
              LOADING SCREEN
            </Typography>
            <div className={styles.upload}>
              {previewElement}
              <UploadButton
                className={styles.button}
                labelClassName={styles.label}
                id={`file-upload-${pageId}`}
                accept="image/*, .mp4"
                onChange={(e) => readURL(e.target, (file: any, fileType: string) => uploadPreloadSource(file, fileType))}
                color="secondary"
              >
                Upload
              </UploadButton>
            </div>
            {
              !pageId &&
              <Button color="secondary" onClick={createSpace3DPage}>
                Create Page
              </Button>
            }
          </div>
        </>
      }
      </MainContent>
      <SaveFooter
        saveClicked={handleSave}
        updatedAt={page.updatedAt}
      />
    </>
  );
}
