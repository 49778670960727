import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import background from '../../../../assets/images/world_map_bg.jpg';

import styles from './ErrorBoundary.module.scss';

type ErrorBoundaryProps = {
  children: React.ReactChild;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <>
          <img className={styles.background} src={background} alt="" />
          <div className={styles.container}>
            <FontAwesomeIcon className={styles.icon} icon={faTriangleExclamation} />
            <h1>Oh no! Something went wrong!</h1>
          </div>
        </>
      );
    }

    return children;
  }
}
