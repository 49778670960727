import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';
import { useUpdateMe } from 'src/queries/me';
import { setUserProfile } from 'src/redux/userProfile';

import { selectOpenAvatarModal, setOpenAvatarModal } from '../../features/PixelStreaming/redux/hud';
import AvatarModal from './customizerModal';

export default function PureWebAvatarModal() {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenAvatarModal);
  const { emitUIInteraction } = usePixelStreamingContext();

  const {
    mutate: updateMe,
    data: updatedUser,
    isSuccess,
    reset,
  } = useUpdateMe();

  useEffect(() => {
    if (!isSuccess || !emitUIInteraction) return;
    reset();
    emitUIInteraction({ type: 'profile_update' });
    dispatch(setUserProfile(updatedUser));
  }, [isSuccess, emitUIInteraction]);

  const handleClose = () => dispatch(setOpenAvatarModal(false));

  const handleSelected = async (avatarUrl: string) => {
    updateMe({ avatarUrl });
    handleClose();
  };

  return (
    <AvatarModal open={open} onClose={handleClose} avatarSelected={handleSelected} />
  );
}
