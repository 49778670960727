import axios from 'axios';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import PlatformSpinner from '../../../../../components/PlatformSpinner/PlatformSpinner';

import styles from './NFTCard.module.scss';

const gateways = window.env.REACT_APP_IPFS_GATEWAYS
  ? window.env.REACT_APP_IPFS_GATEWAYS
    .split(',')
    .map((gateway: string) => gateway.trim())
  : [
    'https://cloudflare-ipfs.com/ipfs',
    'https://gateway.ipfs.io/ipfs',
    'https://ipfs.io/ipfs',
    'https://dweb.link/ipfs',
  ];

function fixIpfsURL(url: string, gateway: string) {
  if (!url.startsWith('ipfs://')) return url;
  const path = url.replace('ipfs://', '');
  return `${gateway}/${path}`;
}

type Props = {
  className?: string;
  nft: any;
  onSelected?: Function;
  onError?: Function;
  onLoad?: Function;
};

export default function NFT({ className, nft, onSelected, onError, onLoad }: Props) {
  const [selectedGateway, setSelectedGateway] = useState(0);
  const [loading, setLoading] = useState(true);

  const usesIpfs = nft.token.image.startsWith('ipfs://');

  const onImgLoad = () => {
    setLoading(false);
    onLoad?.();
  };

  const onImgError = () => {
    if (!usesIpfs || (usesIpfs && selectedGateway === gateways.length - 1)) {
      setLoading(false);
      if (onError) onError();
      return;
    }
    if (selectedGateway < gateways.length - 1) {
      setSelectedGateway(selectedGateway + 1);
    }
  };

  const fixedURL = fixIpfsURL(nft.token.image, gateways[selectedGateway]);

  // Find a more efficient way to do this
  // that doesn't involve a momentary flicker
  useEffect(() => {
    axios.head(fixedURL).then((res: any) => {
      const contentType = res.headers['content-type'] ?? '';
      if (contentType !== 'image/jpeg' && contentType !== 'image/png') {
        onError?.();
      }
    });
  }, [fixedURL]);

  return (
    <button
      className={clsx(className, styles.card)}
      type="button"
      onClick={() => onSelected?.(fixedURL)}
      disabled={loading}
    >
      <img
        className={styles.icon}
        src={fixedURL}
        alt=""
        onLoad={onImgLoad}
        onError={onImgError}
      />
      {
        !loading &&
        <div className={styles.description}>
          <p className={styles.collection}>{nft.name}</p>
          <p className={styles.name}>{nft.token.name}</p>
        </div>
      }
      <PlatformSpinner visible={loading} />
    </button>
  );
}
