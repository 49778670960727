export const SET_USER_AUTH0 = 'SET_USER_AUTH0';

const initialState = { user: false };

export const auth0Reducer = (state = initialState, action = {}) => {
  let newState;
  switch (action.type) {
    case SET_USER_AUTH0: {
      newState = { ...state, user: action.payload };
      return newState;
    }
    default: return state;
  }
};

export function setUserRoles(data) {
  return { type: SET_USER_AUTH0, payload: data };
}
