import {
  DefaultStreamerOptions, InputEmitter, LaunchStatusEvent, LaunchStatusType, ModelDefinition,
  PlatformNext, StreamerStatus, UndefinedModelDefinition,
} from '@pureweb/platform-sdk';
import { LaunchRequestOptions, useLaunchRequest, useStreamer } from '@pureweb/platform-sdk-react';
import EventEmitter from 'events';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Subject } from 'rxjs';
import useAsyncEffect from 'use-async-effect';

import { dataLayer } from 'src/utils/DataLayerUtil';

import { PixelStreamingContext } from '../../PixelStreaming/contexts/PixelStreamingContext';

export interface IPureWebContext {
  launch: () => void,
  isReady: boolean,
  isLaunching: boolean,
  isLaunched: boolean;
  isLocalPixelstreaming: boolean;
  videoStream: MediaStream,
  audioStream: MediaStream,
  streamerStatus: StreamerStatus,
  emitter: InputEmitter,
  status: LaunchStatusEvent,
  messageSubject: Subject<string>,
  descriptor: EventEmitter,
}

export const PureWebContext = createContext<IPureWebContext>(null!);

const platform = new PlatformNext();
platform.initialize({ endpoint: 'https://api.pureweb.io' });

interface PureWebContextProviderProps {
  projectId: string;
  modelId: string;
  children: ReactNode,
}

export default function PureWebContextProvider({
  projectId: providedProjectId,
  modelId,
  children,
}: PureWebContextProviderProps) {
  const [descriptor] = useState(() => new EventEmitter());
  const [modelDefinition, setModelDefinition] = useState(new UndefinedModelDefinition());
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const [isLaunching, setLaunching] = useState(true);
  const [isLaunched, setLaunched] = useState(false);

  const projectId = window.env.REACT_APP_PW_PROJECT_ID ?? providedProjectId;
  const environmentId = window.env.REACT_APP_PW_ENVIRONMENT_ID;
  const isLocalPixelstreaming = !!environmentId;
  const forceRelay = false;
  const streamerOptions = DefaultStreamerOptions;

  const [searchParams] = useSearchParams();

  const launchRequestOptions: LaunchRequestOptions = {
    virtualizationProviderOverride: searchParams.get('virtualizationProviderOverride') || undefined
  };

  useAsyncEffect(async (isActive) => {
    if (!projectId) return;
    try {
      await platform.useAnonymousCredentials(projectId, environmentId);
      await platform.connect();
      streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers;
      streamerOptions.forceRelay = forceRelay;
      const models = await platform.getModels();
      if (!isActive) return;
      setAvailableModels(models);
      setLaunching(false);
    } catch (err) {
      console.error(err);
      // throw error
    }
  }, [projectId]);

  useEffect(() => {
    if (!availableModels?.length || !modelId) return;
    const model = availableModels.find((md) => md.active && md.id === modelId);
    setModelDefinition(model || availableModels[0]);
  }, [availableModels, modelId]);

  useEffect(() => () => {
    try {
      descriptor.removeAllListeners();
      platform.disconnect();
    } catch (e: any) {
      console.error(e);
    }
  }, []);

  const [
    status,
    launchRequest,
    queueLaunchRequest,
  ] = useLaunchRequest(platform, modelDefinition, launchRequestOptions);

  const [
    streamerStatus,
    emitter,
    videoStream,
    audioStream,
    messageSubject,
  ] = useStreamer(platform, launchRequest, streamerOptions);

  useEffect(() => {
    if (isLaunching && (status.status === LaunchStatusType.Unavailable ||
      status.status === LaunchStatusType.Error ||
      status.status === LaunchStatusType.Cancelled ||
      status.status === LaunchStatusType.Serviced ||
      streamerStatus === StreamerStatus.Connected)) {
      setLaunching(false);
    }
  }, [status, streamerStatus, isLaunching]);

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Connected) {
      dataLayer.push({ event: 'pureweb_session_begin' });
    }
  }, [streamerStatus]);

  useEffect(() => {
    const subscription = messageSubject.subscribe((value: string) => {
      try {
        console.log(`value: ${value}`);
        const message = JSON.parse(value);
        console.log('emit', message.type, message);
        descriptor.emit(message.type, message);
      } catch (e: any) {
        if (e instanceof SyntaxError) {
          console.error(`Message is not valid JSON: ${value}`);
        } else {
          console.error(e);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [messageSubject]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      (window as any).emitter = emitter;
    }
  }, [emitter]);

  const launch = () => {
    setLaunching(true);
    if (!isLocalPixelstreaming) queueLaunchRequest();
    setLaunched(true);
    dataLayer.push({ event: 'pureweb_session_launch' });
  };

  const isReady = modelDefinition && !(modelDefinition instanceof UndefinedModelDefinition);

  return (
    <PureWebContext.Provider
      value={{
        launch,
        isReady,
        isLaunching,
        isLaunched,
        isLocalPixelstreaming,
        videoStream,
        audioStream,
        streamerStatus,
        emitter,
        status,
        messageSubject: (messageSubject as any),
        descriptor,
      }}
    >
      <PixelStreamingContext.Provider
        value={{
          emitUIInteraction: emitter?.EmitUIInteraction ?? null,
          descriptor,
          isStreaming: streamerStatus === StreamerStatus.Connected,
          connect: launch,
        }}
      >
        {children}
      </PixelStreamingContext.Provider>
    </PureWebContext.Provider>
  );
}

export function usePureWebContext() {
  const context = useContext(PureWebContext);
  if (!context) {
    throw new Error('Context can only be used within PureWebContextProvider');
  }
  return context;
}
