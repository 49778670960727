import { useEffect } from 'react';
import usePxAuthContext from 'src/authentication/usePxAuthContext';
import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export default function useProfileUpdate() {
  const { user } = usePxAuthContext();
  const { emitUIInteraction } = usePixelStreamingContext();
  useEffect(() => {
    if (!user?.picture || !user?.displayName || !emitUIInteraction) return;
    emitUIInteraction({ type: 'profile_update' });
  }, [user?.picture, user?.displayName]);
}
