import { faImages, faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { ShowcaseDocument, ShowcaseTabDocument } from 'src/interfaces/IShowcase';
import { isShowcaseLinkContent, isShowcaseSlideshowContent, isShowcaseVideoContent, ShowcaseContentDocument } from 'src/interfaces/IShowcaseContent';
import { useShowcase } from 'src/queries/showcase';

import HUDButton from '../HUDButton';
import LinkContentCard from './components/LinkContentCard';
import MediaContentCard from './components/MediaContentCard';
import SlideshowContent from './components/SlideshowContent';
import VideoContent from './components/VideoContent';
import { usePixelStreamingContext } from '../../contexts/PixelStreamingContext';
import { selectActiveContent, selectActiveTab, setActiveTab, unsetActiveContent, unsetActiveTab } from './redux/contentViewer';

import styles from './ContentViewer.module.scss';

interface TutorialOverlayProps {
  className?: string;
  metaverse: MetaverseResponse;
}

export default function ContentViewer({
  className,
  metaverse,
}: TutorialOverlayProps) {
  const dispatch = useDispatch();

  const activeTab = useSelector(selectActiveTab);
  const activeContent = useSelector(selectActiveContent);

  const { descriptor, emitUIInteraction } = usePixelStreamingContext();

  const [showcaseGuid, setShowcaseGuid] = useState<string>();

  useEffect(() => {
    if (!descriptor) return () => {};
    const listener = ({ guid }: { guid: string }) => {
      setShowcaseGuid(guid);
    };
    descriptor.on('content-viewer:open', listener);
    return () => {
      descriptor.off('content-viewer:open', listener);
    };
  }, [descriptor]);

  const { data: showcase } = useShowcase(metaverse?._id, showcaseGuid);

  useEffect(() => {
    if (activeTab || !showcase) return;
    dispatch(setActiveTab(showcase.tabs[0]));
  }, [activeTab, showcase]);

  const handleBackClick = () => {
    if (!emitUIInteraction) return;
    if (activeContent) {
      dispatch(unsetActiveContent());
    } else {
      emitUIInteraction({ type: 'content-viewer:close' });
      setShowcaseGuid(undefined);
      dispatch(unsetActiveTab());
    }
  };

  if (!showcase) return null;

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.header}>
        <div className={styles.top}>
          <div className={styles.back}>
            <HUDButton className={styles.button} onClick={handleBackClick}>Back</HUDButton>
          </div>
          <h2 className={styles.text}>{showcase.name}</h2>
        </div>
        <div className={styles.bottom}>
          <Tabs showcase={showcase} />
        </div>
      </div>
      <MainContent />
    </div>
  );
}

function MainContent() {
  const activeTab = useSelector(selectActiveTab);
  const activeContent = useSelector(selectActiveContent);

  if (!activeContent) {
    if (!activeTab) return null;
    return <Cards key="cards" contents={activeTab.contents} />;
  }

  if (isShowcaseVideoContent(activeContent)) {
    return <VideoContent key={activeContent._id} content={activeContent} />;
  }

  if (isShowcaseSlideshowContent(activeContent)) {
    return <SlideshowContent key={activeContent._id} content={activeContent} />;
  }

  return null;
}

interface CardsProps {
  contents: ShowcaseContentDocument[];
}

function Cards({ contents }: CardsProps) {
  return (
    <main className={styles.content}>
      <div className={styles.cards}>
        {contents.map((content) => <Card key={content._id} content={content} />)}
      </div>
    </main>
  );
}

interface CardProps {
  content: ShowcaseContentDocument;
}

function Card({ content }: CardProps) {
  if (isShowcaseVideoContent(content)) {
    return <MediaContentCard content={content} icon={faPlayCircle} />;
  }
  if (isShowcaseSlideshowContent(content)) {
    return <MediaContentCard content={content} icon={faImages} />;
  }
  if (isShowcaseLinkContent(content)) {
    return <LinkContentCard content={content} />;
  }
  return null;
}

interface TabsProps {
  showcase: ShowcaseDocument;
}

function Tabs({ showcase }: TabsProps) {
  const dispatch = useDispatch();
  const activeContent = useSelector(selectActiveContent);
  const activeTab = useSelector(selectActiveTab);

  if (activeContent) return null;

  const tabs = showcase.tabs ?? [];

  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <Tab
          key={tab._id}
          tab={tab}
          selected={tab === activeTab}
          onSelect={() => dispatch(setActiveTab(tab))}
        />
      ))}
    </div>
  );
}

interface TabProps {
  tab: ShowcaseTabDocument;
  selected: boolean;
  onSelect: (tab: ShowcaseTabDocument) => void;
}

function Tab({ tab, selected, onSelect }: TabProps) {
  return (
    <button
      key={tab.name}
      type="button"
      className={clsx(styles.tab, { [styles.selected]: selected })}
      onClick={() => onSelect(tab)}
    >
      {tab.name}
    </button>
  );
}
