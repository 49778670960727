import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DateTime } from 'luxon';
import WorldMapRequest, { WorldMapResponse } from 'src/features/Dashboard/interfaces/WorldMap';
import api from '../../../services/api';

export const queryKey = 'worldmaps';

export const useWorldMaps = () => useQuery<WorldMapResponse[]>(
  queryKey,
  () => api
    .get('/api/maps')
    .then((res) => res.data)
    .then((data) => data.map((m: any) => ({
      ...m,
      createdAt: DateTime.fromISO(m.createdAt),
      updatedAt: DateTime.fromISO(m.updatedAt),
    }))),
);

export const useWorldMap = (id: string) => useQuery<WorldMapResponse>(
  queryKey,
  () => api
    .get(`/api/maps/${id}`)
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      createdAt: DateTime.fromISO(data.createdAt),
      updatedAt: DateTime.fromISO(data.updatedAt),
    })),
  { enabled: Boolean(id) },
);

export const useCreateWorldMap = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (map: WorldMapRequest) => api
      .post('/api/maps', map)
      .then((res) => res.data)
      .then((data) => ({
        ...data,
        createdAt: DateTime.fromISO(data.createdAt),
        updatedAt: DateTime.fromISO(data.updatedAt),
      })),
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
};

export const useDeleteWorldMap = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => api.delete(`/api/maps/${id}`),
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
};

export const useUpdateWorldMap = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: WorldMapRequest) => api.patch(`/api/maps/${id}`, data),
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
};
