import { useEffect } from 'react';
import { Button } from '@pixelcanvas/ui';
import heroImage from '../../../../assets/images/hero-image.png';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import PxFooter from '../../../../components/PxFooter/PxFooter';
import { resendEmailVerification } from '../../../../services/MetaverseService';

import styles from './EmailVerificationPage.module.scss';
import clsx from 'clsx';

export const verifyEmailDetails = (userEmail: string) => `Before you can create an metaverse, please follow the link we just sent to ${userEmail} to verify your email address.`;

const VerificationExpiredMessage = () => (
  <div className={clsx(styles['verification-details'], styles.support)}>
    Your verification link has expired. Please click the button below to resend the verification email.
  </div>
);

const ContactUsMessage = () => (
  <div className={clsx(styles['verification-details'], styles.support)}>
    Don&apos;t see it? Check your spam folder or contact us at <a href="mailto:support@pixelcanvas.com">support@pixelcanvas.com</a>
  </div>
);

export function EmailVerificationPage() {
  const { user } = usePxAuthContext();
  const urlParams = new URLSearchParams(window.location.search);
  const expired = urlParams.get('expired') === 'true';

  useEffect(() => {
    document.title = 'Pixel Canvas - Email Verification';
  }, []);

  useEffect(() => {
    if (user?.email_verified) {
      window.location.href = '/';
    }
  });

  return (
    <>
      <div className={styles['landing-bg']} />
      <div className={styles['email-verification-page']}>
        <div className={styles['verification-container']}>
          <div className={styles['verification-title']}>
            Please Verify Your Account
          </div>
          {!expired ?
            <div className={styles['verification-details']}>
              {verifyEmailDetails(user?.email ?? '')}
            </div>
            :
            <VerificationExpiredMessage />}
          <ContactUsMessage />
          <Button color="secondary" className={styles['verification-button']} size="lg" onClick={() => resendEmailVerification(user?._id)}>
            Resend Verification Email
          </Button>
        </div>
        <img className={styles['hero-image']} src={heroImage} alt="hero" />
      </div>
      <PxFooter />
    </>
  );
}
