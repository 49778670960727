import { Button, ContentInputList, ImageUploadField, InputFieldsGroup, SelectInputField, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, TextAreaInputField, TextInputField, UploadButton } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { ShowcaseTabDocument } from 'src/interfaces/IShowcase';
import { useUploadImageContent } from 'src/queries/content';
import { useCreateShowcaseContent, useUpdateShowcaseContent } from 'src/queries/showcase';
import { readURL } from 'src/utils/FileUtil';

import VisualSourceInput from 'src/features/Dashboard/components/VisualSourceInput/VisualSourceInput';
import { isShowcaseVideoContent, ShowcaseContentDocument, ShowcaseContentType, ShowcaseImageContentDocument, ShowcaseLinkContentDocument, ShowcaseSlideshowContentDocument, ShowcaseVideoContentDocument } from 'src/interfaces/IShowcaseContent';
import VisualSource, { VISUAL_SOURCE_TYPE } from 'src/interfaces/VisualSource';
import styles from './ContentModal.module.scss';

interface ContentModalProps {
  open: boolean;
  onClose: () => void;
  tab: ShowcaseTabDocument;
  contentToEdit?: ShowcaseContentDocument;
}

export default function ContentModal({
  open,
  onClose,
  tab,
  contentToEdit,
}: ContentModalProps) {
  const { guid } = useParams();
  const { metaverse } = useCurrentMetaverse();
  const [type, setType] = useState(contentToEdit?.type ?? ShowcaseContentType.Video);
  const [name, setName] = useState(contentToEdit?.name ?? '');
  const [source, setSource] = useState<VisualSource | undefined>(contentToEdit ? {
    src: (contentToEdit as ShowcaseVideoContentDocument | ShowcaseImageContentDocument).src,
    srcType: isShowcaseVideoContent(contentToEdit) ? VISUAL_SOURCE_TYPE.Video : VISUAL_SOURCE_TYPE.Image,
  } : undefined);
  const [href, setHref] = useState((contentToEdit as ShowcaseLinkContentDocument)?.href ?? '');
  const [description, setDescription] = useState(contentToEdit?.description ?? '');
  const [images, setImages] = useState<string[]>((contentToEdit as ShowcaseSlideshowContentDocument)?.images ?? []);
  const [thumbnail, setThumbnail] = useState(contentToEdit?.thumbnail ?? '');
  const [showRequired, setShowRequired] = useState(false);
  const [uploadId] = useState(uuidv4());

  const {
    mutate: createContent,
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    reset: resetCreate,
  } = useCreateShowcaseContent(
    metaverse?._id,
    guid,
    tab._id,
  );

  useEffect(() => {
    if (!isCreateSuccess) return;
    onClose();
    resetCreate();
  }, [isCreateSuccess]);

  const {
    mutate: updateContent,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    reset: resetUpdate,
  } = useUpdateShowcaseContent(
    metaverse?._id,
    guid,
    contentToEdit?._id,
  );

  useEffect(() => {
    if (!isUpdateSuccess) return;
    onClose();
    resetUpdate();
  });

  const { mutate: uploadImage, data: uploadedImage } = useUploadImageContent(metaverse?._id);

  useEffect(() => {
    if (!uploadedImage) return;
    setThumbnail(uploadedImage.location);
  }, [uploadedImage]);

  const handleAdd = () => {
    if (!guid || !name) {
      setShowRequired(true);
      return;
    }
    switch (type) {
      case ShowcaseContentType.Video:
      case ShowcaseContentType.Image:
        if (!source) {
          setShowRequired(true);
          return;
        }
        createContent({
          name,
          description,
          type,
          thumbnail,
          src: source.src,
        });
        break;
      case ShowcaseContentType.Link:
        if (!href) {
          setShowRequired(true);
          return;
        }
        createContent({
          name,
          description,
          type,
          thumbnail,
          href,
        });
        break;
      case ShowcaseContentType.Slideshow:
        if (!images.length) {
          setShowRequired(true);
          return;
        }
        createContent({
          name,
          description,
          type,
          thumbnail,
          images,
        });
        break;
      default:
        break;
    }
  };

  const handleUpdate = () => {
    if (!guid || !name) {
      setShowRequired(true);
      return;
    }
    updateContent({
      name,
      description,
      type,
      thumbnail,
      src: source?.src,
      href,
      images,
    });
  };

  const handleConfirm = () => {
    if (contentToEdit) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleThumbnailUpload = (fileURL: string) => {
    uploadImage(fileURL);
  };

  const title = contentToEdit ? 'EDIT CONTENT' : 'ADD NEW CONTENT';

  return (
    <TaskModal className={styles.modal} open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>{title}</TaskModalTitle>
      <TaskModalContent>
        <InputFieldsGroup title="Basic">
          <SelectInputField
            label="Type"
            defaultOption={type}
            options={[ShowcaseContentType.Video, ShowcaseContentType.Link, ShowcaseContentType.Slideshow]}
            onChange={(o) => setType(o)}
            error={showRequired && !type && 'Type is required'}
          />
          <TextInputField
            label="Name"
            placeholder="Enter name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showRequired && !name && 'Name is required'}
          />
          <TextAreaInputField
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={300}
          />
          <ImageUploadField
            id={uploadId}
            label="Thumbnail"
            required
            onChange={(e) => readURL(e.target, handleThumbnailUpload)}
            src={thumbnail ?? ''}
          >
            Upload
          </ImageUploadField>
        </InputFieldsGroup>
        {
            type === ShowcaseContentType.Video && (
              <InputFieldsGroup title="Video Content">
                <VisualSourceInput
                  label="Video"
                  accept=".mp4"
                  onChange={setSource}
                  source={source}
                />
              </InputFieldsGroup>
            )
          }
        {
            type === ShowcaseContentType.Image && (
              <InputFieldsGroup title="Image Content">
                <VisualSourceInput
                  label="Image"
                  accept="image/*"
                  onChange={setSource}
                  source={source}
                />
              </InputFieldsGroup>
            )
          }
        {
            type === ShowcaseContentType.Link && (
              <InputFieldsGroup title="Link Content">
                <TextInputField
                  label="Link"
                  placeholder="Enter link"
                  value={href}
                  onChange={(e) => setHref(e.target.value)}
                  error={showRequired && !href && 'Link is required'}
                />
              </InputFieldsGroup>
            )
          }
        {
            type === ShowcaseContentType.Slideshow && (
              <ShowcaseImageList images={images} onChange={setImages} />
            )
          }
        <PlatformSpinner visible={isCreateLoading || isUpdateLoading} />
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleConfirm} disabled={isCreateLoading}>Confirm</Button>
      </TaskModalActions>
    </TaskModal>
  );
}

interface ShowcaseImageListProps {
  images: string[];
  onChange: (images: string[]) => void;
}

function ShowcaseImageList({ images, onChange }: ShowcaseImageListProps) {
  const [id] = useState(uuidv4());
  const [expanded, setExpanded] = useState(false);
  const { metaverse } = useCurrentMetaverse();
  const {
    mutate: uploadImage,
    data: uploadedImage,
  } = useUploadImageContent(metaverse?._id);

  useEffect(() => {
    if (!uploadedImage) return;
    onChange([...images, uploadedImage.location]);
  }, [uploadedImage]);

  const handleUpload = (fileURL: string) => {
    uploadImage(fileURL);
  };

  const handleDelete = (index: number) => {
    onChange(images.filter((_, i) => i !== index));
  };

  return (
    <InputFieldsGroup title="Slideshow Content">
      <UploadButton
        id={id}
        onChange={(e) => readURL(e.target, handleUpload)}
        color="tertiary"
      >
        Add Image
      </UploadButton>
      <ContentInputList
        label="Slideshow"
        expanded={expanded}
        onToggle={setExpanded}
      >
        {
          images.map((image, i) => (
            <ContentInputList.Content key={image}>
              <img className={styles.image} src={image} alt="" />
              <ContentInputList.ContentActions onDelete={() => handleDelete(i)} />
            </ContentInputList.Content>
          ))
        }
      </ContentInputList>
    </InputFieldsGroup>
  );
}
