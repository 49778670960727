// TODO: This hook can be removed once we have the Unreal client listen to the socket events directly.
import { useEffect } from 'react';

import useSocketContext from 'src/contexts/useRoomsSocketContext';
import { Contact, SocketEvent } from 'src/interfaces';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

interface UserJoinedSocketMessage {
  roomId: string;
  user: Contact;
}

interface UserExitedSocketMessage {
  roomId: string;
  userId: string;
}

export default function useUserJoinAndExit() {
  const { socket } = useSocketContext();
  const { emitUIInteraction } = usePixelStreamingContext();

  useEffect(() => {
    if (!socket || !emitUIInteraction) return () => {};

    const userJoinedListener = ({ roomId, user }: UserJoinedSocketMessage) => {
      if (!emitUIInteraction) return;
      emitUIInteraction({ type: 'join_room', user, roomId });
    };

    const userExitedListener = ({ roomId, userId }: UserExitedSocketMessage) => {
      if (!emitUIInteraction) return;
      emitUIInteraction({ type: 'leave_room', userId, roomId });
    };

    socket.on(SocketEvent.GlobalUserJoinedRoom, userJoinedListener);
    socket.on(SocketEvent.GlobalUserExitedRoom, userExitedListener);
    return () => {
      socket?.off(SocketEvent.GlobalUserJoinedRoom, userJoinedListener);
      socket?.off(SocketEvent.GlobalUserExitedRoom, userExitedListener);
    };
  }, [socket, emitUIInteraction]);
}
