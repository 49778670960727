import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@material-ui/core';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './customizerModal.module.scss';

interface AvatarModalProps {
  open: boolean;
  avatarSelected: (avatarString: string) => void;
  onClose: () => void;
}

export default function AvatarModal({
  open,
  onClose,
  avatarSelected,
}: AvatarModalProps) {
  const subdomain = 'pixelcanvas';
  const iFrameSrc = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [iFrameLoaded, setIFrameLoaded] = useState(false);

  useLayoutEffect(() => {
    const iFrame = iFrameRef.current;
    if (iFrame) {
      iFrame.src = iFrameSrc;
    }
  });

  useEffect(() => {
    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);
    return () => {
      window.removeEventListener('message', subscribe);
      document.removeEventListener('message', subscribe);
    };
  });

  const subscribe = (event: any) => {
    const json = parse(event);
    if (json?.source !== 'readyplayerme') {
      return;
    }
    // Subscribe to all events sent from Ready Player Me
    // once frame is ready
    if (json.eventName === 'v1.frame.ready') {
      const iFrame = iFrameRef.current;
      setIFrameLoaded(true);
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**',
          }),
          '*',
        );
      }
    }
    // Get avatar GLB URL
    if (json.eventName === 'v1.avatar.exported') {
      // console.log(`Avatar URL: ${json.data.url}`);
      localStorage.setItem('avatar_url', json.data.url);
      avatarSelected(json.data.url);
    }
    // Get user id
    if (json.eventName === 'v1.user.set') {
      console.log(`User with id ${json.data.id} set:
    ${JSON.stringify(json)}`);
    }
  };
  const parse = (event: any) => {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}
    >
      <div className={styles['modal-content']}>
        { iFrameLoaded &&
          <FontAwesomeIcon icon={faXmark} className={styles.close} onClick={() => onClose()} />}
        <iframe
          src={iFrameSrc}
          allow="camera *; microphone *"
          className={styles.frame}
          id="frame"
          ref={iFrameRef}
          title="Ready Player Me"
        />
      </div>
    </Modal>
  );
}
