import { useEffect } from 'react';
import useSocketContext from 'src/contexts/useRoomsSocketContext';
import { SocketEvent } from 'src/interfaces';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export default function useJoinSpace(
  spaceId: string | undefined,
) {
  const { emitUIInteraction } = usePixelStreamingContext();
  const { socket } = useSocketContext();
  useEffect(() => {
    if (!spaceId || !emitUIInteraction) return;
    emitUIInteraction({
      type: 'join_space',
      spaceId,
    });
  }, [emitUIInteraction, spaceId]);

  useEffect(() => {
    if (!socket || !spaceId) return;
    socket.emit(SocketEvent.UnrealJoinSpace, { spaceId });
  }, [socket, spaceId]);
}
