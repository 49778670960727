import { Header } from '@pixelcanvas/ui';
import UserPill from '../../../../components/UserPill/UserPill';
import logo from '../../../../assets/images/logo-colored-light.png';

import OrganizationPill from '../../pages/OrganizationPage/components/OrganizationPill/OrganizationPill';

import styles from './MainHeader.module.scss';

export default function MainHeader() {
  return (
    <Header variant="short" className={styles.header}>
      <img className={styles.logo} src={logo} alt="Pixel Canvas Logo" />
      <div className={styles.divider} />
      <OrganizationPill className={styles.organization} />
      <UserPill />
    </Header>
  );
}
