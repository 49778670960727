import { ConfirmationModal } from '@pixelcanvas/ui';

interface PublishMetaverseConfirmModalProps {
  name: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function PublishMetaverseConfirmModal({
  name,
  open,
  onClose,
  onConfirm,
}: PublishMetaverseConfirmModalProps) {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title="PUBLISH YOUR METAVERSE"
      confirm="Publish"
    >
      <ConfirmationModal.Description>Are you sure you want to publish {name}?</ConfirmationModal.Description>
      <ConfirmationModal.Description>Publishing the metaverse will allow visitors to access your metaverse as per the privacy settings.</ConfirmationModal.Description>
    </ConfirmationModal>
  );
}
