import { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as TriangleError } from '../../assets/svg/exclamation-triangle.svg';
import styles from './ChipInput.module.scss';

interface Props {
  title: string;
  placeholder?: string;
  maxLength?: number;
  readOnly?: boolean;
  showRequired?: boolean;
  onAdd?: (value: string) => void;
  onChange?: (value: string) => void;
  validation?: (value: string) => boolean;
  newChipKeys?: string[];
  helperText?: string;
}

export default function ChipInput({
  title,
  placeholder = '',
  maxLength = undefined,
  readOnly = false,
  showRequired = false,
  onAdd = () => {},
  onChange = () => {},
  validation = () => true,
  newChipKeys = [',', ' '],
  helperText = undefined,
}: Props) {
  const [value, setValue] = useState('');

  const onInputChange = (e: any) => {
    let newValue = e.target.value.trim();
    if (!newChipKeys.find((key) => newValue === key)) {
      if (newChipKeys.find((key) => newValue.endsWith(key))) {
        newValue = newValue.slice(0, -1);
      }
      setValue(newValue);
    }
    onChange?.(newValue);
  };

  const onKeyPress = (e: any) => {
    if (newChipKeys.includes(e.key) && validation(value)) {
      onAdd?.(value);
      setValue('');
    }
  };

  const showRequiredVisible = showRequired && value?.length === 0;

  return (
    <div className={styles['detail-input']}>
      <div className={clsx(styles['detail-label'], { [styles.required]: showRequiredVisible })}>
        {title}
      </div>
      <div className={styles['input-container']}>
        <input
          className={clsx(styles['text-input'], { [styles.required]: showRequiredVisible })}
          type="text"
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={onInputChange}
          readOnly={readOnly}
          disabled={readOnly}
          onKeyPress={onKeyPress}
        />
        <div className={styles['input-footer']}>
          {
            showRequiredVisible &&
            <>
              <TriangleError className={styles['triangle-error']} />
              Please fill out this section
            </>
          }
          {
            maxLength !== undefined &&
            <div className={styles['max-length']}>{`${value?.length}/${maxLength}`}</div>
          }
        </div>
        {helperText && <p className={styles['helper-text']}>{helperText}</p>}
      </div>
    </div>
  );
}
