import { MetaverseResponse } from '../interfaces/IMetaverse';

export function getBaseHost() {
  if (window.location.hostname === 'localhost') {
    return window.location.host;
  }

  return window.env.REACT_APP_BASE_URL || 'dev.pixelcanvas.com';
}

export function getBaseOrigin() {
  return window.location.hostname === 'localhost'
    ? window.location.origin
    : `https://dashboard.${getBaseHost()}`;
}

export function getMetaverseEditURL(metaverse: MetaverseResponse) {
  const baseUrl = getBaseHost();
  if (window.location.hostname === 'localhost') {
    return `http://${baseUrl}/edit?id=${metaverse._id}`;
  }
  return `https://${metaverse.subdomain}.${baseUrl}/edit`;
}

export function getMetaverseVisitorURL(metaverse: MetaverseResponse) {
  const baseUrl = getBaseHost();
  if (window.location.hostname === 'localhost') {
    return `http://${baseUrl}/metaverse?id=${metaverse._id}`;
  }
  return `https://${metaverse.subdomain}.${baseUrl}`;
}

export function getMetaverseLandingURL(metaverse: MetaverseResponse) {
  const baseUrl = getBaseHost();
  if (window.location.hostname === 'localhost') {
    return `http://${baseUrl}/landing?id=${metaverse._id}`;
  }
  return `https://${metaverse.subdomain}.${baseUrl}/landing`;
}
