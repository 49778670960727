import { Router } from './Router';
import './App.scss';

function App() {
  return (
    <>
      <Router />
    </>
  );
}
export default App;
