import clsx from 'clsx';
import { Button } from '@pixelcanvas/ui';
import { DateTime } from 'luxon';
import styles from './SaveFooter.module.scss';

type Props = {
  className?: string,
  saveClicked?: Function,
  updatedAt?: Date | string,
  disabled?: boolean,
};

const formatOptions = {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
} as const;

export default function SaveFooter({
  className = undefined,
  saveClicked = () => {},
  updatedAt = undefined,
  disabled = false,
}: Props) {
  const message = useSaveFooter(disabled, updatedAt);
  return (
    <div className={clsx(className, styles.container)}>
      <Button
        className={styles.button}
        color="secondary"
        disabled={disabled}
        onClick={() => saveClicked()}
      >
        Save
      </Button>
      <div className={styles.label}>{message}</div>
    </div>
  );
}

function useSaveFooter(disabled: boolean, updatedAt: Date | string | undefined) {
  if (disabled) return 'Changes on this page are automatically saved';
  if (!updatedAt) return '';
  const updatedAtDateTime = typeof updatedAt === 'string'
    ? DateTime.fromISO(updatedAt) : DateTime.fromJSDate(updatedAt);
  const updatedAtLocaleString = updatedAtDateTime
    .toLocal()
    .toLocaleString(formatOptions);
  return `Last saved on ${updatedAtLocaleString}`;
}
