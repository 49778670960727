import {
  Button,
  MainContent,
  SelectInputField,
  TextInputField,
  Typography,
  UploadButton,
} from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import PageType from 'src/enums/PageType';
import SourceType from 'src/enums/SourceType';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { useWorldMaps } from 'src/features/Dashboard/queries/worldMaps';
import { setTitle } from 'src/features/Dashboard/redux/editMetaverse';
import { isWorldMap3DPage, WorldMap3DPage } from 'src/interfaces/IPage';
import { usePage } from 'src/queries/pages';
import { selectMetaverseId } from 'src/redux/metaverse';
import { addPage, updatePage } from 'src/redux/pages';
import { createPage, patchPage, uploadImageContent, uploadVideoContent } from 'src/services/ContentService';
import { readURL } from 'src/utils/FileUtil';

import imagePlaceholder from '../../../../../../assets/images/placeholder-image-platform.png';

import styles from './WorldMap3DAddOn.module.scss';

export default function WorldMap3DAddOn() {
  const dispatch = useDispatch();
  const { pageId } = useParams<{ pageId: string }>();
  const { metaverse } = useCurrentMetaverse();
  const { data: page } = usePage(metaverse?._id, pageId);
  useEffect(() => {
    if (!page) return;
    dispatch(setTitle(`Add-On: ${page?.title}`));
  }, [page?.title]);
  if (!metaverse || !page || !isWorldMap3DPage(page)) return null;
  return <WorldMap3DForm page={page} pageId={pageId} />;
}

export interface WorldMap3DFormProps {
  page: WorldMap3DPage;
  pageId?: string;
  onSave?: Function;
  onError?: (error: any) => void;
}

function WorldMap3DForm({
  page: savedPage,
  pageId,
  onSave,
  onError,
}: WorldMap3DFormProps) {
  const dispatch = useDispatch();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [page, setPage] = useState<WorldMap3DPage>(savedPage || {
    metaverseId, type: PageType.WorldMap3D, title: '', backgroundImage: '',
  });
  const [showRequired, setShowRequired] = useState(false);
  const { addToast } = useToastMessageContext();
  const { data: worldMaps = [] } = useWorldMaps();

  async function uploadPreloadSource(fileUrl: string, fileType: string) {
    try {
      if (fileType === 'image') {
        const response = await uploadImageContent(metaverseId, fileUrl);
        setPage({ ...page, preloadSource: response.location, preloadSourceType: SourceType.Image });
      } else {
        const response = await uploadVideoContent(metaverseId, fileUrl);
        setPage({ ...page, preloadSource: response.location, preloadSourceType: SourceType.Video });
      }
    } catch (e: any) {
      onError?.(e);
    }
  }

  async function createPurewebPage() {
    try {
      if (!page.title || !page.worldMap) {
        setShowRequired(true);
        return;
      }
      const createdPage = await createPage(page);
      dispatch(addPage(createdPage));

      onSave?.();
    } catch (e: any) {
      onError?.(e);
    }
  }

  async function saveClicked() {
    try {
      if (!page.title || !page.worldMap) {
        setShowRequired(true);
        return;
      }
      const updatedPage = await patchPage(page);
      dispatch(updatePage(updatedPage));
      addToast('Your changes were successfully saved.');
    } catch (e: any) {
      onError?.(e);
    }
  }

  const previewElement = page?.preloadSource?.endsWith('.mp4')
    ? <video className={styles.preview} src={page.preloadSource} controls />
    : <img className={styles.preview} src={page.preloadSource || imagePlaceholder} alt="" />;

  return (
    <>
      {
        worldMaps &&
        <>
          <MainContent className={styles.details}>
            <TextInputField
              className={styles.input}
              label="TITLE"
              required
              placeholder="Page Title"
              value={page.title}
              onChange={(e) => setPage({ ...page, title: e.target.value })}
              error={showRequired && !page.title && 'Please enter page title'}
            />
            <SelectInputField
              className={styles.input}
              label="MAP SELECTION"
              required
              placeholder="Select map"
              defaultOption={savedPage?.worldMap}
              getOptionLabel={(o) => o.nickname}
              getUniqueId={(o) => o._id}
              options={worldMaps}
              onChange={(o) => setPage({ ...page, worldMap: o._id })}
            />
            <Typography variant="h6">
              LOADING SCREEN
            </Typography>
            <div className={styles.upload}>
              {previewElement}
              <UploadButton
                className={styles.button}
                labelClassName={styles.label}
                id={`file-upload-${pageId}`}
                accept="image/*, .mp4"
                onChange={(e) => readURL(e.target, (file: any, fileType: string) => uploadPreloadSource(file, fileType))}
                color="secondary"
              >
                Upload
              </UploadButton>
            </div>
            {
              !pageId &&
              <Button color="secondary" onClick={createPurewebPage}>
                Create Page
              </Button>
            }
          </MainContent>
          <SaveFooter
            saveClicked={() => saveClicked()}
            updatedAt={page.updatedAt?.toString() || ''}
          />
        </>
      }
    </>
  );
}
