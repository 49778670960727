import {
  Popper, Fade, Paper, PopperPlacementType,
} from '@material-ui/core';
import Contact from '../../../../../interfaces/Contact';
import AvatarIcon from '../../../../../assets/images/profile-pic.png';
import styles from './ContactPopover.module.scss';
import { getContactDisplayName } from '../../../../../utils/DisplayNameUtil';
import clsx from 'clsx';

interface Props {
  contact: Contact;
  placement?: string;
  anchorEl: Element | null;
}

const ContactPopover = ({ contact, placement = 'left', anchorEl }: Props) => {
  const src = contact.picture || AvatarIcon;

  if (!anchorEl) {
    return null;
  }

  return (
    <Popper
      id={`popover-contact-${contact.userId}`}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement={placement as PopperPlacementType}
      transition
      style={{ marginRight: '1rem' }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={10}>
            <div className={styles['contact-popover']}>
              <div className={styles.avatar}>
                <img src={src} alt="" />
              </div>
              <div className={styles.details}>
                <h3 className={styles.name}>
                  {getContactDisplayName(contact)}
                </h3>
                {
                  contact.jobTitle &&
                  <div className={styles.contactField}>
                    <div className={styles.label}>
                      TITLE
                    </div>
                    <span className={styles.company}>
                      {contact.jobTitle}
                    </span>
                  </div>
                }
                {
                  contact.company &&
                  <div className={clsx(styles.contactField, styles.companyContactField)}>
                    <div className={styles.label}>
                      COMPANY
                    </div>
                    <span className={styles.company}>
                      {contact.company}
                    </span>
                  </div>
                }
              </div>
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>);
};
export default ContactPopover;
