import { useState } from 'react';
import { DateTime } from 'luxon';
import { Card, MoreOptions, Typography } from '@pixelcanvas/ui';

import { WorldMapResponse } from 'src/features/Dashboard/interfaces/WorldMap';

import { ReactComponent as CalendarIcon } from '../../../../../../assets/svg/calendar-dates.svg';
import { ReactComponent as SignalIcon } from '../../../../../../assets/svg/signal-stream.svg';

import styles from './MapCard.module.scss';

enum MapMoreOption {
  EditMap = 'Edit Map',
  Delete = 'Delete',
}

type Props = {
  map: WorldMapResponse;
  onEdit: () => void;
  onDelete: () => void;
};

export default function MapCard({ map, onEdit, onDelete }: Props) {
  const handleSelect = (option: MapMoreOption) => {
    switch (option) {
      case MapMoreOption.EditMap:
        onEdit();
        break;
      case MapMoreOption.Delete:
        onDelete();
        break;
      default:
        break;
    }
  };
  return (
    <Card className={styles.container}>
      <Card.Content className={styles.content}>
        <Typography className={styles.name} variant="h2" emphasis="high">
          {map.nickname}
        </Typography>
        <Typography variant="subtitle4" emphasis="medium" className={styles.created}>
          Created: {map.createdAt.toLocaleString(DateTime.DATE_FULL)}, by {map.createdBy?.displayName}
        </Typography>
        <div className={styles.assignedModels}>
          <Typography variant="h6" emphasis="header">
            ASSIGNED MODELS:
          </Typography>
          <Typography variant="subtitle3">{map.spaces.length}</Typography>
        </div>
        <Typography className={styles.models} variant="subtitle4" emphasis="medium">
          {map.spaces.map((s) => s.name).join(', ')}
        </Typography>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.info}>
              <SignalIcon className={styles.icon} />
              <span className={styles.text}>
                Published Metaverses: {map.metaverses.filter((metaverse) => metaverse.published).length}
              </span>
            </div>
            <div className={styles.info}>
              <CalendarIcon className={styles.icon} />
              <span className={styles.text}>
                Total Metaverses: {map.metaverses.length}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.lastEdited}>
          Last Edited: {map.updatedAt.toLocaleString(DateTime.DATE_FULL)}, by {map.updatedBy?.displayName}
        </div>
        <MapMoreOptions onSelect={handleSelect} />
      </Card.Content>
    </Card>
  );
}

type MapMoreOptionsProps = {
  onSelect: (option: MapMoreOption) => void;
};

function MapMoreOptions({ onSelect }: MapMoreOptionsProps) {
  const [open, setOpen] = useState(false);
  return (
    <MoreOptions
      classes={{ container: styles.moreOptions }}
      options={Object.values(MapMoreOption)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onSelect={onSelect}
    />
  );
}
