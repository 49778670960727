import { useEffect, useRef } from 'react';
import { usePureWebContext } from '../contexts/PureWebContextProvider';

export default function PureWebAudioComponent() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const { audioStream, isLaunched } = usePureWebContext();

  useEffect(() => {
    if (!audioRef.current || !audioStream) return;
    audioRef.current.srcObject = audioStream;
  }, [audioRef.current, audioStream]);

  useEffect(() => {
    if (!audioRef.current || !isLaunched) return;
    audioRef.current.volume = 0.5;
    audioRef.current.load();
  }, [audioRef.current, isLaunched]);

  return <audio ref={audioRef} autoPlay />;
}
