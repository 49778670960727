import { CSSProperties, useState } from 'react';
import { ClickAwayListener, Portal } from '@material-ui/core';
import { DropdownOption, DropdownOptions, Typography } from '@pixelcanvas/ui';
import { usePopper } from 'react-popper';
import { useNavigate } from 'react-router-dom';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import OrganizationSwitchModal from '../OrganizationSwitchModal/OrganizationSwitchModal';
import DefaultOrganizationIcon from '../DefaultOrganizationIcon';

import { useOrganizationContext } from '../../context/OrganizationContextProvider';
import styles from './OrganizationPill.module.scss';

interface OrganizationPillProps {
  className?: string;
}

export default function OrganizationPill({ className }: OrganizationPillProps) {
  const navigate = useNavigate();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { currentOrganization } = useOrganizationContext();

  const [referenceElment, setReferenceElment] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElment, popperElement,
    {
      strategy: 'fixed',
      placement: 'bottom-end',
      modifiers: [
        { name: 'offset', options: { offset: [0, 6] } },
      ],
    },
  );

  const ArrowIcon = () => (
    isDropdownOpened
      ? <FontAwesomeIcon icon={faChevronUp} className={styles.icon} />
      : <FontAwesomeIcon icon={faChevronDown} className={styles.icon} />
  );

  const handleSwitchOrganization = () => {
    setIsDropdownOpened(false);
    setIsModalOpened(true);
  };

  if (!currentOrganization) {
    return null;
  }

  const organizationIcon = currentOrganization?.logo ?
    <img
      className={styles.picture}
      src={currentOrganization?.logo}
      alt={currentOrganization?.name}
    />
    :
    <DefaultOrganizationIcon />;

  return (
    <>
      <button
        ref={setReferenceElment}
        className={clsx(className, styles.pill)}
        type="button"
        onClick={() => setIsDropdownOpened(true)}
      >
        <span className={styles.span}>
          {organizationIcon}
          <Typography className={styles.name} variant="h5" emphasis="high">{currentOrganization?.name}</Typography>
          <ArrowIcon />
        </span>
      </button>
      {
        isDropdownOpened &&
        <Portal>
          <ClickAwayListener onClickAway={() => setIsDropdownOpened(false)}>
            <DropdownOptions
              ref={setPopperElement}
              style={{ ...popperStyles.popper } as CSSProperties}
              {...attributes.popper}
            >
              <DropdownOption
                type="button"
                data-value="Organization Settings"
                onClick={() => {
                  navigate('/organization/profile');
                }}
              >
                Organization Settings
              </DropdownOption>
              <DropdownOption
                type="button"
                data-value="Switch Organization"
                onClick={handleSwitchOrganization}
              >
                Switch Organization
              </DropdownOption>
            </DropdownOptions>
          </ClickAwayListener>
        </Portal>
      }
      <OrganizationSwitchModal open={isModalOpened} onClose={() => setIsModalOpened(false)} />
    </>
  );
}
