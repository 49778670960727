import { faGamepadModern } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';

import { EnvironmentResponse } from 'src/interfaces/Environment';
import defaultPhoto from '../../../../assets/images/default-event-photo.jpg';

import styles from './EnvironmentCard.module.scss';

interface Props {
  selected: boolean;
  onSelect: (environment: EnvironmentResponse) => void;
  environment: EnvironmentResponse;
}

export default function EnvironmentCard({
  environment,
  selected,
  onSelect,
}: Props) {
  return (
    <Card
      className={styles.card}
      variant="selection"
      onClick={() => onSelect(environment)}
      active={selected}
    >
      <Card.Image
        className={styles.image}
        alt="environment"
        src={environment.image || defaultPhoto}
        darken="none"
      >
        {
          environment.availableGames &&
          <div className={styles.bottomLeft}>
            <div className={styles.games}>
              <FontAwesomeIcon className={styles.icon} icon={faGamepadModern} />
              <Typography variant="h4" emphasis="high">{environment.availableGames}</Typography>
            </div>
          </div>
        }
      </Card.Image>
      <Card.Content className={styles.content}>
        <Typography className={styles.name} variant="h2" emphasis="high">
          {environment.name}
        </Typography>
      </Card.Content>
    </Card>
  );
}
