import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './PxSidebar.module.scss';

interface Props {
  className?: string;
  background: string;
  width: number;
  sidebarButtons: ReactNode;
}

export default function PxSidebar({ className, background, width, sidebarButtons }: Props) {
  return (
    <div className={clsx(styles['px-sidebar'], className)} style={{ width, background }}>
      {sidebarButtons}
    </div>
  );
}
