/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  OnChangeFn,
  RowSelectionState,
} from '@tanstack/react-table';
import { Table, CheckBox } from '@pixelcanvas/ui';
import { IMetaverseMetric } from 'src/interfaces/IMetaverseMetric';

const toWidth = (width: number) => {
  if (width < 0) {
    return `${-width}%`;
  }
  if (width > 0) {
    return `${width}px`;
  }
  return 'auto';
};

const columnHelper = createColumnHelper<IMetaverseMetric>();

const defaultColumns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckBox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </div>
    ),
    cell: ({ row }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckBox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </div>
    ),
    size: 24,
    minSize: 24,
    maxSize: 24,
  }),
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'NAME',
  }),
  columnHelper.accessor((row) => row.coverage, {
    id: 'coverage',
    header: 'COVERAGE',
  }),
];

type Props = {
  className?: string;
  data: IMetaverseMetric[];
  rowSelection: RowSelectionState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
};

export function MetricsSelectionTable({
  data,
  className,
  rowSelection,
  onRowSelectionChange,
}: Props) {
  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    onRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 0,
      minSize: 0,
      maxSize: 0,
    },
  });

  return (
    <Table.Table className={className}>
      <Table.Thead>
        {
          table.getHeaderGroups().map((headerGroup: any) => (
            <Table.HeaderTr key={headerGroup.id}>
              {
                headerGroup.headers.map((header: any) => (
                  <Table.Th
                    key={header.id}
                    colSpan={header.colSpan}
                    width={toWidth(header.getSize())}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Table.Th>
                ))
              }
            </Table.HeaderTr>
          ))
        }
      </Table.Thead>
      <Table.Tbody>
        {
          table.getRowModel().rows.map((row: any, i: number) => (
            <Table.Tr key={row.id} odd={(i + 1) % 2 === 1}>
              {
                row.getVisibleCells().map((cell: any) => (
                  <Table.Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Td>
                ))
              }
            </Table.Tr>
          ))
        }
      </Table.Tbody>
    </Table.Table>
  );
}
