import { MainContent, SelectInputField, TextInputField, Typography, UploadButton } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SaveFooter from 'src/components/SaveFooter/SaveFooter';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import PageType from 'src/enums/PageType';
import SourceType from 'src/enums/SourceType';
import { setTitle } from 'src/features/Dashboard/redux/editMetaverse';
import useCurrentMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { isPureWebPage, PureWebPage } from 'src/interfaces/IPage';
import { usePage, useUpdatePage } from 'src/queries/pages';
import { usePureWebConfigurations } from 'src/queries/pureWebConfigurations';
import { updatePage as updatePageAction } from 'src/redux/pages';
import { uploadImageContent, uploadVideoContent } from 'src/services/ContentService';
import { readURL } from 'src/utils/FileUtil';

import imagePlaceholder from '../../../../../../assets/images/placeholder-image-platform.png';

import styles from './PureWebAddOn.module.scss';

export default function PureWebAddOn() {
  const dispatch = useDispatch();
  const { pageId } = useParams();
  const { metaverse } = useCurrentMetaverse();
  const { data: page } = usePage(metaverse?._id, pageId);
  useEffect(() => {
    dispatch(setTitle(`Add-On: ${page?.title}`));
  }, [page?.title]);
  if (!metaverse || !page || !isPureWebPage(page)) return null;
  return <AddPureWebForm key={page._id} pageId={page._id} metaverse={metaverse} page={page} />;
}

export interface AddPureWebFormProps {
  metaverse: MetaverseResponse;
  page: PureWebPage;
  pageId?: string;
  onSave?: Function;
  onError?: (error: any) => void;
}

function AddPureWebForm({
  metaverse,
  page: savedPage,
  pageId,
  onError,
}: AddPureWebFormProps) {
  const dispatch = useDispatch();
  const metaverseId = metaverse._id;
  const [page, setPage] = useState<PureWebPage>(savedPage || {
    metaverseId, type: PageType.PureWeb3D, title: '', backgroundImage: '',
  });
  const [showRequired, setShowRequired] = useState(false);
  const { addToast } = useToastMessageContext();
  const { data: purewebConfigurations } = usePureWebConfigurations();

  const {
    mutate: updatePage,
    isSuccess,
    reset,
    data: updatedPage,
  } = useUpdatePage(metaverse?._id, page?._id);

  useEffect(() => {
    if (!isSuccess) return;
    reset();
    dispatch(updatePageAction(updatedPage));
    addToast('Your changes were successfully saved.');
  }, [isSuccess]);

  async function uploadPreloadSource(fileUrl: string, fileType: string) {
    try {
      if (fileType === 'image') {
        const response = await uploadImageContent(metaverseId, fileUrl);
        setPage({
          ...page,
          preloadSource: response.location,
          preloadSourceType: SourceType.Image,
        });
      } else {
        const response = await uploadVideoContent(metaverseId, fileUrl);
        setPage({
          ...page,
          preloadSource: response.location,
          preloadSourceType: SourceType.Video,
        });
      }
    } catch (e: any) {
      onError?.(e);
    }
  }

  function handleSave() {
    if (!page.title || !page.purewebConfiguration) {
      setShowRequired(true);
      return;
    }
    updatePage(page);
  }

  const previewElement = page?.preloadSource?.endsWith('.mp4')
    ? <video className={styles.preview} src={page.preloadSource} controls />
    : <img className={styles.preview} src={page.preloadSource || imagePlaceholder} alt="" />;

  return (
    <MainContent>
      {
        purewebConfigurations &&
        <>
          <div className={styles.details}>
            <TextInputField
              className={styles.input}
              label="TITLE"
              required
              placeholder="Page Title"
              value={page.title}
              onChange={(e) => setPage({ ...page, title: e.target.value })}
              error={showRequired && !page.title && 'Please enter page title'}
            />
            <SelectInputField
              className={styles.input}
              label="PUREWEB CONFIGURATION"
              required
              placeholder="Select a PureWeb configuration"
              getOptionLabel={(option) => option.name}
              defaultOption={purewebConfigurations.find((config) => config._id === page.purewebConfiguration?._id)}
              options={purewebConfigurations}
              onChange={(o) => setPage({ ...page, purewebConfiguration: o._id })}
            />
            <TextInputField
              className={styles.input}
              label="MAP NAME"
              placeholder="Map Name"
              value={page.mapName}
              onChange={(e) => setPage({ ...page, mapName: e.target.value })}
            />
            <Typography variant="h6">
              LOADING SCREEN
            </Typography>
            <div className={styles.upload}>
              {previewElement}
              <UploadButton
                className={styles.button}
                labelClassName={styles.label}
                id={`file-upload-${pageId}`}
                accept="image/*, .mp4"
                onChange={(e) => readURL(e.target, (file: any, fileType: string) => uploadPreloadSource(file, fileType))}
                color="secondary"
              >
                Upload
              </UploadButton>
            </div>
          </div>
          {
            pageId &&
            <SaveFooter
              saveClicked={() => handleSave()}
              updatedAt={page.updatedAt?.toString() || ''}
            />
          }
        </>
      }
    </MainContent>
  );
}
