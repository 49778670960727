import React, { useEffect, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import Dropdown from '../../../../Visitor/components/Dropdown/Dropdown';
import useVideoContext from '../useVideoContext';
import { SELECTED_VIDEO_INPUT_KEY } from '../VideoContextProvider';
import styles from './VideoInputList.module.scss';

export default function VideoInputList() {
  const { localVideoTrack } = useVideoContext();

  const [localVideoInputDeviceId, setLocalVideoInputDeviceId] = useState<string | undefined>(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY) || undefined,
  );
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);

  const videoContainerRef = useRef<any>();

  useAsyncEffect(async () => {
    await navigator.mediaDevices.getUserMedia({ video: true });
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    setVideoInputDevices(mediaDevices.filter((device) => device.kind === 'videoinput'));
  }, []);

  useEffect(() => {
    if (localVideoInputDeviceId && localVideoInputDeviceId !== window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)) {
      window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, localVideoInputDeviceId);
    }
  }, [localVideoInputDeviceId]);

  useEffect(() => {
    if (localVideoTrack) {
      localVideoTrack.attach(videoContainerRef.current);
      const mediaStream = localVideoTrack.mediaStreamTrack;
      setLocalVideoInputDeviceId(mediaStream.getSettings().deviceId);
    }
  }, [localVideoTrack]);

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    if (localVideoTrack) {
      localVideoTrack.restart({
        deviceId: { exact: newDeviceId },
      });
    }
  }

  return (
    <div className={styles['video-devices']}>
      {localVideoTrack && (
        <video className={styles['video-track']} ref={videoContainerRef} />
      )}
      {videoInputDevices.length > 1 ? (
        <Dropdown
          title="Video Input"
          options={videoInputDevices.map((device) => (device.label))}
          onValueChange={(value: string) => {
            const newDevice = videoInputDevices.find((device) => device.label === value);
            if (newDevice) {
              replaceTrack(newDevice.deviceId);
            }
          }}
          value={videoInputDevices.find((device) => device.deviceId === localVideoInputDeviceId)?.label || ''}
          showRequired={false}
        />
      ) : (
        <>
          <div className={styles['input-label']}>
            Video Input
          </div>
          <div>{localVideoTrack?.mediaStreamTrack.label || 'No Camera Detected'}</div>
        </>
      )}
    </div>
  );
}
