import { ReactNode, useState } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import {
  TaskModal,
  TaskModalTitle,
  TaskModalContent,
  TaskModalActions,
  Button,
  ConfirmationModal,
  List,
  Select,
  Typography,
  TextInputField,
} from '@pixelcanvas/ui';

import IUser from 'src/interfaces/IUser';

import WorldMapRequest, { WorldMapResponse } from 'src/features/Dashboard/interfaces/WorldMap';
import ISpace, { SpaceResponse } from 'src/features/Dashboard/interfaces/ISpace';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';

import { ReactComponent as ClockIcon } from '../../../../../../assets/svg/clock-solid.svg';
import { ReactComponent as CalendarIcon } from '../../../../../../assets/svg/calendar-dates.svg';
import { ReactComponent as SignalIcon } from '../../../../../../assets/svg/signal-stream.svg';

import styles from './WorldMapModal.module.scss';

type Props = {
  open: boolean;
  onSave: (map: WorldMapRequest) => void;
  onClose: () => void;
  spaces: SpaceResponse[];
  editedMap?: WorldMapResponse;
};

export default function WorldMapModal({
  open,
  onSave,
  onClose,
  spaces,
  editedMap,
}: Props) {
  const [nickname, setNickname] = useState(editedMap?.nickname ?? '');
  const [addedSpaces, setAddedSpaces] = useState<SpaceResponse[]>(editedMap?.spaces ?? []);
  const [spaceToRemove, setSpaceToRemove] = useState<SpaceResponse>();

  const unaddedSpaces = spaces.filter((space) => !addedSpaces.map((s) => s._id).includes(space._id));
  const canSave = Boolean(nickname && addedSpaces.length > 0);

  return (
    <TaskModal open={open} onClose={onClose} className={styles.modal}>
      <TaskModalTitle onClose={() => onClose()}>
        WORLD MAP: {editedMap?.nickname ?? 'NEW MAP'}
      </TaskModalTitle>
      <TaskModalContent>
        <Section title="Details">
          <MetaversesInfo metaverses={editedMap?.metaverses} />
          <TextInputField
            label="NICKNAME"
            required
            maxLength={40}
            onChange={(e) => setNickname(e.target.value)}
            value={nickname}
          />
        </Section>
        <Section title="Connected Models">
          <ModelSelection
            options={unaddedSpaces}
            onChange={(option) => setAddedSpaces((existingAddedSpaces) => [...existingAddedSpaces, option])}
          />
          <Typography className={styles.addedModelsHeader} variant="h5" emphasis="low">
            ADDED MODELS
          </Typography>
          <List
            data={addedSpaces}
            getItemValue={(item) => item.name}
            getUniqueId={(item) => item.tokenUri}
            onDelete={setSpaceToRemove}
          />
        </Section>
        <ConnectedMetaversesSection metaverses={editedMap?.metaverses} />
      </TaskModalContent>
      <TaskModalActions className={styles.actions}>
        <CreatedBy createdAt={editedMap?.createdAt} user={editedMap?.createdBy} />
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button disabled={!canSave} onClick={() => canSave && onSave({ nickname, spaces: addedSpaces.map((m) => m._id) })}>Save</Button>
      </TaskModalActions>
      {
        spaceToRemove &&
        <ConfirmationModal
          title="ARE YOU SURE YOU WANT TO REMOVE MODEL?"
          description={`Remove ${spaceToRemove?.name} from this world map.`}
          open={Boolean(spaceToRemove)}
          onConfirm={() => {
            setAddedSpaces(addedSpaces.filter((space) => space !== spaceToRemove));
            setSpaceToRemove(undefined);
          }}
          onClose={() => setSpaceToRemove(undefined)}
        />
      }
    </TaskModal>
  );
}

type SectionProps = {
  className?: string;
  title: string;
  children?: ReactNode;
};

function Section({ className, title, children }: SectionProps) {
  return (
    <div className={clsx(className, styles.section)}>
      <h4 className={styles.header}>{title}</h4>
      {children}
    </div>
  );
}

type ModelSelectionProps = {
  options: any[];
  onChange: (option: any) => void;
};

function ModelSelection({ options, onChange }: ModelSelectionProps) {
  const [option, setOption] = useState<ISpace>();
  const [open, setOpen] = useState(false);

  const handleAdd = () => {
    onChange(option);
    setOption(undefined);
  };

  return (
    <div className={styles.selection}>
      <Typography variant="h6">SELECT WHICH MODEL TO ADD TO THIS MAP*</Typography>
      <div className={styles.controls}>
        <Select
          className={styles.select}
          options={options}
          placeholder="Select"
          getOptionLabel={(o) => o.name}
          getUniqueId={(item) => item.tokenUri}
          option={option}
          onChange={(o) => setOption(o)}
          open={open}
          onClose={() => setOpen(false)}
          onClick={() => setOpen(true)}
        />
        <Button
          className={styles.button}
          color="tertiary"
          disabled={!option}
          onClick={handleAdd}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

type MetaversesInfoProps = {
  metaverses?: MetaverseResponse[];
};

function MetaversesInfo({ metaverses }: MetaversesInfoProps) {
  const publishedMetaverses = metaverses?.filter((metaverse) => metaverse.published).length;
  const totalMetaverses = metaverses?.length;
  return (
    <div className={styles.eventInfo}>
      <SignalIcon className={styles.icon} />
      <Typography className={styles.info} variant="h7">
        LIVE METAVERSES: {publishedMetaverses ?? '-'}
      </Typography>
      <CalendarIcon className={styles.icon} />
      <Typography className={styles.info} variant="h7">
        TOTAL METAVERSES: {totalMetaverses ?? '-'}
      </Typography>
    </div>
  );
}

type ConnectedMetaversesSectionProps = {
  metaverses?: MetaverseResponse[];
};

function ConnectedMetaversesSection({ metaverses }: ConnectedMetaversesSectionProps) {
  const publishedMetaverses = metaverses?.filter((metaverse) => metaverse.published);
  const publishedMetaverseNames = publishedMetaverses?.map((metaverse) => metaverse.name)?.join(', ');

  const unpublishedMetaverses = metaverses?.filter((metaverse) => !metaverse.published);
  const unpublishedMetaverseNames = unpublishedMetaverses?.map((metaverse) => metaverse.name)?.join(', ');

  return (
    <Section title="Connected Metaverses" className={styles.events}>
      <Typography variant="h7" className={styles.heading}>
        {typeof publishedMetaverses?.length === 'number' ? `${publishedMetaverses.length} ` : ''}LIVE METAVERSE(S):
      </Typography>
      <Typography variant="body2" emphasis="high" className={styles.text}>
        {publishedMetaverseNames || 'There are no live metaverses at this time'}
      </Typography>
      <Typography variant="h7" className={styles.heading}>
        {typeof unpublishedMetaverseNames?.length === 'number' ? `${unpublishedMetaverseNames.length} ` : ''}UNPUBLISHED METAVERSE(S):
      </Typography>
    </Section>
  );
}

type CreatedByProps = {
  createdAt?: DateTime;
  user?: IUser;
};

function CreatedBy({ createdAt, user }: CreatedByProps) {
  if (!user || !createdAt) {
    return (
      <Typography className={styles.created} variant="subtitle4" emphasis="low">
        Created: -
      </Typography>
    );
  }
  return (
    <Typography className={styles.created} variant="subtitle4" emphasis="low">
      Created: {createdAt.toLocaleString(DateTime.DATE_FULL)}, by {user.displayName}
    </Typography>
  );
}
