import clsx from 'clsx';
import { Typography } from '@pixelcanvas/ui';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import styles from './TooltipButton.module.scss';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Placement } from '@popperjs/core';

type HoverCardProps = {
  title: string;
  description: string;
  url: string;
  placement: Placement;
  referenceElement: Element | null;
};

const HoverCard = ({
  title, description, url, placement, referenceElement
}: HoverCardProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement
  });
  return (
    <Portal>
      <div
        ref={setPopperElement}
        style={popperStyles.popper}
        className={styles.tooltip}
        {...attributes.popper}
      >
        <div className={clsx(styles.card, styles[placement])}>
          <div className={styles['card-top']}>
            <img className={styles.image} src={url} />
          </div>
          <div className={styles['card-bottom']}>
            <Typography className={styles.title} variant="subtitle3" emphasis="high">{title}</Typography>
            <Typography className={styles.description} variant="body1" emphasis="high">{description}</Typography>
          </div>
        </div>
      </div>
    </Portal>
  )
};

interface TooltipButtonProps {
  className?: string;
  title: string;
  description: string;
  sourceUrl: string;
  placement: Placement;
}

export default function TooltipButton({
  className,
  title,
  description,
  sourceUrl,
  placement,
}: TooltipButtonProps) {
  const [showHoverCard, setShowHoverCard] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);

  return (
    <div className={clsx(className, styles.tooltip)}>
      <FontAwesomeIcon
        ref={setReferenceElement}
        icon={faQuestionCircle}
        className={styles.icon}
        onMouseOver={() => setShowHoverCard(true)}
        onMouseLeave={() => setShowHoverCard(false)}
      />
      {
        showHoverCard &&
        <HoverCard
          title={title}
          description={description}
          url={sourceUrl}
          placement={placement}
          referenceElement={referenceElement}
        />
      }
    </div>
  );
}
