import { ToggleSwitch, TooltipButton } from '@pixelcanvas/ui';
import clsx from 'clsx';

import { MetaverseResponse } from 'src/interfaces/IMetaverse';

import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { usePrivacySettingsUpdate } from '../EditMetaversePrivacySettingsPage/EditMetaversePrivacySettingsPage';

import styles from './EditMetaversePrivacyGeneralSettings.module.scss';

export default function EditMetaversePrivacyGeneralSettings() {
  const { metaverse } = useCurrentMetaverse();

  if (!metaverse) return null;

  return <PrivacySettings metaverse={metaverse} />;
}

interface PrivacySettingsProps {
  metaverse: MetaverseResponse;
}

function PrivacySettings({ metaverse }: PrivacySettingsProps) {
  const {
    inviteOnly,
    setInviteOnly,
    // privateLandingPage,
    // setPrivateLandingPage,
    setAutosave,
  } = usePrivacySettingsUpdate();

  setAutosave(false);

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        You can choose to make your metaverse invitation-only and/or choose to make your landing page private. If you do not restrict access to invitation-only and the landing page, your metaverse will be open to the public and anyone can register to attend.
      </div>
      <div className={styles.setting}>
        <div className={styles.label}>Invitation-only Metaverse</div>
        <TooltipButton
          classes={{ icon: styles.tooltip }}
          placement="right"
        >
          Only attendees that you invite are allowed to register for this metaverse.
        </TooltipButton>
        <ToggleSwitch
          checked={inviteOnly}
          onChange={setInviteOnly}
        />
      </div>
      {/* {
        metaverse.landingPage &&
        <div className={styles.setting}>
          <div className={styles.label}>Private Landing Page</div>
          <TooltipButton
            classes={{ icon: styles.tooltip }}
            placement="right"
          >
            Only attendees that you invite are allowed to view the landing page.<br />This means that your metaverse is not discoverable by the public.
          </TooltipButton>
          <ToggleSwitch
            checked={privateLandingPage}
            onChange={setPrivateLandingPage}
          />
        </div>
      } */}
    </div>
  );
}
