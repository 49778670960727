import { useEffect, useState, useRef } from 'react';

import {
  dateTimeToUTC, formDateTime, isoDateFromUTC, isoTimeFromUTC,
} from '../../../../utils/TimeHelper';

import styles from './DateTime.module.scss';

interface DateTimeProps {
  dateTime?: string;
  onChange?: (datetime: string) => void;
  name?: string;
  timezone?: string;
}

export default function DateTime(props: DateTimeProps) {
  const { dateTime, onChange, timezone = 'UTC' } = props;
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (dateTime) {
      setDate(isoDateFromUTC(dateTime, timezone));
      const newTime = isoTimeFromUTC(dateTime, timezone, true);
      setTime(newTime);
    } else {
      setDate('');
      setTime('');
    }
  }, [dateTime, timezone]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (onChange) {
      const newDateTime = formDateTime(date, time);
      if (newDateTime) {
        onChange(dateTimeToUTC(newDateTime, timezone));
      }
    }
  }, [date, time]);

  return (
    <div className={styles['date-time']}>
      <input
        className={styles['datetime-input']}
        type="date"
        max="9999-12-31"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
      <input
        className={styles['datetime-input']}
        type="time"
        value={time}
        onChange={(e) => setTime(e.target.value)}
      />
    </div>
  );
}
