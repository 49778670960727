import React, { ReactNode } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { SideNavExpandableButton } from '@pixelcanvas/ui';

type Props = {
  icon: React.ReactElement;
  label: string;
  to: string;
  end?: boolean;
  children: ReactNode;
};

export default function SideNavLink({ icon, label, to, end = false, children }: Props) {
  const match = useMatch({ path: to, end });
  const navigate = useNavigate();
  const handleClick = () => navigate(to);
  return (
    <SideNavExpandableButton
      icon={icon}
      label={label}
      selected={!!match}
      onClick={handleClick}
    >
      {children}
    </SideNavExpandableButton>
  );
}
