import { Button, MainContent, Page, PageContent } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LandingHeader from 'src/components/LandingHeader/LandingHeader';
import { useUpdateMe } from 'src/queries/me';

import { dataLayer } from '../../../../utils/DataLayerUtil';
import ProfileSignup from '../../components/ProfileSignup/ProfileSignup';

import styles from './OnboardingPage.module.scss';

export function OnboardingPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const userProfile = useSelector((state: any) => state.userProfile, shallowEqual);

  const { mutate: updateMe } = useUpdateMe();

  useEffect(() => {
    document.title = 'Pixel Canvas - Onboarding';
  }, []);

  const steps = [
    <ProfileSignup key="step-two" showRequiredFields={showRequiredFields} />,
  ];

  async function completeUserProfile() {
    try {
      if (!userProfile.displayName) {
        setShowRequiredFields(true);
        return;
      }
      updateMe({ ...userProfile, onboarded: true });
      dataLayer.push({ event: 'onboarding_complete' });
      navigate('/metaverses');
    } catch (e) {
      console.error(e);
    }
  }

  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;

  return (
    <Page>
      <PageContent className={styles.pageContent}>
        <LandingHeader className={styles.header} />
        <MainContent className={styles.mainContent} variant="default" background="transparent">
          <div className={styles.container}>
            {steps.length > 1 && <StepsProgress step={step} of={steps.length} />}
            <div className={styles.step}>
              {steps[step]}
            </div>
          </div>
          <div className={styles.nav}>
            {!isFirstStep && (
              <Button color="tertiary" onClick={() => setStep(step - 1)}>
                Back
              </Button>
            )}
            {!isLastStep && (
              <Button color="primary" onClick={() => setStep(step + 1)}>
                Next
              </Button>
            )}
            {isLastStep && (
              <Button color="primary" onClick={completeUserProfile}>
                Complete
              </Button>
            )}
          </div>
        </MainContent>
      </PageContent>
    </Page>
  );
}

interface StepsProgressProps {
  step: number;
  of: number;
}

function StepsProgress({ step, of }: StepsProgressProps) {
  return (
    <div className={styles.progress}>{`Step ${step + 1} of ${of}`}</div>
  );
}
