import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@pixelcanvas/ui';
import clsx from 'clsx';

import heroImage from '../../assets/images/hero-image.png';
import landingBg from '../../assets/images/landing-bg.png';
import usePxAuthContext from '../../authentication/usePxAuthContext';
import PxFooter from '../../components/PxFooter/PxFooter';
import AuthModal from '../../features/Visitor/components/Authentication/StandardAuthModal';
import { dataLayer } from '../../utils/DataLayerUtil';

import styles from './LandingPage.module.scss';

export function LandingPage() {
  const { user } = usePxAuthContext();
  const userProfile = useSelector((state) => state.userProfile, shallowEqual);
  const navigate = useNavigate();
  const clientSuggestions = ['INNOVATION', 'IMMERSION', 'AGENCIES', 'PRODUCT DEMOS', 'SKOS'];
  const [clientSuggestionText, setClientSuggestionText] = useState('');
  const clientTextRef = useRef(clientSuggestionText);
  let curTextIndex = 0;
  let curSuggestionIndex = 0;
  const [authModalOpen, setAuthModalOpen] = useState(false);

  useEffect(() => {
    updateClientText();
  }, []);

  useEffect(() => {
    clientTextRef.current = clientSuggestionText;
  }, [clientSuggestionText]);

  function updateClientText() {
    const curSuggestionText = clientSuggestions[curSuggestionIndex];
    if (curTextIndex < curSuggestionText.length) {
      // Add next character
      setClientSuggestionText(`${clientTextRef.current}${curSuggestionText.charAt(curTextIndex)}`);
      curTextIndex++;
      if (curTextIndex !== curSuggestionText.length) {
        setTimeout(updateClientText, 75);
      } else {
        setTimeout(updateClientText, 1500);
      }
    } else {
      setClientSuggestionText('');
      curTextIndex = 0;
      if (curSuggestionIndex !== clientSuggestions.length - 1) {
        curSuggestionIndex++;
      } else {
        curSuggestionIndex = 0;
      }
      setTimeout(updateClientText, 50);
    }
  }

  useEffect(() => {
    if (user) {
      if (user.email_verified === false) {
        navigate('/verification');
      }
    }
  }, [user]);

  useEffect(() => {
    if (!userProfile._id) return;
    if (userProfile.onboarded) {
      navigate('/metaverses');
    } else {
      dataLayer.push({ event: 'onboarding_begin' });
      navigate('/onboarding');
    }
  }, [userProfile]);

  return (
    <>
      <div className={styles['landing-bg']} style={{ backgroundImage: `url(${landingBg})` }} alt="background" />
      <div className={styles['landing-page']}>
        <div className={styles['landing-title']}>
          A Metaverse Platform Made For
        </div>
        <div className={clsx(styles['client-suggestion'], styles.typewriter)}>
          {clientSuggestionText}
        </div>
        <Button color="primary" className={styles['get-started']} size="lg" onClick={() => setAuthModalOpen(true)}>
          Get Started
        </Button>
        <img className={styles['hero-image']} src={heroImage} alt="hero" />
      </div>
      <PxFooter />
      <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
    </>
  );
}
