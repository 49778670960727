import { useContext } from 'react';
import { RoomsSocketContext } from './RoomsSocketContextProvider';

export default function useRoomsSocket() {
  const context = useContext(RoomsSocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketContextProvider');
  }
  return context;
}
