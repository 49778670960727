import { Button, Header, HeaderTitle, NotificationBanner } from '@pixelcanvas/ui';
import { CSSProperties, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';

import { IToastMessage, useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import UsageDisplay from 'src/components/UsageDisplay/UsageDisplay';
import UserPill from 'src/components/UserPill/UserPill';
import { useUpdateMetaverse } from 'src/queries/metaverse';

import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { selectShowUsage } from '../../redux/editMetaverse';
import MetaversePreviewLink from '../MetaversePreviewLink/MetaversePreviewLink';
import PublishMetaverseConfirmModal from '../PublishMetaverseConfirmModal';
import UnpublishMetaverseConfirmModal from '../UnpublishMetaverseConfirmModal';

import styles from './MetaverseHeader.module.scss';

interface MetaverseHeaderProps {
  title: string;
}

export default function MetaverseHeader({ title }: MetaverseHeaderProps) {
  const { toasts, addToast, removeToast } = useToastMessageContext();
  const { metaverse } = useCurrentMetaverse();
  const showUsage = useSelector(selectShowUsage);

  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState<IToastMessage | null>(toasts[0]);
  const [displayedToast, setDisplayedToast] = useState<IToastMessage | null>(null);

  const [referenceElment, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    referenceElment, popperElement,
    {
      strategy: 'fixed',
      placement: 'bottom',
      modifiers: [{ name: 'offset' }],
    },
  );

  useEffect(() => {
    setToast(toasts[0]);
  }, [toasts]);

  useEffect(() => {
    if (displayedToast !== toast) {
      setShowToast(false);
      const timeout = setTimeout(() => setDisplayedToast(toast), 200);
      return () => clearTimeout(timeout);
    }
    setShowToast(true);
    return () => {};
  }, [displayedToast, toast]);

  const [confirmPublish, setConfirmPublish] = useState(false);
  const [confirmUnpublish, setConfirmUnpublish] = useState(false);

  const {
    mutate: updateMetaverse,
    isSuccess,
    reset,
    data: updatedMetaverse,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess) return;
    reset();
    if (updatedMetaverse?.published) addToast('Metaverse published');
    if (updatedMetaverse && !updatedMetaverse.published) addToast('Metaverse unpublished');
  }, [isSuccess]);

  const published = !!metaverse?.published;

  const handlePublishClick = () => {
    setConfirmPublish(true);
  };

  const handleUnpublishClick = () => {
    setConfirmUnpublish(true);
  };

  const handleConfirmPublishClick = () => {
    updateMetaverse({ published: true });
    setConfirmPublish(false);
  };

  const handleConfirmUnpublishClick = () => {
    updateMetaverse({ published: false });
    setConfirmUnpublish(false);
  };

  return (
    <>
      <Header ref={setReferenceElement} className={styles.header}>
        <HeaderTitle className={styles.title}>{title}</HeaderTitle>
        {showUsage && <UsageDisplay className={styles.usage} />}
        <MetaversePreviewLink className={styles.preview} />
        {!published && <Button className={styles.publish} color="primary" onClick={handlePublishClick}>Publish</Button>}
        {published && <Button className={styles.publish} color="tertiary" onClick={handleUnpublishClick}>Unpublish</Button>}
        <UserPill className={styles.user} />
      </Header>
      <PublishMetaverseConfirmModal
        name={metaverse?.name ?? ''}
        open={confirmPublish}
        onClose={() => setConfirmPublish(false)}
        onConfirm={handleConfirmPublishClick}
      />
      <UnpublishMetaverseConfirmModal
        name={metaverse?.name ?? ''}
        open={confirmUnpublish}
        onClose={() => setConfirmUnpublish(false)}
        onConfirm={handleConfirmUnpublishClick}
      />
      <Portal>
        <NotificationBanner
          ref={setPopperElement}
          show={Boolean(displayedToast) && showToast}
          type={displayedToast?.type}
          onClose={() => displayedToast && removeToast(displayedToast)}
          style={{ ...popperStyles.popper } as CSSProperties}
          {...attributes.popper}
        >
          {displayedToast && displayedToast.message}
        </NotificationBanner>
      </Portal>
    </>
  );
}
