import clsx from 'clsx';

interface Props{
  inputClassName: string,
  buttonText: any,
  onClick: Function,
  decoration?: any;
}

export default function VisitorPrimaryButton({ inputClassName, buttonText, onClick, decoration }: Props) {
  return (
    <div
      className={clsx(inputClassName)}
      onClick={() => onClick()}
    >
      {buttonText} {decoration}
    </div>
  );
}
