import { TaskModal, TaskModalContent, TaskModalTitle } from '@pixelcanvas/ui';
import { useOrganizationContext } from '../../context/OrganizationContextProvider';

import OrganizationSelect from 'src/features/Dashboard/components/OrganizationSelect/OrganizationSelect';
import styles from './OrganizationSwitchModal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function OrganizationSwitchModal({ open, onClose }: Props) {
  const { currentOrganization, organizations, changeCurrentOrganization } = useOrganizationContext();

  const handleOrganizationChange = (organizationId: string) => {
    changeCurrentOrganization(organizationId);
    onClose();
  };

  const organizationList = !organizations ? null :
    organizations.map((organization) => (
      <OrganizationSelect
        key={organization._id}
        className={styles.button}
        selected={organization._id === currentOrganization?._id}
        organization={organization}
        onClick={() => handleOrganizationChange(organization._id)}
      />
    ));

  return (
    <TaskModal open={open} onClose={onClose} className={styles.modal}>
      <TaskModalTitle onClose={onClose}>
        Switch Organization
      </TaskModalTitle>
      <TaskModalContent className={styles.content}>
        {organizationList}
      </TaskModalContent>
    </TaskModal>
  );
}
