import clsx from 'clsx';
import Button from '../../Button';

import VisitorModal from '../../VisitorModal/VisitorModal';
import VisitorModalActions from '../../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../../VisitorModalContent/VisitorModalContent';

import styles from './UsageWarningModal.module.scss';

const supportLink = window.env.REACT_APP_SUPPORT_MAILTO_LINK ?? 'mailto:support@pixelcanvas.com';

const ATTENDEE_MSG = 'The owner of this 3D space has run out of 3D streaming time. \n\nPlease come back later.';

function OwnerMessage() {
  return (
    <>
      Your account has run out of 3D streaming time. You and your metaverse visitors are not able to access the 3D platform without additional 3D streaming time.
      <br /><br />To request more, or if you have any other questions, please contact <a target="_blank" rel="noopener noreferrer" href="mailto:support@pixelcanvas.com">support@pixelcanvas.com</a>
    </>
  );
}

interface Props {
  isOwner: boolean;
  open: boolean;
  onClose: Function;
}

export default function UsageWarningModal({
  isOwner, open, onClose,
}: Props) {
  const handleConfirm = () => {
    window.open(supportLink, '_blank');
    onClose();
  };
  return (
    <VisitorModal
      className={styles.modal}
      open={open}
      onClose={() => onClose()}
    >
      <VisitorModalContent>
        <div className={styles.title}>
          Out Of 3D Streaming Time
        </div>
        <div className={styles.subtext}>
          {isOwner ? <OwnerMessage /> : ATTENDEE_MSG}
        </div>
      </VisitorModalContent>
      <VisitorModalActions className={clsx(styles['actions-container'], styles['actions-container'])}>
        <Button
          className={styles.cancel}
          color="tertiary"
          onClick={() => { onClose(); }}
        >
          Close
        </Button>
        {
          isOwner &&
          <Button
            className={styles.confirm}
            color="primary"
            onClick={handleConfirm}
          >
            Request More Time
          </Button>
        }

      </VisitorModalActions>
    </VisitorModal>
  );
}
