import { faEarthAmericas, faGear, faHandWave, faShirt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';
import { selectBrowserHUDEnabled, selectEmojiCapabilityAvailable, setBrowserHUDEnabled, setEmojiOpened, setOpenAvatarModal, setOpenServerBrowser, setOpenSettings, toggleEmojiOpened } from '../redux/hud';
import PointsDisplay from './PointsDisplay';

import styles from './HUD.module.scss';

interface HUDProps {
  className?: string;
  globeButtonEnabled?: boolean;
}

export default function HUD({
  className,
  globeButtonEnabled = true,
}: HUDProps) {
  const dispatch = useDispatch();
  const { descriptor } = usePixelStreamingContext();
  const browserHUDEnabled = useSelector(selectBrowserHUDEnabled);
  const emojiCapabilityAvailable = useSelector(selectEmojiCapabilityAvailable);

  useEffect(() => {
    if (!descriptor) return () => { };
    const listener = ({ enabled }: { enabled: boolean }) => {
      dispatch(setBrowserHUDEnabled(enabled));
    };
    descriptor.on('settings:browser-hud', listener);
    return () => descriptor.off('settings:browser-hud', listener);
  }, [descriptor]);

  if (!browserHUDEnabled) return null;

  const handleGlobeClick = () => dispatch(setOpenServerBrowser(true));
  const handleSettingsClick = () => dispatch(setOpenSettings(true));
  const handleAvatarClick = () => dispatch(setOpenAvatarModal(true));
  const handleClickEmojis = () => dispatch(toggleEmojiOpened());

  return (
    <div className={clsx(className, styles.container)}>
      <PointsDisplay className={styles.pointsContainer} />
      <div className={styles.buttons}>
        {globeButtonEnabled && (
          <button className={styles.globe} type="button" onClick={handleGlobeClick}>
            <FontAwesomeIcon icon={faEarthAmericas} />
          </button>
        )}
        <button type="button" onClick={handleSettingsClick}>
          <FontAwesomeIcon icon={faGear} />
        </button>
        {emojiCapabilityAvailable && (
          <button type="button" onClick={handleClickEmojis}>
            <FontAwesomeIcon icon={faHandWave} />
          </button>
        )}
        <button type="button" onClick={handleAvatarClick}>
          <FontAwesomeIcon icon={faShirt} />
        </button>
      </div>

    </div>
  );
}
