import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { WorldMapResponse } from 'src/features/Dashboard/interfaces/WorldMap';
import api from '../../../services/api';

export const queryKey = 'metaverses';

export const useMetaverseWorldMaps = (metaverseId: string) => useQuery<WorldMapResponse[]>(
  [queryKey, metaverseId, 'worldmaps'],
  () => api
    .get(`/api/v2/metaverses/${metaverseId}/maps`)
    .then((res) => res.data)
    .then((data) => data.map((m: any) => ({
      ...m,
      createdAt: DateTime.fromISO(m.createdAt),
      updatedAt: DateTime.fromISO(m.updatedAt),
    }))),
  { enabled: !!metaverseId },
);

export const useMetaverseWorldMap = (metaverseId: string, mapId: string) => useQuery<WorldMapResponse>(
  [queryKey, metaverseId, 'worldmaps', mapId],
  () => api
    .get(`/api/v2/metaverses/${metaverseId}/maps/${mapId}`)
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      createdAt: DateTime.fromISO(data.createdAt),
      updatedAt: DateTime.fromISO(data.updatedAt),
    })),
  { enabled: !!mapId && !!metaverseId },
);
