import WideButton from '../WideButton';

import icon from '../../../../../assets/images/email.png';
import { useAuthModalContext } from '../AuthModalContextProvider';

import styles from './EmailLoginWideButton.module.scss';

type Props = {
  className?: string;
};

export default function EmailLoginWideButton({
  className,
}: Props) {
  const { setOpenEmailModal } = useAuthModalContext();
  return (
    <WideButton
      classes={{ button: className, icon: styles.icon }}
      icon={icon}
      onClick={() => setOpenEmailModal(true)}
      name="email-login"
    >
      Email
    </WideButton>
  );
}
