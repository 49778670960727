import DOMPurify from 'dompurify';

const sanitizeOptions = {
  ALLOWED_TAGS: ['a'],
  allowedAttribALLOWED_ATTRutes: ['href', 'target'],
};

export const sanitizeText = (dirty: string) => DOMPurify.sanitize(dirty, sanitizeOptions);

export const urlParse = (str: string) => str.replace(/(https?:\/\/[^\s]+)/g, (url) => `<a target="_blank" href=${url}>${url}</a>`);

export const isValidUrl = (str: string) => /(https?:\/\/[^\s]+)/g.test(str);

export const isValidEmail = (str: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

export function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
  );
}
