import { Table } from '@pixelcanvas/ui';

import LeaderboardSlot from 'src/interfaces/LeaderboardSlot';

type Props = {
  data: LeaderboardSlot[]
};

export default function Leaderboard({ data }: Props) {
  const columns = [
    {
      label: 'RANK',
      field: 'rank',
      width: '6ch',
    },
    {
      label: 'DISPLAY NAME',
      field: 'user.displayName',
      width: 'auto',
    },
    {
      label: 'EMAIL',
      field: 'user.email',
      width: 'auto',
    },
    {
      label: 'POINTS',
      field: 'points',
      width: '20ch',
    },
  ];
  return (
    <Table
      columns={columns}
      data={data}
      keyField="_id"
    />
  );
}
