import { SpaceResponse } from 'src/features/Dashboard/interfaces/ISpace';
import PageType from '../enums/PageType';
import SourceType from '../enums/SourceType';
import { ISpace, MetaverseResponse } from './IMetaverse';
import { PureWebConfigurationResponse } from './IPurewebConfiguration';

export interface IPage {
  _id?: string;
  metaverseId: string;
  title?: string;
  subtitle?: string;
  type: PageType;
  backgroundImage?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PageResponse extends IPage {
  _id: string;
}

export interface PureWebPage extends IPage {
  type: PageType.PureWeb3D;
  purewebConfiguration: PureWebConfigurationResponse;
  mapName?: string;
  preloadSource: string;
  preloadSourceType: SourceType;
}

export function isPureWebPage(page: IPage): page is PureWebPage {
  return page.type === PageType.PureWeb3D;
}

export interface WorldMap3DPage extends IPage {
  type: PageType.WorldMap3D;
  worldMap: string;
  preloadSource: string;
  preloadSourceType: SourceType;
}

export function isWorldMap3DPage(page: IPage): page is WorldMap3DPage {
  return page.type === PageType.WorldMap3D;
}

export interface Space3DPage extends IPage {
  type: PageType.Space3D;
  spaceId: string;
  space: SpaceResponse;
  preloadSource: string;
  preloadSourceType: SourceType;
}

export function isSpace3DPage(page: IPage): page is Space3DPage {
  return page.type === PageType.Space3D;
}

export interface IMetaverse3DPage extends IPage {
  type: PageType.Metaverse3D;
  metaverse: MetaverseResponse;
  spaceId: string;
  space: ISpace;
}

export function isMetaverse3DPage(page: IPage): page is IMetaverse3DPage {
  return page.type === PageType.Metaverse3D;
}
