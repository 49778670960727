import { useMutation, useQueries, useQueryClient } from 'react-query';

import IUser from 'src/interfaces/IUser';
import api from '../services/api';

export const queryKey = 'users';

export const userKeys = {
  all: [queryKey] as const,
  lists: () => [queryKey, 'list'] as const,
  list: (filters: string | undefined) => [...userKeys.lists(), { filters }] as const,
  items: () => [queryKey, 'item'] as const,
  item: (id: string | undefined) => [...userKeys.items(), id] as const,
};

export function useUsers(userIds: string[] = []) {
  return useQueries(userIds.map((userId) => ({
    queryKey: [queryKey, userId],
    queryFn: () => api
      .get<IUser>(`/api/v2/users/${userId}`)
      .then((res) => res.data),
    enabled: !!userId,
  })));
}

export function useUpdateUser(userId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    (user: Partial<IUser>) => api
      .patch(`/api/v2/users/${userId}`, user)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.item(userId));
      },
    },
  );
}
