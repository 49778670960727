import { StreamerStatus } from '@pureweb/platform-sdk';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import PureWebAvatarModal from 'src/components/ReadyPlayerMe/PureWebAvatarModal';
import { SpaceResponse } from 'src/features/Dashboard/interfaces/ISpace';
import { useGetSpace } from 'src/features/Dashboard/queries/spaces';
import Background from 'src/features/PixelStreaming/components/Background/Background';
import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import LaunchContainer from 'src/features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import LoadingInfo from 'src/features/PixelStreaming/components/LoadingInfo/LoadingInfo';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import TutorialOverlay from 'src/features/PixelStreaming/components/TutorialOverlay/TutorialOverlay';
import useDebugEmitUIInteract from 'src/features/PixelStreaming/hooks/useDebugEmitUIInteraction';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinGameSession from 'src/features/PixelStreaming/hooks/useJoinGameSession';
import useJoinSpace from 'src/features/PixelStreaming/hooks/useJoinSpace';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import { selectOpenServerBrowser, setOpenServerBrowser } from 'src/features/PixelStreaming/redux/hud';
import EmbeddedView from 'src/features/PureWeb/components/EmbeddedView';
import PureWebAudioComponent from 'src/features/PureWeb/components/PureWebAudioComponent';
import PureWebContainer from 'src/features/PureWeb/components/PureWebContainer';
import PureWebContextProvider, { usePureWebContext } from 'src/features/PureWeb/contexts/PureWebContextProvider';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import UsageContextProvider, { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import ServerBrowserContainer from 'src/features/Visitor/components/GameliftServerBrowserModal/ServerBrowserContainer';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { WorldMap3DPage as IWorldMap3DPage } from 'src/interfaces/IPage';
import { useGetRandomGameLiftSession } from 'src/queries/gamelift';
import { selectMetaverseId } from 'src/redux/metaverse';

import UploadContentModalContainer from '../../components/UploadContentModal/UploadContentModalContainer';
import WorldMapScreen from '../../components/WorldMapScreen/WorldMapScreen';
import PointsDisplay from 'src/features/PixelStreaming/components/PointsDisplay';

interface WorldMap3DPageProps {
  page: IWorldMap3DPage;
  metaverse: MetaverseResponse;
  gameSessionId: string;
  space: SpaceResponse | undefined;
}

function WorldMap3DPage({ page, metaverse, space, gameSessionId }: WorldMap3DPageProps) {
  const metaverseId = metaverse._id;

  const { hasUsage } = useUsageContext();

  const {
    launch,
    isReady,
    isLaunching,
    isLaunched,
    isLocalPixelstreaming,
    videoStream,
    streamerStatus,
    emitter,
    status,
  } = usePureWebContext();

  const { user, authToken } = usePxAuthContext();

  useJoinGameSession(gameSessionId);
  useInitialPacket(
    metaverseId,
    undefined,
    authToken,
    user,
    gameSessionId,
  );
  useRefreshToken();
  useOpenExternalLink();
  useOpenModal();
  useJoinSpace(space?._id);
  useProfileUpdate();
  useDebugEmitUIInteract();
  useUserJoinAndExit();

  return (
    <>
      <PureWebContainer>
        <Background show={!!page?.preloadSource && streamerStatus !== StreamerStatus.Connected} source={{ src: page?.preloadSource, srcType: page?.preloadSourceType }} />
        <UsageLimitReachedScreen isOwner={false} logo={metaverse?.logo} />
        {
          hasUsage &&
          <EmbeddedView
            videoStream={videoStream}
            streamerStatus={streamerStatus}
            inputEmitter={emitter}
          >
            <HUD />
            <PointsOfInterest />
            <PointsDisplay />
            <ContentViewer metaverse={metaverse} />
            <SettingsOverlay />
            <TutorialOverlay
              active={streamerStatus === StreamerStatus.Connected}
              ftueKey={`space:${space?._id}`}
            />
          </EmbeddedView>
        }
        {
          isLaunching && !isLocalPixelstreaming &&
          <LoadingInfo statuses={[status.status, streamerStatus]} />
        }
        {
          !isLaunched && isReady && !isLocalPixelstreaming && hasUsage &&
          <LaunchContainer
            logo={space?.image}
            onEnter={launch}
            showBrowse={false}
            onBrowse={() => { }}
          />
        }
      </PureWebContainer>
      <PureWebAudioComponent />
      {
        space &&
        <UploadContentModalContainer
          isSiteAdmin={false}
          metaverseId={metaverseId}
        />
      }
      <PureWebAvatarModal />
      <UsageLimitReachedModal isOwner={false} />
    </>
  );
}

interface WorldMap3DPageContainerProps {
  page: IWorldMap3DPage;
  metaverse: MetaverseResponse;
}

export default function WorldMap3DPageContainer({ page, metaverse }: WorldMap3DPageContainerProps) {
  const dispatch = useDispatch();

  const [selectedSpaceId, setSelectedSpaceId] = useState('');
  const [activeSpaceId, setActiveSpaceId] = useState('');
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [gameSessionId, setGameSessionId] = useState('');
  const [openWorldMap, setOpenWorldMap] = useState(true);

  const openServerBrowser = useSelector(selectOpenServerBrowser);

  const { data: selectedSpace } = useGetSpace(selectedSpaceId);
  const { data: activeSpace } = useGetSpace(activeSpaceId);

  const handleOpenServerBrowser = () => dispatch(setOpenServerBrowser(true));
  const handleCloseServerBrowser = () => dispatch(setOpenServerBrowser(false));

  const handleSelectServer = (space: SpaceResponse) => {
    setSelectedSpaceId(space._id);
    handleOpenServerBrowser();
  };

  const handlePlayNow = (space: SpaceResponse) => {
    setActiveSpaceId(space._id);
    setOpenWorldMap(false);
  };

  const handleServerBrowserConfirm = (id: string) => {
    setGameSessionId(id);
    setActiveSpaceId(selectedSpaceId);
    handleCloseServerBrowser();
    setOpenWorldMap(false);
  };

  const projectId = activeSpace?.environment?.purewebConfiguration?.projectId ?? '';
  const modelId = activeSpace?.environment?.purewebConfiguration?.modelId ?? '';
  const aliasId = activeSpace?.environment?.purewebConfiguration?.aliasId ?? '';
  const mapName = activeSpace?.environment?.mapName ?? '';

  const { data: randomGameLiftSession } = useGetRandomGameLiftSession(metaverseId, aliasId, mapName);

  useEffect(() => {
    if (!randomGameLiftSession) return;
    setGameSessionId(randomGameLiftSession.GameSessionId);
  }, [randomGameLiftSession]);

  return (
    <UsageContextProvider metaverseId={page.metaverseId}>
      <WorldMapScreen
        page={page}
        show={openWorldMap}
        closable={!!activeSpace}
        onClose={() => setOpenWorldMap(false)}
        onSelectServerClick={handleSelectServer}
        onPlayNowClick={handlePlayNow}
      />
      <PureWebContextProvider projectId={projectId} modelId={modelId}>
        <WorldMap3DPage page={page} metaverse={metaverse} space={activeSpace} gameSessionId={gameSessionId} />
      </PureWebContextProvider>
      <ServerBrowserContainer
        aliasId={selectedSpace?.environment?.purewebConfiguration?.aliasId ?? ''}
        query={{ metaverseId, mapName: selectedSpace?.environment?.mapName }}
        open={openServerBrowser}
        onClose={handleCloseServerBrowser}
        onConfirm={handleServerBrowserConfirm}
      />
    </UsageContextProvider>
  );
}
