import { TooltipButton, Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { useOrganizationUsage } from 'src/queries/organization';

import PlatformUsageWarningModal from './PlatformUsageWarningModal/PlatformUsageWarningModal';

import styles from './UsageDisplay.module.scss';

const REQUEST_TIME_URL = window.env.REACT_APP_SUPPORT_MAILTO_LINK ?? 'mailto:support@pixelcanvas.com';

const calcPercentage = (usageLimit: number | null, remainingUsage: number | null) => {
  if (usageLimit === null || remainingUsage === null) return 100;
  if (usageLimit === 0) return 0;
  return Math.max(0, (remainingUsage / usageLimit) * 100);
};

export type Props = {
  className?: string;
};

export default function UsageDisplay({ className }: Props) {
  const { metaverse } = useCurrentMetaverse();
  const { data: usageResponse } = useOrganizationUsage(metaverse?.organizationId);
  const [showModal, setShowModal] = useState(false);

  const { usageLimit, remainingUsage } = usageResponse ?? { usageLimit: 0, remainingUsage: 0 };
  const percentage = calcPercentage(usageLimit, remainingUsage);
  const approachingLimit = usageResponse ? percentage <= 10 : false;
  const hourLeft = Math.max(0, Math.floor(remainingUsage / 3600000)).toString().padStart(2, '0');
  const minutesLeft = Math.max(0, Math.floor((remainingUsage % 3600000) / 60000)).toString().padStart(2, '0');
  const readModal = localStorage.getItem('usageWarningRead') === 'true';

  useEffect(() => {
    if (usageLimit && usageLimit > 0 && remainingUsage <= 0 && !readModal) {
      setShowModal(true);
    }
  }, [approachingLimit, readModal]);

  const handleRead = () => {
    localStorage.setItem('usageWarningRead', 'true');
    setShowModal(false);
  };

  const handleRequestTime = () => {
    window.open(REQUEST_TIME_URL, '_blank');
    handleRead();
  };

  const timeContainer = (
    <div className={styles['time-container']}>
      <Typography
        variant="h4"
        className={clsx({ [styles.error]: approachingLimit })}
      >
        {hourLeft}
      </Typography>
      <Typography
        variant="h5"
        className={clsx(styles.unit, { [styles.error]: approachingLimit })}
      >
        h
      </Typography>
      <Typography
        variant="h4"
        className={clsx({ [styles.error]: approachingLimit })}
      >
        :
      </Typography>
      <Typography
        variant="h4"
        className={clsx({ [styles.error]: approachingLimit })}
      >
        {minutesLeft}
      </Typography>
      <Typography
        variant="h5"
        className={clsx(styles.unit, { [styles.error]: approachingLimit })}
      >
        m
      </Typography>
    </div>
  );

  return (
    <div className={clsx(className, styles.container, { [styles.error]: approachingLimit })}>
      <div className={styles['progressbar-container']}>
        <CircularProgressbarWithChildren
          value={percentage}
          styles={buildStyles({
            pathColor: approachingLimit ? '#DE6868' : 'white',
            trailColor: '#3F366B',
          })}
        >
          <FontAwesomeIcon icon={faHourglassHalf} className={styles.icon} />
        </CircularProgressbarWithChildren>
      </div>
      {usageLimit === null ? <Typography variant="h1">∞</Typography> : timeContainer}
      <TooltipButton
        classes={{ icon: styles['tooltip-icon'] }}
        placement="bottom"
      >
        Remaining 3D streaming time
      </TooltipButton>
      {showModal &&
        <PlatformUsageWarningModal
          isOwner
          open={showModal}
          onClose={handleRead}
          onConfirm={handleRequestTime}
        />}
    </div>
  );
}
