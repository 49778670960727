import { useEffect, useState } from 'react';

import { ReactComponent as ArrowLeft } from '../../assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { IContent } from '../../interfaces/IContent';

import styles from './Carousel.module.scss';
import clsx from 'clsx';

interface Props {
  contents: IContent[]
  maxWidth?: number
  onItemClick?: Function
}

export default function Carousel({ contents, maxWidth, onItemClick }: Props) {
  const [startIndex, setStartIndex] = useState(0);
  const [maxVisibleItems, setMaxVisibleItems] = useState(4);

  useEffect(() => {
    if (maxWidth) {
      setMaxVisibleItems(Math.max(Math.floor((maxWidth - 210) / 220), 1));
    }
  }, [maxWidth]);

  function curItems() {
    const shownContents: IContent[] = [];

    for (let i = startIndex; i < Math.min(startIndex + maxVisibleItems, contents.length); i++) {
      shownContents.push(contents[i]);
    }

    return shownContents;
  }

  function leftArrowClicked() {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  }

  function rightArrowClicked() {
    if (startIndex < contents.length - maxVisibleItems) {
      setStartIndex(startIndex + 1);
    }
  }

  return (
    <div className={styles.carousel}>
      <div className={styles['carousel-backdrop']}>
        {
        contents.length > 4 &&
        <ArrowLeft className={clsx(styles.arrow, styles.left)} onClick={() => leftArrowClicked()} />
      }
        {
        contents.length > 4 &&
        <ArrowRight className={clsx(styles.arrow, styles.right)} onClick={() => rightArrowClicked()} />
      }
      </div>
      <div className={styles.items}>
        {
        curItems().map((content) => <div key={content._id} className={styles['carousel-item']} onClick={() => onItemClick?.(content)}>
          <img className={styles.thumbnail} src={content.thumbnail} />
          <div className={styles['content-info']}>
            <div className={styles['content-title']}>
              {content.title}
            </div>
            <div className={styles['content-description']}>
              {content.description}
            </div>
          </div>
        </div>)
      }
      </div>
    </div>
  );
}
