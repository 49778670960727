import styles from './SocialMediaLink.module.scss';

type Props = {
  Icon: any;
  href: string;
};

export default function SocialMediaLink({ Icon, href }: Props) {
  return (
    <a className={styles.link} href={href} target="_blank" rel="noreferrer">
      <Icon className={styles.icon} />
    </a>
  );
}
