import { useMutation, useQuery } from 'react-query';
import { MetaverseMetricResponse } from 'src/interfaces/IMetaverseMetric';
import api from '../services/api';

export const queryKey = 'metaversemetrics';

export const useMetaverseMetrics = (opts: any = {}) => useQuery<MetaverseMetricResponse[]>(
  [queryKey],
  () => api
    .get<MetaverseMetricResponse[]>('/api/v2/metaverses/metrics')
    .then((res) => res.data),
  opts,
);

type UseMetaverseMetricsDataParams = {
  metaverseId: string;
  metricIds: string[];
};

export const useMetaverseMetricsData = (opts: any = {}) => useMutation(
  ({ metaverseId, metricIds }: UseMetaverseMetricsDataParams) => api
    .get(`/api/v2/metaverses/${metaverseId}/metrics-data`, {
      params: { metricIds: metricIds.join(',') },
      responseType: 'blob',
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${metaverseId}-${Date.now()}.zip`);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => {
      console.error(e);
    }),
  opts,
);
