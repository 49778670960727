import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ReactComponent as NotificationsIcon } from '../../../../assets/svg/pixel-connect/notification.svg';
import { useNotificationsContext } from '../Notifications/NotificationsContextProvider';

import styles from './NotificationBell.module.scss';

export default function NotificationBell() {
  const { notifications } = useNotificationsContext();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const unread = notifications.filter((n) => !n.read);
    setUnreadCount(unread.length);
  }, [notifications]);

  return (
    <div className={styles['notification-bell']}>
      <NotificationsIcon className={clsx(styles['svg-logo'], styles['notifications-icon'])} />
      { unreadCount > 0 &&
        <div className={styles.badge}>
          {unreadCount}
        </div>}
    </div>
  );
}
