import { useDispatch, useSelector } from 'react-redux';

import ServerBrowserContainer from 'src/features/Visitor/components/GameliftServerBrowserModal/ServerBrowserContainer';
import { GameLiftSessionQuery } from 'src/services/GameService';
import { selectOpenServerBrowser, setOpenServerBrowser } from '../redux/hud';

interface ServerBrowserModalContainerProps {
  aliasId: string | undefined;
  query: GameLiftSessionQuery;
  onGameSessionIdChange: (gameSessionId: string) => void;
}

export default function ServerBrowserModalContainer({ aliasId, query, onGameSessionIdChange }: ServerBrowserModalContainerProps) {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenServerBrowser);
  const handleClose = () => dispatch(setOpenServerBrowser(false));
  const handleConfirm = (gameSessionId: string) => {
    dispatch(setOpenServerBrowser(false));
    onGameSessionIdChange(gameSessionId);
  };

  if (!aliasId) return null;

  return (
    <ServerBrowserContainer
      aliasId={aliasId}
      query={query}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
    />
  );
}
