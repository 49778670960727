import { Button, TextInputField } from '@pixelcanvas/ui';
import { useState } from 'react';

import styles from './ButtonGatedTextInputField.module.scss';

interface ButtonGatedTextInptFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onConfirm: () => void;
  error?: string;
  maxLength?: number;
  confirmDisabled?: boolean;
  placeholder?: string;
  required?: boolean;
}

export default function ButtonGatedTextInptField({
  label,
  maxLength,
  value,
  onChange,
  error,
  onCancel,
  onConfirm,
  confirmDisabled,
  required,
  placeholder,
}: ButtonGatedTextInptFieldProps) {
  const [editable, setEditable] = useState(false);

  const handleCancel = () => {
    onCancel();
    setEditable(false);
  };

  return (
    <div className={styles.container}>
      <TextInputField
        className={styles.input}
        label={label}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={!editable}
        error={error}
        maxLength={maxLength}
      />
      {
        !editable && (
          <Button
            className={styles.button}
            color="secondary"
            onClick={() => setEditable(true)}
          >
            Edit
          </Button>
        )
      }
      {
        editable && (
          <>
            <Button
              className={styles.button}
              color="tertiary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              color="secondary"
              onClick={onConfirm}
              disabled={confirmDisabled}
            >
              Confirm
            </Button>
          </>
        )
      }
    </div>
  );
}
