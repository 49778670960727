import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import styles from './ListItem.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export default function ListItem({
  className,
  disabled = false,
  onClick,
  children,
}: Props) {
  return (
    <button
      className={clsx(className, styles.button)}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
