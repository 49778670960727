import DomainWhitelist from '../../components/DomainWhitelist/DomainWhitelist';

import styles from './EditMetaversePrivacyDomainSettings.module.scss';

export default function EditMetaversePrivacyDomainSettings() {
  return (
    <div className={styles.container}>
      <DomainWhitelist />
    </div>
  );
}
