import { useEffect, useRef } from 'react';
import { ShowcaseVideoContentDocument } from 'src/interfaces/IShowcaseContent';

import styles from './VideoContent.module.scss';

interface VideoContentProps {
  content: ShowcaseVideoContentDocument;
}

export default function VideoContent({ content }: VideoContentProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleFKeyDown = (e: KeyboardEvent) => {
      console.log(e);
      if (!videoRef.current) return;
      if (!(e.key === 'f' || e.key === 'F')) return;
      if (!document.fullscreenElement) {
        videoRef.current.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

    document.addEventListener('keydown', handleFKeyDown);
    return () => document.removeEventListener('keydown', handleFKeyDown);
  }, [videoRef.current]);

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <video
          ref={videoRef}
          className={styles.video}
          src={content.src}
          autoPlay
          controls
        />
        <h4 className={styles.name}>{content.name}</h4>
        <p className={styles.description}>{content.description}</p>
      </div>
    </main>
  );
}
