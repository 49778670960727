import imageCompression from 'browser-image-compression';

export const compressImage = async (file: File) => imageCompression(file, { maxSizeMB: 2 });

export const readURL = (input: any, onLoad: Function, onStart?: Function) => {
  if (input.files && input.files[0]) {
    onStart?.();
    const reader = new FileReader();
    reader.onload = async () => {
      if (input.files[0].type !== 'video/mp4') {
        const compressedImage = await compressImage(input.files[0]);
        onLoad(compressedImage, 'image');
      } else {
        const blob = new Blob([input.files[0]], { type: input.files[0].type });
        onLoad(blob, 'video');
      }
    };
    reader.readAsDataURL(input.files[0]);
  }
};
