enum PageType {
  LandingPage = 'LandingPage',
  Stage = 'Stage',
  VideoOnDemand = 'VideoOnDemand',
  PureWeb3D = 'PureWeb3D',
  WorldMap3D = 'WorldMap3D',
  Space3D = 'Space3D',
  Exhibit = 'Exhibit',
  Lounge = 'Lounge',
  Agenda = 'Agenda',

  // Virtual Page Type
  Metaverse3D = 'Metaverse3D',
}

export default PageType;
