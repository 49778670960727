import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShowcaseDocument, ShowcaseTabDocument } from 'src/interfaces/IShowcase';
import { ShowcaseContentDocument } from 'src/interfaces/IShowcaseContent';
import { RootState } from 'src/store';

interface ContentViewerState {
  activeShowcase: ShowcaseDocument | null;
  activeContent: ShowcaseContentDocument | null;
  activeTab: ShowcaseTabDocument | null;
}

const initialState: ContentViewerState = {
  activeShowcase: null,
  activeContent: null,
  activeTab: null,
};

export const contentViewerSlice = createSlice({
  name: 'contentViewer',
  initialState,
  reducers: {
    setActiveShowcase: (state, action: PayloadAction<ShowcaseDocument>) => ({ ...state, activeShowcase: action.payload }),
    unsetActiveShowcase: (state) => ({ ...state, activeShowcase: null }),
    setActiveContent: (state, action: PayloadAction<ShowcaseContentDocument>) => ({ ...state, activeContent: action.payload }),
    unsetActiveContent: (state) => ({ ...state, activeContent: null }),
    setActiveTab: (state, action: PayloadAction<ShowcaseTabDocument>) => ({ ...state, activeTab: action.payload }),
    unsetActiveTab: (state) => ({ ...state, activeTab: null }),
  },
});

export const {
  setActiveShowcase,
  unsetActiveShowcase,
  setActiveContent,
  unsetActiveContent,
  setActiveTab,
  unsetActiveTab,
} = contentViewerSlice.actions;

export const selectActiveShowcase = (state: RootState) => state.contentViewer.activeShowcase;
export const selectActiveContent = (state: RootState) => state.contentViewer.activeContent;
export const selectActiveTab = (state: RootState) => state.contentViewer.activeTab;

export default contentViewerSlice.reducer;
