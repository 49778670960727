import clsx from 'clsx';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

import styles from './HUDSlider.module.scss';

interface HUDSliderProps {
  className?: string;
  onChange?: (value: number) => void;
  defaultValue?: number;
}

export default function HUDSlider({
  className,
  onChange,
  defaultValue = 0.2,
}: HUDSliderProps) {
  return (
    <Slider
      className={clsx(className, styles.slider)}
      onChange={onChange as any}
      max={1}
      step={0.01}
      defaultValue={defaultValue}
      railStyle={{ border: '1px solid white', background: 'transparent' }}
      trackStyle={{ border: '1px solid white', background: 'white' }}
      handleStyle={{ border: '4px solid white' }}
    />
  );
}
