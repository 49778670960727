import { Can } from '@casl/react';
import { faChartColumn, faHouseChimneyWindow, faLockKeyhole, faScrewdriverWrench, faShareAll, faShop, faTableList, faUsersGear, faWindowRestore } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MoreOptions, SideNav, SideNavBackButton, SideNavBody, SideNavButtonGroup, SideNavHead, SideNavSection, SubNavAddButton, Typography } from '@pixelcanvas/ui';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';

import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import PageType from 'src/enums/PageType';
import { useDeleteMetaverse, useUpdateMetaverse } from 'src/queries/metaverse';
import { useDeletePage, usePages } from 'src/queries/pages';
import { useDeleteShowcase, useShowcases } from 'src/queries/showcase';
import { getBaseOrigin } from 'src/utils/URLHelper';

import pixelCanvasLogo from '../../../../assets/images/pixel-canvas-logo.png';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import AddShowcaseModal from '../../pages/EditMetaverseShowcase/components/AddShowcaseModal';
import { setAddNewAddOn, setOpenShareModal } from '../../redux/editMetaverse';
import DeleteMetaverseConfirmModal from '../DeleteMetaverseConfirmModal';
import SideNavExpandableLink from '../SideNavExpandableLink';
import SideNavLink from '../SideNavLink';
import SubNavLink from '../SubNavLink';

import styles from './EditMetaverseSideNav.module.scss';

enum EditMetaverseOption {
  Delete = 'Delete',
}

export default function MetaverseNavSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { metaverse, ability } = useCurrentMetaverse();
  const [openOptions, setOpenOptions] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const { addToast } = useToastMessageContext();

  const {
    mutate,
    isLoading,
    isSuccess,
    reset,
  } = useDeleteMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess) return;
    addToast('Metaverse deleted!');
    reset();
    navigate('/metaverses');
  }, [isSuccess]);

  const logo = metaverse?.logo ?? metaverse?.spaces?.[0]?.environment?.image;

  const handleConfirmDelete = () => {
    mutate();
    setOpenConfirmDelete(false);
  };

  const handleSelectOption = (option: EditMetaverseOption) => {
    switch (option) {
      case EditMetaverseOption.Delete:
        setOpenConfirmDelete(true);
        break;
      default:
        break;
    }
  };

  const handleBackToMyMetaverses = () => {
    window.location.href = `${getBaseOrigin()}/metaverses`;
  };

  const handleShareButtonClick = () => {
    dispatch(setOpenShareModal(true));
  };

  const customizeNavs = [];

  if (ability.can(Action.Create, Subject.Showcase) ||
    ability.can(Action.Update, Subject.Showcase) ||
    ability.can(Action.Delete, Subject.Showcase)) {
    customizeNavs.push(
      <SideNavLink
        key="showcases"
        label="Showcases"
        icon={<FontAwesomeIcon icon={faShop} />}
        to="/edit/showcases"
      />,
    );
  }

  return (
    <SideNav>
      <SideNavHead className={styles.head}>
        <SideNavBackButton text="Back to My Metaverse" onClick={handleBackToMyMetaverses} />
        <div className={styles.logo}>
          <img src={pixelCanvasLogo} alt="Pixel Canvas Logo" />
        </div>
      </SideNavHead>
      <SideNavBody>
        <SideNavSection>
          <div className={styles.nameAndOptionsSection}>
            <Typography className={styles.name} variant="subtitle3">{metaverse?.name}</Typography>
            <MoreOptions
              open={openOptions}
              onOpen={() => setOpenOptions(true)}
              onClose={() => setOpenOptions(false)}
              options={Object.values(EditMetaverseOption)}
              onSelect={handleSelectOption}
            />
          </div>
          <div className={styles.logoAndShareSection}>
            {logo && <img className={styles.logo} src={logo} alt="Metaverse Logo" />}
            <ShareButton onClick={handleShareButtonClick} />
          </div>
          <SideNavButtonGroup name="Create">
            <SideNavLink
              label="Home"
              icon={<FontAwesomeIcon icon={faHouseChimneyWindow} />}
              to="/edit/home"
            />
            <SideNavLink
              label="Key Details"
              icon={<FontAwesomeIcon icon={faTableList} />}
              to="/edit/details"
            />
            <SideNavAddOnsLink />
          </SideNavButtonGroup>
          <SideNavButtonGroup name="Manage">
            <SideNavLink
              label="Visitors"
              icon={<FontAwesomeIcon icon={faUsersGear} />}
              to="/edit/visitors"
            />
            <SideNavLink
              label="Privacy Settings"
              icon={<FontAwesomeIcon icon={faLockKeyhole} />}
              to="/edit/privacy"
            />
            <SideNavLink
              label="Analytics"
              icon={<FontAwesomeIcon icon={faChartColumn} />}
              to="/edit/analytics"
            />
            <Can ability={ability} I={Action.Manage} a={Subject.AdvancedSettings}>
              <SideNavLink
                label="Advanced Settings"
                icon={<FontAwesomeIcon icon={faScrewdriverWrench} />}
                to="/edit/advancedsettings"
              />
            </Can>
          </SideNavButtonGroup>
          <SideNavCustomizeSection />
        </SideNavSection>
      </SideNavBody>
      <DeleteMetaverseConfirmModal
        name={metaverse?.name ?? ''}
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={handleConfirmDelete}
      />
      <PlatformSpinner visible={isLoading} fixed />
    </SideNav>
  );
}

interface ShareButtonProps {
  onClick: () => void;
}

function ShareButton({ onClick }: ShareButtonProps) {
  return (
    <button className={styles.share} type="button" onClick={onClick}>
      <Typography variant="body2">
        <FontAwesomeIcon icon={faShareAll} /> Share
      </Typography>
    </button>
  );
}

function SideNavAddOnsLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metaverse } = useCurrentMetaverse();
  const { addToast } = useToastMessageContext();

  const {
    mutate: updateMetaverse,
    isSuccess: isUpdateSuccess,
    reset: resetUpdate,
    data: updatedMetaverse,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isUpdateSuccess || !updatedMetaverse) return;
    addToast('Metaverse updated!');
    resetUpdate();
    if (!updatedMetaverse.landingPage) {
      navigate('/edit/addons');
    }
  }, [isUpdateSuccess, updatedMetaverse]);

  const {
    data: pages = [],
  } = usePages(metaverse?._id);

  const {
    mutate: deletePage,
    isSuccess: isDeleteSuccess,
    reset: resetDelete,
  } = useDeletePage(metaverse?._id);

  useEffect(() => {
    if (!isDeleteSuccess) return;
    addToast('Page deleted!');
    resetDelete();
    navigate('/edit/addons');
  }, [isDeleteSuccess]);

  const handleAdd = () => dispatch(setAddNewAddOn(true));
  const handleDeleteLandingPage = () => updateMetaverse({ landingPage: null } as any); // TODO: Resolve the any type cast

  const addons: ReactNode[] = [];

  if (metaverse?.landingPage) {
    addons.push(
      <SubNavLink
        key="landing-page"
        label="Landing Page"
        to="/edit/addons/landing-page"
        onDelete={handleDeleteLandingPage}
      />,
    );
  }

  const configurablePageTypes = [
    PageType.Stage,
    PageType.VideoOnDemand,
    PageType.PureWeb3D,
    PageType.WorldMap3D,
    PageType.Space3D,
  ];

  addons.push([
    ...pages.filter((page) => configurablePageTypes.includes(page.type)).map((page) => {
      switch (page.type) {
        case PageType.Stage:
          return (
            <SubNavLink
              key={page._id}
              label={page.title ?? ''}
              to={`/edit/addons/stage/${page._id}`}
              onDelete={() => deletePage(page._id)}
            />
          );
        case PageType.VideoOnDemand:
          return (
            <SubNavLink
              key={page._id}
              label={page.title ?? ''}
              to={`/edit/addons/vod/${page._id}`}
              onDelete={() => deletePage(page._id)}
            />
          );
        case PageType.PureWeb3D:
          return (
            <SubNavLink
              key={page._id}
              label={page.title ?? ''}
              to={`/edit/addons/pw/${page._id}`}
              onDelete={() => deletePage(page._id)}
            />
          );
        case PageType.WorldMap3D:
          return (
            <SubNavLink
              key={page._id}
              label={page.title ?? ''}
              to={`/edit/addons/worldmap/${page._id}`}
              onDelete={() => deletePage(page._id)}
            />
          );
        case PageType.Space3D:
          return (
            <SubNavLink
              key={page._id}
              label={page.title ?? ''}
              to={`/edit/addons/legacyspace/${page._id}`}
              onDelete={() => deletePage(page._id)}
            />
          );
        default:
          return null;
      }
    }),
  ]);

  addons.push(
    <SubNavAddButton
      key="add"
      label="Add New"
      onClick={handleAdd}
    />,
  );

  return (
    <SideNavExpandableLink
      label="Add-Ons"
      icon={<FontAwesomeIcon icon={faWindowRestore} />}
      to="/edit/addons"
    >
      {addons}
    </SideNavExpandableLink>
  );
}

function SideNavCustomizeSection() {
  const { ability } = useCurrentMetaverse();
  const customizeNavs = [];

  if (ability.can(Action.Create, Subject.Showcase) ||
    ability.can(Action.Update, Subject.Showcase) ||
    ability.can(Action.Delete, Subject.Showcase)) {
    customizeNavs.push(<SideNavShowcasesLink key="showcases" />);
  }

  if (customizeNavs.length === 0) return null;

  return (
    <SideNavButtonGroup name="Customize">
      {customizeNavs}
    </SideNavButtonGroup>
  );
}

function SideNavShowcasesLink() {
  const navigate = useNavigate();
  const [openAdd, setOpenAdd] = useState(false);
  const { metaverse } = useCurrentMetaverse();
  const { data: showcases = [] } = useShowcases(metaverse?._id);
  const { addToast } = useToastMessageContext();
  const {
    mutate: deleteShowcase,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useDeleteShowcase(metaverse?._id);

  useEffect(() => {
    if (!isDeleteSuccess) return;
    addToast('Showcase deleted!');
    navigate('/edit/showcases');
  }, [isDeleteSuccess]);

  return (
    <SideNavExpandableLink
      key="showcases"
      label="Showcases"
      icon={<FontAwesomeIcon icon={faShop} />}
      to="/edit/showcases"
    >
      {showcases.map((showcase) => (
        <SubNavLink
          key={showcase.guid}
          label={showcase.name ?? ''}
          to={`/edit/showcases/${showcase.guid}`}
          onDelete={() => deleteShowcase(showcase.guid)}
        />
      ))}
      <SubNavAddButton
        key="add"
        label="Add New"
        onClick={() => setOpenAdd(true)}
      />
      <AddShowcaseModal open={openAdd} onClose={() => setOpenAdd(false)} />
      <PlatformSpinner visible={isDeleteLoading} />
    </SideNavExpandableLink>
  );
}
