import { faUsersGear, faUsersRectangle } from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBill } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SideNav, SideNavBackButton, SideNavButtonGroup, SideNavSection } from '@pixelcanvas/ui';
import { useNavigate } from 'react-router-dom';

import SideNavLink from 'src/features/Dashboard/components/SideNavLink';

import styles from './OrganizationSideNav.module.scss';

export default function OrganizationSideNav() {
  const navigate = useNavigate();

  return (
    <SideNav background="transparent" className={styles.container}>
      <SideNavBackButton
        text="Back"
        background="transparent"
        onClick={() => navigate('/metaverses')}
      />
      <SideNavSection>
        <SideNavButtonGroup>
          <SideNavLink
            icon={<FontAwesomeIcon icon={faUsersRectangle} />}
            label="Organization Profile"
            to="/organization/profile"
          />
          <SideNavLink
            icon={<FontAwesomeIcon icon={faUsersGear} />}
            label="Team"
            to="/organization/team"
          />
          <SideNavLink
            icon={<FontAwesomeIcon icon={faMoneyBill} />}
            label="Billing"
            to="/organization/billing"
          />
        </SideNavButtonGroup>
      </SideNavSection>
    </SideNav>
  );
}
