import { Card, Typography } from '@pixelcanvas/ui';
import { AddOn } from 'src/features/Dashboard/domain/AddOn';

import styles from './AddOnCard.module.scss';

interface AddOnCardProps {
  addOn: AddOn,
  selected: boolean,
  onClick: () => void,
}

export default function AddOnCard({ addOn, selected, onClick }: AddOnCardProps) {
  return (
    <Card variant="selection" onClick={onClick} active={selected}>
      <Card.Image className={styles.image} src={addOn.image} darken="none" />
      <Card.Content className={styles.content}>
        <Typography variant="h2" emphasis="high">{addOn.title}</Typography>
        <Typography variant="body1" emphasis="high">{addOn.description}</Typography>
      </Card.Content>
    </Card>
  );
}
