import { useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export default function useSendResizeMessages<T extends HTMLElement>() {
  const { emitUIInteraction, isStreaming } = usePixelStreamingContext();
  const { ref, width, height } = useResizeObserver<T>();

  useEffect(() => {
    if (!emitUIInteraction || !isStreaming || !width || !height) return;
    emitUIInteraction({ type: 50, width, height, action: '1' });
  }, [emitUIInteraction, isStreaming, width, height]);

  return { ref };
}
