import { Typography, RadioButtonInput, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, SelectInputField, Button, InputFieldsGroup } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';

import { usePixelStreamingContext } from '../../contexts/PixelStreamingContext';

import styles from './SceneSlotEditModal.module.scss';
import { useSceneSlot, useDeleteSceneSlot, useSetSceneSlot } from 'src/queries/metaverse';
import { SceneSlotType } from 'src/interfaces/SceneSlot';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';

enum SceneSlotMessageType {
  Edit = 'scene-slot:edit',
}

interface SceneSlotEditMessage {
  type: SceneSlotMessageType.Edit;
  slotId: string;
  availableSceneries: AvailableScenery[];
}

interface SceneSlotEditModalProps {
  metaverseId: string;
  spaceId: string;
  isSiteAdmin?: boolean;
}

interface AvailableScenery {
  type: SceneSlotType;
  id: string;
  name: string;
}

export default function SceneSlotEditModal({
  metaverseId,
  spaceId,
}: SceneSlotEditModalProps) {
  const [slotId, setSlotId] = useState('');
  const [sceneSlotType, setSceneSlotType] = useState<SceneSlotType>(SceneSlotType.Empty);
  const [selectedScenery, setSelectedScenery] = useState<AvailableScenery | undefined>();
  const [availableSceneries, setAvailableSceneries] = useState<AvailableScenery[]>([]);
  const { data: sceneSlot, remove, isLoading } = useSceneSlot(metaverseId, spaceId, slotId, false);

  const { descriptor } = usePixelStreamingContext();

  const {
    mutate: setSceneSlot,
    isSuccess: isSetSuccess,
  } = useSetSceneSlot(metaverseId, spaceId, slotId);

  const {
    mutate: deleteSceneSlot,
    isSuccess: isDeleteSuccess,
  } = useDeleteSceneSlot(metaverseId, spaceId, slotId);

  useEffect(() => {
    closeModal();
    remove();
  }, [isSetSuccess, isDeleteSuccess]);

  useEffect(() => {
    if (!descriptor) return;
    const listener = ({ slotId: id, availableSceneries }: SceneSlotEditMessage) => {
      setSlotId(id);
      setAvailableSceneries(availableSceneries);
    };
    descriptor.on(SceneSlotMessageType.Edit, listener);
    return () => {
      descriptor.off(SceneSlotMessageType.Edit, listener);
    }
  }, [descriptor])

  useEffect(() => {
    if (!sceneSlot) {
      return;
    }
    setSceneSlotType(sceneSlot.type);
    setSelectedScenery(availableSceneries.find(scenery => scenery.id === sceneSlot.sceneryId));
  }, [sceneSlot]);

  const closeModal = () => {
    setSceneSlotType(SceneSlotType.Empty);
    setSelectedScenery(undefined);
    setSlotId('');
  };

  const handleConfirm = () => {
    if (sceneSlotType === SceneSlotType.Empty) {
      if (!sceneSlot) {
        closeModal();
      }
      deleteSceneSlot();
    } else {
      if (!selectedScenery) {
        return;
      }
      setSceneSlot({ slotId, spaceId, metaverseId, type: sceneSlotType, sceneryId: selectedScenery.id });
    }
  }

  const filteredSceneries = availableSceneries
    .filter(scenery => scenery.type === sceneSlotType);

  const hasSceneryType = availableSceneries.some(scenery => scenery.type === SceneSlotType.Scenery);
  const hasMinigameType = availableSceneries.some(scenery => scenery.type === SceneSlotType.MiniGame);

  useEffect(() => {
    if (sceneSlotType === SceneSlotType.Empty) {
      setSelectedScenery(undefined);
      return;
    }
    setSelectedScenery(filteredSceneries[0]);
  }, [sceneSlotType]);

  const handleSelectedSceneryChange = (value: string) => {
    const scenery = filteredSceneries.find(scenery => scenery.name === value);
    if (!scenery) {
      return;
    }
    setSelectedScenery(scenery);
  }

  const open = !!slotId;

  return (
    <TaskModal
      className={styles.modal}
      open={open}
      onClose={closeModal}
    >

      <TaskModalTitle onClose={closeModal}>
        Edit Slot
      </TaskModalTitle>
      <TaskModalContent>
        {
          isLoading &&
          <PlatformSpinner visible={isLoading} />
        }
        {
          !isLoading &&
          <InputFieldsGroup>
            <Typography variant="h5">
              Slot Type*
            </Typography>
            {
              hasSceneryType &&
              <RadioButtonInput
                id="scenery"
                label="Scenery"
                name="slotType"
                checked={sceneSlotType === SceneSlotType.Scenery}
                onChange={() => setSceneSlotType(SceneSlotType.Scenery)}
              />
            }
            {
              hasMinigameType &&
              <RadioButtonInput
                id="minigame"
                label="Mini Game"
                name="slotType"
                checked={sceneSlotType === SceneSlotType.MiniGame}
                onChange={() => setSceneSlotType(SceneSlotType.MiniGame)}
              />
            }
            <RadioButtonInput
              id="empty"
              label="Empty"
              name="slotType"
              checked={sceneSlotType === SceneSlotType.Empty}
              onChange={() => setSceneSlotType(SceneSlotType.Empty)}
            />

            {sceneSlotType !== SceneSlotType.Empty && filteredSceneries.length > 0 &&
              <SelectInputField
                label='Choose a Scenery*'
                options={filteredSceneries.map(scenery => scenery.name)}
                placeholder={filteredSceneries[0].name}
                onChange={handleSelectedSceneryChange}
              />
            }
          </InputFieldsGroup>
        }
      </TaskModalContent>
      <TaskModalActions className={styles['modal-actions']}>
        <Button color="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleConfirm}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </TaskModalActions>
    </TaskModal>
  );
}
