import React, {
  createRef,
} from 'react';
import { Participant } from 'twilio-video';
import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview/web';
import ParticipantVideoTrack from '../ParticipantVideoTrack/ParticipantVideoTrack';
import Controls from '../Controls/Controls';
import { VideoContext } from '../VideoContextProvider';
import styles from './VideoChatList.module.scss';
import { PlatformRole } from '../../../../../interfaces';
import RoomParticipant from '../../../../../interfaces/RoomParticipant';
import RoomInviteModal from '../../../../Visitor/components/RoomInviteModal/RoomInviteModal';
import clsx from 'clsx';

interface ParticipantRoles {
  [key: string]: PlatformRole[]
}

interface VideoChatListProps {
  videoHeightAspect: number;
  videoWidthAspect: number;
  onContextActionClicked: Function | undefined;
  roles: PlatformRole[];
  onExitVideoRoom: Function;
  getUser: Function;
  getChannelParticipant: Function;
  getChannelParticipants: Function;
  videoRoomId: string;
  roomName: string;
}
interface VideoChatListState {
  dataProvider: DataProvider;
  isRoomPresenter: boolean;
  channelParticipantRoles: ParticipantRoles;
  inviteModalVisible: boolean;
}

class VideoChatList extends React.Component<VideoChatListProps, VideoChatListState> {
  static contextType = VideoContext;

  static defaultProps: VideoChatListProps = {
  videoHeightAspect: 9,
  videoWidthAspect: 16,
  onContextActionClicked: undefined,
  onExitVideoRoom: () => {},
  getUser: () => {},
  getChannelParticipant: () => {},
  getChannelParticipants: () => {},
  roles: [PlatformRole.Attendee],
  videoRoomId: '',
  roomName: '',
  };

  listRef: React.RefObject<any>;

  layoutProvider: LayoutProvider;

  constructor(props: VideoChatListProps) {
    super(props);
    this.listRef = createRef();
    const dataProvider = new DataProvider(
      (p1: Participant, p2: Participant) => {
        const value = p1.identity !== p2.identity ||
          p1.videoTracks.values() !== p2.videoTracks.values();
        console.log('DataProvider rowHasChange', value);
        return value;
      },
    );

    this.layoutProvider = new LayoutProvider(() => 0, (type, dim) => {
      dim.width = 0;
      dim.height = 0;
    });

    this.rowRenderer = this.rowRenderer.bind(this);

    this.state = {
      dataProvider: dataProvider.cloneWithRows([]),
      isRoomPresenter: false,
      channelParticipantRoles: {},
      inviteModalVisible: false,
    };
  }

  componentDidMount() {
    const { videoRoom } = this.context;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (videoRoom) {
      this.loadChannelParticipants();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { remoteParticipants, videoRoom, presenter } = this.context;
    const { channelParticipantRoles } = this.state;
    console.log('VideoChatList did update', remoteParticipants);
    const listParticipants: any[] = [videoRoom.localParticipant].concat(remoteParticipants);
    listParticipants.sort((a, b) => (channelParticipantRoles[a.identity]?.includes(PlatformRole.Moderator) ? -1 : channelParticipantRoles[b.identity]?.includes(PlatformRole.Moderator) ? 1 : 0));

    const presenterFilteredRemoteParticipants = (presenter) ?
      listParticipants.filter((participant) => participant.identity !== presenter.identity && participant.identity !== 'video-composer-v1') :
      listParticipants.filter((participant) => participant.identity !== 'video-composer-v1');

    if (prevState.dataProvider.getSize() !== presenterFilteredRemoteParticipants.length && videoRoom) {
      this.onParticipantsChange(presenterFilteredRemoteParticipants);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async loadChannelParticipants() {
    const { dataProvider } = this.state;
    const { getChannelParticipants } = this.props;
    const { remoteParticipants, videoRoom, presenter } = this.context;

    const channelParticipants: RoomParticipant[] = await getChannelParticipants(videoRoom.name);
    const rolesRegistry: ParticipantRoles = {};
    channelParticipants.forEach((participant: RoomParticipant) => {
      rolesRegistry[participant.userId] = participant.roles || [];
    });
    this.setState({ channelParticipantRoles: rolesRegistry });
    const listParticipants: any[] = [videoRoom?.localParticipant].concat(remoteParticipants);
    listParticipants.sort((a, b) => (rolesRegistry[a.identity]?.includes(PlatformRole.Moderator) ? -1 : rolesRegistry[b.identity]?.includes(PlatformRole.Moderator) ? 1 : 0));
    const presenterFilteredParticipants = (presenter) ? listParticipants.filter((participant) => participant.identity !== presenter.identity) : listParticipants.filter((participant) => participant.identity !== 'video-composer-v1');
    this.setState({ dataProvider: dataProvider.cloneWithRows(presenterFilteredParticipants) });
    this.setState({ isRoomPresenter: rolesRegistry[videoRoom.localParticipant.identity]?.includes(PlatformRole.Presenter) || false });
  }

  handleResize = () => {
  const { videoHeightAspect, videoWidthAspect } = this.props;
  const aspectRatio = videoHeightAspect / videoWidthAspect;
  this.layoutProvider = new LayoutProvider(() => 0, (type, dim) => {
    dim.width = this.listRef.current.clientWidth;
    dim.height = dim.width * aspectRatio;
    });
  };

  onParticipantsChange = (participants: Participant[]) => {
  const { dataProvider } = this.state;
  this.setState({ dataProvider: dataProvider.cloneWithRows(participants) });
  };

  onClickInvite() {
    this.setState({ inviteModalVisible: true });
  }

  rowRenderer = (type: any, data: any) => {
  const { onContextActionClicked, getUser, roles } = this.props;
  const { channelParticipantRoles } = this.state;
  return (
    <div key={data.identity} className={clsx(styles['list-item'], styles['list-video'])}>
    <ParticipantVideoTrack
    metaverseRoles={roles}
    roomRoles={channelParticipantRoles[data.identity]}
    onContextActionClicked={onContextActionClicked}
    participant={data}
    getUser={getUser}
    />
    </div>
  );
  };

  render() {
    const { onExitVideoRoom, videoRoomId, roomName } = this.props;
    const { videoRoom } = this.context;
    const { dataProvider, isRoomPresenter, inviteModalVisible } = this.state;
    return (
      <>
        <div className={styles['video-chat-list']} ref={this.listRef}>
          <div className={styles['panel-heading']}>
            {roomName}
            <div className={styles['invite-button']} onClick={() => this.onClickInvite()}>Invite Guests</div>
          </div>
          {
            videoRoom && dataProvider.getSize() > 0 &&
            <RecyclerListView
              layoutProvider={this.layoutProvider}
              dataProvider={this.state.dataProvider}
              rowRenderer={this.rowRenderer}
            />
          }
          {
            dataProvider.getSize() <= 0 && <div className={styles['list-placeholder']} />
          }
          <div className={styles['list-controls']}>
            <Controls onExitVideoRoom={onExitVideoRoom} isRoomPresenter={isRoomPresenter} videoRoomId={videoRoomId} />
          </div>
        </div>
        {inviteModalVisible && <RoomInviteModal roomId={videoRoomId} visible={inviteModalVisible} onClose={() => this.setState({ inviteModalVisible: false })} />}
      </>
    );
  }
}

export default VideoChatList;
