import { Page, PageContent } from '@pixelcanvas/ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequireAuthentication from 'src/authentication/RequireAuthentication';
import MetaverseHeader from '../../components/MetaverseHeader/MetaverseHeader';
import { selectSidePanel, setShowUsage, setSidePanel } from '../../redux/editMetaverse';
import OrganizationContextProvider from '../OrganizationPage/context/OrganizationContextProvider';
import Metaverse3DEditorSession from './components/Metaverse3DEditorSession';
import Metaverse3DEditorSidePanel from './components/Metaverse3DEditorSidePanel';
import MetaverseBoothEditorSidePanel from './components/MetaverseBoothEditorSidePanel';

function Metaverse3DEditor() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowUsage(true));
    return () => { dispatch(setShowUsage(false)); };
  }, []);

  useEffect(() => {
    dispatch(setSidePanel('editor'));
    return () => { dispatch(setSidePanel('sidenav')); };
  }, []);

  return (
    <Page>
      <SidePanel />
      <PageContent>
        <MetaverseHeader title="3D Editor" />
        <Metaverse3DEditorSession />
      </PageContent>
    </Page>
  );
}

function SidePanel() {
  const sidePanel = useSelector(selectSidePanel);
  switch (sidePanel) {
    case 'editor':
      return <Metaverse3DEditorSidePanel />;
    case 'booth':
      return <MetaverseBoothEditorSidePanel />;
    default:
      return null;
  }
}

export default function WrappedMetaverse3DEditor() {
  return (
    <RequireAuthentication>
      <OrganizationContextProvider>
        <Metaverse3DEditor />
      </OrganizationContextProvider>
    </RequireAuthentication>
  );
}
