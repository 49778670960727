import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { ShowcaseImageContentDocument, ShowcaseSlideshowContentDocument, ShowcaseVideoContentDocument } from 'src/interfaces/IShowcaseContent';
import { setActiveContent } from '../redux/contentViewer';
import ContentCard from './ContentCard';

import styles from './MediaContentCard.module.scss';

interface MediaContentCardProps {
  content: ShowcaseVideoContentDocument | ShowcaseImageContentDocument | ShowcaseSlideshowContentDocument;
  icon: IconDefinition;
}

export default function MediaContentCard({ content, icon }: MediaContentCardProps) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setActiveContent(content));
  };
  return (
    <ContentCard className={styles.span} content={content} onClick={handleClick}>
      <FontAwesomeIcon className={styles.icon} icon={icon} />
    </ContentCard>
  );
}
