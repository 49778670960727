import UsageWarningModal from 'src/features/Visitor/components/Usage/UsageWarningModal/UsageWarningModal';
import { useUsageContext } from '../contexts/UsageContextProvider';

interface UsageLimitReachedModalProps {
  isOwner: boolean;
  onDismiss?: () => void;
}

export default function UsageLimitReachedModal({
  isOwner,
  onDismiss = () => location.reload(),
}: UsageLimitReachedModalProps) {
  const { closeUsageModal, isUsageModalOpen } = useUsageContext();
  const handleClose = () => {
    closeUsageModal();
    onDismiss?.();
  };
  return (
    <UsageWarningModal
      isOwner={isOwner}
      open={isUsageModalOpen}
      onClose={handleClose}
    />
  );
}
