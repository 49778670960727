import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';

import { Typography } from '@pixelcanvas/ui';
import { useGetImageSlot } from 'src/queries/metaverse';
import { usePixelStreamingContext } from '../../contexts/PixelStreamingContext';
import HUDButton from '../HUDButton';
import { ImageSlotMessageType } from './enums/ImageSlotMessageType';

import styles from './ImageSlotViewer.module.scss';

interface ImageSlotOverlayProps {
  className?: string;
  metaverse: MetaverseResponse;
}

interface ImageSlotOpenMessage {
  type: ImageSlotMessageType.Viewer;
  slotId: string;
  src: string;
}

export default function ImageSlotViewer({
  className,
  metaverse,
}: ImageSlotOverlayProps) {

  const [slotId, setSlotId] = useState<string | undefined>();
  const { data: imageSlot } = useGetImageSlot(metaverse?._id, metaverse?.spaces[0]._id, slotId);

  const { descriptor } = usePixelStreamingContext();

  useEffect(() => {
    if (!descriptor) return;
    const listener = ({ slotId: id }: ImageSlotOpenMessage) => {
      setSlotId(id);
    };
    descriptor.on(ImageSlotMessageType.Viewer, listener);
    return () => {
      descriptor.off(ImageSlotMessageType.Viewer, listener);
    }
  }, [descriptor])

  const handleBackClick = () => {
    setSlotId(undefined);
  };

  if (!slotId || !imageSlot) return null;

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.header}>
        <div className={styles.top}>
          <div className={styles.back}>
            <HUDButton className={styles.button} onClick={handleBackClick}>Back</HUDButton>
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <img src={imageSlot.src} className={styles.image} />
        </div>
        {imageSlot && (
          <SideCards
            link={imageSlot.href}
            linkText={imageSlot.linkText}
            description={imageSlot.description}
          />
        )}
      </div>
    </div>
  );
}

interface SideCardsProps {
  link?: string;
  linkText?: string;
  description?: string;
}

function SideCards({ description, link, linkText }: SideCardsProps) {

  return (
    <div className={styles.sideCardsContainer}>
      {description && <DescriptionCard description={description} />}
      {link && <LinkCard link={link} linkText={linkText} />}
    </div>
  );
}

interface LinkCardProps {
  link?: string;
  linkText?: string;
}

function LinkCard({ link, linkText }: LinkCardProps) {
  const domainOnlyLink = link?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

  return (
    <div className={styles.sideCard}>
      <div className={styles.sideCardHeader}>
        <Typography variant="h2" emphasis='high'>Explore More</Typography>
      </div>
      <div className={styles.sideCardContent}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={styles.iconLink}
        >
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            className={styles.icon}
          />
        </a>
        <div className={styles.linkTextContainer}>
          <Typography variant="subtitle3">
            VISIT&nbsp;
          </Typography>
          <Typography variant="subtitle3" className={styles.linkText}>
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {linkText === '' ? domainOnlyLink : linkText}
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
}

interface DescriptionCardProps {
  description?: string;
}

function DescriptionCard({ description }: DescriptionCardProps) {
  return (
    <div className={styles.sideCard}>
      <div className={styles.sideCardHeader}>
        <Typography variant="h2" emphasis='high'>Description</Typography>
      </div>
      <div className={clsx(styles.descriptionContainer, styles.sideCardContent)}>
        <Typography variant="subtitle2" emphasis='high'>{description}</Typography>
      </div>
    </div>
  );
}