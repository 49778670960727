import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '@pixelcanvas/ui';
import { GamesSessionResponse } from 'src/features/Dashboard/interfaces/GameSession';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';
import PlatformSpinner from '../../../../components/PlatformSpinner/PlatformSpinner';
import { ReactComponent as RefreshIcon } from '../../../../assets/svg/refresh-icon-solid.svg';
import { ReactComponent as TotalPlayerCountIcon } from '../../../../assets/svg/users-icon-solid.svg';
import { ReactComponent as HeaderPlayerIcon } from '../../../../assets/svg/user-group-icon-solid.svg';
import { ReactComponent as ServerIcon } from '../../../../assets/svg/server-icon-solid.svg';
import { ReactComponent as FullIcon } from '../../../../assets/svg/empty-set-icon-solid.svg';
import { faCircleCheck as defaultCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck as selectedCheck } from '@fortawesome/pro-solid-svg-icons';

import { selectCurrentGameSessionId } from 'src/features/PixelStreaming/redux/gamelift';

import styles from './ServerBrowser.module.scss';


interface Props {
  open: boolean;
  isLoading: boolean;
  gameSessions: GamesSessionResponse[];
  onRefresh: () => void;
  onClose: () => void;
  onConfirm: (gameSessionId: string) => void;
}

export default function ServerBrowserModal({
  open, onRefresh, onClose, onConfirm, gameSessions, isLoading,
}: Props) {
  const currentGameSessionId = useSelector(selectCurrentGameSessionId);
  const [selectedSessionId, setSelectedSessionId] = useState('');

  const closeModal = () => {
    setSelectedSessionId('');
    onClose();
  };

  const playerCount = gameSessions.reduce((acc, session) => acc + session.CurrentPlayerSessionCount, 0);
  const rowElements = gameSessions.map(({
    Name, GameSessionId, CurrentPlayerSessionCount, MaximumPlayerSessionCount, GameProperties
  }) => {
    const roomAccess = GameProperties.find((property) => property.Key === 'roomAccess')?.Value;
    const isFull = CurrentPlayerSessionCount >= MaximumPlayerSessionCount;
    const isCurrent = GameSessionId === currentGameSessionId;

    if (roomAccess !== 'public') {
      return null;
    }

    return (
      <tr
        className={
          clsx(styles['row-data'],
            { [styles.disabled]: isFull },
            { [styles.selected]: selectedSessionId === GameSessionId })
        }
        key={Name}
        onClick={() => setSelectedSessionId(GameSessionId)}
      >
        <td className={styles.item}>
          <div className={styles['server-name-container']}>
            <div className={styles['full-container']}>
              {isFull &&
                <FullIcon className={styles.icon}>&#8709;</FullIcon>}
            </div>
            {Name.replace(/_/g, ' ')}
          </div>
        </td>
        <td className={clsx(styles.item, styles['player-count'])}>
          {CurrentPlayerSessionCount}/{MaximumPlayerSessionCount}
          {
            isCurrent &&
            <FontAwesomeIcon
              icon={selectedSessionId === GameSessionId ? selectedCheck : defaultCheck}
              className={clsx(
                styles.checkicon,
              )}
            />
          }
        </td>
      </tr>
    );
  });

  return (
    <VisitorModal
      className={styles.modal}
      open={open}
      onClose={closeModal}
    >
      <VisitorModalContent
        className={styles.content}
      >
        <div className={styles['player-count-container']}>
          <TotalPlayerCountIcon className={styles.icon} />
          <span className={styles.header}>Total Live Players: {playerCount}</span>
        </div>
        <PlatformSpinner visible={isLoading} />
        <div className={styles.title}>
          Select a Server
        </div>
        <div className={styles.subtext}>
          By selecting a server, you can choose the group of people with whom you play. So if you want to go through the experience with friends and be able to see what they do live, be sure to all select the same server. As you go to various different spaces, you will need to select a server together again to be put in the same instance for that space.
        </div>
        <div className={styles.container}>
          <table className={styles.table}>
            <thead className={styles.head}>
              <tr className={styles['row-header']}>
                <th className={styles.header}>
                  <ServerIcon className={styles.icon} />
                  SERVER NAME
                </th>
                <th className={styles.header}>
                  <HeaderPlayerIcon className={styles.icon} />
                  PLAYERS
                </th>
              </tr>
            </thead>
            {!isLoading && <tbody className={styles.body}>{rowElements}</tbody>}
          </table>
          {
            !isLoading && !gameSessions?.length &&
            <div className={styles.empty}>
              <h3 className={styles['empty-header']}>NO GAME SESSION AVAILABLE</h3>
            </div>
          }
        </div>
      </VisitorModalContent>
      <VisitorModalActions className={styles['modal-actions']}>
        <Button color="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onRefresh}>
          <RefreshIcon className={styles.refresh} />
          Refresh
        </Button>
        <Button
          color="primary"
          disabled={!selectedSessionId}
          onClick={() => onConfirm(selectedSessionId)}
        >
          Join
        </Button>
      </VisitorModalActions>
    </VisitorModal>
  );
}
