import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

interface GameliftState {
  currentGameSessionId: string;
}

const initialState: GameliftState = {
  currentGameSessionId: '',
};

export const gameliftSlice = createSlice({
  name: 'gamelift',
  initialState,
  reducers: {
    setCurentGameSessionId: (state, action: PayloadAction<string>) => ({ ...state, currentGameSessionId: action.payload }),
  },
});

export const {
  setCurentGameSessionId,
} = gameliftSlice.actions;

export const selectCurrentGameSessionId = (state: RootState) => state.gamelift.currentGameSessionId;

export default gameliftSlice.reducer;
