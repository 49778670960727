import { Tab } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { forwardRef, ReactNode, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from './TabNavLink.module.scss';

export interface TabNavLinkProps {
  className?: string;
  to: string;
  end?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  [key: string]: any;
}

const TabNavLink = forwardRef<HTMLButtonElement, TabNavLinkProps>(({
  className,
  to,
  end,
  onClick,
  onMatch,
  children,
  ...props
}: TabNavLinkProps, ref) => {
  const match = useMatch({ path: to, end });
  const navigate = useNavigate();
  useEffect(() => {
    if (!match) return;
    onMatch?.();
  }, [match]);
  const handleClick = () => {
    onClick?.();
    navigate(to);
  };
  return (
    <Tab
      ref={ref}
      selected={!!match}
      className={clsx(className, styles.tab)}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Tab>
  );
});

TabNavLink.displayName = 'TabNavLink';
TabNavLink.defaultProps = {
  className: '',
  children: null,
};

export default TabNavLink;
