import Button from '../../Button';

import styles from './UsageWarningContainer.module.scss';

type UsageWarningContainerProps = {
  isOwner: boolean;
  logo?: string;
  onRequestTime: () => void;
};

const OWNER_MSG = 'This 3D space has run out of streaming time. Please contact Pixel Canvas to request more.';
const ATTENDEE_MSG = 'This 3D space has run out of streaming time. Please come back later.';

const UsageWarningContainer = ({
  isOwner,
  logo,
  onRequestTime,
}: UsageWarningContainerProps) => (
  <div className={styles.wrapper}>
    {
      logo &&
      <div className={styles.logoContainer}>
        <img className={styles.logo} alt="" src={logo} />
      </div>
    }
    <div className={styles.container}>
      <p className={styles.text}>
        {isOwner ? OWNER_MSG : ATTENDEE_MSG}
      </p>
      { isOwner &&
        <Button
          className={styles.button}
          color="primary"
          onClick={onRequestTime}
        >
          Request More Time
        </Button>}
    </div>
  </div>
);

export default UsageWarningContainer;
