import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ContentInputList, InputFieldsGroup, MainContent, TextInputField, Typography } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { faArrowUpRightFromSquare, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import ButtonGatedTextInptField from 'src/features/Dashboard/components/ButtonGatedTextInputField';
import { ShowcaseDocument, ShowcaseTabDocument } from 'src/interfaces/IShowcase';
import { isShowcaseImageContent, isShowcaseLinkContent, isShowcaseSlideshowContent, isShowcaseVideoContent, ShowcaseContentDocument } from 'src/interfaces/IShowcaseContent';
import { useDeleteShowcaseContent, useDeleteShowcaseTab, useShowcase, useUpdateShowcase, useUpdateShowcaseTab } from 'src/queries/showcase';
import useCurrentMetaverse from '../../../hooks/useCurrentMetaverse';
import { setTitle } from '../../../redux/editMetaverse';
import AddShowcaseTabModal from '../components/AddShowcaseTabModal';

import ContentModal from '../components/ContentModal';
import styles from './EditMetaverseShowcase.module.scss';

export default function EditMetaverseShowcase() {
  const { guid } = useParams();
  const dispatch = useDispatch();
  const { metaverse } = useCurrentMetaverse();

  const { data: showcase } = useShowcase(metaverse?._id, guid);

  useEffect(() => {
    if (!showcase) {
      dispatch(setTitle('Showcase'));
      return;
    }
    dispatch(setTitle(`Showcase: ${showcase.name}`));
  }, [showcase]);

  return (
    <MainContent classes={{ content: styles.content }}>
      {showcase && (
        <>
          <Showcase key={showcase._id} showcase={showcase} />
          <ShowcaseTabs showcase={showcase} />
        </>
      )}
    </MainContent>
  );
}

interface ShowcaseProps {
  showcase: ShowcaseDocument;
}

function Showcase({ showcase }: ShowcaseProps) {
  const {
    mutate: updateShowcase,
    isLoading,
  } = useUpdateShowcase(showcase.metaverseId, showcase.guid);
  const [name, setName] = useState(showcase.name);
  const [description, setDescription] = useState(showcase.description);
  useEffect(() => {
    console.log('mounted showcase');
    return () => console.log('unmounted showcase');
  }, []);
  return (
    <InputFieldsGroup>
      <TextInputField
        label="Name"
        placeholder="Enter name"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => updateShowcase({ name })}
      />
      <ShowcaseGuidInputField showcase={showcase} />
      <TextInputField
        label="Description"
        placeholder="Enter description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={() => updateShowcase({ description })}
      />
      <PlatformSpinner visible={isLoading} />
    </InputFieldsGroup>
  );
}

interface ShowcaseGuidInputFieldProps {
  showcase: ShowcaseDocument;
}

function ShowcaseGuidInputField({ showcase }: ShowcaseGuidInputFieldProps) {
  const navigate = useNavigate();
  const { metaverse } = useCurrentMetaverse();
  const { guid } = useParams();
  const [value, setValue] = useState(showcase.guid);

  const { mutate, isSuccess, reset, data } = useUpdateShowcase(metaverse?._id, guid);

  useEffect(() => {
    if (!isSuccess || !data) return;
    navigate(`/edit/showcases/${data.guid}`);
    reset();
  }, [isSuccess, data]);

  const handleConfirm = () => {
    mutate({ guid: value });
    console.log('confirm');
    console.log(value);
  };

  return (
    <ButtonGatedTextInptField
      label="GUID"
      placeholder="Enter GUID that needs to match the GUID in the Unreal build"
      required
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onCancel={() => setValue(showcase.guid)}
      onConfirm={handleConfirm}
    />
  );
}

interface ShowcaseTabsProps {
  showcase: ShowcaseDocument;
}

function ShowcaseTabs({ showcase }: ShowcaseTabsProps) {
  const { tabs } = showcase;
  const [openAddTab, setOpenAddTab] = useState(false);
  return (
    <>
      <InputFieldsGroup>
        <Button color="secondary" onClick={() => setOpenAddTab(true)}>
          <FontAwesomeIcon className={styles.icon} icon={faCirclePlus} />
          Add Tab
        </Button>
      </InputFieldsGroup>
      <InputFieldsGroup title="Tabs">
        {tabs.map((tab) => (<ShowcaseTab key={tab._id} tab={tab} />))}
      </InputFieldsGroup>
      <AddShowcaseTabModal open={openAddTab} onClose={() => setOpenAddTab(false)} />
    </>
  );
}

interface ShowcaseTabProps {
  tab: ShowcaseTabDocument;
}

function ShowcaseTab({ tab }: ShowcaseTabProps) {
  const [expanded, setExpanded] = useState(false);
  const { metaverse } = useCurrentMetaverse();
  const { guid } = useParams();
  const [openAddContent, setOpenAddContent] = useState(false);
  const { mutate: deleteTab } = useDeleteShowcaseTab(metaverse?._id, guid);
  const { mutate: updateTab } = useUpdateShowcaseTab(metaverse?._id, guid, tab._id);

  const [name, setName] = useState(tab.name);
  const [contentToEdit, setContentToEdit] = useState<ShowcaseContentDocument | undefined>();
  const contents = tab.contents ?? [];

  return (
    <InputFieldsGroup>
      <div className={styles.tabactions}>
        <TextInputField
          className={styles.tabname}
          label="Name"
          placeholder="Enter name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => updateTab({ name: e.target.value })}
        />
        <Button
          onClick={() => deleteTab(tab._id)}
          color="tertiary"
        >
          <FontAwesomeIcon className={styles.icon} icon={faTrashCan} />
          Delete Tab
        </Button>
      </div>
      <ContentInputList
        label={tab.name}
        expanded={expanded}
        onToggle={setExpanded}
        onAdd={() => setOpenAddContent(true)}
      >
        {contents.map((content) => (
          <ShowcaseContent
            key={content._id}
            guid={guid}
            content={content}
            onEdit={() => setContentToEdit(content)}
          />
        ))}
      </ContentInputList>
      <ContentModal
        open={openAddContent}
        onClose={() => setOpenAddContent(false)}
        tab={tab}
      />
      <ContentModal
        key={contentToEdit?._id}
        contentToEdit={contentToEdit}
        open={!!contentToEdit}
        onClose={() => setContentToEdit(undefined)}
        tab={tab}
      />
    </InputFieldsGroup>
  );
}

interface ShowcaseContentProps {
  guid: string | undefined;
  content: ShowcaseContentDocument;
  onEdit: (content: ShowcaseContentDocument) => void;
}

function ShowcaseContent({ guid, content, onEdit }: ShowcaseContentProps) {
  const { metaverse } = useCurrentMetaverse();
  const { mutate: deleteContent, isLoading } = useDeleteShowcaseContent(
    metaverse?._id,
    guid,
  );
  return (
    <ContentInputList.Content className={styles.showcasecontent}>
      <div className={styles.text}>
        <Typography variant="h4">{content.name}</Typography>
        <Typography variant="h6" emphasis="header">Description</Typography>
        <Typography variant="body1">{content.description}</Typography>
      </div>
      {isShowcaseVideoContent(content) && (
        <video className={styles.video} src={content.src} />
      )}
      {isShowcaseImageContent(content) && (
        <img className={styles.image} src={content.src} alt="" />
      )}
      {isShowcaseLinkContent(content) && (
        <a className={styles.link} href={content.href} target="_blank" rel="noreferrer">
          {content.href}
          <FontAwesomeIcon className={styles.icon} icon={faArrowUpRightFromSquare} />
        </a>
      )}
      {isShowcaseSlideshowContent(content) && (
        <p className={styles.link}>{content.images.length} images</p>
      )}
      <div className={styles.thumbnail}>
        <Typography variant="h6" emphasis="header">Thumbnail</Typography>
        {content.thumbnail && <img src={content.thumbnail} alt="" />}
      </div>
      <PlatformSpinner visible={isLoading} />
      <ContentInputList.ContentActions
        onDelete={() => deleteContent(content._id)}
        onEdit={() => onEdit(content)}
      />
    </ContentInputList.Content>
  );
}
