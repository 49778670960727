import clsx from 'clsx';
import { useRef, useState } from 'react';
import { Autocomplete } from '@pixelcanvas/ui';
import { shallowEqual, useSelector } from 'react-redux';

import { selectMetaverseId } from 'src/redux/metaverse';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import IUser from '../../../../interfaces/IUser';
import { getMetaverseVisitorsPaginatedSearch } from '../../../../services/MetaverseService';
import { inviteToRoom } from '../../../../services/RoomService';
import { getUserDisplayName } from '../../../../utils/DisplayNameUtil';
import { ReactComponent as RemoveIcon } from '../../../../assets/svg/times.svg';

import styles from './RoomInviteModal.module.scss';
import Button from '../Button';

interface RoomInviteModalProps {
  visible: boolean;
  onClose: Function;
  roomId: string;
}

const SEARCH_DELAY = 300; // ms to wait before making a query

export default function RoomInviteModal({ visible, onClose, roomId }: RoomInviteModalProps) {
  const { inviteUsersToRoom } = useSocketContext();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [inviteQuery, setInviteQuery] = useState('');
  const [invitees, setInvitees] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const searchTimer = useRef<any>();

  const onSearchChange = async (inviteQuery: string) => {
    setInviteQuery(inviteQuery);
    clearTimeout(searchTimer.current);
    if (inviteQuery === '') {
      setSearchResults([]);
    } else {
      const timeout = setTimeout(async () => {
        const users = (await getMetaverseVisitorsPaginatedSearch(metaverseId, 0, 10, inviteQuery)).map((user: any) => ({
          ...user.participant,
          userId: user.userId,
        }));
        setSearchResults(users);
      }, SEARCH_DELAY);
      searchTimer.current = timeout;
    }
  };

  async function sendRoomInvites() {
    const userIds = invitees.map((item: IUser) => item.userId);
    await inviteToRoom(metaverseId, roomId, userIds);
    inviteUsersToRoom(roomId, userIds);
    onClose();
  }

  return (
    <VisitorModal
      className={styles['room-invite-modal']}
      open={visible}
      onClose={() => onClose()}
    >
      <VisitorModalContent
        className={styles['modal-content']}
      >
        <div className={styles['modal-header']}>
          Invite Guest
        </div>
        <div className={styles['modal-description']}>
          SEND INVITES TO
        </div>
        <Autocomplete
          inputProps={{
            className: clsx(styles['invite-input']),
            placeholder: 'Enter a name...',
          }}
          getItemValue={(user: IUser) => getUserDisplayName(user)}
          value={inviteQuery}
          items={searchResults}
          onSelect={(value, item) => {
            setInvitees(invitees.concat(item));
          }}
          onChange={(event, value) => onSearchChange(value)}
          renderItem={(user, isHighlighted) => (
            <div
              className={clsx(styles.item, { [styles['item-highlighted']]: isHighlighted })}
              key={user.userId}
            >
              {getUserDisplayName(user)}
            </div>
          )}
          menuStyle={{
            background: '#FFFFFFBF',
            marginRight: '4rem',
            boxShadow: '0px 0.3rem 0.6rem #00000029',
            borderRadius: '0.5rem',
            backdropFilter: 'blur(3.5rem)',
            userSelect: 'none',
          }}
        />
        <div className={styles['invitee-chips']}>
          {invitees && invitees.map((invitee: IUser) => (
            <div key={invitee.userId} className={styles['invitee-chip']}>
              <div className={styles.name}>{getUserDisplayName(invitee)}</div>
              <div
                className={styles.remove}
                onClick={() => {
                  setInvitees(invitees.filter((i: IUser) => i.userId !== invitee.userId));
                }}
              >
                <RemoveIcon className={styles['remove-icon']} />
              </div>
            </div>
          ))}
        </div>
      </VisitorModalContent>
      <VisitorModalActions>
        <Button
          className={styles['cancel-button']}
          color="tertiary"
          onClick={(e: any) => { e.stopPropagation(); onClose(); }}
        >
          Cancel
        </Button>
        <Button
          className={clsx(styles['save-button'], { [styles.disabled]: invitees.length === 0 })}
          color="primary"
          onClick={() => sendRoomInvites()}
        >
          Save
        </Button>
      </VisitorModalActions>
    </VisitorModal>
  );
}
