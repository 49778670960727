import React from 'react';
import RoomsSocketContextProvider from '../../../contexts/RoomsSocketContextProvider';
import VideoContextProvider from '../components/VideoChat/VideoContextProvider';
import NotificationsContextProvider from '../components/Notifications/NotificationsContextProvider';

interface PixelConnectProps {
  userId: string;
  authToken: string;
  metaverseId: string;
  children: React.ReactNode;
  textChatEnabled: boolean;
  videoChatEnabled: boolean;
}

const PixelConnect = ({
  userId, authToken, metaverseId, children, textChatEnabled, videoChatEnabled,
}: PixelConnectProps) => {
  if (videoChatEnabled) {
    return (
      <RoomsSocketContextProvider userId={userId} authToken={authToken} metaverseId={metaverseId}>
        <VideoContextProvider>
          <NotificationsContextProvider>
            {children}
          </NotificationsContextProvider>
        </VideoContextProvider>
      </RoomsSocketContextProvider>
    );
  }
  if (textChatEnabled) {
    return (
      <RoomsSocketContextProvider userId={userId} authToken={authToken} metaverseId={metaverseId}>
        {children}
      </RoomsSocketContextProvider>
    );
  }
  return (
    <>
      {children}
    </>
  );
};

export default PixelConnect;
