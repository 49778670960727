import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from 'react-use';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import { useOrganizationMembers, useOrganizations, useOrganizationTeam } from 'src/queries/organization';
import { setOrganizationDetails } from 'src/redux/organization';
import { OrganizationResponse } from '../../../../../interfaces/IOrganization';
import { OrganizationMemberResponse, OrganizationTeamMemberResponse } from '../../../../../interfaces/IOrganizationMember';
import { useSearchParams } from 'react-router-dom';

const storageKey = 'pixelcanvas::organizationid';

export interface IOrganizationContext {
  organizations: OrganizationResponse[],
  currentOrganization: OrganizationResponse | undefined,
  currentOrganizationId: string | undefined,
  userMembership: OrganizationMemberResponse | undefined,
  organizationMembers: OrganizationMemberResponse[],
  organizationTeam: OrganizationTeamMemberResponse[],
  changeCurrentOrganization: (organizationId: string) => void,
}

export const OrganizationContext = React.createContext<IOrganizationContext>(null!);

export function useOrganizationContext(): IOrganizationContext {
  const context = useContext<IOrganizationContext>(OrganizationContext);
  if (!context) {
    throw new Error('useOrganizationContext must be used within an OrganizationContext');
  }
  return context;
}

type OrganizationContextProviderProps = {
  children: React.ReactNode;
};

const OrganizationContextProvider = ({ children }: OrganizationContextProviderProps) => {
  const dispatch = useDispatch();
  const { user } = usePxAuthContext();
  const [organizationId, setOrganizationId, removeOrganizationId] =
    useLocalStorage<string>(storageKey);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: organizations = [],
    isSuccess: organizationsLoaded,
    refetch: refetchOrganizations,
  } = useOrganizations();
  const {
    data: organizationMembers = [],
    error: getOrganizationMembersError,
  } = useOrganizationMembers(organizationId);
  const {
    data: organizationTeam = [],
  } = useOrganizationTeam(organizationId);

  useEffect(() => {
    if (getOrganizationMembersError?.response?.status === 404) {
      removeOrganizationId();
    }
  }, [getOrganizationMembersError]);

  useEffect(() => {
    if (!organizationsLoaded) return;
    const id = searchParams.get('organization');
    if (!id || !organizationId) return;
    setOrganizationId(id);
    searchParams.delete('organization');
    setSearchParams(searchParams);
  }, [searchParams, organizationsLoaded]);

  useEffect(() => {
    if (organizationId || !organizationsLoaded || organizations.length === 0) return;
    setOrganizationId(organizations[0]._id);
  }, [organizations, organizationsLoaded, organizationId]);

  const currentOrganization = organizations
    .find((org: OrganizationResponse) => org._id === organizationId);

  useEffect(() => {
    if (!currentOrganization) return;
    dispatch(setOrganizationDetails(currentOrganization));
  }, [currentOrganization]);

  const userMembership = organizationMembers
    .find((member: OrganizationMemberResponse) => member.userId === user?._id);

  const changeCurrentOrganization = (id: string) => {
    setOrganizationId(id);
    refetchOrganizations();
  };

  const value: IOrganizationContext = {
    organizations,
    currentOrganization,
    currentOrganizationId: organizationId,
    organizationMembers,
    organizationTeam,
    userMembership,
    changeCurrentOrganization,
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContextProvider;
