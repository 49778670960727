import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import api from '../services/api';

export const queryKey = 'gamelift';

export const gameliftKeys = {
  all: [queryKey] as const,
  lists: () => [queryKey, 'list'] as const,
  list: (filters: string | undefined) => [...gameliftKeys.lists(), { filters }] as const,
  items: () => [queryKey, 'item'] as const,
  item: (id: string | undefined) => [...gameliftKeys.items(), id] as const,
};

interface CreateGameLiftSessionProps {
  metaverseId: string;
  aliasId: string;
  roomAccess: string;
  mapName: string;
}

export function useGetRandomGameLiftSession(
  metaverseId: string | undefined,
  aliasId: string| undefined,
  mapName: string | undefined,
) {
  return useQuery<{ GameSessionId: string }, AxiosError>(
    gameliftKeys.list(`${metaverseId}-${aliasId}-${mapName}`),
    () => api
      .get('/api/gamelift/session/random', { params: { metaverseId, aliasId, mapName } })
      .then((res) => res.data),
    { enabled: !!metaverseId && !!aliasId && !!mapName, staleTime: Infinity },
  );
}

export const useCreateGameLiftSession = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (props: CreateGameLiftSessionProps) => api
      .post('/api/gamelift/sessions', props)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(gameliftKeys.lists());
      },
    },
  );
};
