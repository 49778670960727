export const SET_CHANNELS = 'SET_CHANNELS';

const initialState = [];

export const channelsReducer = (state = initialState, action = {}) => {
  let newState;
  switch (action.type) {
    case SET_CHANNELS: {
      newState = [...action.payload];
      return newState;
    }
    default: return state;
  }
};

export function setChannels(data) {
  return { type: SET_CHANNELS, payload: data };
}
