import { Player as TwilioPlayer } from '@twilio/live-player-sdk';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { selectMetaverseId } from 'src/redux/metaverse';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import { PlatformRole, Room, SocketEvent } from '../../../../interfaces';
import { IContent } from '../../../../interfaces/IContent';
import { setSelectedTab } from '../../../../redux/pixelconnect';
import {
  endTwilioStream, getRoomByUniqueId, getRoomParticipant, getVideoToken, startTwilioStream,
} from '../../../../services/RoomService';
import { getURLVideoID } from '../../../../utils/YoutubeUrlUtil';
import { SidepanelContent } from '../../../PixelConnect/components/PixelConnectOverlay/Sidepanel/Sidepanel';
import useVideoContext from '../../../PixelConnect/components/VideoChat/useVideoContext';
import UpNext from '../UpNext/UpNext';

import styles from './StageContent.module.scss';
import clsx from 'clsx';

interface StartLivestreamMessage {
  userId: string,
  roomId: string,
}
interface StageContentProps {
  content: IContent | null;
  contentSource: string;
  upNextContent: IContent | null;
  livestreamRooms?: Room[];
}

export default function StageContent({
  content, contentSource, upNextContent, livestreamRooms,
}: StageContentProps) {
  const dispatch = useDispatch();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const userId = useSelector((state: any) => state.userProfile.userId, shallowEqual);
  const { joinSocketRoom, socket } = useSocketContext();
  const {
    connectVideoChat, videoRoom, disposeRoom, inLiveRoom,
  } = useVideoContext();
  const [speakerRoom, setSpeakerRoom] = useState<Room>();
  const [liveStreamPlayer, setLiveStreamPlayer] = useState<TwilioPlayer>();
  const [liveStreamUrl, setLiveStreamUrl] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null!);

  useLayoutEffect(() => {
    if (liveStreamPlayer) {
      liveStreamPlayer.attach(videoRef.current);
      liveStreamPlayer.play();
    }
  }, [liveStreamUrl]);

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvent.LivestreamStarted, onLivestreamStarted);
    }
    return () => {
      socket?.off(SocketEvent.LivestreamStarted, onLivestreamStarted);
    };
  }, [socket, livestreamRooms]);

  useEffect(() => {
    if (content?.pixelCanvasStream) {
      // Check if the stream is live
      fetchAudienceToken();
      // Try fetching room info for room speakers
      fetchContentRoom(content._id as string);
    }
  }, [content]);

  useEffect(() => {
    if (!inLiveRoom) {
      fetchAudienceToken();
    }
  }, [inLiveRoom]);

  useEffect(() => {
    if (liveStreamPlayer) {
      liveStreamPlayer.on(TwilioPlayer.Event.StateChanged, playerStateChanged);
    }

    return () => {
      liveStreamPlayer?.off(TwilioPlayer.Event.StateChanged, playerStateChanged);
    };
  }, [liveStreamPlayer]);

  function onLivestreamStarted(livestreamStarted: StartLivestreamMessage) {
    if (livestreamStarted.userId !== userId && !inSpeakerRoom()) {
      fetchAudienceToken();
    }
  }

  async function fetchContentRoom(contentId: string) {
    const contentSpeakerRoom = await getRoomByUniqueId(metaverseId, `${contentId}_livestream_table`);
    if (!contentSpeakerRoom) {
      return;
    }
    const participant = await getRoomParticipant(metaverseId, contentSpeakerRoom._id, userId);
    if (participant?.roles?.includes(PlatformRole.Moderator)) {
      setSpeakerRoom(contentSpeakerRoom);
    }
  }

  function parseContentSource() {
    try {
      // Try parsing for youtube url
      const videoID = getURLVideoID(contentSource);
      return `https://www.youtube.com/embed/${videoID}`;
    } catch (e) {
      return contentSource;
    }
  }

  async function joinSpeakerRoom() {
    // Get room by uniqueId format: `${content._id}_livestream_table`
    if (speakerRoom) {
      joinSocketRoom(speakerRoom);
      disposeRoom();
      const videoToken = (await getVideoToken(metaverseId, speakerRoom._id) as any).accessToken;
      await connectVideoChat(speakerRoom._id, speakerRoom.name, videoToken);
      dispatch(setSelectedTab(SidepanelContent.VideoChatTab));
      setLiveStreamPlayer(undefined);
      setLiveStreamUrl('');
    }
  }

  async function goLiveOrEndStream() {
    if (speakerRoom && !inLiveRoom) {
      await startTwilioStream(metaverseId, speakerRoom._id);
      const room = livestreamRooms?.[0];
      if (room) {
        const packet: StartLivestreamMessage = {
          userId,
          roomId: room._id,
        };
        socket?.emit(SocketEvent.LivestreamStarted, packet);
      }
    } else if (speakerRoom && inLiveRoom) {
      await endTwilioStream(metaverseId, speakerRoom._id);
    }
  }

  async function fetchAudienceToken() {
    // const audienceToken = await getAudienceToken(metaverseId, `${content?._id}_livestream_table`);

    // if (audienceToken.token !== null) {
    //   const player = await TwilioPlayer.connect((audienceToken as any).token, { playerWasmAssetsPath: 'https://pixelevents-content-dev.s3.us-west-2.amazonaws.com/twilio-wasm-assets' });
    //   setLiveStreamPlayer(player);
    // }
  }

  function playerStateChanged() {
    if (liveStreamPlayer?.state === 'ready') {
      setLiveStreamUrl((liveStreamPlayer as any)._playbackUrl);
    }
  }

  function inSpeakerRoom() {
    return videoRoom && videoRoom?.name === speakerRoom?._id;
  }

  return (
    <div className={styles['stage-content-container']}>
      {
        speakerRoom &&
        <div className={styles['live-stream-controls']}>
          {
            inSpeakerRoom()
              ?
              <div className={clsx(styles['speaker-btn'], styles['go-live'])} onClick={() => goLiveOrEndStream()}>
                {inLiveRoom ? 'Stop Stream' : 'Go Live'}
              </div>
              :
              <div className={clsx(styles['speaker-btn'], styles['join-speaker-room'])} onClick={() => joinSpeakerRoom()}>
                Join Speaker Room
              </div>
          }
        </div>
      }
      <div className={styles['video-container']}>
        {
          content && !content.pixelCanvasStream &&
          <>
            {
              contentSource?.endsWith('.mp4')
                ?
                <video className={styles.video} src={contentSource} autoPlay controls />
                :
                <iframe
                  className={styles.video}
                  frameBorder="0"
                  width="1280"
                  height="720"
                  src={parseContentSource()}
                  allowFullScreen
                  allow="autoplay"
                />
            }
          </>
        }
        {
          content?.pixelCanvasStream && !inSpeakerRoom() &&
          <video ref={videoRef} className={styles.video} autoPlay controls />
        }
        {
          inSpeakerRoom() &&
          <div className={styles.video}>
            {inLiveRoom ? 'You are live' : ''}
          </div>
        }
        {
          !content &&
          <UpNext content={upNextContent} />
        }
      </div>
      <div className={styles.title}>
        {content ? content.title : upNextContent?.title}
      </div>
      <div className={styles.description}>
        <ReactMarkdown children={(content ? content.description : upNextContent?.description) || ''} linkTarget="_blank" />
      </div>
    </div>
  );
}
