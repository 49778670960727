import { useDispatch, useSelector } from 'react-redux';
import { usePixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';

import { closeUploadModal, selectOpenUploadModal, selectUploadModalContentType } from 'src/features/PixelStreaming/redux/hud';

import UploadContentModal from './UploadContentModal';

interface UploadContentModalContainerProps {
  isSiteAdmin?: boolean;
  metaverseId: string | undefined;
}

export default function UploadContentModalContainer({
  isSiteAdmin,
  metaverseId,
}: UploadContentModalContainerProps) {
  const dispatch = useDispatch();

  const open = useSelector(selectOpenUploadModal);
  const type = useSelector(selectUploadModalContentType);

  const { emitUIInteraction } = usePixelStreamingContext();

  const handleClose = () => dispatch(closeUploadModal());
  const handleUpload = (fileUrl: string) => {
    if (!emitUIInteraction) return;
    emitUIInteraction({
      type: 'upload_content_response',
      fileUrl,
    });
  };

  if (!metaverseId) return null;

  return (
    <UploadContentModal
      open={open}
      onClose={handleClose}
      isSiteAdmin={isSiteAdmin}
      onConfirm={handleUpload}
      type={type}
      metaverseId={metaverseId}
    />
  );
}
