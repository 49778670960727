import { createMongoAbility } from '@casl/ability';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { PartialDeep } from 'type-fest';

import { IMetaverse, ISpace, MetaverseResponse } from 'src/interfaces/IMetaverse';
import { IMetaverseCustomFields } from 'src/interfaces/IMetaverseCustomFields';
import IMetaverseVisitor from 'src/interfaces/IMetaverseVisitor';
import api from '../services/api';
import ImageSlot from 'src/interfaces/ImageSlot';
import Booth from 'src/interfaces/Booth';
import SceneSlot from 'src/interfaces/SceneSlot';

export const queryKey = 'metaverse';

export const metaverseKeys = {
  all: [queryKey] as const,
  lists: () => [queryKey, 'list'] as const,
  list: (filters: string | undefined) => [...metaverseKeys.lists(), { filters }] as const,
  items: () => [queryKey, 'item'] as const,
  item: (id: string | undefined) => [...metaverseKeys.items(), id] as const,
};

export function useMetaverses() {
  return useQuery<MetaverseResponse[], AxiosError>(
    metaverseKeys.list('accessible'),
    () => api
      .get('/api/v2/metaverses')
      .then((res) => res.data),
  );
}

export function useOrganizationMetaverses(organizationId: string | undefined) {
  return useQuery<MetaverseResponse[]>(
    [...metaverseKeys.list('organization'), organizationId],
    () => api
      .get(`/api/v2/organizations/${organizationId}/metaverses`)
      .then((res) => res.data),
    { enabled: !!organizationId },
  );
}

export const useCreateOrganizationMetaverse = (organizationId: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation(
    (environmentId: string) => api
      .post(`/api/v2/organizations/${organizationId}/metaverses`, { environmentId })
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.lists());
      },
    },
  );
};

export function useMetaverse(id: string | undefined, keepPreviousData = false) {
  return useQuery<MetaverseResponse, AxiosError>(
    metaverseKeys.item(id),
    () => api
      .get(`/api/v2/metaverses/${id}`)
      .then((res) => res.data),
    { enabled: !!id, keepPreviousData },
  );
}

export function useUpdateMetaverse(id: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: PartialDeep<IMetaverse>) => api
      .patch<MetaverseResponse>(`/api/v2/metaverses/${id}`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.item(id));
        queryClient.invalidateQueries(metaverseKeys.lists());
      },
    },
  );
}

export function useDeleteMetaverse(id: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(
    () => api
      .delete(`/api/v2/metaverses/${id}`)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.item(id));
        queryClient.invalidateQueries(metaverseKeys.lists());
      },
    },
  );
}

export function useUpdateMetaverseSpace(
  metaverseId: string | undefined,
  spaceId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: PartialDeep<ISpace>) => api
      .patch<IMetaverse>(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.item(metaverseId));
        queryClient.invalidateQueries(metaverseKeys.lists());
      },
    },
  );
}

export function useUpdateMetaverseSpaceLogo(
  metaverseId: string | undefined,
  spaceId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (image: string) => uploadMetaverseSpaceLogo(metaverseId, spaceId, image),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.item(metaverseId));
        queryClient.invalidateQueries(metaverseKeys.lists());
      },
    },
  );
}

async function uploadMetaverseSpaceLogo(
  metaverseId: string | undefined,
  spaceId: string | undefined,
  image: string,
) {
  const formData = new FormData();
  formData.append('logo', image);
  const response = await api.put(
    `/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/logo`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return response.data;
}

interface UseMetaverseOptions {
  keepPreviousData?: boolean;
  retry?: boolean;
  fastRetry?: boolean;
}

export function useMetaverseBySubdomain(subdomain: string | undefined, { keepPreviousData = false, retry = undefined, fastRetry = undefined }: UseMetaverseOptions = {}) {
  return useQuery<MetaverseResponse, AxiosError>(
    [...metaverseKeys.lists(), { subdomain }],
    () => api
      .get('/api/v2/metaverses/url', { params: { url: subdomain } })
      .then((res) => res.data),
    {
      enabled: !!subdomain,
      keepPreviousData,
      retry,
      retryDelay: fastRetry ? (attempt) => Math.min(10 * 2 ** attempt, 1000) : undefined,
    },
  );
}

export function useSubdomainAvailbility(subdomain: string, enabled: boolean) {
  return useQuery<{ msg: string }, AxiosError>(
    [...metaverseKeys.item(subdomain), 'url'],
    () => api
      .get('/api/v2/metaverses/url-availability', { params: { url: subdomain } })
      .then((res) => res.data),
    { enabled: enabled && !!subdomain, retry: false },
  );
}

export function useMetaverseJoin(metaverseId: string | undefined, userId: string | undefined) {
  return useQuery<IMetaverseVisitor, AxiosError>(
    [...metaverseKeys.item(metaverseId), 'participant'],
    () => api
      .post(`/api/v2/metaverses/${metaverseId}/participants`, { userId })
      .then((res) => res.data),
    { enabled: !!metaverseId && !!userId },
  );
}

export function useMetaverseAbility(metaverseId: string | undefined) {
  return useQuery(
    [...metaverseKeys.item(metaverseId), 'permissions'],
    () => api
      .get(`/api/v2/metaverses/${metaverseId}/permissions`)
      .then((res) => createMongoAbility(res.data)),
    { enabled: !!metaverseId },
  );
}

// TODO: Add functionality to search, limit and offset
export function useMetaverseVisitors(metaverseId: string | undefined, userIds?: string[] | undefined) {
  return useQuery(
    [...metaverseKeys.item(metaverseId), 'visitors'],
    () => api
      .get<IMetaverseVisitor[]>(
        `/api/v2/metaverses/${metaverseId}/participants`,
        { params: { userIds, useDb: true } },
      )
      .then((res) => res.data),
    { enabled: !!metaverseId },
  );
}

// TODO: Add functionality to search, limit and offset
export function useMetaverseDetailedVisitors(metaverseId: string | undefined) {
  return useQuery(
    [...metaverseKeys.item(metaverseId), 'detailed-visitors'],
    () => api
      .get<IMetaverseVisitor[]>(
        `/api/v2/metaverses/${metaverseId}/participant-details`,
      )
      .then((res) => res.data),
    { enabled: !!metaverseId },
  );
}

export function useMetaverseActiveVisitors(metaverseId: string | undefined, userIds?: string[] | undefined) {
  return useQuery(
    [...metaverseKeys.item(metaverseId), 'active-visitors'],
    () => api
      .get<IMetaverseVisitor[]>(
        `/api/v2/metaverses/${metaverseId}/participants`,
        { params: { userIds } },
      )
      .then((res) => res.data),
    { enabled: !!metaverseId },
  );
}

export function useMetaverseVisitor(metaverseId: string | undefined, userId: string | undefined) {
  return useQuery(
    [...metaverseKeys.item(metaverseId), 'visitor', userId],
    () => api
      .get<IMetaverseVisitor>(`/api/v2/metaverses/${metaverseId}/participants/${userId}`)
      .then((res) => res.data),
    { enabled: !!metaverseId && !!userId },
  );
}

export function useUpdateMetaverseVisitorAccess(
  metaverseId: string | undefined,
  userId: string | undefined,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (status: 'active' | 'banned') => api
      .patch(`/api/v2/metaverses/${metaverseId}/participants/${userId}/accesscontrol`, { status })
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.item(metaverseId));
      },
    },
  );
}

export const useMetaverseUsage = (metaverseId: string | undefined) => useQuery(
  [...metaverseKeys.item(metaverseId), 'usage'],
  () => api
    .get(`/api/v2/metaverses/${metaverseId}/usage`)
    .then((res) => res.data),
  { enabled: !!metaverseId },
);

export const useMetaverseCustomFields = (metaverseId: string | undefined) => useQuery<IMetaverseCustomFields, AxiosError>(
  [...metaverseKeys.item(metaverseId), 'customfields'],
  () => api
    .get(`/api/v2/metaverses/${metaverseId}/customfields`)
    .then((res) => res.data),
  { enabled: !!metaverseId },
);

export const useSetImageSlot = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  slotId: string | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (imageSlot: ImageSlot) => api
      .put(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/images/${slotId}`, imageSlot)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaverseKeys.item(metaverseId));
      },
    },
  );
}

export const useGetImageSlot = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  slotId: string | undefined,
) => useQuery<ImageSlot, AxiosError>(
  [...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'images', slotId],
  () => api
    .get<ImageSlot>(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/images/${slotId}`)
    .then((res) => res.data),
  { enabled: !!metaverseId && !!spaceId && !!slotId },
);

export const useBooth = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  boothId: string | undefined,
) => useQuery<Booth, AxiosError>(
  [...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'booths', boothId],
  () => api
    .get<Booth>(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/booths/${boothId}`)
    .then((res) => res.data),
  { enabled: !!metaverseId && !!spaceId && !!boothId },
);

export const useSetBooth = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  boothId: string | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (booth: Booth) => api
      .put(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/booths/${boothId}`, booth)
      .then((res) => res.data),
    {
      onSuccess: (booth) => {
        queryClient.setQueryData([...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'booths', boothId], booth);
      },
    },
  );
}

export const useDeleteBooth = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  boothId: string | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => api
      .delete(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/booths/${boothId}`)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'booths', boothId]);
      },
    },
  );
}

export const useSceneSlot = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  slotId: string | undefined,
  retry?: boolean,
) => useQuery<SceneSlot, AxiosError>(
  [...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'sceneries', slotId],
  () => api
    .get<SceneSlot>(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/sceneries/${slotId}`)
    .then((res) => res.data),
  { 
    enabled: !!metaverseId && !!spaceId && !!slotId,
    retry: retry ?? false
  },
);

export const useSetSceneSlot = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  slotId: string | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (sceneSlot: SceneSlot) => api
      .put(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/sceneries/${slotId}`, sceneSlot)
      .then((res) => res.data),
    {
      onSuccess: (sceneSlot) => {
        queryClient.setQueryData([...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'sceneries', slotId], sceneSlot);
      },
    },
  );
}

export const useDeleteSceneSlot = (
  metaverseId: string | undefined,
  spaceId: string | undefined,
  slotId: string | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => api
      .delete(`/api/v2/metaverses/${metaverseId}/spaces/${spaceId}/sceneries/${slotId}`)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...metaverseKeys.item(metaverseId), 'spaces', spaceId, 'sceneries', slotId]);
      },
    },
  );
}
