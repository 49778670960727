import { faCirclePlus, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FilterBy, MainContent, Search, Typography } from '@pixelcanvas/ui';
import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import EmailInviteModal from 'src/components/EmailInviteModal/EmailInviteModal';
import IMetaverseVisitor from 'src/interfaces/IMetaverseVisitor';
import { useMetaverseDetailedVisitors } from 'src/queries/metaverse';
import ExportParticipantsModal from '../../components/RegistrationsDetail/ExportParticipantsModal/ExportParticipantsModal';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { setTitle } from '../../redux/editMetaverse';
import VisitorsTable from './components/VisitorsTable/VisitorsTable';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';

import styles from './EditMetaverseVisitorsPage.module.scss';

const fuseOptions = {
  keys: ['participant.displayName', 'participant.email'],
};

export default function EditMetaverseVisitorsPage() {
  const dispatch = useDispatch();
  const { metaverse } = useCurrentMetaverse();
  const { data: visitors = [] } = useMetaverseDetailedVisitors(metaverse?._id);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(setTitle('Visitors'));
  }, []);

  const fuseRef = useRef(new Fuse<IMetaverseVisitor>([], fuseOptions));
  const fuse = fuseRef.current;

  useEffect(() => {
    fuse.setCollection(visitors);
  }, [visitors]);

  const filteredVisitors = search
    ? fuse.search(search.trim()).map((result) => result.item)
    : visitors;

  return (
    <MainContent classes={{ content: styles.content }} variant="table">
      <Typography variant="subtitle1">Manage and export visitors&apos; information. Accounts with moderation capabilities can also manage visitors on the metaverse site after the metaverse is published.</Typography>
      <div className={styles.actions}>
        <Search onChange={setSearch} />
        <FilterBy options={[]} />
        <Button color="tertiary" onClick={() => setIsExportModalVisible(true)}>
          <FontAwesomeIcon className={styles.icon} icon={faDownload} /> Export
        </Button>
        <InviteButton onClick={() => setInviteModalVisible(true)} />
      </div>
      <Typography variant="h2" emphasis="high">{visitors.length} Visitors</Typography>
      <VisitorsTable data={filteredVisitors} onEditRole={() => { }} onRemove={() => { }} />
      <EmailInviteModal visible={inviteModalVisible} onClose={() => setInviteModalVisible(false)} />
      <ExportParticipantsModal visible={isExportModalVisible} onClose={() => setIsExportModalVisible(false)} />
    </MainContent>
  );
}

interface InviteButtonProps {
  onClick: () => void;
}

function InviteButton(
  {
    onClick,
  }: InviteButtonProps,
) {
  const { metaverse } = useCurrentMetaverse();
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  return (
    <div
      ref={setReferenceElement}
      onMouseEnter={() => setTooltipOpen(true)}
      onPointerLeave={() => setTooltipOpen(false)}
    >
      <Button
        color="secondary"
        onClick={onClick}
        disabled={!metaverse?.published}
      >
        <FontAwesomeIcon className={styles.icon} icon={faCirclePlus} /> Invite
      </Button>
      {
        tooltipOpen && !metaverse?.published &&
        <Portal>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            className={styles.tooltip}
            {...attributes.popper}
          >
            <Typography variant="body2" emphasis="high">
              Please publish the metaverse before inviting.
            </Typography>
            <div ref={setArrowElement} style={popperStyles.arrow} className={styles.arrow} />
          </div>
        </Portal>
      }
    </div>
  );
}