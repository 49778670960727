import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { ethers } from 'ethers';
import Handlebars from 'handlebars';

import api from '../../services/api';
import { connectors } from '../connectors';
import { WalletLoginMethod } from '../LoginMethod';
import usePxAuthContext from '../usePxAuthContext';

function getConnector(method: WalletLoginMethod) {
  switch (method) {
    case WalletLoginMethod.MetaMask:
      return connectors.injected;
    case WalletLoginMethod.WalletConnect:
      return connectors.walletConnect;
    case WalletLoginMethod.CoinbaseWallet:
      return connectors.coinbaseWallet;
    default:
      throw new Error(`Unknown wallet login method ${method}`);
  }
}

export default function useWalletLogin() {
  const { activate, account, deactivate, library: provider, error: web3Error } = useWeb3React<ethers.providers.Web3Provider>();
  const { saveTokenResponse } = usePxAuthContext();
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    console.info({ account, provider });
  }, [account, provider]);

  useAsyncEffect(async () => {
    if (!account || !connecting || !provider) return;

    try {
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      let res;
      try {
        res = await api.get('/api/auth/eth/identity', { params: { address } });
      } catch (e: any) {
        res = await api.post('/api/auth/eth/identity', { address });
      }
      const { nonce } = res.data;
      const template = Handlebars.compile(
        window.env.REACT_APP_ETH_VERIFICATION_MESSAGE ??
          'Verification ID: {{nonce}}',
      );
      const message = template({ nonce });
      const signature = await signer.signMessage(message);
      const postTokenResponse = await api.post('/api/auth/eth/token', {
        address, signature,
      });
      saveTokenResponse(postTokenResponse.data);
    } catch (e: any) {
      setError(e);
    } finally {
      setConnecting(false);
      deactivate();
    }
  }, [account, connecting]);

  const login = async (method: WalletLoginMethod) => {
    try {
      setConnecting(true);
      await activate(getConnector(method), undefined, true);
    } catch (e: any) {
      setError(e);
      setConnecting(false);
    }
  };

  return {
    login,
    connecting,
    error: error ?? web3Error,
  };
}
