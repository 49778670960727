import clsx from 'clsx';
import { Typography } from '@pixelcanvas/ui';
import styles from './PointsNotification.module.scss';

interface PointsNotificationProps {
  className?: string;
  show?: boolean;
  points: number;
}

export default function PointsNotification({
  className,
  show = false,
  points,
}: PointsNotificationProps) {
  return (
    <div className={clsx(className, styles.container, { [styles.hide]: !show })}>
      <div className={styles.whiteBorder} />
      <div className={styles.textContainer}>
        <Typography variant='body1' emphasis='high'>
          Congratulations! You&apos;ve Earned {points} Points.
        </Typography>
      </div>
    </div >
  );
}