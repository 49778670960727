import { useEffect, useRef, useState } from 'react';
import Fuse from 'fuse.js';

import styles from './NFTSelectionModal.module.scss';
import VisitorModal from '../VisitorModal/VisitorModal';
import SearchBar from '../../../PixelConnect/components/SearchBar/SearchBar';
import NFTCard from './NFTCard/NFTCard';
import useGetNFTs from './useGetNFTs';

const fuseOptions = {
  keys: ['name', 'token.name'],
};

export type Props = {
  open: boolean;
  onNFTSelected: (selectedNFT: string) => void;
  onClose: Function;
};

const NFTSelectionModal = ({ open, onClose, onNFTSelected }: Props) => {
  const { nfts } = useGetNFTs(open);
  const [unloadableNFTs, setUnloadableNFTs] = useState<any[]>([]);
  const [loadedNFTs, setLoadedNFTs] = useState<any[]>([]);
  const [pattern, setPattern] = useState('');
  const fuseRef = useRef(new Fuse<any>([], fuseOptions));

  const fuse = fuseRef.current;

  useEffect(() => {
    fuse.setCollection(nfts);
  }, [nfts]);

  const onSelected = (picture: string) => {
    onNFTSelected(picture);
  };

  const onError = (nft: any) => setUnloadableNFTs(
    (currentUnloadableNfts) => [...currentUnloadableNfts, nft],
  );

  const selectedNfts = pattern.trim()
    ? fuse.search(pattern.trim()).map((result) => result.item)
    : nfts;

  const loadableNfts = selectedNfts
    .filter((nft: any) => !unloadableNFTs.includes(nft));

  const onLoad = (nft: any) => {
    setLoadedNFTs((currentLoadedNfts) => [...currentLoadedNfts, nft]);
  };

  const nftElements = loadableNfts
    // sort loaded NFTs before NFTs still loading
    .sort((a: any, b: any) => {
      if (loadedNFTs.includes(a) && !loadedNFTs.includes(b)) return -1;
      if (loadedNFTs.includes(b) && !loadedNFTs.includes(a)) return 1;
      return 0;
    })
    .map((nft: any) => <NFTCard
      key={nft.id}
      nft={nft}
      onSelected={(image: string) => onSelected(image)}
      onError={() => onError(nft)}
      onLoad={() => onLoad(nft)}
    />);

  return (
    <VisitorModal className={styles.modal} open={open} onClose={onClose}>
      <div className={styles.heading}>
        <div className={styles.header}>Select a NFT</div>
        <SearchBar className={styles.search} onChange={(e: any) => setPattern(e.target.value)} />
      </div>
      <div className={styles.nfts}>
        {nftElements}
      </div>
    </VisitorModal>
  );
};

NFTSelectionModal.displayName = 'NFTSelectionModal';

export default NFTSelectionModal;
