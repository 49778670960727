import { InputFieldsGroup, MainContent, TextInputField, ToggleSwitch } from '@pixelcanvas/ui';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import { useUpdateMetaverse } from 'src/queries/metaverse';

import SaveFooter from '../../../../components/SaveFooter/SaveFooter';
import { useToastMessageContext } from '../../../../components/ToastMessage/ToastMessageContextProvider';
import { setMetaverseDetails } from '../../../../redux/metaverse';
import { setTitle } from '../../redux/editMetaverse';

import styles from './EditMetaverseAdvancedSettings.module.scss';

export default function EditMetaverseAdvancedSettings() {
  useEffect(() => {
    dispatch(setTitle('Advanced Settings'));
  }, []);

  const dispatch = useDispatch();
  const { metaverse } = useAccessMetaverse();
  const { addToast } = useToastMessageContext();
  const [zendeskKey, setZendeskKey] = useState(metaverse?.crm.zendeskKey ?? '');

  useEffect(() => {
    setZendeskKey(metaverse?.crm.zendeskKey ?? '');
  }, [metaverse?.crm.zendeskKey]);

  const {
    mutate: updateMetaverse,
    data: updatedMetaverse,
    isSuccess,
    reset,
  } = useUpdateMetaverse(metaverse?._id);

  useEffect(() => {
    if (!isSuccess) return;
    addToast('Your changes were successfully saved.');
    reset();
  }, [isSuccess]);

  useEffect(() => {
    if (!updatedMetaverse) return;
    dispatch(setMetaverseDetails(updatedMetaverse));
  }, [updatedMetaverse]);

  const handleTechSupportChange = (value: boolean) => {
    updateMetaverse({ settings: { techSupportEnabled: value } });
  };

  const handleZendeskKeyBlur: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateMetaverse({ crm: { zendeskKey: e.target.value } });
  };

  const handleFaqChange = (value: boolean) => {
    updateMetaverse({ settings: { faqEnabled: value } });
  };

  const techSupportEnabled = !!metaverse?.settings.techSupportEnabled;

  return (
    <>
      <MainContent className={styles.container}>
        <InputFieldsGroup title="Settings">
          <div className={styles.setting}>
            <div className={styles.label}>FAQ Button</div>
            <ToggleSwitch
              checked={!!metaverse?.settings.faqEnabled}
              onChange={handleFaqChange}
            />
          </div>
          <div className={styles.setting}>
            <div className={styles.label}>Tech Support Button</div>
            <ToggleSwitch
              checked={techSupportEnabled}
              onChange={handleTechSupportChange}
            />
          </div>
        </InputFieldsGroup>
        {techSupportEnabled && (
          <InputFieldsGroup title="Tech Support">
            <TextInputField
              label="Zendesk Key"
              value={zendeskKey}
              onChange={(e) => setZendeskKey(e.target.value)}
              onBlur={handleZendeskKeyBlur}
            />
          </InputFieldsGroup>
        )}
      </MainContent>
      <SaveFooter disabled />
    </>
  );
}
