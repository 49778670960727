import React, { ReactNode, useContext, useEffect, useState } from 'react';
import useSocketContext from 'src/contexts/useRoomsSocketContext';
import SocketEvent from 'src/interfaces/SocketEvent';
import { useMetaverseUsage } from 'src/queries/metaverse';

export interface IUsageContext {
  hasUsage: boolean;
  isLoadingUsage: boolean;
  isUsageModalOpen: boolean;
  closeUsageModal: () => void;
}

export const UsageContext = React.createContext<IUsageContext>(null!);

interface UsageContextProviderProps {
  metaverseId: string;
  children: ReactNode;
}

const UsageContextProvider = ({ metaverseId, children }: UsageContextProviderProps) => {
  const [hasUsage, setHasUsage] = useState(false);
  const [isUsageModalOpen, setIsOpenUsageModalOpen] = useState(false);

  const { data, isLoading: isLoadingUsage } = useMetaverseUsage(metaverseId);
  useEffect(() => {
    if (!data) return;
    setHasUsage(data.remainingUsage > 0 || data.remainingUsage === null);
  }, [data]);

  const { socket } = useSocketContext();
  useEffect(() => {
    if (!socket) return () => { };
    const listener = () => {
      setIsOpenUsageModalOpen(true);
      setHasUsage(false);
    };
    socket.on(SocketEvent.UnrealUsageLimitReached, listener);
    return () => {
      socket.off(SocketEvent.UnrealUsageLimitReached, listener);
    };
  }, [socket]);

  const closeUsageModal = () => setIsOpenUsageModalOpen(false);

  return (
    <UsageContext.Provider
      value={{ hasUsage, isLoadingUsage, isUsageModalOpen, closeUsageModal }}
    >
      {children}
    </UsageContext.Provider>
  );
};

export function useUsageContext() {
  const context = useContext(UsageContext);
  if (!context) {
    throw new Error('useUsageContext must be used within a UsageContextProvider');
  }
  return context;
}

export default UsageContextProvider;
