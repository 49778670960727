import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import styles from './VisitorModal.module.scss';

interface Props {
  modalClassName?: string;
  className?: string;
  open: boolean;
  onClose: Function;
  closable?: boolean;
  children: React.ReactNode;
}

export default function VisitorModal({
  modalClassName,
  className,
  open,
  onClose,
  closable = true,
  children,
}: Props) {
  return (
    <Modal
      className={clsx(modalClassName, styles['modal-container'])}
      open={open}
      onClose={() => onClose()}
    >
      <div className={clsx(className, styles.modal)}>
        {closable && <FontAwesomeIcon icon={faXmark} className={styles.close} onClick={() => onClose()} />}
        {children}
      </div>
    </Modal>
  );
}
