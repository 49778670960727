import { Button } from '@pixelcanvas/ui';

import { ReactComponent as AngleRight } from '../../../../assets/svg/angle-right.svg';

import styles from './LaunchContainer.module.scss';

type LaunchContainerProps = {
  logo?: string;
  onEnter: () => void;
  showBrowse?: boolean;
  onBrowse?: () => void;
};

const LaunchContainer = ({
  logo,
  onEnter,
  showBrowse = false,
  onBrowse,
}: LaunchContainerProps) => (
  <div className={styles.wrapper}>
    {
      logo &&
      <div className={styles.logoContainer}>
        <img className={styles.logo} alt="" src={logo} />
      </div>
    }
    <div className={styles.container}>
      <p className={styles.text}>
        Click to <strong>ENTER</strong> the metaverse.
      </p>
      <Button className={styles.button} onClick={onEnter}>
        Enter <AngleRight className={styles.icon} />
      </Button>
      {
        showBrowse &&
        <Button className={styles.button} onClick={onBrowse}>
          Browse Servers
          <AngleRight className={styles.icon} />
        </Button>
      }
    </div>
  </div>
);

export default LaunchContainer;
