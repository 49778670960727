import AuthModal from './AuthModal';
import EmailModal from './EmailModal/EmailModal';
import WalletListModal from './WalletListModal/WalletListModal';
import GoogleLoginWideButton from './WideButtons/GoogleLoginWideButton';
import EmailLoginWideButton from './WideButtons/EmailLoginWideButton';
import WalletLoginWideButton from './WideButtons/WalletLoginWideButton';
import ButtonGroup from './ButtonGroup';

type ClassesProps = {
  modal?: string;
  container?: string;
};

interface Props {
  classes?: ClassesProps;
  open: boolean;
  onClose?: () => void;
  closable?: boolean;
}

export default function StandardAuthModal({
  classes = {},
  open,
  onClose = () => {},
  closable = true,
}: Props) {
  return (
    <AuthModal
      modalClassName={classes.modal}
      className={classes.container}
      open={open}
      onClose={onClose}
      closable={closable}
    >
      <ButtonGroup type="wide">
        <GoogleLoginWideButton />
        <EmailLoginWideButton />
      </ButtonGroup>
      <WalletListModal />
      <EmailModal defaultForm="login" />
    </AuthModal>
  );
}
