import { ActionModal, Typography } from '@pixelcanvas/ui';

import styles from './ErrorDialog.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  error: any;
}

export default function ErrorDialog({ open, onClose, error }: Props) {
  const message = error?.message || error || '';
  return (
    <ActionModal className={styles.modal} open={Boolean(open && error)} onClose={onClose}>
      <ActionModal.Title>Error</ActionModal.Title>
      <ActionModal.Content>
        <Typography variant="body1">{message}</Typography>
      </ActionModal.Content>
    </ActionModal>
  );
}
