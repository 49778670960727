/* eslint-disable class-methods-use-this */
import React from 'react';

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/times.svg';
import channelIcon from '../../../../../assets/svg/user-group-solid-circle.svg';
import { RoomsSocketContext } from '../../../../../contexts/RoomsSocketContextProvider';
import { Room, RoomType } from '../../../../../interfaces';
import { getDisplayName } from '../../../../../utils/DisplayNameUtil';

import styles from './ChatBubble.module.scss';

interface ChatBubbleProps {
  roomId: string;
  getRoom: Function;
  getRoomParticipants: Function;
}

interface ChatBubbleState {
  title: string;
  backgroundImageSrc: string;
  roomType: RoomType;
}

class ChatBubble extends React.Component<ChatBubbleProps, ChatBubbleState> {
  static contextType = RoomsSocketContext;

  static self: any;

  state = {
    title: '',
    backgroundImageSrc: '',
    roomType: RoomType.Public,
  };

  componentDidMount() {
    ChatBubble.self = this;
    this.loadRoom();
  }

  async loadRoom() {
    const { userId } = this.context;
    const { roomId, getRoom, getRoomParticipants } = this.props;

    const room: Room = await getRoom(roomId);
    let title = room.name;
    let backgroundImageSrc = channelIcon;
    if (room.type === RoomType.Direct) {
      const roomParticipants = await getRoomParticipants(roomId);
      const otherParticipants = roomParticipants
        .filter((user: any) => user.userId !== userId);
      title = otherParticipants
        .map((user: any) => getDisplayName(user.participant)).join(', ');
      if (otherParticipants.length === 1) {
        backgroundImageSrc = otherParticipants[0].participant.picture;
      }
    }
    this.setState({ title, backgroundImageSrc, roomType: room.type });
  }

  render() {
    const { roomId } = this.props;
    const { title, backgroundImageSrc } = this.state;
    const { hideRecentRoom, unreadMessageCounts, clearUnreadMessages } = this.context;
    return (
      <div
        className={styles['chat-bubble-container']}
        onClick={() => clearUnreadMessages(roomId)}
      >
        {backgroundImageSrc && <img className={styles['bubble-image']} src={backgroundImageSrc} />}
        {
          unreadMessageCounts[roomId] > 0 &&
          <div className={styles.badge}>
            {/* {unreadMessageCounts[roomId]} */}
          </div>
        }
        <div className={styles['tooltip-text']}>
          {title}
        </div>
        <div
          className={styles.close}
          onClick={(event: any) => {
            hideRecentRoom(roomId);
            event?.stopPropagation();
          }}
        >
          <CloseIcon className={styles.icon} />
        </div>
      </div>
    );
  }
}

export default ChatBubble;
