import { GamesSessionResponse } from 'src/features/Dashboard/interfaces/GameSession';
import api from './api';

export async function getPlayer(userId: string, metaverseId: string) {
  const response = await api.get(`/api/game/players/${userId}/${metaverseId}`);
  return response.data;
}

export async function getRanking(userId: string, metaverseId: string) {
  const response = await api.get(`/api/game/ranking/${userId}/${metaverseId}`);
  return response.data;
}

export async function getLeaderBoard(metaverseId: string) {
  const response = await api.get(`/api/game/leaderboard/${metaverseId}`);
  return response.data;
}

export async function getOrderedEscapeRoomLeaderBoard(metaverseId: string) {
  const response = await api.get(`/api/game/leaderboard/escaperoom/${metaverseId}`);
  return response.data;
}

export interface GameLiftSessionQuery {
  metaverseId: string;
  mapName?: string;
}

export async function getGameliftSessions(
  aliasId: string, query: GameLiftSessionQuery,
) {
  const response = await api.get<GamesSessionResponse[]>(
    '/api/gamelift/sessions',
    { params: { aliasId, ...query } },
  );
  return response.data;
}

export async function getRandomGameliftSession(
  metaverseId: string, aliasId: string, mapName: string | undefined,
) {
  console.log('executing getRandomGameliftSession', metaverseId, aliasId, mapName);
  const response = await api.get<GamesSessionResponse>(
    '/api/gamelift/session/random',
    { params: { metaverseId, aliasId, mapName } },
  );
  return response.data;
}

export async function createGameliftSession(metaverseId: string, aliasId: string, roomAccess: string, mapName: string) {
  const response = await api.post<GamesSessionResponse>('/api/gamelift/sessions', { metaverseId, aliasId, roomAccess, mapName });
  return response.data;
}

export async function describeGameliftSession(gameSessionId: string) {
  const response = await api.get<GamesSessionResponse>(`/api/gamelift/sessions/${gameSessionId}`);
  return response.data;
}
