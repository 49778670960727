import clsx from 'clsx';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import {
  forwardRef, useImperativeHandle, useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import styles from './WYSIWYGEditor.module.scss';

interface Props {
  title: string;
  initialValue?: string;
  onChange?: Function;
  showRequired?: boolean;
}

export type WYSIWYGEditorInterface = {
  clear: () => void,
};

const WYSIWYGEditor = forwardRef(({
  title, initialValue, onChange, showRequired,
}: Props, ref) => {
  const [editorState, setEditorState] = useState(initialValue ? EditorState.createWithContent(convertFromRaw(markdownToDraft(initialValue))) : EditorState.createEmpty());
  const [markdown, setMarkdown] = useState(initialValue || '');

  const toolbarOpts = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
  };

  const handleChange = (editorState: any) => {
    setEditorState(editorState);
    const updatedMarkdown = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
    setMarkdown(updatedMarkdown);
    if (onChange) onChange(updatedMarkdown);
  };

  function clear() {
    setEditorState(EditorState.createEmpty());
  }

  useImperativeHandle(ref, () => ({
    clear,
  }));

  return (
    <div className={styles['detail-input']}>
      <div className={clsx(styles['detail-label'], { [styles.required]: showRequired && markdown?.length === 0 })}>
        {title}
      </div>
      <div className={clsx(styles['input-container'], styles['wysiwyg-container'])}>
        <Editor
          wrapperClassName={styles['rdw-editor-wrapper']}
          toolbarClassName={styles['rdw-editor-toolbar']}
          editorState={editorState}
          toolbar={toolbarOpts}
          onEditorStateChange={handleChange}
        />
      </div>
    </div>
  );
});

WYSIWYGEditor.displayName = 'WYSIWYGEditor';

export default WYSIWYGEditor;
