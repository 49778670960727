import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';

import styles from './MetaversePreviewLink.module.scss';

interface MetaversePreviewLinkProps {
  className?: string;
}

export default function MetaversePreviewLink({ className }: MetaversePreviewLinkProps) {
  const { metaverseLink } = useCurrentMetaverse();
  return (
    <a className={clsx(className, styles.link)} href={metaverseLink} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faUpRightFromSquare} />
      <Typography className={styles.text} variant="body2">Metaverse Preview</Typography>
    </a>
  );
}
