import EventEmitter from 'events';
import { createContext, ReactNode, RefCallback, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Config, PixelStreaming, PlayStreamErrorEvent, PlayStreamEvent, PlayStreamRejectedEvent, StreamLoadingEvent, TextParameters, VideoInitializedEvent, WebRtcConnectedEvent, WebRtcConnectingEvent, WebRtcDisconnectedEvent, WebRtcFailedEvent } from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.2';

import { PixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';

import { SPSSignalling } from './SignallingExtension';

import styles from './SpsContextProvider.module.scss';

export type Status = 'unknown' | 'error' | 'ready' | 'connecting' | 'connected' | 'streaming' | 'disconnected';

export interface ISpsContext {
  pixelStreaming: PixelStreaming | undefined;
  status: Status;
  launchStatus: string;
  errorMessage: string;
  playerRef: RefCallback<HTMLDivElement> | null,
}

export const SpsContext = createContext<ISpsContext>({
  pixelStreaming: undefined,
  status: 'unknown',
  launchStatus: '',
  errorMessage: '',
  playerRef: null,
});

interface SpsContextProviderProps {
  signallingServerURL: string;
  children?: ReactNode;
}

export default function SpsContextProvider({
  signallingServerURL,
  children,
}: SpsContextProviderProps) {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const setRef: RefCallback<HTMLDivElement> = useCallback((node) => {
    playerRef.current = node;
  }, []);
  const [descriptor] = useState(() => new EventEmitter());
  const [pixelStreaming, setPixelStreaming] = useState<PixelStreaming>();
  const [streamActive, setStreamActive] = useState(false);
  const [status, setStatus] = useState<Status>('unknown');
  const [errorMessage, setErrorMessage] = useState('');
  const [launchStatus, setLaunchStatus] = useState('');
  const [emitReady, setEmitReady] = useState(false);

  const handleStreamActive = () => {
    setStatus('streaming');
    setStreamActive(true);
  }

  const handleErrorMessage = (message: string) => {
    setStatus('error');
    setErrorMessage(message);
  };

  useEffect(() => {
    if (!playerRef.current) return;
    const config = new Config({
      initialSettings: {
        AutoPlayVideo: true,
        ss: signallingServerURL,
        StartVideoMuted: false,
        HoveringMouse: true,
        AFKTimeout: 60,
        OfferToReceive: true,
        TimeoutIfIdle: true,
      }
    });
    const ps = new PixelStreaming(config, {
      videoElementParent: playerRef.current,
    });
    ps.setSignallingUrlBuilder(() => {
      let signallingUrl = ps.config.getTextSettingValue(TextParameters.SignallingServerUrl);

			if (signallingUrl && signallingUrl !== undefined && !signallingUrl.endsWith("/ws")) {
				signallingUrl = signallingUrl.endsWith("/") ? signallingUrl + "ws" : signallingUrl + window.location.pathname + "/ws";
			}

			return signallingUrl;
    });
    new SPSSignalling(ps.webSocketController, { onInstanceStateChanged: (message) => setLaunchStatus(message) });
    ps.addResponseEventListener('parser', (message: string) => {
      try {
        const value = JSON.parse(message);
        descriptor.emit(value.type, value);
      } catch (e: any) {
        if (e instanceof SyntaxError) {
          console.error(`Message is not valid JSON: ${message}`);
        } else {
          console.error(e);
        }
      }
    });
    ps.addEventListener<StreamLoadingEvent['type'], StreamLoadingEvent>(
      'streamLoading', () => {
        setLaunchStatus('Video stream loading...');
      },
    );
    ps.addEventListener<WebRtcConnectingEvent['type'], WebRtcConnectingEvent>(
      'webRtcConnecting',
      () => {
        setLaunchStatus('Connecting to WebRTC...');
      },
    );
    ps.addEventListener<WebRtcConnectedEvent['type'], WebRtcConnectedEvent>(
      'webRtcConnected',
      () => {
        setLaunchStatus('WebRTC connected.');
      },
    );
    ps.addEventListener<WebRtcFailedEvent['type'], WebRtcFailedEvent>(
      'webRtcFailed',
      () => {
        setLaunchStatus('Launch failed.');
        handleErrorMessage('WebRTC failed to connect.');
      },
    );
    ps.addEventListener<WebRtcDisconnectedEvent['type'], WebRtcDisconnectedEvent>(
      'webRtcDisconnected',
      () => {
        setStatus('disconnected');
        setLaunchStatus('WebRTC disconnected.');
      },
    );
    ps.addEventListener<PlayStreamEvent['type'], PlayStreamEvent>(
      'playStream',
      () => {
        handleStreamActive();
        setLaunchStatus('Playing video stream...');
        setEmitReady(true);
      },
    );
    ps.addEventListener<PlayStreamErrorEvent['type'], PlayStreamErrorEvent>(
      'playStreamError', ({ data }) => {
        setLaunchStatus('Launch failed.');
        handleErrorMessage(data.message);
      },
    );
    ps.addEventListener<PlayStreamRejectedEvent['type'], PlayStreamRejectedEvent>(
      'playStreamRejected',
      ({ data }) => {
        setLaunchStatus('Launch failed.');
        handleErrorMessage(String(data.reason));
      },
    )
    ps.addEventListener<VideoInitializedEvent['type'], VideoInitializedEvent>(
      'videoInitialized',
      () => {
        setLaunchStatus('Video stream initialized.');
      },
    );
    setPixelStreaming(ps);
    setStatus('ready');
    return () => {
      try {
        ps.disconnect();
      } catch {
        console.warn('Failed to disconnect from PixelStreaming');
      }
    }
  }, [signallingServerURL, playerRef.current]);

  useEffect(() => () => {
    descriptor.removeAllListeners();
  }, []);

  useEffect(() => {
    console.log({ launchStatus, status, errorMessage });
  }, [launchStatus, status, errorMessage]);

  const emitUIInteraction = useMemo(() => {
    if (!pixelStreaming?.emitUIInteraction || !emitReady) return null;
    return (value: any) => pixelStreaming.emitUIInteraction(value);
  }, [pixelStreaming?.emitUIInteraction, emitReady]);

  const connect = () => {
    if (!pixelStreaming) return;
    pixelStreaming.connect();
    setStatus('connecting');
  }

  return (
    <div className={styles.container}>
      <SpsContext.Provider
        value={{
          pixelStreaming,
          status,
          launchStatus,
          errorMessage,
          playerRef: setRef,
        }}
      >
        <PixelStreamingContext.Provider
          value={{
            descriptor,
            emitUIInteraction,
            isStreaming: streamActive,
            connect,
          }}
        >
          {children}
        </PixelStreamingContext.Provider>
      </SpsContext.Provider>
    </div>
  );
}

export function useSpsContext() {
  const context = useContext(SpsContext);
  if (!context) {
    throw new Error('useSpsContext must be used within a SpsContextProvider');
  }
  return context;
}
