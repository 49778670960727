import { z } from 'zod';

export const OrganizationSubscriptionSchema = z.object({
  description: z.string(),
  plan: z.string(),
  features: z.array(z.string()),
  currentPeriodEnd: z.date(),
  price: z.object({
    amount: z.number(),
    currency: z.string(),
    recurring: z.object({
      interval: z.string(),
      count: z.number(),
    }),
  }),
});

export type OrganizationSubscription = z.infer<typeof OrganizationSubscriptionSchema>;

export const isOrganizationSubscription = (obj: unknown): obj is OrganizationSubscription => OrganizationSubscriptionSchema.safeParse(obj).success;
