import { useMutation } from 'react-query';
import api from '../services/api';

interface CheckoutParams {
  plan: string,
  organizationId?: string,
  referral?: string,
  name?: string,
}

interface StartCheckoutResponse {
  url: string;
}

export function useCheckout() {
  return useMutation(
    ({ plan, organizationId, referral, name }: CheckoutParams) => {
      return api
        .get<StartCheckoutResponse>(`/api/v2/checkout/start/${plan}`, {
          params: { organization: organizationId, referral, name },
        })
        .then((res) => res.data);
    }
  );
}
