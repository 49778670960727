import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openUploadModal, setOpenAvatarModal, setOpenServerBrowser } from 'src/features/PixelStreaming/redux/hud';
import { ContentType } from 'src/features/Visitor/components/UploadContentModal/UploadContentModal';

import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';

export interface OpenModalMessage {
  id: string;
  uploadType: ContentType;
}

export default function useHandlePixelStreamModal() {
  const dispatch = useDispatch();
  const { descriptor } = usePixelStreamingContext();

  useEffect(() => {
    if (!descriptor) return () => {};
    const listener = ({ id, uploadType }: OpenModalMessage) => {
      switch (id) {
        case 'server-browser':
          dispatch(setOpenServerBrowser(true));
          break;
        case 'upload-content':
          dispatch(openUploadModal(uploadType));
          break;
        case 'customize-avatar':
          dispatch(setOpenAvatarModal(true));
          break;
        default:
          break;
      }
    };
    descriptor.on('modal', listener);
    return () => descriptor.off('modal', listener);
  }, [descriptor]);
}
