import clsx from 'clsx';

import { ReactComponent as PlayIcon } from '../../../../../../assets/svg/play-solid.svg';

import styles from './PlayNowButton.module.scss';

interface PlayNowButtonProps {
  className?: string;
  onClick: () => void;
}

export default function PlayNowButton({
  className,
  onClick,
}: PlayNowButtonProps) {
  return (
    <button
      type="button"
      className={clsx(className, styles.button)}
      onClick={onClick}
    >
      <span className={styles.container}>
        <div className={styles.icon}>
          <PlayIcon className={styles.svg} />
        </div>
        <span className={styles.label}>Play Now</span>
      </span>
    </button>
  );
}
