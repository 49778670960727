import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { ContentType } from 'src/features/Visitor/components/UploadContentModal/UploadContentModal';

const initialState = {
  browserHUDEnabled: false,
  openServerBrowser: false,
  openSettings: false,
  openAvatarModal: false,
  musicVolume: 0.2,
  invertYAxisControls: true,
  firstPersonCameraView: false,
  pointsOfInterest: [] as string[],
  uploadModal: {
    open: false,
    uploadType: ContentType.Image,
  },
  openUsageModal: false,
  points: 0,
  pointsDisplayEnabled: false,
  emoji: {
    available: false,
    emojis: [] as string[],
    open: false,
  },
};

export const hudSlice = createSlice({
  name: 'hud',
  initialState,
  reducers: {
    setBrowserHUDEnabled: (state, action: PayloadAction<boolean>) => ({ ...state, browserHUDEnabled: action.payload }),
    setOpenServerBrowser: (state, action: PayloadAction<boolean>) => ({ ...state, openServerBrowser: action.payload }),
    setOpenSettings: (state, action: PayloadAction<boolean>) => ({ ...state, openSettings: action.payload }),
    setOpenAvatarModal: (state, action: PayloadAction<boolean>) => ({ ...state, openAvatarModal: action.payload }),
    setMusicVolume: (state, action: PayloadAction<number>) => ({ ...state, musicVolume: action.payload }),
    setInvertYAxisControls: (state, action: PayloadAction<boolean>) => ({ ...state, invertYAxisControls: action.payload }),
    setFirstPersonCameraView: (state, action: PayloadAction<boolean>) => ({ ...state, firstPersonCameraView: action.payload }),
    setPointsOfInterest: (state, action: PayloadAction<string[]>) => ({ ...state, pointsOfInterest: action.payload }),
    openUploadModal: (state, action: PayloadAction<ContentType>) => ({ ...state, uploadModal: { open: true, uploadType: action.payload } }),
    closeUploadModal: (state) => ({ ...state, uploadModal: { ...state.uploadModal, open: false } }),
    setOpenUsageModal: (state, action: PayloadAction<boolean>) => ({ ...state, openUsageModal: action.payload }),
    setPoints: (state, action: PayloadAction<number>) => ({ ...state, points: action.payload }),
    setPointsDisplayEnabled: (state, action: PayloadAction<boolean>) => ({ ...state, pointsDisplayEnabled: action.payload }),
    setAvailableEmojis: (state, action: PayloadAction<string[]>) => {
      state.emoji.emojis = action.payload;
      state.emoji.available = true;
    },
    setEmojiOpened: (state, action: PayloadAction<boolean>) => {
      state.emoji.open = action.payload;
    },
    toggleEmojiOpened: (state) => {
      state.emoji.open = !state.emoji.open;
    },
  },
});

export const {
  setBrowserHUDEnabled,
  setOpenServerBrowser,
  setOpenSettings,
  setOpenAvatarModal,
  setMusicVolume,
  setInvertYAxisControls,
  setFirstPersonCameraView,
  setPointsOfInterest,
  openUploadModal,
  closeUploadModal,
  setOpenUsageModal,
  setPoints,
  setPointsDisplayEnabled,
  setAvailableEmojis,
  setEmojiOpened,
  toggleEmojiOpened,
} = hudSlice.actions;

export const selectBrowserHUDEnabled = (state: RootState) => state.hud.browserHUDEnabled;
export const selectOpenServerBrowser = (state: RootState) => state.hud.openServerBrowser;
export const selectOpenSettings = (state: RootState) => state.hud.openSettings;
export const selectOpenAvatarModal = (state: RootState) => state.hud.openAvatarModal;
export const selectMusicVolume = (state: RootState) => state.hud.musicVolume;
export const selectInvertYAxisControls = (state: RootState) => state.hud.invertYAxisControls;
export const selectFirstPersonCameraView = (state: RootState) => state.hud.firstPersonCameraView;
export const selectPointsOfInterest = (state: RootState) => state.hud.pointsOfInterest;
export const selectOpenUploadModal = (state: RootState) => state.hud.uploadModal.open;
export const selectUploadModalContentType = (state: RootState) => state.hud.uploadModal.uploadType;
export const selectOpenUsageModal = (state: RootState) => state.hud.openUsageModal;
export const selectPoints = (state: RootState) => state.hud.points;
export const selectPointsDisplayEnabled = (state: RootState) => state.hud.pointsDisplayEnabled;
export const selectEmojiCapabilityAvailable = (state: RootState) => state.hud.emoji.available;
export const selectAvailableEmojis = (state: RootState) => state.hud.emoji.emojis;
export const selectEmojiOpened = (state: RootState) => state.hud.emoji.open;

export default hudSlice.reducer;
