import { Button, CheckBoxInput, InputFieldsGroup, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, TextInputField, Typography, UploadButton } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import { useUpdateMe } from 'src/queries/me';
import defaultAvatar from '../../../../assets/images/profile-pic.png';
import PlatformSpinner from '../../../../components/PlatformSpinner/PlatformSpinner';
import { IMetaverseCustomFields } from '../../../../interfaces/IMetaverseCustomFields';
import { selectMetaverseVisitor, setMetaverseVisitor } from '../../../../redux/metaverseVisitor';
import { setUserProfile } from '../../../../redux/userProfile';
import {
  getCustomMetaverseFields,
  patchMetaverseUserDetails, saveUserProfilePic,
} from '../../../../services/MetaverseService';
import { DEFAULT_EMAIL_USE_PERMISSION_TEXT } from '../../../../static/strings';
import { readURL } from '../../../../utils/FileUtil';

import styles from './ProfileModal.module.scss';

export type ProfileModalProps = {
  open: boolean;
  close: () => void;
};

export default function ProfileModal({ open, close }: ProfileModalProps) {
  const dispatch = useDispatch();
  const userProfile = useSelector(
    (state: any) => state.userProfile,
    shallowEqual,
  );
  const { metaverse: metaverseDetails } = useAccessMetaverse();
  const metaverseVisitor = useSelector(selectMetaverseVisitor, shallowEqual);

  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const [customFields, setCustomFields] = useState<IMetaverseCustomFields>();
  const [showSpinner, setShowSpinner] = useState(false);

  const { mutate: updateMe } = useUpdateMe();

  useEffect(() => {
    fetchMetaverseFields();
  }, [metaverseDetails]);

  async function fetchMetaverseFields() {
    if (!metaverseDetails?._id) return;
    const fieldData = await getCustomMetaverseFields(metaverseDetails._id);
    setCustomFields(fieldData);
  }

  async function completeUserProfile() {
    try {
      const hasRequiredCustomFields = (customFields?.fields || [])
        .filter((field) => field.required)
        .every(
          (requiredField: any) => metaverseVisitor.fields[requiredField.name],
        );
      if (!userProfile.displayName || !hasRequiredCustomFields) {
        setShowRequiredFields(true);
        return;
      }
      updateMe(userProfile);
      if (metaverseVisitor?.fields) {
        await patchMetaverseUserDetails(metaverseDetails?._id ?? '', metaverseVisitor.fields);
      }
      close();
    } catch (e) { }
  }

  async function uploadUserImage(image: string) {
    try {
      const response: any = await saveUserProfilePic(userProfile.userId, image);
      dispatch(setUserProfile({ ...userProfile, picture: response.location }));
    } catch (e: any) {
    } finally {
      setShowSpinner(false);
    }
  }

  const handleClose = () => {
    if (!userProfile.displayName) {
      setShowRequiredFields(true);
      return;
    }
    close();
  }

  return (
    <TaskModal open={open} onClose={handleClose}>
      <TaskModalTitle onClose={handleClose}>
        Profile
      </TaskModalTitle>
      <TaskModalContent>
        <InputFieldsGroup>
          <Typography variant="body1">
            Please fill out your profile so people can know more about you
            during metaverses.
          </Typography>

          <Typography className={styles.profile} variant="h6">Profile Photo</Typography>
          <div className={styles.upload}>
            <div className={styles.rendered}>
              <PlatformSpinner visible={showSpinner} />
              <img
                className={styles.picture}
                src={
                  userProfile.picture
                    ? userProfile.picture
                    : customFields?.customProfilePicture || defaultAvatar
                }
              />
            </div>
            <div className={styles.uploadButtonContainer}>
              <UploadButton
                color="secondary"
                id="file-upload-profile-modal"
                type="file"
                accept="image/*"
                onChange={(e) => readURL(
                  e.target,
                  (file: any) => uploadUserImage(file),
                  () => setShowSpinner(true),
                )}
              >
                Upload
              </UploadButton>
              <div className={styles.description}>
                Please upload your profile picture. (2MB limit)
              </div>
            </div>
          </div>
          <TextInputField
            label="Display Name"
            required
            placeholder="Click to type..."
            maxLength={50}
            value={userProfile.displayName}
            onChange={(e) => dispatch(setUserProfile({ ...userProfile, displayName: e.target.value }))}
            error={showRequiredFields && !userProfile.displayName && 'Please fill out this section'}
          />
          <CheckBoxInput
            checked={metaverseVisitor.permitEmailUse}
            onChange={() => dispatch(
              setMetaverseVisitor({
                ...metaverseVisitor,
                permitEmailUse: !metaverseVisitor.permitEmailUse,
              }),
            )}
            label={
              customFields?.permitEmailUseText ||
              DEFAULT_EMAIL_USE_PERMISSION_TEXT
            }
          />
        </InputFieldsGroup>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button color="primary" onClick={completeUserProfile}>
          Save
        </Button>
      </TaskModalActions>
    </TaskModal>
  );
}
