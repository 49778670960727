import clsx from 'clsx';
import { useLocalStorage } from 'react-use';
import HUDButton from '../HUDButton';

import dragImage from './assets/Drag.png';
import leftClickImage from './assets/LeftClick.png';
import leftRightImage from './assets/LeftRight.png';
import orImage from './assets/OR.png';
import qeImage from './assets/QE.png';
import upDownImage from './assets/UpDown.png';
import wasdImage from './assets/WASD.png';

import styles from './TutorialOverlay.module.scss';

interface TutorialOverlayProps {
  className?: string;
  active: boolean;
  ftueKey: string;
}

export default function TutorialOverlay({
  className,
  active,
  ftueKey,
}: TutorialOverlayProps) {
  const [visited, setVisited] = useLocalStorage<boolean>(`pixelcanvas::tutorial:${ftueKey}`, false);

  const handleClose = () => {
    setVisited(true);
  };

  if (!active || visited) return null;

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.text}>Press the Left and Right Arrow Keys or</div>
      <div className={styles.text}>Click/Tap and Drag to <strong>Look Around</strong></div>
      <div className={styles.images}>
        <img className={styles.image} src={qeImage} alt="" />
        <img className={styles.image} src={orImage} alt="" />
        <img className={styles.image} src={leftRightImage} alt="" />
        <img className={styles.image} src={orImage} alt="" />
        <img className={styles.image} src={leftClickImage} alt="" />
        <img className={styles.image} src={orImage} alt="" />
        <img className={styles.image} src={dragImage} alt="" />
      </div>
      <div className={clsx(styles.nextsection, styles.text)}><strong>Move Around</strong> with your Keyboard or</div>
      <div className={styles.text}>Left Click on the Ground</div>
      <div className={styles.smtext}>For <strong>desktop users</strong>, you can move around with your keyboard or pointing and clicking</div>
      <div className={styles.images}>
        <img className={styles.image} src={wasdImage} alt="" />
        <img className={styles.image} src={orImage} alt="" />
        <img className={styles.image} src={upDownImage} alt="" />
        <img className={styles.image} src={orImage} alt="" />
        <img className={styles.image} src={dragImage} alt="" />
      </div>
      <HUDButton className={styles.button} onClick={handleClose}>Got It</HUDButton>
    </div>
  );
}
