import { MainContent, Typography } from '@pixelcanvas/ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { setTitle } from 'src/features/Dashboard/redux/editMetaverse';
import { useShowcases } from 'src/queries/showcase';

export default function ShowcasesIndexPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metaverse } = useCurrentMetaverse();
  const {
    data: showcases = [],
    isFetching: isShowcasesFetching,
    isSuccess: isShowcasesSuccess,
  } = useShowcases(metaverse?._id);

  useEffect(() => {
    dispatch(setTitle('Showcases'));
  }, []);

  useEffect(() => {
    if (!isShowcasesSuccess || isShowcasesFetching || showcases.length === 0) {
      return;
    }
    navigate(`/edit/showcases/${showcases[0].guid}`);
  }, [showcases, isShowcasesFetching, isShowcasesSuccess]);

  return (
    <MainContent>
      <Typography variant="body1">You don&apos;t have any showcases yet. Click on &quot;Add New&quot; to include add showcase.</Typography>
    </MainContent>
  );
}
