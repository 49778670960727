import OrganizationRole from './OrganizationRole';
import { OrganizationResponse } from './IOrganization';
import IUser from './IUser';

export interface OrganizationMemberRequest {
  userId: string;
  role: OrganizationRole;
}

export interface OrganizationMemberResponse extends OrganizationMemberRequest {
  _id: string;
  user: IUser;
  status: OrganizationMemberStatus;
  organizationId: string;
  organization: OrganizationResponse;
  createdAt: Date;
  updatedAt: Date;
}

export interface IOrganizationMember {
  userId: string;
  user: IUser;
  role: OrganizationRole;
  status: OrganizationMemberStatus;
  organizationId: string;
  organization: OrganizationResponse;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum OrganizationMemberStatus {
  Active = 'active',
  Pending = 'pending',
  Accepted = 'accepted',
  Expired = 'expired',
  Canceled = 'canceled',
}

export interface OrganizationInviteResponse {
  _id: string;
  uniqueId: string;
  email: string;
  role: OrganizationRole;
  status: OrganizationMemberStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface OrganizationTeamMemberResponse {
  displayName?: string;
  email: string;
  role: OrganizationRole;
  status: OrganizationMemberStatus;
  memberId?: string;
  inviteId?: string;
}
