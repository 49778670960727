import { useState } from 'react';

import usePxContext, { LoginMethod } from '../../../../../authentication/usePxAuthContext';
import { isValidEmail } from '../../../../../utils/StringUtils';
import Button from '../../Button';
import VisitorInput from '../../VisitorInput/VisitorInput';
import Actions from '../Actions';
import { useAuthModalContext } from '../AuthModalContextProvider';
import Description from '../Description';
import InputFields from '../InputFields';
import Title from '../Title';

import styles from './Modal.module.scss';

interface Props {
  onClose: () => void;
  onSwitchFormClick: Function;
}

function toHumanReadableErrorMessage(error: any): string | null {
  if (!error) return null;
  if (error?.response?.data?.message === 'Invalid password') {
    return 'Invalid password';
  }
  if (error?.response?.data?.title === 'User not found') {
    return 'User not found';
  }
  if (error?.message === 'Invalid email') {
    return 'Invalid email';
  }
  return 'Sign in failed.';
}

export default function SignInForm({
  onClose, onSwitchFormClick,
}: Props) {
  const { login } = usePxContext();
  const { hasEmailRegisterForm, hasEmailResetForm } = useAuthModalContext();
  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<Error>();

  async function completeUserSignIn() {
    try {
      if (!email || !password) {
        setShowRequiredFields(true);
        return;
      }
      if (!isValidEmail(email)) {
        setError(new Error('Invalid email'));
        return;
      }
      await login(LoginMethod.EmailPassword, { email, password });
      onClose();
    } catch (e: any) {
      setError(e);
    }
  }

  return (
    <>
      <Title>Sign in with Email</Title>
      {
        hasEmailRegisterForm &&
        <Description>
          Don&apos;t have an account yet? <button type="button" onClick={() => onSwitchFormClick('register')}>Register</button>
        </Description>
      }
      <InputFields>
        <VisitorInput
          title="Email*"
          placeholder="Click to type..."
          value={email}
          onValueChange={(value: string) => setEmail(value)}
          showRequired={showRequiredFields}
          name="email"
        />
        <VisitorInput
          type="password"
          title="Password*"
          placeholder="Click to type..."
          value={password}
          onValueChange={(value: string) => setPassword(value)}
          showRequired={showRequiredFields}
          name="password"
        />
      </InputFields>
      {
        error &&
        <div className={styles.error}>
          {toHumanReadableErrorMessage(error)}
        </div>
      }
      <Actions>
        {
          hasEmailResetForm &&
          <Description>
            Forgot password? <button type="button" onClick={() => onSwitchFormClick('reset')}>Recover</button>
          </Description>
        }
        <Button
          color="primary"
          onClick={completeUserSignIn}
          name="login"
        >
          Sign In
        </Button>
      </Actions>
    </>
  );
}
