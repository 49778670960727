import { createMongoAbility } from '@casl/ability';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useMetaverse, useMetaverseAbility, useMetaverseBySubdomain } from 'src/queries/metaverse';
import { setMetaverseId } from 'src/redux/metaverse';
import { dataLayer } from 'src/utils/DataLayerUtil';

export default function useAccessMetaverse() {
  const subdomain = window.location.hostname.split('.')[0];
  const isLocalhost = subdomain === 'localhost';

  const localhostQueryResult = useLocalhostMetaverse(isLocalhost);
  const subdomainQueryResult = useSubdomainMetaverse(isLocalhost, subdomain);

  const queryResult = isLocalhost ? localhostQueryResult : subdomainQueryResult;
  const { data: metaverse, error, isLoading, isFetching } = queryResult;

  const {
    data: ability = createMongoAbility(),
    isLoading: isMetaverseAbilityLoading,
    isFetching: isMetaverseAbilityFetching,
  } = useMetaverseAbility(metaverse?._id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!metaverse) return;
    dispatch(setMetaverseId(metaverse._id));
    dataLayer.push({ px_event_id: metaverse._id });
  }, [metaverse]);

  return {
    metaverse,
    error,
    isLoading: isLoading || isMetaverseAbilityLoading,
    isFetching: isFetching || isMetaverseAbilityFetching,
    ability,
  };
}

function useLocalhostMetaverse(isLocalhost: boolean) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [metaverseId, setStoredMetaverseId] = useLocalStorage<string>('pixelcanvas::metaverseId');

  const queryMetaverseId = searchParams.get('id') ?? undefined;

  /*
    On mount of this provider, set metaverseId from ?id=<metaverseId>
    only if parameter is present and not on localhost.

    If metaverseId is set this means the query parameter has already been
    parsed and removed. Use the existing metaverseId.
  */

  useEffect(() => {
    if (!isLocalhost) return;
    if (queryMetaverseId) {
      dispatch(setMetaverseId(queryMetaverseId));
      setStoredMetaverseId(queryMetaverseId);
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [isLocalhost, queryMetaverseId, metaverseId]);

  const { data, error, isLoading, isFetching } = useMetaverse(metaverseId, true);
  return { data, error, isLoading, isFetching };
}

function useSubdomainMetaverse(isLocalhost: boolean, subdomain: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryMetaverseId = searchParams.get('id') ?? undefined;

  useEffect(() => {
    if (isLocalhost) return;
    if (queryMetaverseId) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [subdomain, queryMetaverseId]);

  const { data, error, isLoading, isFetching } = useMetaverseBySubdomain(
    !isLocalhost ? subdomain : undefined, { keepPreviousData: true, fastRetry: true },
  );
  return { data, error, isLoading, isFetching };
}
