import { Button, Cards, TaskModal, TaskModalActions, TaskModalContent, TaskModalTitle, Typography } from '@pixelcanvas/ui';
import { useState } from 'react';
import { useWindowSize } from 'react-use';
import { EnvironmentResponse } from 'src/interfaces/Environment';
import { useOrganizationEnvironments } from 'src/queries/organization';
import { useOrganizationContext } from '../../pages/OrganizationPage/context/OrganizationContextProvider';
import EnvironmentCard from '../EnvironmentCard/EnvironmentCard';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelect: (environment: EnvironmentResponse) => void;
}

export default function SelectEnvironmentModal({ open, onClose, onSelect }: Props) {
  const { currentOrganizationId } = useOrganizationContext();
  const { data: environments = [] } = useOrganizationEnvironments(currentOrganizationId);
  const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentResponse>();
  const { width: windowWidth } = useWindowSize();

  const variant = environments.length * 400 > windowWidth * 0.75
    ? 'grid'
    : 'flex';

  const handleConfirm = () => {
    if (!selectedEnvironment) return;
    onSelect(selectedEnvironment);
  };

  return (
    <TaskModal open={open} onClose={onClose}>
      <TaskModalTitle onClose={onClose}>SELECT AN ENVIRONMENT</TaskModalTitle>
      <TaskModalContent>
        <Typography variant="subtitle2">Select an environment to build your metaverse. You will be able to customize the metaverse in the 3D editor.</Typography>
        <Cards variant={variant}>
          {
            environments.map((environment) => (
              <EnvironmentCard
                key={environment._id}
                environment={environment}
                selected={environment._id === selectedEnvironment?._id}
                onSelect={setSelectedEnvironment}
              />
            ))
          }
        </Cards>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button color="primary" disabled={!selectedEnvironment} onClick={handleConfirm}>Confirm</Button>
      </TaskModalActions>
    </TaskModal>
  );
}
