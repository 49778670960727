/* eslint-disable jsx-a11y/media-has-caption */
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import {
  AudioTrack, AudioTrackPublication, LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteTrack,
  RemoteTrackPublication, RemoteVideoTrack, VideoTrack,
  VideoTrackPublication,
} from 'twilio-video';
import useAsyncEffect from 'use-async-effect';

import { ReactComponent as ScreenShareIcon } from '../../../../../assets/svg/tv.svg';
import IMetaverseVisitor from '../../../../../interfaces/IMetaverseVisitor';
import { TrackType } from '../../../../../interfaces/TrackType';
import { getUserDisplayName } from '../../../../../utils/DisplayNameUtil';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import useVideoContext from '../useVideoContext';

import styles from './PresenterVideo.module.scss';

const PRESENTER_SCREEN_AUDIO = 'presenter-screen-audio';
const PRESENTER_SCREEN_VIDEO = 'presenter-screen-video';
const PRESENTER_CAMERA = 'presenter-camera';

interface PresenterVideoProps {
  videoWidth?: number;
  participant: Participant;
  getUser: Function;
}

const PresenterVideo = (props: PresenterVideoProps) => {
  const {
    presenter, videoRoom, localScreenTrack, createLocalScreenTracks, removeLocalScreenTracks,
  } = useVideoContext();
  const [mainVideoTrack, setMainVideoTrack] = useState<(LocalVideoTrack | RemoteVideoTrack | undefined)>(undefined);
  const [secondaryVideoTrack, setSecondaryVideoTrack] = useState<(LocalVideoTrack | RemoteVideoTrack | undefined)>(undefined);
  const [mainAudioTrack, setMainAudioTrack] = useState<(LocalAudioTrack | RemoteAudioTrack | undefined)>(undefined);
  const [secondaryAudioTrack, setSecondaryAudioTrack] = useState<(LocalAudioTrack | RemoteAudioTrack | undefined)>(undefined);
  const [screenShareActive, setScreenShareActive] = useState<boolean>(false);
  const [metaverseVisitor, setMetaverseVisitor] = useState<IMetaverseVisitor | undefined>(undefined);
  const mainContainerRef = useRef<any>();
  const secondaryContainerRef = useRef<any>();
  const { participant } = props;

  const trackSubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Video) {
      if (track.name?.includes(PRESENTER_SCREEN_VIDEO)) {
        setMainVideoTrack((prevMainVideoTrack) => {
          if (prevMainVideoTrack && prevMainVideoTrack.name.includes(PRESENTER_CAMERA)) setSecondaryVideoTrack(prevMainVideoTrack);
          return track;
        });
        setScreenShareActive(true);
      } else if (track.name?.includes(PRESENTER_CAMERA)) {
        setMainVideoTrack((prevMainVideoTrack) => {
          if (prevMainVideoTrack) setSecondaryVideoTrack(track);
          return prevMainVideoTrack || track;
        });
      }
    }
    if (track.kind === TrackType.Audio) {
      if (track.name?.includes(PRESENTER_SCREEN_AUDIO)) {
        setMainAudioTrack((prevMainAudioTrack) => {
          if (prevMainAudioTrack && !prevMainAudioTrack.name.includes(PRESENTER_SCREEN_AUDIO)) setSecondaryAudioTrack(prevMainAudioTrack);
          return track;
        });
      } else {
        setMainAudioTrack((prevMainAudioTrack) => {
          if (prevMainAudioTrack) setSecondaryAudioTrack(track);
          return prevMainAudioTrack || track;
        });
      }
    }
  };
  const trackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Video) {
      if (track.name?.includes(PRESENTER_SCREEN_VIDEO)) {
        console.log('presenter screen track unsubscribed');
        setSecondaryVideoTrack((prevSecondaryVideoTrack) => {
          if (prevSecondaryVideoTrack) {
            setMainVideoTrack(prevSecondaryVideoTrack);
          } else {
            setMainVideoTrack(undefined);
          }
          return undefined;
        });
        setScreenShareActive(false);
      } else if (track.name?.includes(PRESENTER_CAMERA)) {
        console.log('presenter camera track unsubscribed');
        if (mainVideoTrack?.name?.includes(PRESENTER_CAMERA)) setMainVideoTrack(undefined);
        if (secondaryVideoTrack?.name?.includes(PRESENTER_CAMERA)) setSecondaryVideoTrack(undefined);
      }
    }

    if (track.kind === TrackType.Audio) {
      if (track.name?.includes(PRESENTER_SCREEN_AUDIO)) {
        console.log('presenter screen audio track unsubscribed');
        if (mainAudioTrack?.name?.includes(PRESENTER_SCREEN_AUDIO)) {
          setSecondaryAudioTrack((prevSecondaryAudioTrack) => {
            if (prevSecondaryAudioTrack) {
              setMainAudioTrack(prevSecondaryAudioTrack);
            } else {
              setMainAudioTrack(undefined);
            }
            return undefined;
          });
        } else {
          setMainAudioTrack(undefined);
        }
      } else {
        if (mainAudioTrack?.name?.includes(PRESENTER_SCREEN_AUDIO)) setMainAudioTrack(undefined);
        if (secondaryAudioTrack?.name?.includes(PRESENTER_SCREEN_AUDIO)) setSecondaryAudioTrack(undefined);
      }
    }
  };
  const trackPublished = (publication: RemoteTrackPublication) => {
    const track = publication?.track;
    if (!track) return;
    trackSubscribed(track);
  };
  const trackUnpublished = (publication: RemoteTrackPublication) => {
    const track = publication?.track;
    if (!track) return;
    trackUnsubscribed(track);
  };

  useEffect(() => {
    props.participant.on('trackPublished', trackPublished);
    props.participant.on('trackUnpublished', trackUnpublished);
    props.participant.on('trackSubscribed', trackSubscribed);
    props.participant.on('trackUnsubscribed', trackUnsubscribed);
    return () => {
      props.participant.off('trackSubscribed', trackSubscribed);
      props.participant.off('trackUnsubscribed', trackUnsubscribed);
      props.participant.off('trackPublished', trackPublished);
      props.participant.off('trackUnpublished', trackUnpublished);
    };
  }, []);

  useAsyncEffect(async () => {
    try {
      const user = await props.getUser(props.participant.identity);
      setMetaverseVisitor(user);
    } catch (e) {

    }
  }, []);

  useEffect(() => {
    if (mainVideoTrack) {
      mainVideoTrack.attach(mainContainerRef.current);
      console.log('Attaching mainVideoTrack', mainVideoTrack);
    }
  }, [mainVideoTrack]);

  useEffect(() => {
    if (secondaryVideoTrack) {
      secondaryVideoTrack.attach(secondaryContainerRef.current);
      console.log('Attaching secondaryVideoTrack', secondaryContainerRef);
    }
  }, [secondaryVideoTrack]);

  useEffect(() => {
    const existingPublications = Array.from(props.participant.videoTracks.values());
    const screenVideoPublications: VideoTrackPublication[] = [];
    const cameraVideoPublications: VideoTrackPublication[] = [];
    existingPublications.forEach((publication: VideoTrackPublication) => {
      if (publication.trackName.includes(PRESENTER_SCREEN_VIDEO)) {
        screenVideoPublications.push(publication);
      } else if (publication.trackName.includes(PRESENTER_CAMERA)) {
        cameraVideoPublications.push(publication);
      }
    });
    const screenVideoTrack = screenVideoPublications
      .map((publication: VideoTrackPublication) => publication.track)
      .find((track: VideoTrack | null) => track !== null) || undefined;
    const cameraVideoTrack = cameraVideoPublications
      .map((publication: VideoTrackPublication) => publication.track)
      .find((track: VideoTrack | null) => track !== null) || undefined;
    if (screenVideoTrack) {
      setScreenShareActive(true);
      setMainVideoTrack(screenVideoTrack);
      setSecondaryVideoTrack(cameraVideoTrack);
    } else {
      setMainVideoTrack(cameraVideoTrack);
    }
  }, [props.participant.videoTracks]);

  useEffect(() => {
    const existingPublications = Array.from(props.participant.audioTracks.values());
    const screenAudioPublications: AudioTrackPublication[] = [];
    const cameraAudioPublications: AudioTrackPublication[] = [];
    existingPublications.forEach((publication: AudioTrackPublication) => {
      if (publication.trackName.includes(PRESENTER_SCREEN_AUDIO)) {
        screenAudioPublications.push(publication);
      } else {
        cameraAudioPublications.push(publication);
      }
    });
    const screenAudioTrack = screenAudioPublications
      .map((publication: AudioTrackPublication) => publication.track)
      .find((track: AudioTrack | null) => track !== null) || undefined;
    const cameraAudioTrack = cameraAudioPublications
      .map((publication: AudioTrackPublication) => publication.track)
      .find((track: AudioTrack | null) => track !== null) || undefined;

    if (screenAudioTrack) {
      setMainAudioTrack(screenAudioTrack);
      setSecondaryAudioTrack(cameraAudioTrack);
    } else if (secondaryVideoTrack) {
      setMainAudioTrack(undefined);
      setSecondaryAudioTrack(cameraAudioTrack);
    } else {
      setMainAudioTrack(cameraAudioTrack);
      setSecondaryAudioTrack(undefined);
    }
  }, [props.participant.audioTracks, secondaryVideoTrack]);

  return (
    <>
      <div className={styles['participant-screen-video']} style={{ width: props.videoWidth }}>
        <video className={styles['screen-video-track']} ref={mainContainerRef} />
        {metaverseVisitor && <div className={styles['participant-name']}>{getUserDisplayName(metaverseVisitor.participant)}</div>}
        <div className={styles['audio-level-indicator']}>
          <AudioLevelIndicator id={`${participant.identity}-screen-audio`} audioTrack={mainAudioTrack} />
        </div>
      </div>
      {
        secondaryVideoTrack &&
        <div className={styles['participant-camera-video']}>
          <video className={styles['camera-video-track']} ref={secondaryContainerRef} />
          <div className={styles['camera-audio-level-indicator']}>
            <AudioLevelIndicator id={participant.identity} audioTrack={secondaryAudioTrack} />
          </div>
        </div>
      }
      {
        presenter?.identity === videoRoom?.localParticipant.identity &&
        <div className={styles['presenter-controls']}>
          <div
            className={clsx(styles.button, { [styles['screen-share-active']]: screenShareActive })}
            onClick={() => {
              if (localScreenTrack) {
                removeLocalScreenTracks();
              } else {
                createLocalScreenTracks();
              }
            }}
          >
            <ScreenShareIcon className={styles['screen-share-icon']} />
          </div>
        </div>
      }
    </>
  );
};

export default PresenterVideo;
