import {
  TaskModal,
  TaskModalTitle,
  TaskModalContent,
  TaskModalActions,
  Button,
  RadioButton,
  Typography,
  TooltipButton,
} from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { OrganizationTeamMemberResponse } from 'src/interfaces/IOrganizationMember';
import { usePatchOrganizationMember } from '../../../../../../queries/organization';
import { useOrganizationContext } from '../../context/OrganizationContextProvider';
import OrganizationRole from '../../../../../../interfaces/OrganizationRole';
import { errorAlert } from '../../../../../../utils/SimpleErrorUtil';

import styles from './OrganizationEditRoleModal.module.scss';

type Props = {
  open: boolean;
  member: OrganizationTeamMemberResponse;
  onClose: () => void;
};

export default function OrganizationEditRoleModal({
  open,
  member,
  onClose,
}: Props) {
  const { currentOrganization } = useOrganizationContext();
  const [role, setRole] = useState<OrganizationRole>();
  const { mutate, error, reset, isSuccess } = usePatchOrganizationMember(currentOrganization?._id as string, member?.memberId as string);

  useEffect(() => {
    if (error) {
      errorAlert(error);
      reset();
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess]);

  const onConfirmClick = () => {
    if (!role) {
      errorAlert('Please select a role.');
      return;
    }
    mutate(role);
  };

  return (
    <TaskModal
      className={styles.modal}
      open={open}
      onClose={onClose}
    >
      <TaskModalTitle onClose={() => onClose()}>EDIT ROLE</TaskModalTitle>
      <TaskModalContent className={styles.content}>
        <Typography variant="h6">
          NAME
        </Typography>
        <Typography variant="body1">
          {member.displayName}
        </Typography>
        <Typography variant="h6" className={styles.header}>
          EMAIL
        </Typography>
        <Typography variant="body1">
          {member.email}
        </Typography>
        <Typography variant="h6" className={styles.header}>
          ROLE*
        </Typography>
        <div className={styles.roleRadioGroup}>
          <div className={styles.roleRow}>
            <RadioButton
              key={OrganizationRole.Administrator}
              label={OrganizationRole.Administrator}
              name="roles"
              onChange={() => setRole(OrganizationRole.Administrator)}
              defaultChecked={member.role === OrganizationRole.Administrator}
            />
            <Typography variant="body1" emphasis="high">Administrator</Typography>
            <TooltipButton
              placement="right"
            >
              Has full access but can’t manage billing
            </TooltipButton>
          </div>
          <div className={styles.roleRow}>
            <RadioButton
              key={OrganizationRole.Moderator}
              label={OrganizationRole.Moderator}
              name="roles"
              onChange={() => setRole(OrganizationRole.Moderator)}
              defaultChecked={member.role === OrganizationRole.Moderator}
            />
            <Typography variant="body1" emphasis="high">Moderator</Typography>
            <TooltipButton>
              Has viewing access and can moderate chats and metaverse visitors
            </TooltipButton>
          </div>
          <div className={styles.roleRow}>
            <RadioButton
              key={OrganizationRole.Viewer}
              label={OrganizationRole.Viewer}
              name="roles"
              onChange={() => setRole(OrganizationRole.Viewer)}
              defaultChecked={member.role === OrganizationRole.Viewer}
            />
            <Typography variant="body1" emphasis="high">Viewer</Typography>
            <TooltipButton>
              Can view the dashboard and download documents
            </TooltipButton>
          </div>
        </div>
      </TaskModalContent>
      <TaskModalActions>
        <Button color="tertiary" onClick={onClose}>Cancel</Button>
        <Button onClick={() => onConfirmClick()}>Save</Button>
      </TaskModalActions>
    </TaskModal>
  );
}
