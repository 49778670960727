import WideButton from '../WideButton';

import icon from '../../../../../assets/svg/google-g-logo.svg';
import { useAuthModalContext } from '../AuthModalContextProvider';

type Props = {
  className?: string;
  returnTo?: string;
};

export default function GoogleLoginWideButton({ className, returnTo }: Props) {
  const { loginWithGoogle } = useAuthModalContext();
  return (
    <WideButton
      classes={{ button: className }}
      icon={icon}
      onClick={() => loginWithGoogle(returnTo)}
    >
      Google
    </WideButton>
  );
}
