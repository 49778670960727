export interface RGB {
  r: number;
  g: number;
  b: number;
}

export const validHexColor = (hexString: string) => {
  const regExp = /^#[0-9A-F]{6}$/i;
  return regExp.test(hexString);
};

export const hexToRgb = (hex: string): RGB | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

export const getColorBrightness = (hexColor: string) => {
  const color = hexToRgb(hexColor);
  if (!color) return 0;

  return Math.sqrt(
    color.r * color.r * 0.241 +
    color.g * color.g * 0.691 +
    color.b * color.b * 0.068,
  );
};

export const isBright = (hexColor: string | undefined) : boolean => {
  if (!hexColor) return false;
  if (getColorBrightness(hexColor) > 130) {
    return true;
  }
  return false;
};
