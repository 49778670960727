import { Navigate, Route, Routes } from 'react-router-dom';

import RequireAuthentication from './authentication/RequireAuthentication';
import LandingHeader from './components/LandingHeader/LandingHeader';
import EditMetaverse from './features/Dashboard/layouts/EditMetaverse/EditMetaverse';
import EditMetaverseAddOnsPage from './features/Dashboard/pages/EditMetaverseAddOnsPage';
import AddOnsIndexPage from './features/Dashboard/pages/EditMetaverseAddOnsPage/pages/AddOnsIndexPage';
import LegacySpace3DAddOn from './features/Dashboard/pages/EditMetaverseAddOnsPage/pages/LegacySpace3DAddOn/LegacySpace3DAddOn';
import PureWebAddOn from './features/Dashboard/pages/EditMetaverseAddOnsPage/pages/PureWebAddOn/PureWebAddOn';
import StageAddOn from './features/Dashboard/pages/EditMetaverseAddOnsPage/pages/StageAddOn/StageAddOn';
import WorldMap3DAddOn from './features/Dashboard/pages/EditMetaverseAddOnsPage/pages/WorldMap3DAddOn/WorldMap3DAddOn';
import EditMetaverseAdvancedSettings from './features/Dashboard/pages/EditMetaverseAdvancedSettings/EditMetaverseAdvancedSettings';
import EditMetaverseAnalyticsPage from './features/Dashboard/pages/EditMetaverseAnalyticsPage/EditMetaverseAnalyticsPage';
import EditMetaverseHomePage from './features/Dashboard/pages/EditMetaverseHomePage/EditMetaverseHomePage';
import EditMetaverseKeyDetailsPage from './features/Dashboard/pages/EditMetaverseKeyDetailsPage';
import EditMetaverseLandingPage from './features/Dashboard/pages/EditMetaverseLandingPage/EditMetaverseLandingPage';
import EditMetaversePrivacyDomainSettings from './features/Dashboard/pages/EditMetaversePrivacyDomainSettings';
import EditMetaversePrivacyGeneralSettings from './features/Dashboard/pages/EditMetaversePrivacyGeneralSettings';
import EditMetaversePrivacySettingsPage from './features/Dashboard/pages/EditMetaversePrivacySettingsPage/EditMetaversePrivacySettingsPage';
import EditMetaverseShowcasesPage from './features/Dashboard/pages/EditMetaverseShowcase';
import EditMetaverseShowcasePage from './features/Dashboard/pages/EditMetaverseShowcase/pages/EditMetaverseShowcase';
import EditMetaverseVisitorsPage from './features/Dashboard/pages/EditMetaverseVisitorsPage';
import Metaverse3DEditor from './features/Dashboard/pages/Metaverse3DEditor/Metaverse3DEditor';
import MetaversesDashboardPage from './features/Dashboard/pages/MetaversesDashboardPage';
import { OnboardingPage } from './features/Dashboard/pages/OnboardingPage/OnboardingPage';
import OrganizationInviteLandingPage from './features/Dashboard/pages/OrganizationInviteLandingPage';
import OrganizationContextProvider from './features/Dashboard/pages/OrganizationPage/context/OrganizationContextProvider';
import OrganizationPage from './features/Dashboard/pages/OrganizationPage/OrganizationPage';
import SpacesPage from './features/Dashboard/pages/SpacesPage/SpacesPage';
import WorldMapsPage from './features/Dashboard/pages/WorldMapsPage/WorldMapsPage';
import { EmailVerificationPage } from './features/Visitor/pages/EmailVerificationPage/EmailVerificationPage';
import { EmailVerifiedPage } from './features/Visitor/pages/EmailVerifiedPage/EmailVerifiedPage';
import MetaverseLandingPageAuthContainer from './features/Visitor/pages/MetaverseLandingPage/MetaverseLandingPageAuthContainer';
import MetaverseNoAccessPage from './features/Visitor/pages/MetaverseNoAccessPage/MetaverseNoAccessPage';
import MetaversePage from './features/Visitor/pages/MetaversePage/MetaversePage';
import { PasswordResetPage } from './features/Visitor/pages/PasswordResetPage/PasswordResetPage';
import InviteLandingPage from './pages/InviteLandingPage';
import JoinPage from './pages/JoinPage/JoinPage';
import { LandingPage } from './pages/LandingPage/LandingPage';
import CheckoutPage from './features/Dashboard/pages/CheckoutPage';
import CheckoutSelectOrganizationPage from './features/Dashboard/pages/CheckoutSelectOrganizationPage';

export function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <LandingHeader />
            <LandingPage />
          </>
        }
      />
      <Route
        path="/verification"
        element={
          <RequireAuthentication>
            <LandingHeader />
            <EmailVerificationPage />
          </RequireAuthentication>
        }
      />
      <Route
        path="/verified"
        element={
          <RequireAuthentication>
            <LandingHeader />
            <EmailVerifiedPage />
          </RequireAuthentication>
        }
      />
      <Route
        path="/passwordreset"
        element={
          <>
            <LandingHeader />
            <PasswordResetPage />
          </>
        }
      />
      <Route
        path="/onboarding"
        element={
          <RequireAuthentication>
            <OnboardingPage />
          </RequireAuthentication>
        }
      />
      <Route
        path="/organization/*"
        element={
          <RequireAuthentication>
            <OrganizationContextProvider>
              <OrganizationPage />
            </OrganizationContextProvider>
          </RequireAuthentication>
        }
      />
      <Route
        path="/organization-new-user"
        element={<OrganizationInviteLandingPage />}
      />
      <Route
        path="/spaces"
        element={
          <RequireAuthentication>
            <OrganizationContextProvider>
              <SpacesPage />
            </OrganizationContextProvider>

          </RequireAuthentication>
        }
      />
      <Route
        path="/worldmaps"
        element={
          <RequireAuthentication>
            <OrganizationContextProvider>
              <WorldMapsPage />
            </OrganizationContextProvider>
          </RequireAuthentication>
        }
      />
      <Route
        path="/metaverses"
        element={
          <RequireAuthentication>
            <OrganizationContextProvider>
              <MetaversesDashboardPage />
            </OrganizationContextProvider>
          </RequireAuthentication>
        }
      />
      <Route
        path="/metaverse"
        element={
          <RequireAuthentication>
            <MetaversePage />
          </RequireAuthentication>
        }
      />
      <Route
        path="/landing"
        element={<MetaverseLandingPageAuthContainer />}
      />
      <Route path="/edit/3d" element={<Metaverse3DEditor />} />
      <Route path="/edit" element={<EditMetaverse />}>
        <Route index element={<Navigate to="/edit/home" />} />
        <Route path="home" element={<EditMetaverseHomePage />} />
        <Route path="details" element={<EditMetaverseKeyDetailsPage />} />
        <Route path="addons" element={<EditMetaverseAddOnsPage />}>
          <Route index element={<AddOnsIndexPage />} />
          <Route path="landing-page" element={<EditMetaverseLandingPage />} />
          <Route path="stage/:pageId" element={<StageAddOn />} />
          <Route path="vod/:pageId" element={<StageAddOn />} />
          <Route path="pw/:pageId" element={<PureWebAddOn />} />
          <Route path="legacyspace/:pageId" element={<LegacySpace3DAddOn />} />
          <Route path="worldmap/:pageId" element={<WorldMap3DAddOn />} />
        </Route>
        <Route path="visitors" element={<EditMetaverseVisitorsPage />} />
        <Route path="privacy" element={<EditMetaversePrivacySettingsPage />}>
          <Route index element={<Navigate to="/edit/privacy/general" />} />
          <Route path="general" element={<EditMetaversePrivacyGeneralSettings />} />
          <Route path="domain" element={<EditMetaversePrivacyDomainSettings />} />
          <Route path="*" element={<Navigate to="/edit/privacy/general" />} />
        </Route>
        <Route path="analytics" element={<EditMetaverseAnalyticsPage />} />
        <Route path="advancedsettings" element={<EditMetaverseAdvancedSettings />} />
        <Route path="showcases/:guid" element={<EditMetaverseShowcasePage />} />
        <Route path="showcases" element={<EditMetaverseShowcasesPage />} />
        <Route path="*" element={<Navigate to="/edit/home" />} />
      </Route>
      <Route
        path="/join"
        element={
          <RequireAuthentication>
            <JoinPage />
          </RequireAuthentication>
        }
      />
      <Route
        path="/newuser"
        element={<InviteLandingPage />}
      />
      <Route path="/checkout/:plan" element={<CheckoutPage />} />
      <Route
        path="/checkout/:plan/next"
        element={
          <RequireAuthentication>
            <CheckoutSelectOrganizationPage />
          </RequireAuthentication>
        }
      />
      <Route
        path="/noaccess"
        element={<MetaverseNoAccessPage />}
      />
      <Route
        path="/*"
        element={<Navigate to="/" />}
      />
    </Routes>
  );
}
