import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

import styles from './WideButton.module.scss';

export interface WideButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'className'> {
  classes?: any;
  icon: string;
  children?: React.ReactNode;
}

export default function WideButton({
  classes = {},
  icon,
  children,
  ...props
}: WideButtonProps) {
  return (
    <button
      className={clsx(classes.button, styles.button)}
      type="button"
      {...props}
    >
      <span className={clsx(classes.span, styles.span)}>
        <img className={clsx(classes.icon, styles.icon)} src={icon} alt="" />
        {children}
      </span>
    </button>
  );
}
