import { useEffect } from 'react';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import PureWebAvatarModal from 'src/components/ReadyPlayerMe/PureWebAvatarModal';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import Background from 'src/features/PixelStreaming/components/Background/Background';
import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import ImageSlotModal from 'src/features/PixelStreaming/components/ImageSlot/ImageSlotUploadModal';
import LaunchContainer from 'src/features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import LoadingInfo from 'src/features/PixelStreaming/components/LoadingInfo/LoadingInfo';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import SceneSlotEditModal from 'src/features/PixelStreaming/components/SceneSlot/SceneSlotEditModal';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import { usePixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';
import useDebugEmitUIInteract from 'src/features/PixelStreaming/hooks/useDebugEmitUIInteraction';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinSpace from 'src/features/PixelStreaming/hooks/useJoinSpace';
import useOpenBooth from 'src/features/PixelStreaming/hooks/useOpenBooth';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenMap from 'src/features/PixelStreaming/hooks/useOpenMap';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useSendResizeMessages from 'src/features/PixelStreaming/hooks/useSendResizeMessages';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import ErrorOverlay from 'src/features/ScalablePixelStreaming/components/ErrorOverlay';
import PlayOverlay from 'src/features/ScalablePixelStreaming/components/PlayOverlay';
import { useSpsContext } from 'src/features/ScalablePixelStreaming/components/SpsContextProvider';
import TimeoutModal from 'src/features/ScalablePixelStreaming/components/SpsTimeoutModal';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import UploadContentModalContainer from 'src/features/Visitor/components/UploadContentModal/UploadContentModalContainer';
import { ISpace, MetaverseResponse } from 'src/interfaces/IMetaverse';
import VisualSource from 'src/interfaces/VisualSource';
import { getMetaverseEditURL } from 'src/utils/URLHelper';

import defaultBackground from '../../../../../../assets/images/world_map_bg.jpg';

import styles from './Metaverse3DEditorSpsSession.module.scss';

interface Metaverse3DEditorSpsSessionProps {
  metaverse: MetaverseResponse,
  space: ISpace;
}

export default function Metaverse3DEditorSpsSession({ metaverse, space }: Metaverse3DEditorSpsSessionProps) {
  const { user, authToken } = usePxAuthContext();
  const { metaverse: currentMetaverse, ability } = useCurrentMetaverse();

  const hasAdvancedUpload = ability.can(Action.Manage, Subject.AdvancedContentUpload);
  const canUploadImageSlot = ability.can(Action.Create, Subject.ImageSlot);

  const { _id: metaverseId } = metaverse;
  const { mapName } = space.environment;

  useAutoLaunch();

  const { hasUsage, isLoadingUsage } = useUsageContext();
  useInitialPacket(
    metaverseId,
    currentMetaverse?.organizationId,
    authToken,
    user,
    null,
  );

  useRefreshToken();
  useOpenExternalLink();
  useJoinSpace(space._id);
  useOpenModal();
  useProfileUpdate();
  useDebugEmitUIInteract();
  useUserJoinAndExit();
  useOpenBooth();
  useOpenMap(mapName);

  const {
    playerRef,
    status,
    launchStatus,
  } = useSpsContext();

  const { connect } = usePixelStreamingContext();
  const { ref: resizeRef } = useSendResizeMessages<HTMLDivElement>();

  const handleTimeout = () => {
    window.location.href = getMetaverseEditURL(metaverse);
  };

  const defaultBackgroundSource: VisualSource = { srcType: 'image', src: defaultBackground };

  const attachRefs = (ref: HTMLDivElement) => {
    playerRef?.(ref);
    resizeRef(ref);
  };

  return (
    <>
      {status !== 'streaming' && <Background source={space.background ?? defaultBackgroundSource} />}
      <div ref={attachRefs} className={styles.player} />
      {!isLoadingUsage && hasUsage && <UsageLimitReachedScreen isOwner logo={metaverse?.logo} />}
      {status === 'ready' && <LaunchContainer logo={metaverse?.logo} onEnter={connect} />}
      {status === 'connecting' && <LoadingInfo statuses={[launchStatus]} />}
      {status === 'connected' && <PlayOverlay />}
      {status === 'error' && <ErrorOverlay />}
      <HUD />
      <PointsOfInterest />
      <ContentViewer metaverse={metaverse} />
      <SettingsOverlay />
      <UploadContentModalContainer isSiteAdmin={hasAdvancedUpload} metaverseId={metaverseId} />
      <TimeoutModal onTimeout={handleTimeout} />
      <PureWebAvatarModal />
      <UsageLimitReachedModal isOwner={hasAdvancedUpload} onDismiss={handleTimeout} />
      <ImageSlotModal
        metaverseId={metaverse._id}
        spaceId={space._id}
        isSiteAdmin={canUploadImageSlot}
      />
      <SceneSlotEditModal
        metaverseId={metaverse._id}
        spaceId={space._id}
      />
    </>
  );
}

function useAutoLaunch() {
  const { hasUsage } = useUsageContext();

  const {
    status,
    pixelStreaming,
  } = useSpsContext();
  const { connect } = usePixelStreamingContext();

  useEffect(() => {
    if (!hasUsage || !status || status !== 'ready') return;
    connect();
  }, [pixelStreaming, status, hasUsage]);
}
