import { default as cn } from 'clsx';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectAvailableEmojis, selectEmojiOpened, setEmojiOpened } from "src/features/PixelStreaming/redux/hud";
import { z } from "zod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { usePixelStreamingContext } from 'src/features/PixelStreaming/contexts/PixelStreamingContext';

import styles from "./EmojiGestureSelection.module.scss"

interface EmojiGestureSelectionProps {
  className?: string;
}

export default function EmojiGestureSelection({ className }: EmojiGestureSelectionProps) {
  const dispatch = useDispatch();
  const emojis = useSelector(selectAvailableEmojis);
  const opened = useSelector(selectEmojiOpened);
  const handleClose = () => dispatch(setEmojiOpened(false));
  if (!opened) return null;
  return (
    <div
      className={cn(className, styles.container)}
    >
      <button type="button" onClick={handleClose} className={cn(
          styles.closeButton,
        )}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
      {emojis.map((emoji) => <EmojiSelectionButton key={emoji} emoji={emoji} onClose={handleClose} />)}
    </div>
  );
}

const MESSAGE_TYPE = "emoji:use" as const;

const emojiUseDescriptorMessageSchema = z.object({
  type: z.literal(MESSAGE_TYPE),
  emoji: z.string(),
});

export type EmojiUseDescriptorMessage = z.infer<typeof emojiUseDescriptorMessageSchema>;

// TODO: Add more configurability based on emoji name, e.g. add icon/picture
function toHumanReadable(input: string) {
  return input
    .replaceAll(/_/g, " ")
    .replaceAll(/([0-9]+)/g, " $1")
    .replaceAll(/  +/g, " ");
}

interface EmojiSelectionButtonProps {
  className?: string;
  emoji: string;
  onClose: () => void;
}

function EmojiSelectionButton({ className, emoji, onClose }: EmojiSelectionButtonProps) {
  const { emitUIInteraction } = usePixelStreamingContext();
  const handleClick = () => {
    if (!emitUIInteraction) return;
    const message: EmojiUseDescriptorMessage = {
      type: 'emoji:use',
      emoji,
    }
    emitUIInteraction(message);
    onClose();
  }
  return (
    <button
      className={cn(className, styles.emojiButton)}
      type="button"
      onClick={handleClick}
    >
      {toHumanReadable(emoji)}
    </button>
  )
}