import { Button, MainContent, Typography } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';

import { useLeaderboard } from 'src/queries/leaderboard';
import { useMetaverseVisitors } from 'src/queries/metaverse';
import { useMetaverseMetrics, useMetaverseMetricsData } from 'src/queries/metaverseMetrics';
import Leaderboard from '../../components/Leaderboard/Leaderboard';
import { MetricsSelectionTable } from '../../components/MetricsSelectionTable/MetricsSelectionTable';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { setTitle } from '../../redux/editMetaverse';

import styles from './EditMetaverseAnalyticsPage.module.scss';

export default function AnalyticsDetail() {
  const dispatch = useDispatch();
  const [rowSelection, setRowSelection] = useState({});
  const { metaverse } = useCurrentMetaverse();
  const metaverseId = metaverse?._id;
  const { data: rawLeaderboard = [] } = useLeaderboard(metaverseId);
  const { data: metaverseVisitors = [] } = useMetaverseVisitors(metaverseId, rawLeaderboard.map(({ userId }) => userId));
  const { data: metrics = [] } = useMetaverseMetrics();
  const { mutate: downloadMetaverseMetricsData } = useMetaverseMetricsData();
  const { addToast } = useToastMessageContext();

  useEffect(() => {
    dispatch(setTitle('Analytics'));
  }, []);

  const visitors = metaverseVisitors.map((metaverseVisitor) => metaverseVisitor.participant);

  const leaderboard = rawLeaderboard
    .map((slot) => ({
      ...slot,
      user: visitors.find((participant) => String((participant as any)._id) === slot.userId),
    }));

  const selectedMetricIds = metrics
    .filter((metric, i) => !!(rowSelection as any)[i])
    .map(({ _id }) => _id);

  const handleExportData = () => {
    if (!metaverseId) return;
    addToast('Processing... The download will start shortly.');
    downloadMetaverseMetricsData({
      metaverseId, metricIds: selectedMetricIds,
    });
  };

  return (
    <MainContent variant="table">
      <div className={clsx(styles.heading, styles.metrics)}>
        <Typography variant="h2" emphasis="high">Available Metrics</Typography>
        <Button color="secondary" onClick={handleExportData}>Export Data</Button>
      </div>
      <MetricsSelectionTable
        className={styles.table}
        data={metrics}
        rowSelection={rowSelection}
        onRowSelectionChange={setRowSelection}
      />
      <div className={styles.heading}>
        <Typography variant="h2" emphasis="high">Leaderboard</Typography>
        <Typography variant="body2" emphasis="high">Top 20 players</Typography>
      </div>
      <Leaderboard data={leaderboard} />
    </MainContent>
  );
}
