import { ReactNode, useRef } from 'react';
import { InputEmitter, StreamerStatus, StreamResolutionConfiguration } from '@pureweb/platform-sdk';
import { IdleTimeout, VideoStream } from '@pureweb/platform-sdk-react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { dataLayer } from '../../../../utils/DataLayerUtil';

import styles from './EmbeddedView.module.scss';

export interface EmbeddedViewProps {
  inputEmitter: InputEmitter;
  videoStream: MediaStream;
  streamerStatus: StreamerStatus;
  resolution?: StreamResolutionConfiguration;
  usePointerLock?: boolean;
  pointerLockRelease?: boolean;
  reservedKeys?: string[];
  warningThreshold?: number;
  exitThreshold?: number;
  onTimeout?: () => void;
  children?: ReactNode;
}

const defaultProps = {
  reservedKeys: ['F5', 'F11', 'F8', 'Tab', 'Escape', ';', ':', '~', '`', '\'', '"'],
  warningThreshold: 120,
  exitThreshold: 300,
  usePointerLock: false,
  pointerLockRelease: false,
};

const EmbeddedView = ({
  videoStream,
  streamerStatus,
  inputEmitter,
  resolution,
  usePointerLock = defaultProps.usePointerLock,
  pointerLockRelease = defaultProps.pointerLockRelease,
  reservedKeys = window.env.REACT_APP_PUREWEB_RESERVE_KEYS?.split(',') ??
    defaultProps.reservedKeys,
  warningThreshold = window.env.REACT_APP_PUREWEB_WARNING_THRESHOLD ?
    parseInt(window.env.REACT_APP_PUREWEB_WARNING_THRESHOLD, 10) :
    defaultProps.warningThreshold,
  exitThreshold = window.env.REACT_APP_PUREWEB_EXIT_THRESHOLD ?
    parseInt(window.env.REACT_APP_PUREWEB_EXIT_THRESHOLD, 10) :
    defaultProps.exitThreshold,
  onTimeout = () => window.location.reload(),
  children,
}: EmbeddedViewProps) => {
  const handle = useFullScreenHandle();
  const videoRef = useRef(null);

  const handleTimeout = () => {
    dataLayer.push({ event: 'pureweb_session_timeout' });
    onTimeout();
  };

  return (
    <div className={styles.container}>
      <FullScreen handle={handle}>
        <IdleTimeout
          Status={streamerStatus}
          WarningThreshold={warningThreshold}
          ExitThreshold={exitThreshold}
          WarningCallback={handle.exit}
          ExitCallback={handleTimeout}
        />

        <VideoStream
          VideoRef={videoRef}
          Emitter={inputEmitter}
          Stream={videoStream}
          Resolution={resolution}
          ReservedKeys={reservedKeys}
          UsePointerLock={usePointerLock}
          PointerLockRelease={pointerLockRelease}
        />

        {children}
      </FullScreen>
    </div>
  );
};

export default EmbeddedView;
