import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPage } from 'src/interfaces/IPage';
import PageType from 'src/enums/PageType';
import type { RootState } from '../../../store';

interface MetaversePageState {
  selectedPage: IPage | undefined;
  is3DPage: boolean;
  is3DPageActive: boolean;
}

const initialState: MetaversePageState = {
  selectedPage: undefined,
  is3DPage: false,
  is3DPageActive: false,
};

const threeDPageTypes = [
  PageType.PureWeb3D,
  PageType.WorldMap3D,
  PageType.Space3D,
];

export const metaversePageSlice = createSlice({
  name: 'metaversePage',
  initialState,
  reducers: {
    setSelectedPage: (state, action: PayloadAction<IPage>) => {
      state.selectedPage = action.payload;
      if (threeDPageTypes.includes(action.payload.type)) {
        state.is3DPage = true;
        state.is3DPageActive = false;
      } else {
        state.is3DPage = false;
        state.is3DPageActive = false;
      }
    },
    set3DPageActive: (state) => {
      if (state.is3DPage) state.is3DPageActive = true;
    },
  },
});

export const { setSelectedPage, set3DPageActive } = metaversePageSlice.actions;

export const selectSelectedPage = (state: RootState) => state.metaversePage.selectedPage;
export const selectIs3DPage = (state: RootState) => state.metaversePage.is3DPage;
export const selectIs3DPageActive = (state: RootState) => state.metaversePage.is3DPageActive;

export default metaversePageSlice.reducer;
