import { ChangeEvent } from 'react';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/search.svg';
import styles from './SearchBox.module.scss';

interface Props {
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AdminSearchBox({ className, onChange }: Props) {
  return (
    <div className={clsx(className, styles.container)}>
      <input
        className={styles.input}
        type="text"
        placeholder="Search"
        onChange={onChange}
      />
      <SearchIcon className={styles.icon} />
    </div>
  );
}
