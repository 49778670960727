import React, { ReactNode, RefAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './SubheaderNavLink.module.scss';

interface Props extends RefAttributes<HTMLAnchorElement> {
  to: string;
  end?: boolean;
  children: ReactNode;
  [key: string]: any;
}

export default function SubheaderNavLink({
  to,
  end = false,
  children,
  ...props
}: Props) {
  return (
    <NavLink
      className={({ isActive }) => clsx(styles.button, { [styles.selected]: isActive })}
      to={to}
      end={end}
      {...props}
    >
      {children}
    </NavLink>
  );
}
