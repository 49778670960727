import React, { useEffect, useState } from 'react';
import * as SocketIOClient from 'socket.io-client';

import { MessagePacket, Room, RoomType, SocketEvent } from '../interfaces';
import { dataLayer } from '../utils/DataLayerUtil';

export interface IOnlineUsers { [key: string]: boolean }

export interface IUnreadMessageCounts { [key: string]: number }

export interface IRoomsSocketContext {
  socket: SocketIOClient.Socket | undefined;
  connectedRooms: string[];
  disconnectSocketRoom: Function;
  recentRooms: string[];
  hideRecentRoom: Function;
  inviteUsersToRoom: Function;
  joinSocketRoom: Function;
  userId: string;
  focusedTextChatRoomId: string | undefined;
  setFocusedTextChatRoomId: Function;
  currentChannel: Room | undefined;
  setCurrentChannel: Function;
  onlineUsers: IOnlineUsers;
  unreadMessageCounts: IUnreadMessageCounts;
  clearUnreadMessages: Function;
  addOnlineUsers: (userIds: string[]) => void;
  removeOnlineUsers: (userIds: string[]) => void;
}

export const RoomsSocketContext = React.createContext<IRoomsSocketContext>(null!);

interface RoomsSocketContextProps {
  userId: string;
  authToken: string;
  metaverseId: string;
  children: React.ReactNode;
}

const RoomsSocketContextProvider = ({
  userId, authToken, metaverseId, children,
}: RoomsSocketContextProps) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket>();
  const [focusedTextChatRoomId, setFocusedTextChatRoomId] = useState<string>();
  const [currentChannel, setCurrentChannel] = useState<Room>();
  const [connectedRooms, setConnectedRooms] = useState<string[]>([]);
  const [recentRooms, setRecentRooms] = useState<string[]>([]);
  const [unreadMessageCounts, setUnreadMessageCounts] = useState<IUnreadMessageCounts>({});
  const [onlineUsers, setOnlineUsers] = useState<IOnlineUsers>({});
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const addOnlineUsers = (userIds: string[]) => {
    setOnlineUsers((prevOnlineUsers) => {
      const newOnlineUsers: IOnlineUsers = { ...prevOnlineUsers };
      userIds.forEach((userId) => newOnlineUsers[userId] = true);
      return newOnlineUsers;
    });
  };

  const removeOnlineUsers = (userIds: string[]) => {
    setOnlineUsers((prevOnlineUsers) => {
      const trimmedOnlineUsers: IOnlineUsers = { ...prevOnlineUsers };
      userIds.forEach((userId) => delete trimmedOnlineUsers[userId]);
      return trimmedOnlineUsers;
    });
  };

  const onUserJoinedRoom = ({ remoteUserId, roomId }: { remoteUserId: string, roomId: string }) => {
    if (remoteUserId === userId) {
      setConnectedRooms(
        connectedRooms.includes(roomId) ? connectedRooms : connectedRooms.concat(roomId),
      );
      dataLayer.push({ event: 'socket_room_connect', px_event_id: metaverseId, px_room_id: roomId });
    }
  };

  const onMessageReceived = (receivedMessage: MessagePacket) => {
    if (connectedRooms.includes(receivedMessage.roomId) && !recentRooms.includes(receivedMessage.roomId)) {
      setRecentRooms(recentRooms.concat(receivedMessage.roomId));
    }
    if (receivedMessage.userId !== userId && receivedMessage.roomId !== focusedTextChatRoomId) {
      setUnreadMessageCounts((prevUnreadMessageCounts) => {
        const newCounts = { ...prevUnreadMessageCounts };
        if (!newCounts[receivedMessage.roomId]) {
          newCounts[receivedMessage.roomId] = 0;
        }
        newCounts[receivedMessage.roomId] += 1;
        return newCounts;
      });
    }
  };

  const onError = (err: any) => {
    if (err === 'Failed to validate event permissions.') {
      // Attempt to reconnect
      if (reconnectAttempts < 5) {
        window.setTimeout(() => {
          socket?.disconnect();
          socket?.connect();
        }, 5000);
        setReconnectAttempts((curAttempts) => curAttempts + 1);
      }
    }
  };

  const disconnectSocketRoom = (roomId: string) => {
    socket?.emit(SocketEvent.LeaveRoom, roomId);
    setConnectedRooms((prevConnectedRooms) => prevConnectedRooms.filter((r) => r !== roomId));
    setRecentRooms((prevRecentRooms) => prevRecentRooms.filter((r) => r !== roomId));
    if (roomId === focusedTextChatRoomId) {
      setFocusedTextChatRoomId(undefined);
    }
    if (roomId === currentChannel?._id) {
      setCurrentChannel(undefined);
    }
    dataLayer.push({ event: 'socket_room_disconnect', px_event_id: metaverseId, px_room_id: roomId });
  };

  const hideRecentRoom = (roomId: string) => {
    setRecentRooms((prevRecentRooms) => prevRecentRooms.filter((r) => r !== roomId));
    if (roomId === focusedTextChatRoomId) {
      setFocusedTextChatRoomId(undefined);
    }
  };

  const joinSocketRoom = (room: Room) => {
    if (room.type !== RoomType.Livestream) {
      if (room.capabilities.text) {
        setFocusedTextChatRoomId(room._id);
      }
      if (room.type !== RoomType.Direct) {
        if (currentChannel) {
          socket?.emit(SocketEvent.LeaveRoom, room._id);
          setConnectedRooms((prevConnectedRooms) => prevConnectedRooms.filter((r) => r !== room._id));
          setRecentRooms((prevRecentRooms) => prevRecentRooms.filter((r) => r !== room._id));
          dataLayer.push({ event: 'socket_room_disconnect', px_event_id: metaverseId, px_room_id: room._id });
        }
        setCurrentChannel(room);
      }
      setRecentRooms((prevRecentRooms: string[]) => (prevRecentRooms.includes(room._id) ? prevRecentRooms : prevRecentRooms.concat(room._id)));
    }
    socket?.emit(SocketEvent.JoinRoom, room._id);
  };

  const inviteUsersToRoom = (roomId: string, userIds: string[]) => {
    socket?.emit(SocketEvent.InviteToRoom, {
      user: userId,
      invitees: userIds,
      roomId,
    });
    dataLayer.push({
      event: 'socket_room_invite', px_event_id: metaverseId, px_room_id: roomId, px_invitees: userIds,
    });
  };

  const clearUnreadMessages = (roomId: string) => {
    setUnreadMessageCounts((prevUnreadMessageCounts) => {
      const newCounts = { ...prevUnreadMessageCounts };
      newCounts[roomId] = 0;
      return newCounts;
    });
  };

  useEffect(() => {
    const devSocketServer = Boolean(window.env.REACT_APP_DEV_SOCKET_SERVER);
    const newSocket = SocketIOClient.connect('/rooms', {
      query: { token: authToken, metaverseId },
      transports: !devSocketServer ? ['websocket'] : undefined,
      forceNew: true,
    });
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvent.UserJoinedRoom, onUserJoinedRoom);
      socket.on(SocketEvent.RoomMessage, onMessageReceived);
      socket.on('error', onError);
    }
    return () => {
      socket?.off(SocketEvent.UserJoinedRoom, onUserJoinedRoom);
      socket?.off(SocketEvent.RoomMessage, onMessageReceived);
      socket?.off('error', onError);
    };
  }, [socket, connectedRooms, recentRooms]);

  useEffect(() => {
    if (!socket) return;
    const timer = setInterval(() => {
      socket.emit(SocketEvent.ClientPing, { currentChannel });
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [socket, currentChannel]);

  return (
    <RoomsSocketContext.Provider value={{
      socket,
      connectedRooms,
      disconnectSocketRoom,
      inviteUsersToRoom,
      recentRooms,
      hideRecentRoom,
      joinSocketRoom,
      focusedTextChatRoomId,
      setFocusedTextChatRoomId,
      currentChannel,
      setCurrentChannel,
      userId,
      onlineUsers,
      unreadMessageCounts,
      clearUnreadMessages,
      addOnlineUsers,
      removeOnlineUsers,
    }}
    >
      {socket && children}
    </RoomsSocketContext.Provider>
  );
};

export default RoomsSocketContextProvider;
