export const ADD_PAGE = 'ADD_PAGE';
export const SET_PAGES = 'SET_PAGES';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const ADD_EXHIBIT = 'ADD_EXHIBIT';
export const SET_EXHIBITS = 'SET_EXHIBITS';
export const UPDATE_EXHIBIT = 'UPDATE_EXHIBIT';
export const SET_THREED_CONFIGS = 'SET_THREED_CONFIGS';

const initialState = {
  pages: [],
  exhibits: [],
  threeDConfigs: [],
};

export const pagesReducer = (state = initialState, action = {}) => {
  let newState;
  switch (action.type) {
    case ADD_PAGE: {
      newState = {
        ...state,
        pages: [...state.pages, action.payload],
      };
      return newState;
    }
    case SET_PAGES: {
      newState = {
        ...state,
        pages: action.payload,
      };
      return newState;
    }
    case UPDATE_PAGE: {
      newState = {
        ...state,
        pages: state.pages.map((page) => {
          if (page._id === action.payload._id) {
            return action.payload;
          }
          return page;
        }),
      };
      return newState;
    }
    case ADD_EXHIBIT: {
      newState = {
        ...state,
        exhibits: [...state.exhibits, action.payload],
      };
      return newState;
    }
    case SET_EXHIBITS: {
      newState = {
        ...state,
        exhibits: action.payload,
      };
      return newState;
    }
    case UPDATE_EXHIBIT: {
      newState = {
        ...state,
        exhibits: state.exhibits.map((page) => {
          if (page._id === action.payload._id) {
            return action.payload;
          }
          return page;
        }),
      };
      return newState;
    }
    case SET_THREED_CONFIGS: {
      newState = {
        ...state,
        threeDConfigs: action.payload,
      };
      return newState;
    }
    default: return state;
  }
};

export function addPage(data) {
  return { type: ADD_PAGE, payload: data };
}

export function setPages(data) {
  return { type: SET_PAGES, payload: data };
}

export function updatePage(data) {
  return { type: UPDATE_PAGE, payload: data };
}

export function addExhibit(data) {
  return { type: ADD_EXHIBIT, payload: data };
}

export function setExhibits(data) {
  return { type: SET_EXHIBITS, payload: data };
}

export function updateExhibit(data) {
  return { type: UPDATE_EXHIBIT, payload: data };
}

export function setthreeDConfigs(data) {
  return { type: SET_THREED_CONFIGS, payload: data };
}
