import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Zendesk from 'react-zendesk';

import WorldMap3DPage from 'src/features/Visitor/pages/WorldMap3DPage/WorldMap3DPage';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { useMetaverseJoin } from 'src/queries/metaverse';

import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import ThreeDPage from '../../../../components/ThreeDPage/ThreeDPage';
import PageType from '../../../../enums/PageType';
import { IPage, isMetaverse3DPage, isPureWebPage, isSpace3DPage, isWorldMap3DPage } from '../../../../interfaces/IPage';
import { setExhibits, setPages } from '../../../../redux/pages';
import { getPages } from '../../../../services/ContentService';
import PixelConnectOverlay from '../../../PixelConnect/components/PixelConnectOverlay/PixelConnectOverlay';
import PixelConnect from '../../../PixelConnect/contexts/PixelConnect';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import PagesSidebar from '../../components/PagesSidebar/PagesSidebar';
import VisitorErrorModal from '../../components/VisitorErrorModal/VisitorErrorModal';
import useAccessMetaverse from '../../hooks/useAccessMetaverse';
import ExhibitHall from '../../layouts/ExhibitHall/ExhibitHall';
import Stage from '../../layouts/Stage/Stage';
import VODPage from '../../layouts/VODPage/VODPage';
import { selectIs3DPage, selectIs3DPageActive, selectSelectedPage, setSelectedPage } from '../../redux/metaversePageSlice';
import Metaverse3DPage from '../Metaverse3DPage/Metaverse3DPage';
import Space3DPage from '../Space3DPage/Space3DPage';

import SpsMetaversePage from '../SpsMetaversePage';
import styles from './MetaversePage.module.scss';

const zendeskSettings = {
  offset: {
    horizontal: '100px',
  },
};

export default function MetaversePage() {
  const { metaverse, error } = useAccessMetaverse();
  const { user, authToken } = usePxAuthContext();
  const {
    data: metaverseVisitor,
    error: joinMetaverseError,
  } = useMetaverseJoin(metaverse?._id, user?._id);

  const dispatch = useDispatch();
  const [confirmPage, setConfirmPage] = useState<IPage | null>(null);
  const selectedPage = useSelector(selectSelectedPage);
  const is3DPage = useSelector(selectIs3DPage);
  const is3DPageActive = useSelector(selectIs3DPageActive);
  const navigate = useNavigate();

  useEffect(() => { document.title = 'Pixel Canvas - Metaverse'; }, []);

  useEffect(() => {
    if (!error) return;
    navigate('/noaccess');
  }, [error]);

  useEffect(() => {
    if (!joinMetaverseError) return;
    navigate('/noaccess');
  }, [joinMetaverseError]);

  useEffect(() => {
    if (!metaverse || !metaverseVisitor) return;
    fetchPages();
  }, [metaverse, metaverseVisitor]);

  if (!metaverse) return null;

  const fetchPages = async () => {
    // Fetch pages
    const curPages = await getPages(metaverse._id);
    const exhibits: IPage[] = [];
    const otherPages: IPage[] = [];

    // Separate exhibit pages from rest of pages;
    curPages.forEach((page) => {
      if (page.type === PageType.Exhibit) {
        exhibits.push(page);
      } else {
        otherPages.push(page);
      }
    });

    dispatch(setPages(otherPages));
    dispatch(setExhibits(exhibits));
  };

  const handlePageClick = (page: IPage) => {
    if (selectedPage === page) return;
    if (is3DPage && is3DPageActive) {
      setConfirmPage(page);
    } else {
      dispatch(setSelectedPage(page));
    }
  };

  const handlePageSwitch = () => {
    if (!confirmPage) return;
    dispatch(setSelectedPage(confirmPage));
    setConfirmPage(null);
  };

  return (
    <div className={styles.container}>
      {
        user && authToken && metaverse && metaverseVisitor &&
        <PixelConnect
          textChatEnabled
          videoChatEnabled
          userId={user.userId}
          metaverseId={metaverse._id}
          authToken={authToken}
        >
          <PagesSidebar onPageClick={handlePageClick} />
          <div className={styles.main}>
            <div className={styles.page}>
              {
                selectedPage &&
                <ErrorBoundary>
                  <SelectedPage page={selectedPage} metaverse={metaverse} />
                </ErrorBoundary>
              }
            </div>
          </div>
          <div className={styles['pixel-connect-overlay']}>
            <PixelConnectOverlay />
          </div>
        </PixelConnect>
      }
      <ConfirmationModal
        title="You are about to leave the 3D session."
        instructions1="If you continue, you will need to rejoin the 3D session."
        open={Boolean(confirmPage)}
        onClose={() => setConfirmPage(null)}
        confirmText="Continue"
        onConfirm={handlePageSwitch}
        onCancel={() => setConfirmPage(null)}
      />
      <VisitorErrorModal />
      {metaverse?.settings.techSupportEnabled && metaverse?.crm.zendeskKey && (
        <Zendesk
          defer
          zendeskKey={metaverse.crm.zendeskKey}
          onLoaded={() => console.log('zendesk loaded')}
          {...zendeskSettings}
        />
      )}
    </div>
  );
}

type SelectedPageProps = {
  page: IPage;
  metaverse: MetaverseResponse;
};

function SelectedPage({ page, metaverse }: SelectedPageProps) {
  if (isSpace3DPage(page)) {
    return <Space3DPage page={page} metaverse={metaverse} />;
  }
  if (isPureWebPage(page)) {
    return <ThreeDPage page={page} metaverse={metaverse} />;
  }
  if (isWorldMap3DPage(page)) {
    return <WorldMap3DPage page={page} metaverse={metaverse} />;
  }
  if (isMetaverse3DPage(page)) {
    if (page.space.environment.purewebConfiguration.spsSignallingServerURL) {
      return <SpsMetaversePage page={page} />;
    }
    return <Metaverse3DPage page={page} />;
  }
  switch (page.type) {
    case PageType.Stage:
      return <Stage page={page} />;
    case PageType.VideoOnDemand:
      return <VODPage page={page} />;
    case PageType.Exhibit:
      return <ExhibitHall />;
    default:
      return null;
  }
}
