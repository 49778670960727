import { ClickAwayListener, Portal } from '@material-ui/core';
import { DropdownOption, DropdownOptions, PicturePill } from '@pixelcanvas/ui';
import { CSSProperties, useState } from 'react';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';

import defaultAvatar from '../../assets/images/profile-pic.png';
import ProfileModal from '../../features/Dashboard/components/ProfileModal/ProfileModal';

import usePxAuthContext from '../../authentication/usePxAuthContext';

type Props = {
  className?: string;
};

export default function UserPill({ className }: Props) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const userProfile = useSelector((state: any) => state.userProfile);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const { logout } = usePxAuthContext();

  const [referenceElment, setReferenceElment] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElment, popperElement,
    {
      strategy: 'fixed',
      placement: 'bottom-end',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
  );

  const handleContactUsClick = () => {
    window.open('mailto:support@pixelcanvas.com', '_blank');
  };

  return (
    <>
      <PicturePill
        classes={{ button: className }}
        ref={setReferenceElment}
        src={userProfile.picture ?? defaultAvatar}
        alt={userProfile?.displayName}
        onClick={() => setIsDropdownOpened(!isDropdownOpened)}
      />
      {
        isDropdownOpened &&
        <Portal>
          <ClickAwayListener onClickAway={() => setIsDropdownOpened(false)}>
            <DropdownOptions
              ref={setPopperElement}
              style={{ ...popperStyles.popper } as CSSProperties}
              {...attributes.popper}
            >
              <DropdownOption
                type="button"
                data-value="Profile"
                onClick={() => setOpenProfileModal(true)}
              >
                Profile
              </DropdownOption>
              <DropdownOption
                type="button"
                data-value="Help & Contact"
                onClick={() => handleContactUsClick()}
              >
                Help & Contact
              </DropdownOption>
              <DropdownOption
                type="button"
                data-value="Sign Out"
                onClick={() => logout()}
              >
                Logout
              </DropdownOption>
            </DropdownOptions>
          </ClickAwayListener>
        </Portal>
      }
      <ProfileModal open={openProfileModal} close={() => setOpenProfileModal(false)} />
    </>
  );
}
