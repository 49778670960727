import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

import styles from './HUDButton.module.scss';

interface HUDButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'type'> {
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}

export default function HUDButton({
  className,
  onClick,
  children,
  ...props
}: HUDButtonProps) {
  return (
    <button
      className={clsx(className, styles.button)}
      type="button"
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}
