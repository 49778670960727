import PulseLoader from 'react-spinners/PulseLoader';

type Props = {
  className?: string,
};

const LoadingSpinner = ({ className }: Props) => (
  <div className={className}>
    <PulseLoader color="#DEDEDE" />
  </div>
);
export default LoadingSpinner;
