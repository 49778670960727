import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './MapsView.module.scss';

interface MapsViewProps {
  className?: string;
  children: ReactNode;
}

export default function MapsView({ className, children }: MapsViewProps) {
  return (
    <div className={clsx(className, styles.container)}>
      {children}
    </div>
  );
}
