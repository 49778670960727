import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import styles from './Overlay.module.scss';
import { useSpsContext } from './SpsContextProvider';

export default function ErrorOverlay() {
  const { errorMessage } = useSpsContext();
  return (
    <div className={clsx(styles.overlay, styles.error)}>
      <FontAwesomeIcon className={styles.icon} icon={faTriangleExclamation} />
      <p>Error: {errorMessage}</p>
    </div>
  );
}
