import clsx from 'clsx';
import Color from 'color';
import { CSSProperties, ReactNode } from 'react';

import styles from './PageButton.module.scss';

interface PageButtonProps {
  className?: string;
  onClick: () => void;
  active: boolean;
  navBarColor: string;
  tabColor?: string;
  children: ReactNode;
}

export default function PageButton({
  className,
  onClick,
  active,
  navBarColor = 'transparent',
  tabColor = 'white',
  children,
}: PageButtonProps) {
  const activeColor = Color(navBarColor).isLight() ? 'black' : 'white';
  const isButtonLight = Color(navBarColor).isLight();
  const isActiveLight = Color(activeColor).isLight();
  const isLight = active || navBarColor === 'transparent'
    ? isActiveLight : isButtonLight;
  return (
    <button
      type="button"
      className={clsx(className, styles.button, { [styles.dark]: isLight })}
      style={{
        '--button-color': navBarColor,
        '--tab-color': tabColor,
        '--active-color': activeColor,
      } as CSSProperties}
      onClick={onClick}
    >
      <div className={styles.tab} />
      <div className={clsx(styles.content, { [styles.dark]: isLight, [styles.active]: active })}>
        {children}
      </div>
    </button>
  );
}
