import { AnyAbility, createMongoAbility } from '@casl/ability';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { SpaceResponse } from 'src/features/Dashboard/interfaces/ISpace';
import { EnvironmentResponse } from 'src/interfaces/Environment';
import { OrganizationInviteResponse, OrganizationMemberResponse, OrganizationTeamMemberResponse } from 'src/interfaces/IOrganizationMember';
import OrganizationRole from 'src/interfaces/OrganizationRole';
import { OrganizationRequest, OrganizationResponse } from '../interfaces/IOrganization';
import { OrganizationSubscriptionSchema } from '../interfaces/OrganizationSubscription';
import api from '../services/api';

export const queryKey = 'organizations';

export const useOrganizations = () => useQuery<OrganizationResponse[]>(
  [queryKey],
  () => api
    .get('/api/v2/organizations')
    .then((res) => res.data),
);

export const useOrganization = (id: string) => useQuery<OrganizationResponse>(
  [queryKey],
  () => api
    .get(`/api/v2/organizations/${id}`)
    .then((res) => res.data),
);

export const useOrganizationMembers = (id: string | undefined) => useQuery<OrganizationMemberResponse[], AxiosError>(
  [queryKey, `${queryKey}-${id}-members`],
  () => api
    .get<OrganizationMemberResponse[]>(`/api/v2/organizations/${id}/members`)
    .then((res) => res.data),
  { enabled: !!id },
);

export const useOrganizationTeam = (id: string | undefined) => useQuery<OrganizationTeamMemberResponse[]>(
  [queryKey, `${queryKey}-${id}-team`],
  () => api
    .get(`/api/v2/organizations/${id}/team`)
    .then((res) => res.data),
  { enabled: !!id },
);

export async function postOrganizationImage(organizationId: string, image: string) {
  try {
    const formData = new FormData();
    formData.append('picture', image);
    const response = await api.post(`/api/v2/organizations/${organizationId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e?.response?.data?.detail || 'Image upload failed');
  }
}

export const usePatchOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: OrganizationRequest) => api
      .patch(`/api/v2/organizations/${organizationId}`, data)
      .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey]);
      },
    },
  );
};

export const usePatchOrganizationMember = (organizationId: string, memberId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (role: string) => api
      .patch(`/api/v2/organizations/${organizationId}/members/${memberId}`, { role })
      .then((res) => res.data),
    { onSuccess: () => queryClient.invalidateQueries([queryKey]) },
  );
};

export const useDeleteOrganizationMember = (organizationId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (memberId: string) => api
      .delete(`/api/v2/organizations/${organizationId}/members/${memberId}`)
      .then((res) => res.data),
    { onSuccess: () => queryClient.invalidateQueries([queryKey]) },
  );
};

export const useOrganizationSpaces = (organizationId: string | undefined) => useQuery<SpaceResponse[]>(
  [`${queryKey}-spaces`, organizationId],
  () => api
    .get(`/api/v2/organizations/${organizationId}/spaces`)
    .then((res) => res.data),
  { enabled: !!organizationId },
);

export function useOrganizationEnvironments(organizationId: string | undefined) {
  return useQuery<EnvironmentResponse[], AxiosError>(
    [queryKey, 'lists', 'environments', organizationId],
    () => api
      .get(`/api/v2/organizations/${organizationId}/environments`)
      .then((res) => res.data),
    { enabled: !!organizationId },
  );
}

type CreateInviteParams = {
  email: string;
  role: OrganizationRole;
};

function transformResponse(data: any): OrganizationInviteResponse {
  return {
    ...data,
    createdAt: DateTime.fromISO(data.createdAt),
    updatedAt: DateTime.fromISO(data.updatedAt),
    expireAt: DateTime.fromISO(data.expireAt),
  };
}

export const useInvites = (organizationId: string) => useQuery<OrganizationInviteResponse[]>(
  queryKey,
  () => api
    .get(`/api/v2/organizations/${organizationId}/invites`)
    .then((res) => res.data.map(transformResponse)),
);

export const useCreateInvite = (organizationId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: CreateInviteParams) => api
      .post(`/api/v2/organizations/${organizationId}/invites`, data)
      .then((res) => res.data),
    { onSuccess: () => queryClient.invalidateQueries([
      queryKey,
      `${queryKey}-${organizationId}-team`,
    ]) },
  );
};

export const useCancelOrganizationInvite = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (uniqueId: string) => api
      .post(`/api/v2/invites/organizations/cancel/${uniqueId}`)
      .then((res) => res.data),
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
};

export function useAcceptOrganizationInvite() {
  return useMutation(
    (token: string) => api
      .post('/api/v2/invites/organizations/accept', { token })
      .then((res) => res.data),
  );
}

export const useOrganizationUsage = (organizationId: string | undefined) => useQuery(
  `${queryKey}-usage`,
  () => api
    .get(`/api/v2/organizations/${organizationId}/usage`)
    .then((res) => res.data),
  { enabled: !!organizationId },
);

export const useOrganizationAbility = (organizationId: string | undefined) => useQuery<AnyAbility, AxiosError>(
  [queryKey, organizationId, 'permissions'],
  () => api
    .get(`/api/v2/organizations/${organizationId}/me/permissions`)
    .then((res) => createMongoAbility(res.data)),
  { enabled: !!organizationId },
);

export function useOrganizationSubscription(organizationId: string | undefined, options: any = {}) {
  return useQuery(
    [queryKey, organizationId, 'subscription'],
    () => api
      .get(`/api/v2/organizations/${organizationId}/subscription`)
      .then((res) => OrganizationSubscriptionSchema.parse({
        ...res.data,
        currentPeriodEnd: new Date(res.data.currentPeriodEnd),
      })),
    { ...options, enabled: !!organizationId },
  );
}

export function useManageOrganizationBilling() {
  return useMutation(
    (organizationId: string) => api
      .post(`/api/v2/organizations/${organizationId}/billing`)
      .then((res) => res.data),
  );
}

