import { useQuery } from 'react-query';
import LeaderboardSlot from 'src/interfaces/LeaderboardSlot';
import api from '../services/api';

export const queryKey = 'leaderboard';

export const useLeaderboard = (metaverseId: string | undefined, options: any = {}) => useQuery<LeaderboardSlot[]>(
  [queryKey, metaverseId],
  () => api
    .get<LeaderboardSlot[]>(`/api/game/leaderboard/${metaverseId}`)
    .then((res) => res.data)
    .then((data) => data.map((slot, i) => ({ ...slot, rank: i + 1 }))),
  { enabled: !!metaverseId, ...options },
);
