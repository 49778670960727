import styles from './UpNext.module.scss';
import { DateTime } from 'luxon';
import { isoTimeFromUTC } from '../../../../utils/TimeHelper';
import { IContent } from '../../../../interfaces/IContent';

interface UpNextProps {
  content: IContent | null;
}

export default function UpNext({ content }: UpNextProps) {
  return (
    <>
      {
          content && content.timeFrame?.startTime &&
          <>
            <div className={styles['blurred-background']}><img className={styles['blurred-image']} src={content.thumbnail} /></div>
            <div className={styles['up-next']}>
              <img className={styles.thumbnail} src={content.thumbnail} />
              <div className={styles['info-block']}>
                <div className={styles.time}>Up Next at {isoTimeFromUTC(content.timeFrame.startTime, DateTime.local().zoneName, false)} {DateTime.local().toFormat('ZZZZ')}</div>
                <div className={styles['up-next-title']}>{content.title}</div>
              </div>
            </div>
          </>
        }
      {
          !content &&
          <div className={styles['up-next']}>
            <div className={styles['no-livestream']}>
              There is currently no scheduled livestream on this stage.
            </div>
          </div>
        }
    </>
  );
}
