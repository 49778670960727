import { Button, Card, Cards, Typography } from '@pixelcanvas/ui';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

import { OrganizationResponse } from 'src/interfaces/IOrganization';
import { useManageOrganizationBilling, useOrganizationSubscription } from 'src/queries/organization';
import PlatformSpinner from 'src/components/PlatformSpinner/PlatformSpinner';
import { OrganizationSubscription } from 'src/interfaces/OrganizationSubscription';

import styles from './OrganizationBillingPage.module.scss';

interface OrganizationBillingPageProps {
  organization?: OrganizationResponse;
}

export default function OrganizationBillingPage({
  organization,
}: OrganizationBillingPageProps) {
  const { mutate: manage, data } = useManageOrganizationBilling();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
  } = useOrganizationSubscription(organization?._id, { retry: 1, staleTime: 2 * 60 * 1000 });

  useEffect(() => {
    if (!data?.url) return;
    window.location.href = data.url;
  }, [data?.url]);

  if (isSubscriptionLoading || !organization) return <PlatformSpinner visible />;

  return (
    <>
      <Typography variant="h2" emphasis="high" className={styles.heading}>Billing</Typography>
      <BillingCard subscription={subscription} organization={organization} onManageClick={() => manage(organization._id)} />
      {
        subscription && (
          <section>
            <Typography variant="h2" emphasis="high" className={styles.plan}>Plan</Typography>
            <Cards classes={{ container: styles.plans, cards: styles.planCards }}>
              <PlanCard subscription={subscription} />
            </Cards>
          </section>
        )
      }
    </>
  );
}

interface BillingCardProps {
  subscription?: OrganizationSubscription;
  organization: OrganizationResponse;
  onManageClick: () => void;
}

function BillingCard({ subscription, organization, onManageClick }: BillingCardProps) {
  return (
    <Card variant="data" className={styles.billing}>
      <Card.Content className={styles.content}>
        <div className={styles.summary}>
          <Typography variant="h6">BILLING SUMMARY</Typography>
          <SubscriptionPlanPrice plan={organization.subscription} subscription={subscription} />
          {subscription ? <SubscriptionNextBilling subscription={subscription} /> : <Typography variant="body2">No subscription found</Typography>}
        </div>
        <div className={styles.manage}>
          <Button color="primary" className={styles.button} disabled={!organization.customerId} onClick={onManageClick}>
            Manage Billing in Stripe
          </Button>
          <Typography variant="body1" emphasis="medium" className={styles.note}>
            For upgrades, downgrades, or cancellations:
          </Typography>
          <a href="mailto:support@pixelcanvas.com" className={styles.support}>Contact support</a>
        </div>
      </Card.Content>
    </Card>
  )
}

interface PlanCardProps {
  subscription: OrganizationSubscription;
}

function PlanCard({ subscription }: PlanCardProps) {
  return (
    <Card variant="data">
      <Card.Content className={styles.summary}>
        <Typography variant="h6">{subscription.plan.toUpperCase()}</Typography>
        <Typography variant="h4"><SubscriptionPrice subscription={subscription} /></Typography>
        <Typography variant="body2">{subscription.description}</Typography>
        <ul className={styles.features}>
          {
            subscription.features.map((feature) => (
              <Typography key={feature} variant="body1" component="li">
                {feature}
              </Typography>
            ))
          }
        </ul>
      </Card.Content>
    </Card>
  )
}

interface SubscriptionPlanPriceProps {
  plan: string;
  subscription?: OrganizationSubscription;
}

function SubscriptionPlanPrice({ plan, subscription }: SubscriptionPlanPriceProps) {
  return (
    <Typography variant="h4">
      {plan} {subscription && <SubscriptionPrice subscription={subscription} />}
    </Typography>
  );
}

interface SubscriptionPriceProps {
  subscription: OrganizationSubscription;
}

function SubscriptionPrice({ subscription }: SubscriptionPriceProps) {
  const fraction = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: subscription.price.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: subscription.price.currency,
  });
  const amount = subscription.price.amount / 100;
  const price = (amount % 1 === 0) ? fraction.format(amount) : formatter.format(amount);
  const period = subscription.price.recurring.count > 1
    ? `${subscription.price.recurring.count} ${subscription.price.recurring.interval}`
    : subscription.price.recurring.interval;
  return (
    <>{price}/<span className={styles.period}>{period}</span></>
  )
}

interface SubscriptionNextBillingProps {
  subscription?: OrganizationSubscription;
}

function SubscriptionNextBilling({ subscription }: SubscriptionNextBillingProps) {
  if (!subscription) return null;
  const nextBillingOnDate = DateTime
    .fromJSDate(subscription.currentPeriodEnd)
    .toFormat('LLL dd, yyyy');
  return (
    <Typography variant="body2">Next billing on {nextBillingOnDate}</Typography>
  )
}
