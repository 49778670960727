/* eslint-disable object-curly-newline */
import { DateTime } from 'luxon';
import api from './api';
import { SocketEvent } from '../interfaces';
import { IUserNotification } from '../features/PixelConnect/components/Notifications/NotificationsContextProvider';

export interface NotificationRequest {
  metaverseId: string;
  type: SocketEvent;
  recipients: any[];
  payload: any;
  timestamp?: Date;
}

export interface TextNotificationRequest extends NotificationRequest {
  type: SocketEvent.TextNotification;
  payload: {
    text: string;
  }
}

type NotificationResponse = NotificationRequest & { timestamp: string };

export type Notification = Omit<Required<NotificationRequest>, 'timestamp'> & { sentAt?: boolean, timestamp: DateTime };

export async function getNotifications(metaverseId: string, types?: SocketEvent[]): Promise<Notification[]> {
  const params = { metaverseId, types: types?.join(','), skip: 0, limit: 100 };
  const response = await api.get('/api/notifications', { params });
  return response.data
    .map((notification: NotificationResponse) => ({
      ...notification,
      timestamp: DateTime.fromISO(notification.timestamp),
    }))
    .sort((a: Notification, b: Notification) => b.timestamp.toMillis() - a.timestamp.toMillis());
}

export async function createNotification(request: NotificationRequest): Promise<Notification> {
  const response = await api.post('/api/notifications', request);
  const notification: Notification = {
    ...response.data,
    timestamp: DateTime.fromISO(response.data.timestamp),
  };
  return notification;
}

export async function getUnreadNotifications(metaverseId: string): Promise<IUserNotification[]> {
  const res = await api.get(`/api/notifications/unread?metaverseId=${metaverseId}`);
  return res.data;
}

export async function markReadBefore(metaverseId: string, userNotificationId: string): Promise<any> {
  const res = await api.patch('/api/notifications/markReadBefore', { metaverseId, userNotificationId });
  return res.data;
}

export async function markRead(metaverseId: string, userNotificationId: string): Promise<any> {
  const res = await api.patch('/api/notifications/markRead', { metaverseId, userNotificationId });
  return res.data;
}
