enum SocketEvent {
  ClientPing = 'client-ping',
  ControlAccess = 'control-access',
  DeleteMessage = 'delete-message',
  DeleteRoom = 'delete-room',
  InitializeUser = 'initialize-user',
  InviteToRoom = 'invite-to-room',
  InvitedToRoom = 'invited-to-room',
  JoinRoom = 'join-room',
  LeaveRoom = 'leave-room',
  MessageDeleted = 'message-deleted',
  ModerationEnforced = 'moderation-enforced',
  PresentationStarted = 'presentation-started',
  PresentationEnded = 'presentation-ended',
  RoomDeleted = 'room-deleted',
  RoomMessage = 'room-message',
  SendMessage = 'send-message',
  UserJoinedRoom = 'user-joined-room',
  UserStatusChanged = 'user-status-changed',
  UserProfileChanged = 'user-profile-changed',
  UnrealRoomSelected = 'unreal:room-selected',
  UnrealRoomExited = 'unreal:room-exited',
  StartPresentation = 'start-presentation',
  EndPresentation = 'end-presentation',
  GlobalUserJoinedRoom = 'event-notifications:user-joined-room',
  GlobalUserExitedRoom = 'event-notifications:user-exited-room',
  TextNotification = 'event-notifications:text',
  LivestreamStarted = 'livestream-started',
  UnrealUsageLimitReached = 'unreal:usage-limit-reached',
  UnrealJoinSpace = 'unreal:join-space',
}

export default SocketEvent;
