import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';

import styles from './DefaultOrganizationIcon.module.scss';

interface DefaultOrganizationIconProps {
  className?: string;
}

export default function DefaultOrganizationIcon({ className }: DefaultOrganizationIconProps) {
  return <FontAwesomeIcon icon={faUsers} className={clsx(className, styles.avatar)} />;
}
