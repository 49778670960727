import { useEffect, useRef, useState } from 'react';
import Fuse from 'fuse.js';
import {
  Button,
  Cards,
  ConfirmationModal,
  FilterBy,
  MainContent,
  Page,
  PageContent,
  Search,
  SortBy,
} from '@pixelcanvas/ui';

import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';

import { IUser } from 'src/interfaces';
import { useMe } from 'src/queries/me';
import SubheaderNav from '../../components/SubheaderNav/SubheaderNav';
import MainHeader from '../../components/MainHeader/MainHeader';
import MapCard from './components/MapCard/MapCard';

import { ReactComponent as PlusIcon } from '../../../../assets/svg/plus-circle.svg';

import { useCreateWorldMap, useDeleteWorldMap, useUpdateWorldMap, useWorldMaps } from '../../queries/worldMaps';

import WorldMapRequest, { WorldMapResponse } from '../../interfaces/WorldMap';
import { useGetSpaces } from '../../queries/spaces';
import WorldMapModal from './components/WorldMapModal/WorldMapModal';

import styles from './WorldMapsPage.module.scss';

enum SortByOption {
  NameAscending = 'Name: A-Z',
  NameDescending = 'Name: Z-A',
  CreatedDescending = 'Created: Newest to Oldest',
  CreatedAscending = 'Created: Oldest to Newest',
  MetaversesAscending = 'Metaverses: Most to Least',
  MetaversesDescending = 'Metaverses: Least to Most',
  // TimeSpentDescending = 'Metaverses: Most to Least',
  // TimeSpentAscending = 'Metaverses: Least to Most',
}

const sortByOptions = [...Object.values(SortByOption)];

export function sortByCompareFn(
  sortBy: SortByOption, a: WorldMapResponse, b: WorldMapResponse,
) {
  switch (sortBy) {
    case SortByOption.NameAscending:
      return a.nickname.localeCompare(b.nickname);
    case SortByOption.NameDescending:
      return -a.nickname.localeCompare(b.nickname);
    case SortByOption.CreatedDescending:
      return b.createdAt.toMillis() - a.createdAt.toMillis();
    case SortByOption.CreatedAscending:
      return a.createdAt.toMillis() - b.createdAt.toMillis();
    case SortByOption.MetaversesAscending:
      return b.metaverses.length - a.metaverses.length;
    case SortByOption.MetaversesDescending:
      return a.metaverses.length - b.metaverses.length;
    default:
      return 1;
  }
}

enum FilterByOption {
  LinkedToLiveMetaverses = 'Linked to Live Metaverse(s)',
  LinkedToMetaverses = 'Linked to Metaverse(s)',
  NotLinkedToMetaverses = 'Not Linked to Metaverse(s)',
  CreatedByYou = 'Created By You',
  CreatedByOthers = 'Created By Others',
}

const filterByOptions = [...Object.values(FilterByOption)];

export function filterByPredicateFn(
  filterBy: FilterByOption[], map: WorldMapResponse, userInfo: IUser,
) {
  if (filterBy.length === 0) return true;
  return filterBy.some((by) => {
    switch (by) {
      case FilterByOption.LinkedToLiveMetaverses:
        return map.metaverses.some((metaverse) => metaverse.published);
      case FilterByOption.LinkedToMetaverses:
        return map.metaverses.length > 0;
      case FilterByOption.NotLinkedToMetaverses:
        return map.metaverses.length === 0;
      case FilterByOption.CreatedByYou:
        return map.createdBy.userId === userInfo.userId;
      case FilterByOption.CreatedByOthers:
        return map.createdBy.userId !== userInfo.userId;
      default:
        return true;
    }
  });
}

const fuseOptions = {
  keys: ['nickname'],
  threshold: 0.3,
};

export default function WorldMapsPage() {
  const [openModal, setOpenModal] = useState(false);
  const [editedMap, setEditedMap] = useState<WorldMapResponse>();
  const [mapToDelete, setMapToDelete] = useState<WorldMapResponse>();

  const [sortBy, setSortBy] = useState<SortByOption>(sortByOptions[0]);
  const [filterBy, setFilterBy] = useState<FilterByOption[]>([]);
  const [pattern, setPattern] = useState('');

  const fuseRef = useRef(new Fuse<WorldMapResponse>([], fuseOptions));

  const { data: worldMaps = [] } = useWorldMaps();
  const { data: userInfo } = useMe();
  const { data: spaces = [], isSuccess: isSpacesLoaded } = useGetSpaces();
  const { mutate: deleteWorldMap, isSuccess: isDeleteWorldMapSuccess, reset: resetDeleteWorldMap } = useDeleteWorldMap();
  const { mutate: createWorldMap, isSuccess: isCreateWorldMapSuccess, reset: resetCreateWorldMap } = useCreateWorldMap();
  const { mutate: updateWorldMap, isSuccess: isUpdateWorldMapSuccess, reset: resetUpdateWorldMap } = useUpdateWorldMap(editedMap?._id ?? '');

  const { addToast } = useToastMessageContext();

  const fuse = fuseRef.current;

  useEffect(() => {
    if (!worldMaps) return;
    fuse.setCollection(worldMaps);
  }, [worldMaps]);

  useEffect(() => {
    if (!isDeleteWorldMapSuccess) return;
    addToast('World map deleted successfully.');
    resetDeleteWorldMap();
  }, [isDeleteWorldMapSuccess]);

  useEffect(() => {
    if (!isCreateWorldMapSuccess) return;
    addToast('World map created successfully.');
    resetCreateWorldMap();
  }, [isCreateWorldMapSuccess]);

  useEffect(() => {
    if (!isUpdateWorldMapSuccess) return;
    addToast('World map updated successfully.');
    resetUpdateWorldMap();
  }, [isUpdateWorldMapSuccess]);

  const worldMapElements = !userInfo?.userId
    ? []
    : (pattern.trim() ? fuse.search(pattern.trim()).map((result) => result.item) : worldMaps)
      .filter((map) => filterByPredicateFn(filterBy, map, userInfo))
      .sort((a, b) => sortByCompareFn(sortBy, a, b))
      .map((map) => {
        const handleEdit = () => {
          setEditedMap(map);
          setOpenModal(true);
        };
        const handleDelete = () => {
          setMapToDelete(map);
        };
        return (
          <MapCard
            key={map._id}
            map={map}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        );
      });

  const handleCreateNewMap = () => {
    setEditedMap(undefined);
    setOpenModal(true);
  };

  const handleSave = (map: WorldMapRequest) => {
    if (editedMap) {
      updateWorldMap(map);
    } else {
      createWorldMap(map);
    }
    setOpenModal(false);
  };

  return (
    <Page>
      <PageContent>
        <MainHeader />
        <SubheaderNav />
        <MainContent variant="cards">
          <div className={styles.controls}>
            <Search onChange={setPattern} />
            <SortBy
              className={styles.sort}
              options={sortByOptions}
              onChange={setSortBy}
              maxVisible={6}
            />
            <FilterBy
              options={filterByOptions}
              onChange={setFilterBy}
            />
            <Button onClick={handleCreateNewMap}>
              <PlusIcon className={styles.add} />
              Create a New Map
            </Button>
          </div>
          <Cards>{worldMapElements}</Cards>
        </MainContent>
      </PageContent>
      {
        isSpacesLoaded && openModal &&
        <WorldMapModal
          open={openModal}
          onSave={handleSave}
          onClose={() => setOpenModal(false)}
          spaces={spaces}
          editedMap={editedMap}
        />
      }
      {
        mapToDelete &&
        <ConfirmationModal
          title="ARE YOU SURE YOU WANT TO DELETE THIS WORLD MAP?"
          description="Delete world map. This action cannot be undone."
          open={Boolean(mapToDelete)}
          onConfirm={() => {
            deleteWorldMap(mapToDelete._id);
            setMapToDelete(undefined);
          }}
          onClose={() => setMapToDelete(undefined)}
        />
      }

    </Page>
  );
}
