import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IMetaverseVisitor from 'src/interfaces/IMetaverseVisitor';
import Role from 'src/interfaces/Role';
import { RootState } from 'src/store';

const initialState: IMetaverseVisitor = {
  _id: '',
  metaverseId: '',
  participant: { email_verified: false, email: '', role: Role.Visitor, userId: '', newProfile: true },
  roles: [],
  tags: [],
  personalAgenda: [],
  userId: '',
  fields: {},
  permitEmailUse: false,
  status: 'active',
};

export const metaverseVisitorSlice = createSlice({
  name: 'metaverseVisitor',
  initialState,
  reducers: {
    setMetaverseVisitor: (state, action: PayloadAction<IMetaverseVisitor>) => ({ ...action.payload }),
    setMetaverseVisitorField: (state, action: PayloadAction<{ fieldName: string, value: any }>) => {
      state.fields = { ...state.fields, [action.payload.fieldName]: action.payload.value };
    },
  },
});

export const { setMetaverseVisitor, setMetaverseVisitorField } = metaverseVisitorSlice.actions;

export const selectMetaverseVisitor = (state: RootState) => state.metaverseVisitor;

export default metaverseVisitorSlice.reducer;
