import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, MoreOptions, Typography } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';

import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import { useDeleteMetaverse, useUpdateMetaverse } from 'src/queries/metaverse';
import defaultPhoto from '../../../../assets/images/default-event-photo.jpg';
import { MetaverseResponse } from '../../../../interfaces/IMetaverse';
import { getDisplayName } from '../../../../utils/DisplayNameUtil';
import { getMetaverseEditURL, getMetaverseVisitorURL } from '../../../../utils/URLHelper';
import DeleteMetaverseConfirmModal from '../DeleteMetaverseConfirmModal';
import MetaverseStatus from '../MetaverseStatus/MetaverseStatus';
import PublishMetaverseConfirmModal from '../PublishMetaverseConfirmModal';
import UnpublishMetaverseConfirmModal from '../UnpublishMetaverseConfirmModal';

import styles from './MetaverseCard.module.scss';

enum MetaverseOption {
  Unpublish = 'Unpublish',
  Publish = 'Publish',
  Delete = 'Delete',
}

interface Props {
  metaverse: MetaverseResponse;
}

export default function MetaverseCard({ metaverse }: Props) {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const { addToast } = useToastMessageContext();

  const {
    mutate: updateMetaverse,
    data: updatedMetaverse,
    isSuccess: isUpdateMetaverseSuccess,
    reset: resetUpdateMetaverse,
  } = useUpdateMetaverse(metaverse._id);
  const {
    mutate: deleteMetaverse,
    isSuccess: isDeleteMetaverseSuccess,
    reset: resetDeleteMetaverse,
  } = useDeleteMetaverse(metaverse._id);

  useEffect(() => {
    if (!isUpdateMetaverseSuccess || !updatedMetaverse) return;
    const message = updatedMetaverse.published
      ? 'Metaverse published!'
      : 'Metaverse unpublished!';
    addToast(message);
    resetUpdateMetaverse();
  }, [isUpdateMetaverseSuccess, updatedMetaverse]);

  useEffect(() => {
    if (!isDeleteMetaverseSuccess) return;
    addToast('Metaverse deleted!');
    resetDeleteMetaverse();
  }, [isUpdateMetaverseSuccess, updatedMetaverse]);

  const confirmPublishMetaverse = () => {
    updateMetaverse({ published: true });
    setShowPublishModal(false);
  };

  const confirmUnpublishMetaverse = () => {
    updateMetaverse({ published: false });
    setShowUnpublishModal(false);
  };

  const confirmDeleteMetaverse = () => deleteMetaverse();

  const onOptionSelected = (option: MetaverseOption) => {
    switch (option) {
      case MetaverseOption.Unpublish:
        setShowUnpublishModal(true);
        break;
      case MetaverseOption.Publish:
        setShowPublishModal(true);
        break;
      case MetaverseOption.Delete:
        setShowDeleteModal(true);
        break;
      default:
        break;
    }
  };

  const options = metaverse.published
    ? [MetaverseOption.Unpublish]
    : [MetaverseOption.Publish, MetaverseOption.Delete];

  const metaverseImage =
    metaverse.logo ??
    metaverse.spaces?.[0]?.logo ??
    defaultPhoto;

  return (
    <Card className={styles.card}>
      <Card.Image
        className={styles.image}
        alt="event"
        src={metaverseImage}
        darken="solid"
      >
        <div className={styles.topLeft}>
          <MetaverseStatus metaverse={metaverse} />
        </div>
        <div className={styles.topRight}>
          <MoreOptions
            classes={{ container: styles.options }}
            options={options}
            open={openOptions}
            onOpen={() => setOpenOptions(true)}
            onClose={() => setOpenOptions(false)}
            onSelect={onOptionSelected}
          />
        </div>
        <div className={styles.center}>
          <Button
            className={styles.edit}
            color="tertiary"
            onClick={() => {
              window.location.href = getMetaverseEditURL(metaverse);
            }}
          >
            Edit Metaverse
          </Button>
        </div>
      </Card.Image>
      <Card.Content className={styles.content}>
        <Typography className={styles.name} variant="h2">
          {metaverse.name}
        </Typography>
        <Typography className={styles.owner} variant="subtitle4">
          Created by {getDisplayName(metaverse.creator)}
        </Typography>
        <CopyLinkButton metaverse={metaverse} />
      </Card.Content>
      <PublishMetaverseConfirmModal
        name={metaverse.name}
        open={showPublishModal}
        onClose={() => setShowPublishModal(false)}
        onConfirm={confirmPublishMetaverse}
      />
      <DeleteMetaverseConfirmModal
        name={metaverse.name}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={confirmDeleteMetaverse}
      />
      <UnpublishMetaverseConfirmModal
        name={metaverse.name}
        open={showUnpublishModal}
        onClose={() => setShowUnpublishModal(false)}
        onConfirm={confirmUnpublishMetaverse}
      />
    </Card>
  );
}

interface CopyLinkButtonProps {
  metaverse: MetaverseResponse;
}

function CopyLinkButton({ metaverse }: CopyLinkButtonProps) {
  const { addToast } = useToastMessageContext();

  return (
    <div className={styles.link}>
      <Typography className={styles.label} variant="body2" emphasis="medium">
        {getMetaverseVisitorURL(metaverse)}
      </Typography>
      <FontAwesomeIcon
        icon={faLink}
        className={styles.icon}
        onClick={() => {
          if (!navigator.clipboard) {
            console.error('Clipboard API not supported');
            return;
          }
          navigator.clipboard
            .writeText(getMetaverseVisitorURL(metaverse))
            .then(() => addToast('Link copied!'));
        }}
      />
    </div>
  );
}
