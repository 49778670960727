import { useState } from 'react';
import clsx from 'clsx';
import { UserRejectedRequestError as InjectedConnectorUserRejectedRequestError } from '@web3-react/injected-connector';
import { UserRejectedRequestError as WalletConnectConnectorUserRejectedRequestError } from '@web3-react/walletconnect-connector';

import VisitorModal from '../../VisitorModal/VisitorModal';
import VisitorModalContent from '../../VisitorModalContent/VisitorModalContent';
import CoinbaseIcon from '../../../../../assets/images/coinbase-wallet.png';
import WalletConnectIcon from '../../../../../assets/svg/WalletConnect-icon.svg';
import MetaMaskIcon from '../../../../../assets/svg/metamask-fox.svg';
import WalletLogin from './WalletLogin/WalletLogin';
import { WalletLoginMethod } from '../../../../../authentication/LoginMethod';
import { useAuthModalContext } from '../AuthModalContextProvider';
import styles from './WalletListModal.module.scss';
import Description from '../Description';

interface HumanReadableErrorMessageProps {
  error: any;
}

function HumanReadableErrorMessage({ error }: HumanReadableErrorMessageProps) {
  if (!error) return null;
  console.error(error);
  if (error instanceof InjectedConnectorUserRejectedRequestError ||
      error instanceof WalletConnectConnectorUserRejectedRequestError ||
      error?.message === 'User denied account authorization' ||
      error?.code === 4001) {
    return (
      <div className={styles.error}>
        Sign in request was rejected.<br />Please complete the process to continue to the site.
      </div>
    );
  }
  if (error?.code === -32002) {
    return (
      <div className={styles.error}>
        Please continue logging in through the MetaMask extension.
      </div>
    );
  }
  return (
    <div className={styles.error}>
      Sign in failed.
    </div>
  );
}

export default function WalletListModal() {
  const [error, setError] = useState<Error>();
  const { openWalletModal, setOpenWalletModal } = useAuthModalContext();

  return (
    <VisitorModal
      className={styles.modal}
      open={openWalletModal}
      onClose={() => setOpenWalletModal(false)}
    >
      <VisitorModalContent className={styles.content}>
        <div className={styles.heading}>
          <div className={styles.title}>
            Choose a Wallet
          </div>
        </div>
        <div className={styles.logins}>
          <WalletLogin
            method={WalletLoginMethod.MetaMask}
            src={MetaMaskIcon}
            text="MetaMask"
            onError={setError}
          />
          <WalletLogin
            method={WalletLoginMethod.CoinbaseWallet}
            src={CoinbaseIcon}
            text="Coinbase Wallet"
            onError={setError}
          />
          <WalletLogin
            method={WalletLoginMethod.WalletConnect}
            src={WalletConnectIcon}
            text="WalletConnect"
            onError={setError}
          />
        </div>
        {error && <HumanReadableErrorMessage error={error} />}
        <div className={styles.footer}>
          <Description>
            Don&rsquo;t see a wallet you&rsquo;re looking for? Let&rsquo;s build this together. <a target="_blank" rel="noopener noreferrer" href="mailto:support@pixelcanvas.com">Email us</a> with feedback and we&rsquo;ll see what we can do.
          </Description>
        </div>
      </VisitorModalContent>
    </VisitorModal>
  );
}
