import clsx from 'clsx';

import styles from './InputFields.module.scss';

interface InputFieldsProps {
  className?: string;
  children?: React.ReactNode;
}

export default function InputFields({ className, children }: InputFieldsProps) {
  return (
    <div className={clsx(styles.inputs, className)}>
      {children}
    </div>
  );
}
