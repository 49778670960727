import { useEffect, useState } from 'react';
import { usePixelStreamingContext } from '../contexts/PixelStreamingContext';
import { setCurentGameSessionId } from '../redux/gamelift';
import { useDispatch } from 'react-redux';

export default function useJoinGameSession(
  gameSessionId: string | undefined,
) {
  const dispatch = useDispatch();
  const [previousGameSessionId, setPreviousGameSessionId] = useState('');
  const { emitUIInteraction, isStreaming } = usePixelStreamingContext();
  useEffect(() => {
    if (!emitUIInteraction || !isStreaming || !gameSessionId || gameSessionId === previousGameSessionId) return;
    emitUIInteraction({ type: 'join_session', gameSessionId });
    setPreviousGameSessionId(gameSessionId);
    dispatch(setCurentGameSessionId(gameSessionId));
  }, [emitUIInteraction, gameSessionId, isStreaming, previousGameSessionId]);
}
