import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import landingBg from '../../../../assets/images/landing-bg.png';
import heroImage from '../../../../assets/images/hero-image.png';
import PxFooter from '../../../../components/PxFooter/PxFooter';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import VisitorInput from '../../components/VisitorInput/VisitorInput';
import api from '../../../../services/api';
import styles from './PasswordResetPage.module.scss';
import VisitorModal from '../../components/VisitorModal/VisitorModal';

export function PasswordResetPage() {
  const { user } = usePxAuthContext();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const queryParamsString = window.location.search;
  const queryParams = new URLSearchParams(window.location.search);
  const email = decodeURIComponent(queryParams.get('email') ?? '');
  const token = decodeURIComponent(queryParams.get('token') ?? '');
  const baseUrl = process.env.DEPLOYMENT_URL ?? 'http://localhost:3000';
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Pixel Canvas - Password Recovery';
  }, []);

  async function updatePassword(email: string, newPassword: string) {
    try {
      if (!token) throw new Error('Invalid token');
      if (newPassword != newPasswordConfirm) {
        setPasswordError('Passwords must match');
      } else {
        setPasswordError('');
        const resetResult = await api.post('/api/v2/auth/reset/update', { email, token, password: newPassword });
        setResetSuccess(true);
      }
    } catch (e: any) {
      if (e.response.data.message) {
        setResetError(String(e.response?.data?.message));
      } else {
        setResetError(String(e.message));
      }
    }
  }

  async function onClose() {
    navigate('/landing');
  }

  return (
    <>
      <div className={styles['landing-bg']} style={{ backgroundImage: `url(${landingBg})` }} />
      <div className={styles.page}>
        <div className={styles.container}>
          {
            !resetSuccess && !resetError &&
            <VisitorModal
              className={styles.container}
              closable={false}
              open
              onClose={onClose}
            >
              <div className={styles.title}>
                Set New Password
              </div>
              <div className={styles.details}>
                <VisitorInput
                  title="New Password*"
                  placeholder="Click to type..."
                  value={newPassword}
                  type="password"
                  className={styles.input}
                  onValueChange={(value: string) => setNewPassword(value)}
                />
                <VisitorInput
                  title="Confirm New Password*"
                  placeholder="Click to type..."
                  value={newPasswordConfirm}
                  type="password"
                  className={styles.input}
                  onValueChange={(value: string) => setNewPasswordConfirm(value)}
                />
                { passwordError && <div className={styles.error}> {passwordError} </div> }
              </div>

              <div className={styles.submit} onClick={() => updatePassword(email, newPassword)}>
                Update Password
              </div>
            </VisitorModal>
          }
          {
            resetSuccess &&
            <VisitorModal
              className={styles.container}
              closable
              open
              onClose={onClose}
            >
              <div className={styles.title}>
                Password Changed!
              </div>
            </VisitorModal>
          }
          {
            resetError &&
            <VisitorModal
              className={styles.container}
              closable
              open
              onClose={onClose}
            >
              <div className={styles.title}>
                {resetError}
              </div>
            </VisitorModal>
          }
        </div>
        <img className={styles['hero-image']} src={heroImage} alt="hero" />
      </div>
      <PxFooter />
    </>
  );
}
