import { ColorInputField, ImageUploadField, InputFieldsGroup, SelectInputField, SideNav, SideNavBackButton, SideNavBody, SideNavFooter, SideNavSection, TextInputField, TooltipButton, Typography } from '@pixelcanvas/ui';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import { useToastMessageContext } from 'src/components/ToastMessage/ToastMessageContextProvider';
import VisualSourceInput from 'src/features/Dashboard/components/VisualSourceInput/VisualSourceInput';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import { EnvironmentResponse } from 'src/interfaces/Environment';
import VisualSource from 'src/interfaces/VisualSource';
import { useUpdateMetaverseSpace, useUpdateMetaverseSpaceLogo } from 'src/queries/metaverse';
import { useOrganizationEnvironments } from 'src/queries/organization';
import HovercardTooltip from '../../../../components/TooltipButton/TooltipButton';
import { readURL } from 'src/utils/FileUtil';
import { useOrganizationContext } from '../../../OrganizationPage/context/OrganizationContextProvider';
import PageTitleTooltip from '../../../../../../assets/images/page_title_tooltip.png'

import styles from './Metaverse3DEditorSidePanel.module.scss';

export default function Metaverse3DEditorSidePanel() {
  const { currentOrganization } = useOrganizationContext();
  const { metaverse, ability } = useCurrentMetaverse();
  const space = metaverse?.spaces[0];
  const {
    mutate: updateMetaverseSpace,
    isSuccess: updateMetaverseSpaceSuccess,
    reset: resetMetaverseSpace,
  } = useUpdateMetaverseSpace(metaverse?._id, space?._id);
  const [title, setTitle] = useState(space?.title ?? '');
  const navigate = useNavigate();
  const [uploadId] = useState(uuidv4());

  useEffect(() => setTitle(space?.title ?? ''), [space?.title]);
  const { addToast } = useToastMessageContext();

  const { data: environments } = useOrganizationEnvironments(currentOrganization?._id);
  const canChangeEnvironment = ability.can(Action.Manage, Subject.ChangeEnvironmentOnExistingMetaverse);

  useEffect(() => {
    if (!updateMetaverseSpaceSuccess || !resetMetaverseSpace) return;
    resetMetaverseSpace();
  }, [updateMetaverseSpaceSuccess, resetMetaverseSpace]);

  const {
    mutate: uploadLogo,
  } = useUpdateMetaverseSpaceLogo(metaverse?._id, space?._id);

  if (!space) return null;

  const handleTitleChange = () => {
    if (!title) {
      setTitle(space.title ?? '');
      addToast('Title cannot be empty', { type: 'error' });
      return;
    }
    if (title === space.title) return;
    updateMetaverseSpace({ title });
  };

  const handlePrimaryColorChange = (color: string) => {
    updateMetaverseSpace({ colors: { primary: color } });
  };

  const handleSecondaryColorChange = (color: string) => {
    updateMetaverseSpace({ colors: { secondary: color } });
  };

  const handleUploadLoadingScreen = (s: VisualSource) => {
    updateMetaverseSpace({ background: s });
  };

  const handleEnvironmentSelection = (environment: EnvironmentResponse) => {
    updateMetaverseSpace({ environmentId: environment._id });
  };

  const handleUploadLogo = (fileURL: string) => {
    uploadLogo(fileURL);
  };

  return (
    <SideNav>
      <SideNavBackButton text="Back to Home" onClick={() => navigate('/edit/home')} />
      <SideNavBody>
        <SideNavSection className={styles.titleSection}>
          <Typography variant="h2" emphasis="high">3D Editor</Typography>
        </SideNavSection>
        <SideNavSection>
          <InputFieldsGroup>
            {
              canChangeEnvironment && environments &&
              <SelectInputField
                label="ENVIRONMENT"
                required
                options={environments}
                onChange={handleEnvironmentSelection}
                getOptionLabel={(option) => option.name}
                defaultOption={environments.find((e) => e._id === space.environmentId)}
              />
            }
            <TextInputField
              label="PAGE TITLE"
              required
              maxLength={30}
              value={title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              onBlur={handleTitleChange}
              tooltip={
                <HovercardTooltip
                  title='Page Titles Look Like This'
                  description='This page title appears on the left-hand navigation of your metaverse.'
                  sourceUrl={PageTitleTooltip}
                  placement='right'
                />
              }
            />
            <VisualSourceInput
              label="LOADING SCREEN"
              source={space.background}
              onChange={handleUploadLoadingScreen}
              tooltip={<TooltipButton>Upload an image or video for visitors to see while they load into the 3D space.</TooltipButton>}
            />
          </InputFieldsGroup>
        </SideNavSection>
        <SideNavSection>
          <InputFieldsGroup title="Branding">
            <ImageUploadField
              className={styles.upload}
              label="LOGO"
              id={uploadId}
              src={space?.logo ?? ''}
              accept="image/*"
              color="secondary"
              onChange={(e) => readURL(e.target, handleUploadLogo)}
            >
              Upload
            </ImageUploadField>
            {
              space.colors &&
              <>
                <ColorInputField
                  label="PRIMARY COLOR"
                  color={space.colors.primary ?? ''}
                  onChange={handlePrimaryColorChange}
                />
                <ColorInputField
                  label="SECONDARY COLOR"
                  color={space.colors.secondary ?? ''}
                  onChange={handleSecondaryColorChange}
                />
              </>
            }
          </InputFieldsGroup>
        </SideNavSection>
      </SideNavBody>
      <SideNavFooter>
        <Typography className={styles.autosave} variant="body2">
          The changes on this page are automatically saved
        </Typography>
      </SideNavFooter>
    </SideNav>
  );
}
