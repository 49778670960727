import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeftFromLine, faArrowLeftToLine, faArrowRightFromLine, faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import styles from './CollapseButton.module.scss';

interface CollapseButtonProps {
  className?: string;
  direction?: 'left' | 'right';
  collapsed: boolean;
  onClick: () => void;
}

export default function CollapseButton({
  className,
  direction = 'left',
  collapsed,
  onClick,
}: CollapseButtonProps) {
  const handleClick = () => {
    onClick?.();
  };

  const ArrowIcon = () => {
    if (direction === 'left') {
      return collapsed ? faArrowRightFromLine : faArrowLeftToLine;
    }
    return collapsed ? faArrowLeftFromLine : faArrowRightToLine;
  };

  return (
    <button
      type="button"
      className={clsx(className, styles.button, { [styles.collapsed]: collapsed, [styles.right]: direction === 'right' })}
      onClick={handleClick}
    >
      <span className={clsx(styles.span, { [styles.collapsed]: collapsed })}>
        <FontAwesomeIcon icon={ArrowIcon()} className={styles.icon} />
      </span>
    </button>
  );
}
