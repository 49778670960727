import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from '@pixelcanvas/ui/dist/SideNavButton/SideNavButton.module.scss';

type Props = {
  icon: React.ReactElement;
  label: string;
  to: string;
  end?: boolean;
};

export default function SideNavLink({ icon, label, to, end = false }: Props) {
  return (
    <NavLink
      className={({ isActive }) => clsx(styles.button, { [styles.selected]: isActive })}
      to={to}
      end={end}
    >
      {icon}{label}
    </NavLink>
  );
}
