import { createMongoAbility } from '@casl/ability';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import IUser, { UserDocument } from 'src/interfaces/IUser';

import { dataLayer } from 'src/utils/DataLayerUtil';
import { userKeys } from './users';
import api from '../services/api';

export const queryKey = 'me';

export function useMe(enabled = true) {
  return useQuery(
    [queryKey],
    () => api
      .get<UserDocument>('/api/v2/me')
      .then((res) => res.data)
      .then((user) => ({
        ...user,
        displayName: user?.displayName ?? '',
        email_verified: user?.email_verified ?? false,
      })),
    { enabled },
  );
}

export function useUpdateMe() {
  const queryClient = useQueryClient();
  return useMutation(
    (user: Partial<IUser>) => api
      .patch<UserDocument>('/api/v2/me', user)
      .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(userKeys.item(data._id));
        dataLayer.push({ event: 'user_update' });
      },
    },
  );
}

export function useAbility() {
  return useQuery(
    [queryKey, 'permissions'],
    () => api
      .get('/api/v2/me/permissions')
      .then((res) => createMongoAbility(res.data)),
  );
}
