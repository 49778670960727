import { Button, CheckBoxInput, TextInputField } from '@pixelcanvas/ui';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectMetaverseId } from 'src/redux/metaverse';

import { ICta } from '../../../../interfaces/ICta';
import { IPage } from '../../../../interfaces/IPage';
import { createCta, patchCta } from '../../../../services/ContentService';

import styles from './CtaDetails.module.scss';

export interface CtaDetailsProps {
  page: IPage;
  cta?: ICta;
  onSave?: (cta: ICta, createAnother: boolean) => void;
  onCancel?: () => void;
}

export default function CtaDetails({
  page, cta, onSave, onCancel,
}: CtaDetailsProps) {
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const [localCta, setLocalCta] = useState<any>({ });
  const [createAnother, setCreateAnother] = useState(false);

  useEffect(() => {
    if (cta) {
      setLocalCta(cta);
    }
  }, [cta]);

  function requiredFieldsFilled() {
    if (
      !localCta?.title ||
      !localCta?.link
    ) {
      return false;
    }

    return true;
  }

  async function createOrSaveCta() {
    let ctaResult;
    if (cta) {
      ctaResult = await patchCta(localCta);
    } else {
      ctaResult = await createCta(metaverseId, page._id as string, localCta);
    }

    onSave?.(ctaResult, createAnother);
    if (createAnother) setLocalCta({});
  }

  return (
    <div className={styles['content-details']}>
      <div className={styles['page-title']}>
        {page.title}
      </div>
      <TextInputField
        label="TITLE"
        required
        placeholder="CTA Title"
        value={localCta.title}
        onChange={(e) => setLocalCta({ ...localCta, title: e.target.value })}
      />
      <TextInputField
        label="DESCRIPTION"
        placeholder="CTA Description"
        value={localCta.description}
        onChange={(e) => setLocalCta({ ...localCta, description: e.target.value })}
      />
      <TextInputField
        label="LINK"
        required
        placeholder="CTA Link"
        value={localCta.link}
        onChange={(e) => setLocalCta({ ...localCta, link: e.target.value })}
      />
      <div className={styles['buttons-wrapper']}>
        {!cta && <CheckBoxInput checked={createAnother} onChange={() => setCreateAnother(!createAnother)} label="Create another" />}
        <Button color="tertiary" onClick={() => { if (onCancel) onCancel(); }}>
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={!requiredFieldsFilled()}
          onClick={() => createOrSaveCta()}
        >
          { cta ? 'Save' : 'Create' }
        </Button>
      </div>

    </div>
  );
}
