import { Typography, UploadButton, Placeholder } from '@pixelcanvas/ui';
import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import VisualSource, { VISUAL_SOURCE_TYPE } from 'src/interfaces/VisualSource';
import { v4 as uuidv4 } from 'uuid';

import useCurrentMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import { useUploadImageContent, useUploadVideoContent } from 'src/queries/content';
import { readURL } from 'src/utils/FileUtil';

import styles from './VisualSourceInput.module.scss';

interface VisualSourceInputProps {
  className?: string;
  label: string;
  source?: VisualSource;
  onChange: (s: VisualSource) => void;
  tooltip?: ReactNode;
  accept?: string;
}

export default function VisualSourceInput({
  className,
  label,
  source,
  onChange,
  tooltip,
  accept = 'image/*, .mp4',
}: VisualSourceInputProps) {
  const [id] = useState(uuidv4());

  const { metaverse } = useCurrentMetaverse();

  const {
    mutate: uploadImage,
    isSuccess: uploadImageSuccess,
    reset: resetImageUpload,
    data: uploadImageResponse,
  } = useUploadImageContent(metaverse?._id);

  useEffect(() => {
    if (!uploadImageSuccess || !uploadImageResponse) return;
    resetImageUpload();
    onChange({
      srcType: VISUAL_SOURCE_TYPE.Image,
      src: uploadImageResponse.location,
    });
  }, [uploadImageSuccess, uploadImageResponse]);

  const {
    mutate: uploadVideo,
    isSuccess: uploadVideoSuccess,
    reset: resetVideoUpload,
    data: uploadVideoResponse,
  } = useUploadVideoContent(metaverse?._id);

  useEffect(() => {
    if (!uploadVideoSuccess || !uploadVideoResponse) return;
    resetVideoUpload();
    onChange({
      srcType: VISUAL_SOURCE_TYPE.Video,
      src: uploadVideoResponse.location,
    });
  }, [uploadVideoSuccess, uploadVideoResponse]);

  const handleUpload = (fileURL: string, fileType: string) => {
    if (fileType === 'image') {
      uploadImage(fileURL);
    } else {
      uploadVideo(fileURL);
    }
  };

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.labelContainer}>
        <Typography className={styles.label} variant="h6">
          {label}
        </Typography>
        {tooltip}
      </div>
      <div className={styles.upload}>
        <Preview source={source} />
        <UploadButton
          className={styles.button}
          labelClassName={styles.label}
          id={id}
          accept={accept}
          onChange={(e) => readURL(e.target, handleUpload)}
          color="secondary"
        >
          Upload
        </UploadButton>
      </div>
    </div>
  );
}

interface PreviewProps {
  source?: VisualSource;
}

function Preview({ source }: PreviewProps) {
  if (!source) {
    return <Placeholder size="sm" />;
  }

  switch (source.srcType) {
    case VISUAL_SOURCE_TYPE.Video:
      return <video className={styles.preview} src={source.src} muted autoPlay />;
    default:
      return <img className={styles.preview} src={source.src} alt="" />;
  }
}
