import React, { useContext, useEffect, useState } from 'react';
import * as SocketIOClient from 'socket.io-client';
import { IItemCompleted } from 'src/interfaces/IItemCompleted';

export interface IGameSocketContext {
  socket: SocketIOClient.Socket | undefined;
  points: number;
  itemCompleted: IItemCompleted | undefined;
}

export const GameSocketContext = React.createContext<IGameSocketContext>(
  {
    socket: undefined,
    points: 0,
    itemCompleted: undefined,
  }
);

interface GameSocketContextProps {
  authToken: string | undefined;
  metaverseId: string;
  children: React.ReactNode;
}

const GameSocketContextProvider = ({
  authToken, metaverseId, children,
}: GameSocketContextProps) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket>();
  const [points, setPoints] = useState(0);
  const [itemCompleted, setItemCompleted] = useState<IItemCompleted | undefined>(undefined);

  useEffect(() => {
    const devSocketServer = Boolean(window.env.REACT_APP_DEV_SOCKET_SERVER);
    const newSocket = SocketIOClient.connect('/game', {
      query: { token: authToken, metaverseId },
      transports: !devSocketServer ? ['websocket'] : undefined,
      forceNew: true,
    });
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    }
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.emit('get-player-points', (points: number) => {
      setPoints(points);
    });
  }, [socket]);

  const onItemCompleted = (item: IItemCompleted) => {
    setPoints((p) => p + item.pointsEarned);
    setItemCompleted(item);
  }

  const onError = (error: any) => {
    console.log('error', error);
  }

  useEffect(() => {
    if (socket) {
      socket.on('item-completed', onItemCompleted);
      socket.on('error', onError);
    }
    return () => {
      socket?.off('item-completed', onItemCompleted);
      socket?.off('error', onError);
    };
  }, [socket]);

  return (
    <GameSocketContext.Provider value={{
      socket,
      points,
      itemCompleted,
    }}
    >
      {socket && children}
    </GameSocketContext.Provider>
  );
};

export default GameSocketContextProvider;

export function useGameSocket() {
  const context = useContext(GameSocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketContextProvider');
  }
  return context;
}
