import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

const walletconnect = new WalletConnectConnector({
  rpc: {
    1: `https://mainnet.infura.io/v3/${window.env.REACT_APP_INFURA_ID}`,
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${window.env.REACT_APP_INFURA_ID}`,
  appName: window.env.REACT_APP_COINBASE_APP_NAME,
});

export const connectors = {
  injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink,
};
