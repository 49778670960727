import { Ability } from '@casl/ability';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmationModal, MoreOptions, Table } from '@pixelcanvas/ui';
import {
  createColumnHelper, flexRender, getCoreRowModel,
  getSortedRowModel, SortingState, useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useState } from 'react';

import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import IMetaverseVisitor from 'src/interfaces/IMetaverseVisitor';

import { useMe } from 'src/queries/me';
import { useUpdateMetaverseVisitorAccess } from 'src/queries/metaverse';
import { useOrganizationAbility } from 'src/queries/organization';
import { useOrganizationContext } from '../../../OrganizationPage/context/OrganizationContextProvider';
import { getContactDisplayName } from '../../../../../../utils/DisplayNameUtil';

import styles from './VisitorsTable.module.scss';

export interface VisitorsTableProps {
  data: IMetaverseVisitor[];
  onEditRole: (visitor: IMetaverseVisitor) => void;
  onRemove: (member: IMetaverseVisitor) => void;
}

const VisitorOptions = {
  Ban: 'Ban',
  Unban: 'Unban',
} as const;

type VisitorOption = typeof VisitorOptions[keyof typeof VisitorOptions];

export default function VisitorsTable({
  data,
  onEditRole,
  onRemove,
}: VisitorsTableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<IMetaverseVisitor>();

  const defaultColumns = [
    columnHelper.accessor((row) => row.participant?.displayName, {
      id: 'displayName',
      header: 'NAME',
    }),
    columnHelper.accessor((row) => row.participant?.email, {
      id: 'email',
      header: 'EMAIL',
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'initialVisit',
      header: 'INITIAL VISIT',
      cell: (props) => {
        const value = props.getValue();
        if (!value) return null;
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
      },
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: 'STATUS',
      cell: (props) => toHumanReadableStatus(props.getValue()),
    }),
    // columnHelper.accessor((row) => row.status, {
    //   id: 'status',
    //   header: 'STATUS',
    //   // eslint-disable-next-line react/display-name
    //   cell: (cell: any) => (
    //     // statusMap[cell.row.original.status]
    //   ),
    // }),
    columnHelper.display({
      id: 'actions',
      cell: ActionCell,
      size: 24,
      minSize: 24,
      maxSize: 24,
    }),
  ];

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  const toWidth = (width: number) => {
    if (width < 0) {
      return `${-width}%`;
    }
    if (width > 0) {
      return `${width}px`;
    }
    return 'auto';
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    filterFns: {

    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 0,
      minSize: 0,
      maxSize: 0,
    },
  });

  return (
    <div className={styles.container}>
      <Table.Table className={styles.table}>
        <Table.Thead>
          {
          table.getHeaderGroups().map((headerGroup: any) => (
            <Table.HeaderTr key={headerGroup.id}>
              {
                headerGroup.headers.map((header: any) => (
                  <Table.Th
                    key={header.id}
                    colSpan={header.colSpan}
                    width={toWidth(header.getSize())}
                  >
                    { header.id !== 'actions' &&
                    <button
                      type="button"
                      className={styles.header}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      <SortIcon isSorted={header.column.getIsSorted()} />
                    </button>}
                  </Table.Th>
                ))
              }
            </Table.HeaderTr>
          ))
        }
        </Table.Thead>
        <Table.Tbody>
          {
          table.getRowModel().rows.map((row: any, i: number) => (
            <Table.Tr className={styles.tr} key={row.id} odd={(i + 1) % 2 === 1}>
              {
                row.getVisibleCells().map((cell: any) => (
                  <Table.Td className={styles.td} key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Td>
                ))
              }
            </Table.Tr>
          ))
        }
        </Table.Tbody>
      </Table.Table>
    </div>

  );
}

interface SortIconProps {
  isSorted?: string;
}

function SortIcon({ isSorted }: SortIconProps) {
  return (
    <div className={styles.sortIcon}>
      <FontAwesomeIcon icon={faSortUp} className={clsx(styles.icon, { [styles.enabled]: isSorted === 'asc' })} />
      <FontAwesomeIcon icon={faSortDown} className={clsx(styles.icon, { [styles.enabled]: isSorted === 'desc' })} />
    </div>
  );
}

function toHumanReadableStatus(status: string) {
  switch (status) {
    case 'active':
      return 'Active User';
    case 'banned':
      return 'Banned User';
    default:
      return status;
  }
}

function ActionCell({ row }: { row: any }) {
  const { data: me } = useMe();
  const { metaverse } = useCurrentMetaverse();
  const rowVisitor = row.original as IMetaverseVisitor;
  const { mutate: updateAccess } = useUpdateMetaverseVisitorAccess(metaverse?._id, rowVisitor.userId);
  const [actionOpen, setActionOpen] = useState(false);
  const { currentOrganizationId } = useOrganizationContext();
  const { data: ability = new Ability() } = useOrganizationAbility(currentOrganizationId);
  const [banUserModalOpen, setBanUserModalOpen] = useState(false);
  const [unbanUserModalOpen, setUnbanUserModalOpen] = useState(false);

  const isSelf = rowVisitor.userId && me && rowVisitor.userId === me?._id;
  if (isSelf) return null;

  const canEdit = ability.can(Action.Update, Subject.OrganizationMember);
  if (!canEdit) return null;

  const handleOpen = () => {
    setActionOpen(true);
  };

  const handleClose = () => {
    setActionOpen(false);
  };

  if (isSelf) {
    return null;
  }

  const handleSelect = (option: VisitorOption) => {
    if (option === 'Ban') {
      setBanUserModalOpen(true);
      setActionOpen(false);
    }
    if (option === 'Unban') {
      setUnbanUserModalOpen(true);
      setActionOpen(false);
    }
  };

  const handleBanUser = () => {
    updateAccess('banned');
    setBanUserModalOpen(false);
  };

  const handleUnbanUser = () => {
    updateAccess('active');
    setUnbanUserModalOpen(false);
  };

  return (
    <div className={styles.action}>
      <MoreOptions
        open={actionOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onSelect={handleSelect}
        options={getOptions(rowVisitor.status)}
      />
      {banUserModalOpen &&
      <BanUserConfirmModal
        name={getContactDisplayName(rowVisitor.participant)}
        open={banUserModalOpen}
        onClose={() => setBanUserModalOpen(false)}
        onConfirm={handleBanUser}
      />}
      {unbanUserModalOpen &&
      <UnbanUserConfirmModal
        name={getContactDisplayName(rowVisitor.participant)}
        open={unbanUserModalOpen}
        onClose={() => setUnbanUserModalOpen(false)}
        onConfirm={handleUnbanUser}
      />}
    </div>
  );
}

function getOptions(status: string) {
  switch (status) {
    case 'active':
      return [VisitorOptions.Ban];
    case 'banned':
      return [VisitorOptions.Unban];
    default:
      return [];
  }
}

interface ConfirmModalProps {
  name: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function UnbanUserConfirmModal({
  name,
  open,
  onClose,
  onConfirm,
}: ConfirmModalProps) {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Unban User"
      confirm="Confirm"
    >
      <ConfirmationModal.Description>Are you sure you want to unban {name}?</ConfirmationModal.Description>
    </ConfirmationModal>
  );
}

function BanUserConfirmModal({
  name,
  open,
  onClose,
  onConfirm,
}: ConfirmModalProps) {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Ban User"
      confirm="Confirm"
    >
      <ConfirmationModal.Description>Are you sure you want to ban {name}?</ConfirmationModal.Description>
    </ConfirmationModal>
  );
}
